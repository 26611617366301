import { MultipleFamilyBean } from "./agriPropertyValuationRequestBean";
import { BuildingPropertyValuationRequestBean } from "./buildingPropertyValuationRequestBean";
import { MPenagarpalikaResponsModel } from "./enagarpalikaModel";
import { IgrsEregPropImgDisplayBean } from "./IgrsEregPropImgDisplayBean";
import { IgrsSubClausePropertyMappingBean } from "./IgrsSubClausePropertyMappingBean";
import { IndependentBuildingTypeBean } from "./IndependentBuildingTypeBean";
import { MstDemographyBean } from "./MstDemographyBean";
import { MstPropertyTypeBean } from "./MstPropertyTypeBean";
import { PnrdModel } from "./PnrdModel";
import { PropertyOwner } from "./PropertyOwner";
import { WebGisResponseBeanData } from "./WebGisResponseData";


export class PropertyValuationRequestBean {
	//seller
	isCoOwner?:boolean
	isMoreSellers?:boolean
	isOwnerSameFamily?:boolean
	noOfSellers?:string
	//Buyer
	noOfBuyers?:string
	isBuyerSameFamily?:any
	isMoreBuyers?:boolean
	webGisResponseBeanDataArr?:WebGisResponseBeanData[]=new Array()
	mPenagarpalikaResponseData?:MPenagarpalikaResponsModel
	pNRDResponseData?:PnrdModel;
	isZero?:boolean=false
	id: number;
	areaType: number;
	totalArea: any;
	overAllArea:any
	isHousingBoard?:boolean=false;
	subClassId:any
	isIndustrialDevAuth?:boolean=false
	isSuperSturct?:boolean=false
	buildingAreaType:string="no";
	isResidensial:boolean=false;
	isCommercial:boolean=false;
	isIndustrial:boolean=false;
	isHealth:boolean=false;
	isEduArea:boolean=false;
	isOthers:boolean=false;
	isResidentialCumCommercial:boolean=false;
	residentialPlotArea:number|string;
	commercialPlotArea:number|string;
	industrialPlotArea:number|string;
	healthPlotArea:number|string;
	eduPlotArea:number|string;
	othersPlotArea:number|string;
	residentialCumCommercialPlotArea:number|string;
    //isShowValButton:boolean=false
	sourceId?=new Array()
	floorTypeResidensialList=new Array();
	floorTypeIndustrialList=new Array();
	floorTypeHealthList=new Array();
	floorTypeEducationList=new  Array();
	floorTypeOtherList=new  Array();
	floorTypeCommercialList=new  Array();
	independentBuildBean:IndependentBuildingTypeBean[]=new Array();
	floorResidentialCumCommercialList:any[]= new Array();
	eduType: string="school";
	workGroupId?:number
	subClause: IgrsSubClausePropertyMappingBean[] = new Array();
	demography: MstDemographyBean = new MstDemographyBean();
	propertyType: MstPropertyTypeBean = new MstPropertyTypeBean();
	address?:string
	houseFlatNo?:string;
	latitude?:any
	longitude?:any
	northboundary?:string
	southboundary?:string
	westboundary?:string
	eastboundary?:string
	custodiomDeparementIdArr?=new Array()
	identifationId?:number
	propId:string;
	propertyId:[];
	unit:string;
	usage?: string// Diverted / Un diverted agricultural land
	subType: string; // Irrigated / Un Irrigated agricultural	
	floorType?: number; //building
	constrnType?: string; // building
	constrnTime?: number; // building 
	buildingSubType:string;//building 
	liftFacility: boolean;// building
	conveyanceType?: string=null;//building
	conveyanceArea?: string;//building
	buitupArea?: number;//building multistory 
	commonArea?:number;//building multistory 
	transactOpenTerrace?: boolean;//building
	buildingType: string;//building multistory
	//Is there any construction done on the Agriculture land
	agriculturalWithConstruction?: PropertyValuationRequestBean
	agriculturalWithPlot?: PropertyValuationRequestBean
	//building multistory Commercial property Type
	multistoryCommercialPopType: string="Shop";
	unIrrigatedArea?:string
	irrigatedArea?:string
    singleCropArea?:string
    doubleCropArea?:string
    plotAreaType:string=''
    // isFullyTransact:boolean;
    isPartialTransact:boolean=false;
    isConstOnAgriLand:boolean=false;
	isPlotOnAgriLand:boolean=false
    
   
	partialTransactArea:number|string
    totalSellableArea?:string;
    totalSellableAreaUndiverted?:string='';
    totalSellableAreaDiverted?:string;
	ULPin:string
	sameAdiffSeller?:boolean
	sameAdiffBuyer?:boolean
	independentBuyer?:number
	independentBuyerShare?:number
	independentSeller?:number
	independentSellerShare?:number
	propertySource?:string
	gisSearchTxt:string
	landParcelId?:string[]
	
	propertyOwner:PropertyOwner[]=new Array();
    calculatedValue:number
	considerationAmount?:number
	isAdjacentKhashra?:boolean|boolean
	multipleOwnerFamilyBean?:MultipleFamilyBean[]=new Array();
	multipleBuyerFamilyBean?:MultipleFamilyBean[]=new Array();
	builderShare:number;
	eregId:number;
	valTxnId:number;
	marketValue:number;
	identifactionId:number;
	identifiedAddress:string;
	makerRemarksVal:string;
	checkerRemarksVal:string;
	makerRemarksPropAddress:string;
	checkerRemarksPropAddress:string;
	isImpoundAddProperty:boolean
	isCOSImpoundAddProperty:boolean
	isCOSAdjudicationAddProp:boolean
	fromCheckerModule:boolean
	fromCMSModule:boolean
	fromCMSModuleAdjudication:boolean
	impoundId:number;
	adjudicationPropId:number;
	isImpound?:boolean
	igrsEregPropImgDisplayBean?:IgrsEregPropImgDisplayBean

	constructor(){
		this.considerationAmount=null
		this.id=null
		this.areaType=null
		this.totalArea=null
		this.isHousingBoard=false;
		this.subClassId=null
		this.plotAreaType=''
		this.buildingAreaType="no";
		this.isResidensial=false
		this.isCommercial=false
		this.isIndustrial=false
		this.isHealth=false
		this.isEduArea=false
		this.isOthers=false
		this.isResidentialCumCommercial=false;
		this.propertyId=[]
	
		this.residentialPlotArea=null
		this.commercialPlotArea=null
		this.industrialPlotArea=null
		this.healthPlotArea=null
		this.eduPlotArea=null
		this.othersPlotArea=null
		this.residentialCumCommercialPlotArea=null
		this.houseFlatNo=null
		this.floorTypeResidensialList=new Array();
		this.floorTypeIndustrialList=new Array();
		this.floorTypeHealthList=new Array();
		this.floorTypeEducationList=new  Array();
		this.floorTypeOtherList=new  Array();
		this.floorTypeCommercialList=new  Array();
		this.independentBuildBean=new Array();
		this.floorResidentialCumCommercialList= new Array();
		this.eduType=null
		this.subClause = new Array();
		this.demography = new MstDemographyBean();
		this.propertyType = new MstPropertyTypeBean();
		this.unit="SQMT"
		this.usage='undiverted'
		this.subType=null // Irrigated / Un Irrigated agricultural	
		this.floorType=null //building
		this.constrnType=null // building
		this.constrnTime=null // building 
		this.buildingSubType='Independent Building'//building 
		this.liftFacility// building
		this.conveyanceType//building
		this.conveyanceArea//building
		this.buitupArea//building multistory 
		this.commonArea;//building multistory 
		this.transactOpenTerrace//building
		//this.buildingType='Residential';//building multistory
		//Is there any construction done on the Agriculture land
		//agriculturalWithConstruction: PropertyValuationRequestBean;
		//building multistory Commercial property Type
		this.multistoryCommercialPopType="Shop";
		this.unIrrigatedArea=null
		this.irrigatedArea=null
		this.singleCropArea=null  
		this.doubleCropArea=null
		
		// isFullyTransact:boolean;
		this.isPartialTransact=false;
		this.isConstOnAgriLand=false;
		this.isMoreBuyers=false;
		
	
		this.partialTransactArea=null
		
		this.totalSellableArea=null;
		this.totalSellableAreaUndiverted=null
		this.totalSellableAreaDiverted=null
	//indrajeet
		this.ULPin=null
		this.gisSearchTxt=null
		this.sourceId=null
		this.landParcelId=null
		this.propertyOwner=null
		this.mPenagarpalikaResponseData=null
		this.valTxnId=null;
		this.builderShare =  null;

		this.isImpoundAddProperty= false;
		this.fromCheckerModule = false;
		this.fromCMSModule = false
		this.isImpound=false;
	}

}