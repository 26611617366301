import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DetailReportsService } from 'projects/admin-web/src/app/pages/matrixreport/detail-reports/details-report.service';
import { DistrictModel } from 'projects/citizen-web/src/app/pages/documentSearch/search-document/documentSearchModel';
import { CommonService } from 'projects/common-lib/src/lib/helper-lib/services/common.service';
import { CommonServiceService } from 'projects/common-lib/src/lib/helper-lib/services/common/common-service.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';

@Component({
  selector: 'app-register-documents-details',
  templateUrl: './register-documents-details.component.html',
  styleUrls: ['./register-documents-details.component.scss']
})
export class RegisterDocumentsDetailsComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  dataSourceCount = new MatTableDataSource<any>();
  displayedColumns: string[] = ['position', 'district', 'registeredDocumentCount', 'totalFees', 'AvgFees'];
  //displayedColumns2: string[] = ['position', 'registrationInitation', 'submissionDate', 'docRegiDate', 'Totalfess'];
  displayedColumns2: string[] = ['position', 'district', 'registrationInitation','Applicationdate','ApprovalDate','STAMP_DUTY', 'JANPAD_DUTY', 'MUNICIPAL_DUTY', 'UPKAR_DUTY', 'TOTAL_DUTY', 'REGISTRATION_FEE'];
  maxToDate: string;
  minFromDate: string;
  minToDate: string;
  fromDate: string;
  toDate: string;
  lan: number;
  districtList: DistrictModel[] = [];
  selectedFilter2: string[] = [];
  dataGroupFilters: string[] = [];
  columnNames: string[] = [];
  columnObjectss: any = {};
  filters2: any[] = [];
  param: any = {
    "pageno": "0",
    "iDisplayStart": "0",
    "iDisplayLength": "52",
    "groupBy": "",
    "displayColumn": "",
    "fromDate": "",
    "toDate": ""
  }
  paramDetail = {
    "pageno": "0",
    "iDisplayStart": "0",
    "iDisplayLength": "20",
    "districtId": "",
    "displayColumn": "",
    "fromDate": "",
    "toDate": ""
  }
  selectedDMY: string = "";
  districtId: any = "";
  dataList: any[] = [];
  dataListDetail: any[] = [];
  startDate: string = "";
  endDate: string = "";
  showDetailData: boolean = false
  pageLength: number = 0;
  _pageIndex: number = 0;
  _pageSize: number = 10;
  pageIndex: number = 0;
  pageSize: number = 52;
  iDisplayStart: number = 0;
  previewpageoption: number = 0;
  downloadUrl: string = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  step = 0;
  currentPage: number=0;
  minStartDate: string;
  pageLengthDtls: number=0;
  dataPageTotalList: any;
  dataPerPageLength: number=0;
  totalPages: number=0;
  isLastPage: boolean=false;

  setStep(index: number) {
    this.step = index;
  }
  constructor
    (
      private translateService: TranslateHEService,
      private translateServiceIns: TranslateService,
      private ngxService: NgxUiLoaderService,
      private modalService: NgbModal,
      private translate: TranslateService,
      private common: CommonServiceService, public commonService: CommonService,
    ) {

    try {

      let selectedLanguage = sessionStorage.getItem("selectedLanguage");
      if (sessionStorage.getItem("selectedLanguage") == null || sessionStorage.getItem("selectedLanguage") == '' || sessionStorage.getItem("selectedLanguage") == undefined) {
        this.lan = 1;
        selectedLanguage = 'hi';
      }
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg == 1 ? 'hi' : 'en')
      }
      );
    } catch (err) {
      console.log(err);
    }
    const today = new Date();
    this.maxToDate = today.toISOString().split('T')[0];
    this.minStartDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()).toISOString().split('T')[0];;
  
  }
  ngOnInit(): void {
    this.ngxService.start();
    setTimeout(() => {
      this.ngxService.stop();
    }, 5000);
    this.ngxService.startBackground('do-background-things');
    this.ngxService.stopBackground('do-background-things');
    const currentDate = new Date();
    // this.startDate = currentDate;
    // this.endDate = currentDate;
    const yesterday = new Date();
    const last15Days = new Date();
yesterday.setDate(currentDate.getDate() - 1);
last15Days.setDate(currentDate.getDate() - 15);
// Format date as 'YYYY-MM-DD' (for input fields)
this.startDate = yesterday.toISOString().split('T')[0];
this.endDate = yesterday.toISOString().split('T')[0];
this.fromDate = yesterday.toISOString().split('T')[0];
this.minFromDate=last15Days.toISOString().split('T')[0];
this.toDate = yesterday.toISOString().split('T')[0];
    this.getDistrictList();
    this.onSubmit();
    // this.loadFiltersConfig();
  }
  loadFiltersConfig() {
    this.common.getFiltersConfig().subscribe(
      (config: any) => {
        this.filters2 = config;
      },
      (error: any) => {
        console.error('Error loading filters configuration:', error);
      }
    );
  }
  onSubmit() {
  //  this.resetAll();
    this.showData();
  }
  showData() {
    this.showDetailData = false
    if (this.startDate > this.maxToDate || this.endDate > this.maxToDate || this.fromDate > this.endDate) 
     {
      alert("From date can not be Greater than To date");
      return;
     }
    //this.reset();
    this.param["pageno"] = this.pageIndex+"";
    this.param["iDisplayStart"] = this.iDisplayStart+"";
    this.param["iDisplayLength"] = this.pageSize+"";
    // let p1=(this.selectedDMY != ""?this.selectedDMY+",":"")
    // this.param["groupBy"] = p1+this.dataGroupFilters.join(",");
    if (this.startDate != "") {
      this.param["fromDate"] = this.startDate
    }
    if (this.endDate != "") {
      this.param["toDate"] = this.endDate
    }
    this.param.displayColumn = "REGISTRY_COMPLTED,TOTAL_PAYABLE,EREG_PRINCIPAL_STAMP_DUTY,EREG_UPKAR_DUTY,EREG_JANPAD_DUTY,EREG_MUNICIPAL_DUTY,EREG_EXEMP_REG,EXEMP_STAMP,TOTAL_PAYABLE_REGFEE"
    this.param.groupBy = "DIST";
    this.param.iDisplayLength = this.pageSize+"";
    this.ngxService.start();
    this.common.registeredDocDtls(this.param).subscribe((res: any) => {
      if (res.responseStatus == "true") {
        this.ngxService.stop();
        this.columnNames = res.responseData.column;
        this.dataList = res.responseData.dataList;
        this.dataPageTotalList=res.responseData?.dataTotalList;
        this.dataSourceCount= new MatTableDataSource<any>(this.dataList);
        this.dataPerPageLength=res.responseData?.dataPageTotalList[0]?.CNT;
        res.responseData.headerInfo[0].tableTr.forEach((header: any) => {
          this.columnObjectss[header.headerKey] = header;
        });
        //this.pageLength = res.responseData.rowCounts;
        this.pageLength = 52;
        if (this.totalPages == 0) {
          if (this.pageLength <= this.pageSize)
            this.isLastPage = true;
          else
            this.isLastPage = false;
        }
        this.downloadUrl = res.responseData.downloadUrl
      }
      else
      {
        this.ngxService.stop();
      }
    })
  }

  getPageEvent(evnt: any) {
      this.pageIndex = evnt.pageIndex;
      this.iDisplayStart = evnt.pageIndex * evnt.pageSize
        this.pageSize = evnt.pageSize;
        this.totalPages = Math.ceil(this.pageLength / this.pageSize);
        if (this.pageIndex === this.totalPages - 1)
          this.isLastPage = true;
        else
          this.isLastPage = false;
    this.showData();
  }

  //Detail methods
  registryDetailShowData() {
    if(this.districtId==null || this.districtId==undefined || this.districtId=="" )
    {
      alert("Please Select District");
      return;
    }
    if (this.toDate > this.maxToDate || this.fromDate > this.maxToDate || this.fromDate > this.toDate) 
      {
       alert("From date can not be Greater than To date");
       return;
      }
    this.showDetailData = false
   // this.reset();
    this.paramDetail.pageno= this.currentPage+"";
    this.paramDetail.iDisplayStart= this.currentPage+"";
    this.paramDetail.iDisplayLength= this._pageSize+"";
    this.paramDetail.districtId= this.districtId+"";
    this.paramDetail.fromDate = this.fromDate;
    this.paramDetail.toDate = this.toDate;
    this.ngxService.start();
    this.common.registeredDocDetailData(this.paramDetail).subscribe((res: any) => {
      if (res.responseStatus == "true") {
        this.ngxService.stop();
        this.dataListDetail = res.responseData;
        this.pageLengthDtls =   this.dataListDetail[0]?.totalCount;
        this.dataSource = new MatTableDataSource<any>(this.dataListDetail);
      }
      else
      {
        this.ngxService.stop();
      }
    })
  }
  pageChanged(event: PageEvent) {
    this._pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
       this.registryDetailShowData();
  }

  reset() {
    this.columnNames = [];
    this.dataList = [];
    this.columnObjectss = {};
  }
  resetAll() {
    this.columnNames = [];
    this.dataList = [];
    this.columnObjectss = {};
    this.pageLength = 0;
    this._pageIndex = 0;
    this._pageSize = 20;
    this.pageIndex = 0;
    this.pageSize = 52;
    this.previewpageoption = 20;
    this.iDisplayStart = 0;
  }
  resetDates() {
    this.startDate = "";
    this.endDate = "";
  }
  currentDates() {
    const currentDate = new Date().toISOString().split("T")[0];
    this.startDate = currentDate;
    this.endDate = currentDate;
  }
  stopEvent(event: any): void {
    event.stopPropagation();
  }
  getDistrictList() {
    let type_id = 3;
    let parent_id = 1
    this.commonService.getdemographyByParentAndtype(type_id, parent_id).subscribe(data => {
      if (data.responseStatus == 'true') {
        this.districtList = data.responseData;
      }
    });
  }
  resetButton() {
    this.resetDates();
    this.reset();
  }
  disableKeyboardInput(event: KeyboardEvent): void {
    event.preventDefault();
  }

  getRoundedValue(REGISTRY_COMPLTED:number,TOTAL_PAYABLE_REGFEE:number,EREG_JANPAD_DUTY:number,EREG_MUNICIPAL_DUTY:number,
    EREG_PRINCIPAL_STAMP_DUTY:number,EREG_UPKAR_DUTY:number
  ): number {
    if (REGISTRY_COMPLTED) {
      const  sum=TOTAL_PAYABLE_REGFEE + 
                 EREG_JANPAD_DUTY + 
                 EREG_MUNICIPAL_DUTY + 
                 EREG_UPKAR_DUTY + 
                 EREG_PRINCIPAL_STAMP_DUTY;

      const result = sum / REGISTRY_COMPLTED;

      return Math.round(result); // Rounding to the nearest integer
    }
    return 0; // If REGISTRY_COMPLTED is not defined, return 0 or some fallback value
  }
}
