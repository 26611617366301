import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';

@Component({
  selector: 'app-video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.scss']
})
export class VideoGalleryComponent implements OnInit {
  baseCitizenUrl:string
 lan: number;
  settings: AppSetting;
    baseDepartUrl:string
  constructor(private appSettingsService: AppSettingService,
     private translate: TranslateService,
    private translateServiceIns: TranslateService,
    private translateService: TranslateHEService, ) {
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
      this.baseDepartUrl=settings.baseUrlDepartment.replace('/igrs', '');
      this.baseCitizenUrl=settings.baseUrlCitizen.replace('/igrs', '');
    });

 try {
      console.log("selected Language->" + sessionStorage.getItem("selectedLanguage"));
      let selectedLanguage = sessionStorage.getItem("selectedLanguage");
      if (sessionStorage.getItem("selectedLanguage") == null || sessionStorage.getItem("selectedLanguage") == '' || sessionStorage.getItem("selectedLanguage") == undefined) {
        this.lan = 1;
        selectedLanguage = 'hi';
      }
 
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
       this.lan = msg
       this.translate.use(msg==1?'hi':'en')
      }
        );
    } catch (err) {
      console.log(err);
    }

 

   }

  ngOnInit(): void {
  }
 
}
