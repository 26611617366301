<lib-alertmessage></lib-alertmessage>
    <div class="row" >
        <form #form="ngForm">
    <div class="p-0  col-lg-12">
       
            <div class="card filterArea border-curve1" >
            
                <div class="card-body p-1" >
                <div class="moreFiler">
                    <div class="row">
                      <!--TotalArea-->
                            <div class="col-md-6" *ngIf="!isPartialTransact; else isPartialTransactfalse">
                            <h3 class="text-primary mb-0">{{'property-valuation.Total-Area' | translate}}
                                <span class="rstarcolor">*</span>
                            </h3>
                            <input type="text"
                                placeholder="{{'property-valuation.Total-Area' | translate}}"
                                maxlength="12" [(ngModel)]="agriPropertyValuationRequestBean.totalArea"
                                value="" appPositiveNumbersOnlyDirective
                                id="totalArea"
                                name="totalArea" 
                                #TotalArea="ngModel"
                                pattern="[0-9]+(\.[0-9]{1,5})?" required
                                (ngModelChange)="inputChangeEventValidation()"
                                [appCopyPastValidation]="true"
                                class="form-control form-control-sm">
                            <div *ngIf="totalAreaMsg&&!agriPropertyValuationRequestBean.totalArea"
                                class="custom-invalid-feedback">
                                <div *ngIf="totalAreaMsg">{{'mandatory' | translate}}</div>

                            </div>
                            <small ><span id="error" class="alert-danger"></span></small>
                   

                        </div>
                        <!--OverAll Area-->
                        <ng-template #isPartialTransactfalse>
                        <div class="col-md-6" >
                            <h3 class="text-primary mb-0">{{'property-valuation.Total-Area' | translate}}
                                <span class="rstarcolor">*</span>
                            </h3>
                            <input type="text" readonly
                                placeholder="{{'property-valuation.Total-Area' | translate}}"
                                maxlength="12" [(ngModel)]="agriPropertyValuationRequestBean.overAllArea"
                                [value]="agriPropertyValuationRequestBean.totalArea"
                                appPositiveNumbersOnlyDirective
                                name="overAllArea"
                                [appCopyPastValidation]="true"

                                class="form-control form-control-sm">
                            <div *ngIf="totalAreaMsg&&!agriPropertyValuationRequestBean.overAllArea"
                                class="custom-invalid-feedback">
                                <div *ngIf="totalAreaMsg">{{'mandatory' | translate}}</div>

                            </div>

                        </div>
                        </ng-template>
                        <!--Unit -->
                        <div class="col-md-6" >
                            <h3 class="text-primary mb-0">{{'property-valuation.Unit' | translate}}</h3>
                            <select class="form-control form-control-sm"
                                [(ngModel)]="agriPropertyValuationRequestBean.unit" name="unit">
                                <option *ngFor="let unt of uomList;trackBy:trackByFn" [value]="unt">
                                  <div *ngIf="lan==0">{{convertedUOM(unt)}}</div>
                                  <div *ngIf="lan==1">{{convertedUOM(unt)}}</div>
                                </option>
                            </select>
                        </div>
                        <!--Is Partial checkbox-->
                        <ng-container class="col-md-12">
                                <div class="checkbox-form">
                                    <input type="checkbox" name="isPartialTransact"
                                    [(ngModel)]="agriPropertyValuationRequestBean.isPartialTransact"
                                    (change)="onCheckDataPush($event);inputChangeEventValidation()"
                                    value=true 
                                    placeholder="{{'property-valuation.partial-Area-received' | translate}}">
                                      <label class="form-check-label"  >
                                        {{'property-valuation.partial-Area-received' |translate}}
                                      </label>
                                    </div>
                            
                           
                            <div class="col-md-12 mt-2 mb-2">
                                <label class="w-100">{{'valuationproperty.TransactArea' | translate}}</label>
                             <ng-container *ngIf="agriPropertyValuationRequestBean.webGisResponseBeanDataArr.length>0 else block">
                                <div class="col-md-6 pl-0">
                                    <input type="text" name="partialTransactArea" 
                                    [(ngModel)]="agriPropertyValuationRequestBean.partialTransactArea"
                                    appPositiveNumbersOnlyDirective
                                    (change)="partialTransactAreaAlwaysLessThanTotalArea($event);inputChangeEventValidation()"
                                    [disabled]="true"
                                    class="w-auto form-control"
                                    placeholder="{{'valuationproperty.TransactArea' | translate}}"
                                   >
                                 </div>
                             </ng-container>
                          <ng-template #block>
                           
                            <div class="col-md-6 pl-0">
                                <input type="text" name="partialTransactArea" 
                                [(ngModel)]="agriPropertyValuationRequestBean.partialTransactArea"
                                appPositiveNumbersOnlyDirective
                                (change)="partialTransactAreaAlwaysLessThanTotalArea($event);inputChangeEventValidation()"
                                [disabled]="!isPartialTransact"
                                class="w-auto form-control"
                                placeholder="{{'valuationproperty.TransactArea' | translate}}"
                               >

                             </div>
                          </ng-template>
                            </div>
                        </ng-container>
                             <!--WEB GIS ARRAY-->
                <div class="row m-0 col-md-12 mb-2" *ngFor="let webGisResponseBeanData of agriPropertyValuationRequestBean.webGisResponseBeanDataArr;let i = index">

                            <div class="col-md-4 p-0">
                                <div class="border p-1">
                                <label class="text-secondary">{{'valuationproperty.LandParcel' | translate}}</label>
                                <br>
                                <input type="text" id="webland-{{i}}" name="landid-{{i}}" [disabled]="true"
                                name="landParcelId"
                                [(ngModel)]="webGisResponseBeanData.landParcelId"
                                class="w-auto form-control"
                                 >
                            </div>
                            </div>
                            <div class="col-md-4 pr-0">
                                <div class="border p-1">
                                <label class="text-secondary">{{'valuationproperty.TotalArea' | translate}}</label>
                                <br>
                                <input type="text" id="webtotalArea-{{i}}" name="totallandArea-{{i}}" [disabled]="true"
                                name="webGisResponseBeanData.totalArea"
                                [(ngModel)]="webGisResponseBeanData.totalArea"
                                class="w-auto form-control"
                                 >
                            </div>
                            </div>
                            <div class="col-md-4 pr-0">
                                <div class="border p-1">
                                <label class="text-secondary">{{'valuationproperty.TransactArea' | translate}}</label>
                                <br> 
                                <input type="text" id="webtotalLandTranArea-{{i}}"  name="landtranArea-{{i}}"
                                [(ngModel)]="webGisResponseBeanData.partialTransactArea"
                                appPositiveNumbersOnlyDirective
                               
                                [disabled]="!agriPropertyValuationRequestBean.isPartialTransact"
                                (keyup)="addPatrialTypeArea(agriPropertyValuationRequestBean.partialTransactArea)"
                                class="w-auto form-control"
                                 >
                                 
                            </div>
                            <div *ngIf="totalAreaMsg&&!webGisResponseBeanData.overAllArea"
                            class="custom-invalid-feedback">
                            <div *ngIf="totalAreaMsg">{{'mandatory' | translate}}</div>
                        </div>
                            </div>
                        </div>
                    </div>

                 
                </div>
                <div class="moreFiler" *ngIf="agriPropertyValuationRequestBean.webGisResponseBeanDataArr.length==1">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="inlineRadioOptionsP"
                        #myCheckbox
                        [disabled]="agriPropertyValuationRequestBean.isConstOnAgriLand"
                        [(ngModel)]="agriPropertyValuationRequestBean.isPlotOnAgriLand"
                        [(value)]="agriPropertyValuationRequestBean.isPlotOnAgriLand"
                        (ngModelChange)="agriPropertyValuationRequestBean.isPlotOnAgriLand=isThereCostructionPlotEvent($event)"
                            id="isThereCostructionP">
                        <label class="form-check-label"
                            for="isThereCostruction">{{'property-valuation.Is-there-any-plot-done-on-the-Agriculture-land?'
                            | translate}}</label>
                    </div>
                </div>


                <ng-container class="moreFiler pr-0" >
                    <h3 class="text-primary mb-0 px-2">{{'property-valuation.Usage' | translate}} <span
                        class="rstarcolor">*</span></h3>
                    <div class="px-2">
                        <div class="  bdrone d-block">
                            <!--Usage Type diverted-->
                                <div class="col-md-12 threegrid p-0">
                                <label class="btn btn-sm p-0 py-1 px-1 mr-1"
                                    [ngClass]="{ 'active': agriPropertyValuationRequestBean.usage=='diverted'}">
                                    <input type="radio" name="diverted"
                                        [(ngModel)]="agriPropertyValuationRequestBean.usage"
                                        (ngModelChange)="usageEvent($event)"
                                        value="diverted" id="diverted" class="h-75">
                                    {{'property-valuation.Diverted'
                                    | translate}}
                                </label>
                            <!--Usage Type undiverted-->
                                <label class="btn btn-sm p-0 py-1 px-1 mr-1"
                                    [ngClass]="{ 'active': agriPropertyValuationRequestBean.usage=='undiverted'}">
                                    <input type="radio" name="undiverted" #undiverted
                                        [(ngModel)]="agriPropertyValuationRequestBean.usage"
                                        (ngModelChange)="usageEvent($event)"
                                        value="undiverted" id="undiverted" class="h-75">
                                    {{'property-valuation.Un-Diverted' | translate}}
                                </label>
                                 <!-- Usage Type both -->

                                <label class="btn btn-sm p-0 py-1 px-1"
                                    [ngClass]="{ 'active': agriPropertyValuationRequestBean.usage=='both'}">
                                    <input type="radio" name="both" #both
                                        [(ngModel)]="agriPropertyValuationRequestBean.usage"
                                        (ngModelChange)="usageEvent($event);inputChangeEventValidation()"
                                        value="both" class="h-75" id="both" checked>
                                    {{'property-valuation.Both' | translate}}
                                </label>
                            </div>
                            <div class="col-md-12 row p-0">
                                <div class="col-md-6">
                                    <span *ngIf="agriPropertyValuationRequestBean.usage=='both'">
                                        {{'property-valuation.Diverted' | translate}}</span>
                                    <input type="text"
                                        *ngIf="agriPropertyValuationRequestBean.usage=='both'"
                                        appPositiveNumbersOnlyDirective
                                        [appCopyPastValidation]="true"
                                        name="totalSellableAreaDiverted"
                                        [(ngModel)]="agriPropertyValuationRequestBean.totalSellableAreaDiverted"
                                        (ngModelChange)="inputChangeEventValidation()"
                                        placeholder="{{'property-valuation.Diverted'| translate}} {{'e-stamp.Area'| translate}}"
                                        maxlength="12" class="form-control form-control-sm">
                                </div>

                                <div class="col-md-6 pr-0">
                                    <span *ngIf="agriPropertyValuationRequestBean.usage=='both'"
                                       >{{'property-valuation.Un-Diverted'
                                        | translate}}</span>
                                    <input type="text"
                                        *ngIf="agriPropertyValuationRequestBean.usage=='both'"
                                        appPositiveNumbersOnlyDirective
                                        [appCopyPastValidation]="true"
                                        name="totalSellableAreaUndiverted"
                                        [(ngModel)]="agriPropertyValuationRequestBean.totalSellableAreaUndiverted"
                                        [disabled]="agriPropertyValuationRequestBean.totalSellableAreaUndiverted!=''"
                                        (ngModelChange)="inputChangeEventValidation()"
                                        placeholder="{{'property-valuation.Un-Diverted' | translate}} {{'e-stamp.Area'| translate}}"
                                        maxlength="12" class="form-control form-control-sm">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>


                <div class="moreFiler" *ngIf="agriPropertyValuationRequestBean.usage=='both' || agriPropertyValuationRequestBean.usage=='undiverted'">
                    <h3 class="text-primary mb-0">{{'property-valuation.Sub-Type' | translate}} <span
                        class="rstarcolor">*</span></h3>
                    <div class="">
                        <small  role="alert" [ngClass]="{'alert-info col-12 p-1 d-block mb-1': !isActive, 'alert-danger col-12 p-1 d-block mb-1': isActive}"  >
                            {{'property-valuation.Subtype-Area-must' | translate}}|
                            {{lan==0?errorObj?.responseMessageEn:errorObj?.responseMessageHi}}
                        </small>
                       
                        <div class="">
                            <div class="col-md-12">
                                <div class="row no-radio-box">
                                    <label
                                        class="btn btn-sm col-md-7 p-0 bdrnone pb-3 d-block text-left m-0">
                                        <input type="radio" name="subtype" value="irrigated"
                                            id="irrigated" class="form-control form-control-sm">
                                        {{'property-valuation.Irrigated' | translate}}

                                    </label>
                                    <input type="text" appPositiveNumbersOnlyDirective
                                        [appCopyPastValidation]="true" id="irrigatedArea"
                                         name="irrigatedArea"
                                        [(ngModel)]="agriPropertyValuationRequestBean.irrigatedArea"
                                        (input)="unDivertedSubAreaEvent('irrigatedArea')"
                                        [value]="agriPropertyValuationRequestBean.irrigatedArea"
                                        placeholder="{{'property-valuation.Irrigated-area' | translate}}"
                                        maxlength="12"
                                        class="form-control form-control-sm col-md-5">
                                </div>

                                <div class="row no-radio-box">
                                    <label
                                        class="btn btn-sm col-md-7 p-0 bdrnone pb-3 d-block text-left m-0">
                                        <input type="radio" name="subtype" value="Unirrigated1"
                                            id="Unirrigated" class="form-control form-control-sm">
                                        {{'property-valuation.Unirrigated-single' | translate}}
                                    </label>
                                    <input type="text" appPositiveNumbersOnlyDirective
                                        [appCopyPastValidation]="true"
                                        name="singleCropArea"
                                        appTwoDecimalPlaces 
                                        [(ngModel)]="agriPropertyValuationRequestBean.singleCropArea"
                                        id="unirrigatedSArea"
                                        (input)="unDivertedSubAreaEvent('unirrigatedSArea')"
                                        placeholder=" {{'property-valuation.Single-crop-Area' | translate}}"
                                        maxlength="12"
                                        class="form-control form-control-sm col-md-5">
                                </div>
                                <div class="row no-radio-box">
                                    <label
                                        class="btn btn-sm col-md-7 p-0 bdrnone pb-3 d-block text-left m-0">
                                        <input type="radio" name="subtype" value="Unirrigated2"
                                            id="Unirrigated2" class="form-control form-control-sm ">

                                        {{'property-valuation.Unirrigated-double-crop' | translate}}

                                    </label>
                                    <input type="text" appPositiveNumbersOnlyDirective
                                        [appCopyPastValidation]="true" id="unirrigatedDArea"
                                        name="doubleCropArea"
                                        [(ngModel)]="agriPropertyValuationRequestBean.doubleCropArea"
                                        (input)="unDivertedSubAreaEvent('unirrigatedDArea')"
                                        placeholder="{{'property-valuation.Double-crop-Area' | translate}}"
                                        maxlength="12"
                                        class="form-control form-control-sm  col-md-5">
                                </div>
                            </div>


                            <!-- </div> -->
                        </div>
                    </div>

                </div>


                <div class="moreFiler"
                    *ngIf="agriPropertyValuationRequestBean.usage=='both' || agriPropertyValuationRequestBean.usage=='diverted'">
                    <h3 class="text-primary mb-0">{{'property-valuation.Usage-Type' | translate}} <span
                        class="rstarcolor">*</span></h3>

                    <div class="">
                        <small  role="alert" [ngClass]="{'alert-info col-12 p-1 d-block mb-1': !isActive, 'alert-danger col-12 p-1 d-block mb-1': isActive}"  >
                            {{'property-valuation.Subtype-Area-must' | translate}}|
                            {{lan==0?errorObj?.responseMessageEn:errorObj?.responseMessageHi}}
                        </small>
                        <div class="btn-group btn-group-toggle d-block" data-toggle="buttons">
                            <fieldset>
                                
                                <input type="text"
                                    name="residentialCumCommercialPlotArea"
                                    [(ngModel)]="agriPropertyValuationRequestBean.residentialCumCommercialPlotArea"
                                    *ngIf="usageType=='RCumCom'"
                                    appPositiveNumbersOnlyDirective [appCopyPastValidation]="true"
                                    maxlength="12" id="residentialCumCommercialPlotArea"
                                    placeholder="{{'property-valuation.Residential-Cum-Commercial-area' | translate}}"
                                    (ngModelChange)="inputChangeEventValidation();usageTypeEventPlot_Agri('residentialCumCommercialPlotArea')"
                                    class="form-control form-control-sm">
                                <div *ngIf="usageType!='RCumCom'" class="row no-check-box">
                                    <div class="col-md-7">
                                        <label class="btn btn-sm rounded-0 mb-1 px-0">
                                            <input type="checkbox" name="usage" value="residensial"
                                                id="residensial"> {{'property-valuation.Residential'
                                            | translate}}
                                        </label>
                                    </div>
                                    <div class="col-md-5 p-0 pr-3">
                                        <input type="text" appPositiveNumbersOnlyDirective
                                            [appCopyPastValidation]="true" maxlength="12"
                                            id="residentialPlotArea"
                                            name="residentialPlotArea"
                                            (keyup)="usageTypeEventPlot_Agri('residentialPlotArea')"
                                            [(ngModel)]="agriPropertyValuationRequestBean.residentialPlotArea"
                                            placeholder="{{'property-valuation.Residential-area' | translate}}"
                                            class="form-control form-control-sm">
                                    </div>
                                    <div class="col-md-7">
                                        <label class="btn btn-sm rounded-0 mb-1 px-0">
                                            <input type="checkbox" name="commercial"
                                                value="commercial"
                                                id="commercial">{{'property-valuation.Commercial' |
                                            translate}}
                                        </label>
                                    </div>
                                    <div class="col-md-5 p-0 pr-3">
                                        <input type="text" appPositiveNumbersOnlyDirective
                                            [appCopyPastValidation]="true" maxlength="12"
                                            id="commercialPlotArea"
                                            name="commercialPlotArea"
                                            (keyup)="usageTypeEventPlot_Agri('commercialPlotArea')"
                                            [(ngModel)]="agriPropertyValuationRequestBean.commercialPlotArea"
                                            placeholder="{{'property-valuation.Commercial-area' | translate}}"
                                            class="form-control form-control-sm">

                                    </div>

                                    <div class="col-md-7">
                                        <label class="btn btn-sm rounded-0 mb-1 px-0">
                                            <input type="checkbox" name="industrial"
                                                value="industrial" id="industrial">
                                            {{'property-valuation.Industrial' | translate}}
                                        </label>
                                    </div>
                                    <div class="col-md-5 p-0 pr-3">
                                        <input type="text" appPositiveNumbersOnlyDirective
                                            [appCopyPastValidation]="true" maxlength="12"
                                            id="industrialPlotArea"
                                            name="industrialPlotArea"
                                            (keyup)="usageTypeEventPlot_Agri('industrialPlotArea')"
                                            [(ngModel)]="agriPropertyValuationRequestBean.industrialPlotArea"
                                            placeholder="{{'property-valuation.Industrial-area' | translate}}"
                                            class="form-control form-control-sm">
                                    </div>
                                    <div class="col-md-7">
                                        <label class="btn btn-sm rounded-0 mb-1 px-0">
                                            <input type="checkbox" name="health" value="health"
                                                id="health">
                                            {{'property-valuation.Health' | translate}}
                                        </label>
                                    </div>
                                    <div class="col-md-5 p-0 pr-3">
                                        <input type="text" appPositiveNumbersOnlyDirective
                                            [appCopyPastValidation]="true" maxlength="12"
                                            id="healthPlotArea"
                                            name="healthPlotArea"
                                            (keyup)="usageTypeEventPlot_Agri('healthPlotArea')"
                                            [(ngModel)]="agriPropertyValuationRequestBean.healthPlotArea"
                                            placeholder="{{'property-valuation.Health-area' | translate}}"
                                            class="form-control form-control-sm">
                                    </div>
                                    <div class="col-md-7">
                                        <label class="btn btn-sm rounded-0 mb-1 px-0">
                                            <input type="checkbox" name="education"
                                                value="education" id="education">
                                            {{'property-valuation.Education' | translate}}
                                        </label>
                                    </div>
                                    <div class="col-md-5 p-0 pr-3">
                                        <input type="text" appPositiveNumbersOnlyDirective
                                            [appCopyPastValidation]="true" maxlength="12"
                                            id="eduPlotArea"
                                            name="eduPlotArea"
                                            (keyup)="usageTypeEventPlot_Agri('eduPlotArea')"
                                            [(ngModel)]="agriPropertyValuationRequestBean.eduPlotArea"
                                            placeholder="{{'property-valuation.Education-area' | translate}}"
                                            class="form-control form-control-sm">
                                    </div>
                                </div>

                            </fieldset>


                        </div>
                    </div>
                </div>




                <fieldset class="m-2">
    
                    
                </fieldset>

                <!-- Added new section as new wireframe -->
                <fieldset class="legend-section">
                    <div class="innner-box1">
                        <fieldset class="legend-section">
                            <legend class="sidebar__widget-title">
                                {{'property-valuation.ownerSeller' | translate}} <sup class="text-danger">*</sup>
                            </legend>
                
                
                        <div class="moreFiler m-2 px-1">
                
                            <div class="row mt-2">
                                <label class="col-md-7  bdrnone d-block text-left m-0">
                                    {{'property-valuation.moreThenOne' | translate}}
                                </label>
                                    <div class="pl-2 mr-2 ml-3">
                                        <input class="form-check-input" [(ngModel)]="agriPropertyValuationRequestBean.isMoreSellers" 
                                         type="radio" name="inlineRadioOptions1" id="do_all_SellersYes" [value]=true >
                                        <label class="form-check-label"  for="do_all_SellersYes">{{'property-valuation.Yes' | translate}}</label>
                                      </div>
                                      <div class="ml-4">
                                        <input class="form-check-input"   [(ngModel)]="agriPropertyValuationRequestBean.isMoreSellers" type="radio"
                                         name="inlineRadioOptions1" id="do_all_SellersNo" [value]=false>
                                        <label class="form-check-label" for="do_all_SellersNo">{{'property-valuation.No' | translate}}</label>
                                      </div>
                                 </div>
                            <div class="row mt-2" *ngIf="agriPropertyValuationRequestBean.isMoreSellers">
                                <label class="col-md-7  bdrnone d-block text-left m-0">
                                    {{'property-valuation.do_all_Sellers' | translate}}
                                </label>
                                    <div class="pl-2 mr-2 ml-3">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions2"    [(ngModel)]="agriPropertyValuationRequestBean.isCoOwner"
                                         (ngModelChange)="IsSameFamilly()"  id="do_all_SellersYoption2" [value]=true >
                                        <label class="form-check-label" for="do_all_SellersYoption2">{{'property-valuation.Yes' | translate}}</label>
                                      </div>
                                      <div class="ml-4">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions2"    [(ngModel)]="agriPropertyValuationRequestBean.isCoOwner" 
                                         id="do_all_SellersYoption3"  [value]=false>
                                        <label class="form-check-label" for="do_all_SellersYoption3">{{'property-valuation.No' | translate}}</label>
                                      </div>
                            </div>
                            <div class="row mt-2" *ngIf="agriPropertyValuationRequestBean.isMoreSellers&&!agriPropertyValuationRequestBean.isCoOwner">
                                <label class="col-md-7  bdrnone d-block text-left m-0">
                                    {{'property-valuation.belongToTheFamily' | translate}}
                                </label>
                                    <div class="pl-2 mr-2 ml-3">
                                        <input   class="form-check-input" type="radio" name="inlineRadioOptions3"  
                                         [(ngModel)]="agriPropertyValuationRequestBean.isOwnerSameFamily" 
                                         id="belongToFamilyYes" [value]=true >
                                        <label class="form-check-label" for="belongToFamilyYes">{{'property-valuation.Yes' | translate}}</label>
                                      </div>
                                      <div class="ml-4">
                                        <input   class="form-check-input" type="radio" name="inlineRadioOptions3" 
                                         [(ngModel)]="agriPropertyValuationRequestBean.isOwnerSameFamily"
                                          id="belongToFamilyNo" [value]=false>
                                        <label class="form-check-label" for="belongToFamilyNo">{{'property-valuation.No' | translate}}</label>
                                      </div>
                            </div>
                            <div class="row mt-2" *ngIf="agriPropertyValuationRequestBean.isMoreSellers">
                
                
                                <label class="col-md-7  bdrnone d-block text-left m-0">                                    
                                    {{'valuationproperty.EnterNumberofSellersOwners' | translate}}
                                </label>
                                <div class="pl-1 mr-0">
                                <input  [(ngModel)]="agriPropertyValuationRequestBean.noOfSellers"
                                  appPositiveNumbersOnlyDirective [appCopyPastValidation]="true"
                                  placeholder=" {{'property-valuation.Total-buyers' |
                                translate}}" 
                                 class="form-control form-control-sm col-md-5" 
                                 type="text" name="noOfSellers" id="noOfSellers" >
                
                                </div>
                            </div>
                      
                
                </div>
                
                
                </fieldset>
                        <!-- Buyer Section -->
                
                        <fieldset class="legend-section">
                            <legend class="sidebar__widget-title">
                                {{'property-valuation.buyer' | translate}}  <sup class="text-danger">*</sup>
                            </legend>
                        <div class="moreFiler m-2 mt-2 px-1">
                            <div class="row mt-2">
                                <label class="col-md-7  bdrnone d-block text-left m-0">
                                    {{'property-valuation.oneBuyer' | translate}}
                                </label>
                                    <div class="pl-2 mr-2 ml-3">
                                        <input class="form-check-input"  type="radio" [(ngModel)]="agriPropertyValuationRequestBean.isMoreBuyers"  
                                         name="isMoreBuyers" id="oneBuyer" [value]=true >
                                        <label class="form-check-label" for="oneBuyer">{{'property-valuation.Yes' | translate}}</label>
                                      </div>
                                      <div class="ml-4">
                                        <input class="form-check-input"  type="radio" [(ngModel)]="agriPropertyValuationRequestBean.isMoreBuyers"  
                                        name="isMoreBuyers" id="oneBuyer2" [value]=false>
                                        <label class="form-check-label" for="oneBuyer2">{{'property-valuation.No' | translate}}</label>
                                      </div>
                            </div>
                            <div class="row mt-2" *ngIf="agriPropertyValuationRequestBean.isMoreBuyers">
                                <label class="col-md-7  bdrnone d-block text-left m-0">
                                    {{'property-valuation.familyBelong' | translate}}
                                </label>
                                    <div class="pl-2 mr-2 ml-3">
                                        <input   class="form-check-input" type="radio" [(ngModel)]="agriPropertyValuationRequestBean.isBuyerSameFamily" 
                                        name="isBuyerSameFamily" id="do_all_BuyerYes" [value]=true>
                                        <label class="form-check-label" for="do_all_BuyerYes">{{'property-valuation.Yes' | translate}}</label>
                                      </div>
                                      <div class="ml-4">
                                        <input   class="form-check-input" type="radio" [(ngModel)]="agriPropertyValuationRequestBean.isBuyerSameFamily" 
                                        name="isBuyerSameFamily" id="do_all_BuyerNo" [value]=false>
                                        <label class="form-check-label" for="do_all_SellersNo">{{'property-valuation.No' | translate}}</label>
                                      </div>
                
                            </div>
                            <div class="row mt-2" *ngIf="agriPropertyValuationRequestBean.isMoreBuyers">
                                <label class="col-md-7  bdrnone d-block text-left m-0">
                                    
                                    {{'valuationproperty.EnterNumberofBuyers' | translate}}
                                </label>
                                <div class="pl-1 mr-0 ">
                                        <input   class="form-control form-control-sm col-md-5"
                                        [(ngModel)]="agriPropertyValuationRequestBean.noOfBuyers"
                                     
                                        appPositiveNumbersOnlyDirective [appCopyPastValidation]="true"
                                        placeholder=" {{'property-valuation.Total-buyers' |
                                        translate}}" type="text" name="noOfBuyers" id="noOfBuyers" >
                
                                </div>
                                 </div>
                
                
            
                
                
                         </div>
                      </fieldset>
                
                        </div>
                </fieldset>

                


                <div class="moreFiler subclauses m-2 px-1">
                   
                    <h3 class="text-primary mb-0">{{'property-valuation.Subclause' | translate}}</h3>
                   
                    <div *ngFor="let sbc of subClauseMasterBean">
                       
                        <div class="form-check" >
                            <div>

                                <label class="form-check-label" for="subclause{{sbc.id}}">
                                   
                                    <input class="form-check-input" type="checkbox" name="subclause"
                                    [(ngModel)]="sbc.checked" [disabled]="sbc.disabled" [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="addSubclauseEvent($event,sbc)" value="{{sbc}}"
                                        id="subclause{{sbc.id}}">
                                    {{lan==0?sbc.descEn:sbc.descHi}}
                                </label>
                            </div>
                        </div>
                        <div class="form-check"
                            *ngIf="mainAreaType==sbc.propertyTypeId&&sbc.id==7">
                            <label class="form-check-label" for="subclause{{sbc.id}}">
                                <input class="form-check-input" type="checkbox" name="subclause"
                                [(ngModel)]="sbc.checked" [disabled]="sbc.disabled" [ngModelOptions]="{standalone: true}"
                                    (ngModelChange)="addSubclauseEvent($event,sbc)" value="{{sbc}}"
                                    id="subclause{{sbc.id}}">
                                {{lan==0?sbc.descEn:sbc.descHi}}
                            </label>
                        </div>
                    </div>

                </div>

                <!--This For Building cons done on agriculture land-->
                <div class="moreFiler" >

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="inlineRadioOptionsB"
                        #myCheckboxB
                        [disabled]="agriPropertyValuationRequestBean.isPlotOnAgriLand"
                        [(ngModel)]="agriPropertyValuationRequestBean.isConstOnAgriLand"
                        [(value)]="agriPropertyValuationRequestBean.isConstOnAgriLand"
                        (ngModelChange)="agriPropertyValuationRequestBean.isConstOnAgriLand=isThereCostructionBuildingEvent($event)"
                        
                            id="isThereCostructionB">
                        <label class="form-check-label"
                            for="isThereCostruction">{{'property-valuation.Is-there-any-construction-done-on-the-Agriculture-land?'
                            | translate}}</label>
                    </div>
                </div>
                 <!--This For plot is there done on agriculture land-->
               

                <!-- [disabled]="showMarketValueBtn" -->
                <button mat-flat-button class="btn btn-primary m-2"
                    (click)="showMarketValueEvent(form)">{{'property-valuation.Show-Market-Value' |
                    translate}}</button>


            </div>
        </div>
    </div>

    <ng-template #holdsuccessfullyMessage let-c="close" let-d="dismiss">
        <h2 matDialogTitle class="Consenterdialog-title">
            {{'ereg-property-details.familyCoOwner' | translate}}
        </h2>
        <!--<button type="button" class="colsebtn" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>-->
      <div class="modal-body pt-0 pb-0">
            <fieldset class="legend-section">
                <div class="form-group">
                    <div class="row">
                        <label class="col-md-8 mb-0">
                            {{'ereg-property-details.co-owner' | translate}}
                        </label>
                        <div class="col-md-auto">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="isCoOwner" id="isCoOwner1" [(ngModel)]="agriPropertyValuationRequestBean.isCoOwner"  [value]=true
                                (ngModelChange)="isCoowner=false"  >
                                <label class="form-check-label" for="isCoOwner1">{{"yes" | translate}}</label>
                            </div>
                            <div class="form-check form-check-inline" >
                                <input class="form-check-input" type="radio" name="isCoOwner" id="isCoOwner2" [(ngModel)]="agriPropertyValuationRequestBean.isCoOwner" value=false
                                (ngModelChange)="agriPropertyValuationRequestBean.isCoOwner=false;isCoowner=true;agriPropertyValuationRequestBean.isOwnerSameFamily=false">
                                <label class="form-check-label" for="isCoOwner2">{{"No" | translate}}</label>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="form-group" *ngIf="isCoowner">
                    <div class="row">
                        <label class="col-md-8 mb-0">
                           {{'ereg-property-details.samefamil' | translate}}
                        </label>
                        <div class="col-md-auto " >
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="isOwnerSameFamily" id="isOwnerSameFamily1" [(ngModel)]="agriPropertyValuationRequestBean.isOwnerSameFamily" [value]=true>
                                <label class="form-check-label" for="isOwnerSameFamily1">{{"yes" | translate}}</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="isOwnerSameFamily" id="isOwnerSameFamily2"  [(ngModel)]="agriPropertyValuationRequestBean.isOwnerSameFamily"  [value]=false
                                (ngModelChange)="agriPropertyValuationRequestBean.isOwnerSameFamily=false" >
                                <label class="form-check-label" for="isOwnerSameFamily2">{{"No" | translate}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>

            <div class="form-group text-right mb-2 mt-2">
                <button class="btn btn-primary"  (click)="showAlert()">{{"eRegistry-declaration.okbtn" |
                    translate}}</button>
            </div>
        </div>
    </ng-template>

    <ng-template #Adjacent let-c="close" let-d="dismiss">
        <h2 matDialogTitle class="Consenterdialog-title"></h2>
        <button type="button" class="colsebtn" aria-label="Close" (click)="d('Cross click')" style="top: 0">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body pb-0">

                <h3 class="text-left mb-0 ">

                    <b>
                        {{'valuationproperty.Ifyouwanttoaddmultiple' | translate}}
                        </b>
         </h3>
         <ul class="mt-3 listnote">
            <li>{{'valuationproperty.AllKhasralandusetype' | translate}}.</li>
            <li> {{'valuationproperty.Eitherall' | translate}}
            </li>
         </ul>

           <div class="form-group text-right">
                <button class="btn btn-primary"  (click)="closeAlert()" >
                    {{ "eRegistry-declaration.okbtn" | translate }}
                </button>
            </div>


        </div>
    </ng-template>

    <ngx-ui-loader [loaderId]="'AgriCulture-01'"></ngx-ui-loader>
</form>
</div>
<!-- agriculture with building case -->
