
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { CitizenAuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/citizen-authentication.service';
import { CommonService } from 'projects/common-lib/src/lib/helper-lib/services/common.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { Department } from 'projects/common-lib/src/lib/models/api.masterdata.model/department';

import { CitizenUserProfileDetails, Demography } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model';
import { IgrsEregPartyDetailsBean } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import Swal from 'sweetalert2';
import { UserManagementServive } from '../../service/UserManagementServive';
import { CountryModel } from 'projects/common-lib/src/lib/models/CountryModel';
import { Citizen } from 'projects/common-lib/src/lib/models';
import { EstampPartyModel } from 'projects/common-lib/src/lib/models/addEstamp.model/add-estamp.model';

@Component({
  selector: 'app-demographic-details',
  templateUrl: './demographic-details.component.html',
  styleUrls: ['./demographic-details.component.scss']
})
export class DemographicDetailsComponent implements OnInit {

  panelOpenState = true;
  passhide = true;
  passhidec = true;

  sameAddressChecked: boolean = false;
  addisabled: boolean = false;

  detailCheckedVal: boolean = false;
  consentChecked: boolean = false;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  @ViewChild('profilePersonalDetailHtml', { static: true }) form: NgForm;

  @Input() citizenType: any;
  @Input() isIndian: any;
  @Input() mobileNo: any;
  @Input() kycSource: string;
  @Input() kycObject: any;
  @Input() juristicPersonProfileModel: any;
  @Input() isProtestReq: any;

  @Output() demographicDetailsSaveOut = new EventEmitter<any>();

  dobmaxdate: any = new Date();

  usernametaken = false;
  usernameavail = false;
  emailtaken = false;
  emailavail = false;

  stateId: any;
  stateIdPre: any;
  districtId: any;
  districtIdPre: any;

  countryIdPre: any;

  age: any = 0;

  confirmPass: any;

  districts: Demography[] = [];
  districtForAadharEKYC: Demography[] = [];
  districtsPre: Demography[] = [];
  states: Demography[] = [];
  country: CountryModel[] = []

  userProfileModel: CitizenUserProfileDetails = new CitizenUserProfileDetails();
  userProfileModelAadharInfo: CitizenUserProfileDetails = new CitizenUserProfileDetails();

  profileImage: any;
  profileImageError: any;
  flag: boolean = true;
  lan: number = 0
  relativeNameDisable: boolean = false;
  firstNameDisable: boolean = false;
  middleNameDisable: boolean = false;
  lastNameDisable: boolean = false;
  dobDisable: boolean = false;
  genderDisable: boolean = false;
  addressLine1Disable: boolean = false;
  addressLine2Disable: boolean = false;
  addressLine1HiDisable: boolean = false;
  addressLine2HiDisable: boolean = false;
  cityDisable: boolean = false;
  cityHiDisable: boolean = false;
  postalCodeDisable: boolean = false;
  invalidAltMob: boolean = false;
  invalidAltEmail: boolean = false;
  invalidUserNameCheck: boolean = false;
  invalidPasswordCheck: boolean = false;
  isCountryDisable: boolean = false
  isSubmitted: boolean = false;

  minToDate: any;
  maxToDate: any = moment(new Date()).format('YYYY-MM-DD');

  departmentList: Department[] = new Array();
  citizenModel: Citizen = {};
  convertedDate: string;

  constructor(private common: CommonService
    , private citizenAuthService: CitizenAuthenticationService
    , private ngxService: NgxUiLoaderService
    , private translateService: TranslateHEService
    , private translate: TranslateService
    , config: NgbModalConfig
    , private modalService: NgbModal
    , @Inject(MAT_DIALOG_DATA) public eRegPartyModel: IgrsEregPartyDetailsBean
    , private dialogRef: MatDialogRef<any>
    , public datepipe: DatePipe
    , private userService: UserManagementServive) {
    try {
      console.log("selected Language->" + sessionStorage.getItem("selectedLanguage"));
      let selectedLanguage = sessionStorage.getItem("selectedLanguage");
      if (sessionStorage.getItem("selectedLanguage") == null || sessionStorage.getItem("selectedLanguage") == '' || sessionStorage.getItem("selectedLanguage") == undefined) {
        this.lan = 0;
        selectedLanguage = 'en';
      }

      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg == 1 ? 'hi' : 'en')
      })
    } catch (err) {
      //console.log(err);
    }
    config.backdrop = 'static';
    config.keyboard = false;
    this.userProfileModel.title = 'Mr.';
  }

  open(content: any) {
    this.modalService.open(content, { size: 'xl' });
  }


  ngOnInit(): void {
    //console.log( this.form)
    //console.log("Demographic-details_eRegPartyModel : ", this.eRegPartyModel)
    this.userProfileModel.caste = '';
    this.userProfileModel.gender = '';
    this.userProfileModel.relativeRelation = '';
    this.userProfileModel.state = '';
    this.userProfileModel.statePre = '';
    this.userProfileModel.country = '';
    //this.userProfileModel.countryName = '';
    this.countryIdPre = ""
    this.stateId = "";
    this.stateIdPre = "";
    this.districtId = "";
    this.districtIdPre = "";

    this.getCountries();
    this.getStates();
    // this.getDistricts();
    this.userProfileModel.citizenType = this.citizenType;
    this.userProfileModel.isIndianResident = this.isIndian;
    //this.userProfileModel.kycType = this.kycSource;


    if (this.isIndian == 1) {
      this.userProfileModel.contactNo = this.mobileNo;
    }

    if (this.isIndian == 0) {
      this.isCountryDisable = true
    }

    this.fromDateChange("1900");
    this.toDateChange(this.dobmaxdate);

    // let dateInput = "2019";
    // let dateArray = dateInput.split("-");
    // let newDate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;

    // if (dateArray[2] == undefined && dateArray[1] == undefined)
    //   console.log(dateArray[0]);
  }

  ngAfterViewInIt() {
    if (this.kycSource == 'Aadhaar') {
      // console.log('kycobj:', this.kycObject);
      this.getUserAadhaarDetails(this.kycObject.transactionId)
      this.userProfileModel.kycType = "Aadhaar";
      if(undefined==this.userProfileModel.kycTxnLogNo || ''==this.userProfileModel.kycTxnLogNo)
        this.userProfileModel.kycTxnLogNo = this.kycObject.aadharTxnLogNo;
      this.userProfileModel.kycUniqueToken = this.kycObject.residentDetails.uidToken;
      this.userProfileModelAadharInfo.kycTxnLogNo = this.kycObject.aadharTxnLogNo;
      this.userProfileModelAadharInfo.kycUniqueToken = this.kycObject.residentDetails.uidToken;

      let names: any = this.kycObject.residentDetails.resName.split(" ");
      // console.log(names);
      if (names.length == 1) {
        this.userProfileModel.firstNameEn = names[0]?.trim();
        this.userProfileModelAadharInfo.firstNameEn = names[0]?.trim();
      } else if (names.length == 2) {
        this.userProfileModel.firstNameEn = names[0]?.trim();
        this.userProfileModel.lastNameEn = names[1]?.trim() ? names[1]?.trim() : "";
        this.userProfileModelAadharInfo.firstNameEn = names[0]?.trim();
        this.userProfileModelAadharInfo.lastNameEn = names[1] ? names[1]?.trim() : "";
      } else if (names.length == 3) {
        this.userProfileModel.firstNameEn = names[0]?.trim();
        this.userProfileModel.middleNameEn = names[1] ? names[1]?.trim() : "";
        this.userProfileModel.lastNameEn = names[2]?.trim() ? names[2]?.trim() : "";
        this.userProfileModelAadharInfo.firstNameEn = names[0]?.trim();
        this.userProfileModelAadharInfo.middleNameEn = names[1] ? names[1]?.trim() : "";
        this.userProfileModelAadharInfo.lastNameEn = names[2]?.trim() ? names[2]?.trim() : "";
      } else if (names.length == 4) {
        this.userProfileModel.firstNameEn = names[0]?.trim() + names[1]?.trim() + names[2]?.trim();
        this.userProfileModel.lastNameEn = names[3]?.trim() ? names[3]?.trim() : "";
        this.userProfileModelAadharInfo.firstNameEn = names[0]?.trim() + names[1]?.trim() + names[2]?.trim();
        this.userProfileModelAadharInfo.lastNameEn = names[3]?.trim() ? names[3]?.trim() : "";
      } else {
        this.userProfileModel.firstNameEn = this.kycObject.residentDetails.resName?.trim();
        this.userProfileModelAadharInfo.firstNameEn = this.kycObject.residentDetails.resName?.trim();
      }

      if (this.userProfileModel.firstNameEn != null && this.userProfileModel.firstNameEn != '') {
        this.convertHindi(this.userProfileModel.firstNameEn, 'fNameHi');
        this.firstNameDisable = true;
      }
      if (this.userProfileModel.middleNameEn != null && this.userProfileModel.middleNameEn != '') {
        this.convertHindi(this.userProfileModel.middleNameEn, 'mNameHi');
        this.middleNameDisable = true;
      }
      if (this.userProfileModel.lastNameEn != null && this.userProfileModel.lastNameEn != '') {
        this.convertHindi(this.userProfileModel.lastNameEn, 'lNameHi');
        this.lastNameDisable = true;
      }

      if (this.kycObject.residentDetails != null && this.kycObject.residentDetails.dob != null && this.kycObject.residentDetails.dob != '') {
        this.userProfileModel.dob = this.kycObject.residentDetails.dob;
        this.userProfileModelAadharInfo.dob = this.kycObject.residentDetails.dob;

        //Convert date formate DD-MM-YYYY to YYYY-MM-DD
        //this.convertedDate = this.convertDateFormat(this.userProfileModel.dob);
        //this.userProfileModel.dob = this.convertedDate;

        this.validateDOB(this.userProfileModel.dob);
        //this.dobDisable = true;

        let dateInput = this.userProfileModel.dob;
        let dateArray = dateInput.split("-");
        //let newDate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;

        if (dateArray[2] == undefined && dateArray[1] == undefined) {
          console.log(dateArray[0]);
          // let newDate = `${dateArray[0]}-01-01`;
          // this.userProfileModel.dob = newDate;
          // this.fromDateChange(dateArray[0])
          // this.toDateChange(dateArray[0])
          this.fromDateChange(this.userProfileModel.dob)
          this.toDateChange(this.userProfileModel.dob)
        } else {
          this.dobDisable = true;
        }

        // if (moment("2010-02-29", "YYYY-MM-DD").isValid()) {
        //   this.fromDateChange(this.userProfileModel.dob)
        //   console.log('valid date formate')
        // } else {
        //   console.log('not valid date formate')
        // }
      }

      if (this.kycObject.residentDetails != null && this.kycObject.residentDetails.gender != null && this.kycObject.residentDetails.gender != '') {
        this.userProfileModel.gender = this.kycObject.residentDetails.gender;
        this.userProfileModelAadharInfo.gender = this.kycObject.residentDetails.gender;
        this.genderDisable = true;
      }

      if (this.kycObject.residentDetails != null && this.kycObject.residentDetails.guardianName != null && this.kycObject.residentDetails.guardianName != '') {
        this.userProfileModel.relativeNameEn = this.kycObject.residentDetails.guardianName;
        this.userProfileModelAadharInfo.relativeNameEn = this.kycObject.residentDetails.guardianName;
        this.convertHindi(this.userProfileModel.relativeNameEn, 'relativeNameHi');
        this.relativeNameDisable = true;
      }

      this.userProfileModel.email = this.kycObject.residentDetails.emailId != null ? this.kycObject.residentDetails.emailId : '';
      this.userProfileModel.altEmail = this.userProfileModel.email;
      this.userProfileModel.altContactNo = this.kycObject.residentDetails.mobile != null ? this.kycObject.residentDetails.mobile : '';

      this.userProfileModel.addressLine1 = (this.kycObject.residentDetails.building ? (this.kycObject.residentDetails.building + ', ') : '')
        + (this.kycObject.residentDetails.street ? (this.kycObject.residentDetails.street + ', ') : '')
        + (this.kycObject.residentDetails.landmark ? (this.kycObject.residentDetails.landmark + ', ') : '')
        + (this.kycObject.residentDetails.locality ? this.kycObject.residentDetails.locality : '');
      this.convertHindi(this.userProfileModel.addressLine1, 'addressLine1Hi');
      if (this.userProfileModel.addressLine1 == '') {
        this.addressLine1Disable = false;
      } else {
        this.addressLine1Disable = true;
      }
      this.userProfileModelAadharInfo.addressLine1 = this.userProfileModel.addressLine1;

      this.userProfileModel.addressLine2 = (this.kycObject.residentDetails.po ? (this.kycObject.residentDetails.po + ', ') : '')
        + (this.kycObject.residentDetails.subdistrict != null ? this.kycObject.residentDetails.subdistrict : '');
      this.convertHindi(this.userProfileModel.addressLine2, 'addressLine2Hi');
      if (this.userProfileModel.addressLine2 == '') {
        this.addressLine2Disable = false;
      } else {
        this.addressLine2Disable = true;
      }
      this.userProfileModelAadharInfo.addressLine2 = this.userProfileModel.addressLine2;

      //this.userProfileModel.city = this.kycObject.residentDetails.district != null ? this.kycObject.residentDetails.district : '';
      this.userProfileModel.city = this.kycObject.residentDetails.vtc != null ? this.kycObject.residentDetails.vtc : '';
      this.convertHindi(this.userProfileModel.city, 'cityHi');
      if (this.userProfileModel.city == '') {
        this.cityDisable = false;
      } else {
        this.cityDisable = true;
      }
      this.userProfileModelAadharInfo.city = this.userProfileModel.city;

      this.userProfileModel.postalCode = this.kycObject.residentDetails.pincode != null ? this.kycObject.residentDetails.pincode : '';
      if (this.userProfileModel.postalCode == '') {
        this.postalCodeDisable = false;
      } else {
        this.postalCodeDisable = true;
      }
      this.userProfileModelAadharInfo.postalCode = this.userProfileModel.postalCode;
      this.userProfileModel.profilePic = this.kycObject.residentDetails.residentPhoto;

      //this.saveUserProfilePicByAadhar(this.userProfileModel.id, this.kycObject.residentDetails.residentPhoto);

      //set state
      for (let i = 0; i < this.states.length; i++) {//Hoshangabad
        if (this.states[i].demographyNameEn.toLowerCase() == this.kycObject?.residentDetails?.state?.toLowerCase() || this.states[i].demographyNameHi?.trim() == this.kycObject?.residentDetails?.state?.trim()) {
          //this.setStateForAadharEKYC(this.states[i].id)
          this.stateId = this.states[i].id
          this.setState("Aadhaar");

          break;
        }
      }
    }
    if (this.kycSource == 'Pan') {
      this.userProfileModel.kycType = "Pan";
      this.userProfileModel.kycTxnLogNo = this.kycObject.txno;
      this.userProfileModel.kycUniqueToken = this.kycObject.pan;
      this.userProfileModel.firstNameEn = this.kycObject.firstName?.trim();
      this.userProfileModel.lastNameEn = this.kycObject.lastName?.trim();
      this.userProfileModel.middleNameEn = this.kycObject.middleName;
      this.userProfileModel.dob = this.kycObject.dobStatusDesc;
      this.validateDOB(this.userProfileModel.dob);

      this.userProfileModelAadharInfo.kycTxnLogNo = this.kycObject.txno;
      this.userProfileModelAadharInfo.kycUniqueToken = this.kycObject.pan;
      this.userProfileModelAadharInfo.firstNameEn = this.kycObject.firstName?.trim();
      this.userProfileModelAadharInfo.lastNameEn = this.kycObject.lastName?.trim();
      this.userProfileModelAadharInfo.middleNameEn = this.kycObject.middleName;

      if (this.userProfileModel.firstNameEn != null && this.userProfileModel.firstNameEn != '') {
        this.convertHindi(this.userProfileModel.firstNameEn, 'fNameHi');
        this.firstNameDisable = true;
      }
      if (this.userProfileModel.lastNameEn != null && this.userProfileModel.lastNameEn != '') {
        this.convertHindi(this.userProfileModel.lastNameEn, 'lNameHi');
        this.lastNameDisable = true;
      }
      if (this.userProfileModel.middleNameEn != null && this.userProfileModel.middleNameEn != '') {
        this.convertHindi(this.userProfileModel.middleNameEn, 'mNameHi');
        this.middleNameDisable = true;
      }

    }
    if (this.kycSource == 'passport') {
      this.userProfileModel.kycTxnLogNo = this.kycObject.txno;
      this.userProfileModel.kycUniqueToken = this.kycObject.residentDetails.uidToken;
      this.userProfileModel.firstNameEn = this.kycObject.firstName?.trim();
      //this.userProfileModel.kycUniqueID = this.kycObject.firstName;
      if (this.userProfileModel.firstNameEn != null && this.userProfileModel.firstNameEn != '') {
        this.convertHindi(this.userProfileModel.firstNameEn, 'fNameHi');
        this.firstNameDisable = true;
      }
    }



    if (this.citizenType == 2) {
      this.userProfileModel.juristicPerson = this.juristicPersonProfileModel;
      this.userProfileModel.email = this.juristicPersonProfileModel.companyEmail;
    }


    if (this.userProfileModel.citizenType == 1 && this.eRegPartyModel?.applicantType == '2')
      this.allDepartmentList();

    // console.log('Demographic Detail Page---------------------');
    // console.log('userProfile', this.userProfileModel);
    // console.log('kycObject', this.kycObject);
    // console.log('kycSource', this.kycSource);
    // console.log('isIndian', this.isIndian);
    // console.log('citizenType', this.citizenType);
    // console.log('mobileNo', this.mobileNo);
    // console.log('juristicPersonProfileModel', this.juristicPersonProfileModel);
  }

  fromDateChange(date: any) {
    this.minToDate = moment(0).year(date).month(0).format('YYYY-MM-DD');
    console.log('this.minToDate', this.minToDate);
  }

  toDateChange(date: any) {
    this.maxToDate = moment(0).year(date).month(11).add(30, 'days').format('YYYY-MM-DD');
    console.log('this.maxToDate', this.maxToDate);
  }

  saveUserProfilePicByAadhar(userId: number, profileBase64: any) {
    if (profileBase64 != null || profileBase64 != '' || profileBase64 != undefined) {
      this.citizenModel.userId = userId;
      this.citizenModel.profileBase64 = profileBase64;
      this.citizenModel.userType = 'c';
      this.citizenAuthService.saveUserProfilePic(this.citizenModel).subscribe(res => {
        if (res && res.responseStatus == 'true') {
          this.userProfileModel.profilePic = res.responseData;

          if (this.eRegPartyModel?.partyTypeNameEn != undefined || this.eRegPartyModel?.partyTypeNameEn != null || this.eRegPartyModel?.poaType != undefined
            || this.eRegPartyModel?.poaType != null || this.eRegPartyModel?.partyBean?.poaType != undefined || this.eRegPartyModel?.partyBean?.poaType != null
            || this.eRegPartyModel?.transactingThroughPOA == true || this.isProtestReq != undefined || this.isProtestReq != null || this.eRegPartyModel?.isAadhaarKycPerformedByEregCompl) {

            // this.userProfileModel.id = res.responseData.citizenId;
            // this.eRegPartyModel.userProfileModel = this.userProfileModel
            // this.eRegPartyModel.juristicPersonProfileModel = res.responseData; //this.userProfileModel.juristicPerson
            // this.eRegPartyModel.userId = res.responseData.citizenId;

            this.eRegPartyModel.userProfileModel.profilePic = res.responseData;

            this.dialogRef.close({ data: this.eRegPartyModel });
          }

        } else {
          this.alertmessage.errorAlertMessage(res);
        }
      });
    }
  }

  // getUserProfilePic(userName: string, profileBase64: any) {
  //   if (profileBase64 != null || profileBase64 != '' || profileBase64 != undefined) {
  //     this.citizenModel.userName = userName;
  //     this.citizenModel.profileBase64 = profileBase64;
  //     this.citizenAuthService.getUserProfilePic(this.citizenModel).subscribe(res => {
  //       // console.log(res);
  //       if (res && res.responseStatus == 'true') {
  //         this.userProfileModel.profilePic = 'PROFILE_IMG_' + this.citizenModel.userName +".jpg";
  //         console.log("profilePic Name: ", this.userProfileModel.profilePic);
  //       } else {
  //         this.alertmessage.errorAlertMessage(res);
  //       }
  //     });
  //   }
  // }

  allDepartmentList() {

    this.ngxService.start();
    this.userService.get_AllDepartmet_FirstLevel("SUB_DEP").subscribe((result) => {
      if (result) {
        this.departmentList = result.responseData;
        console.log('this.departmentList', this.departmentList);
      }
      this.ngxService.stop();
    });
  }

  // setStateForAadharEKYC(stateId: any) {
  //   this.stateId = stateId
  //   this.userProfileModel.state = stateId;
  //   let demoNameEn = this.states.find(t => t.id == stateId)?.demographyNameEn;
  //   let demoNameHi = this.states.find(t => t.id == stateId)?.demographyNameHi;
  //   this.userProfileModel.stateName = (demoNameEn ? demoNameEn : "") + " - " + (demoNameHi ? demoNameHi : "");
  //   this.getDistrictForAadharEKYC(this.userProfileModel.state);

  //   this.userProfileModel.demography = new Demography;
  //   //this.districtId = "";
  // }

  // getDistrictForAadharEKYC(parent: any) {
  //   this.ngxService.start();
  //   this.common.getDistrictsWithParent(parent).subscribe(res => {
  //     this.ngxService.stop();
  //     if (res.responseStatus) {
  //       this.districtForAadharEKYC = res.responseData;


  //       //set district
  //       for (let i = 0; i < this.districtForAadharEKYC.length; i++) {
  //         if (this.districtForAadharEKYC[i].demographyName.toLowerCase() == this.kycObject.residentDetails.district.toLowerCase()) {
  //           this.setDistrictForAadharEKYC(this.districtForAadharEKYC[i].id)

  //           break;
  //         }
  //       }
  //     } else {
  //       this.alertmessage.errorAlertMessage(res);
  //     }
  //   });
  // }


  // setDistrictForAadharEKYC(districtId: any) {
  //   let demo: Demography = new Demography();
  //   this.districtId = districtId;
  //   demo.id = districtId;
  //   let demoName = this.districtForAadharEKYC.find(t => t.id == districtId)?.demographyName;
  //   let demoNameEn = this.districtForAadharEKYC.find(t => t.id == districtId)?.demographyNameEn;
  //   let demoNameHi = this.districtForAadharEKYC.find(t => t.id == districtId)?.demographyNameHi;

  //   demo.demographyName = demoName ? demoName : "";
  //   demo.demographyNameEn = demoNameEn ? demoNameEn : "";
  //   demo.demographyNameHi = demoNameHi ? demoNameHi : "";
  //   this.userProfileModel.demography = demo;
  // }

  getCountries() {
    this.common.getCountries().subscribe(res => {
      this.country = res.responseData;
      console.log("countries : ", this.country)
    });
  }

  onChangeCountry(event: any) {
    let countryId = event
    this.userProfileModel.country = countryId

    let demoNameEn = this.country.find(t => t.id == countryId)?.countryNameEn;
    let demoNameHi = this.country.find(t => t.id == countryId)?.countryNameHi;
    this.userProfileModel.countryNameEn = (demoNameEn ? demoNameEn : "") + " - " + (demoNameHi ? demoNameHi : "")

    if (countryId == null) {
      countryId = "80"
    }
    if (countryId == "80") {
      this.isCountryDisable = false

    } else {
      this.isCountryDisable = true
    }


    console.log("Country :", countryId)
  }

  getStates() {
    // this.common.getStates().subscribe(res=>{
    //  this.states = [{ "id": 1, "nameEn": "Madhya Pradesh", "nameHi": "मध्य प्रदेश", "name": "Madhya Pradesh"}];
    // });

    this.common.getStates().subscribe(res => {
      this.states = res.responseData;
      console.log("states : ", this.states)

      this.ngAfterViewInIt();
    });
  }

  getDistricts(state: string, from: any) {
    this.common.getDistrictsWithParent(state).subscribe(res => {
      this.districts = res.responseData;

      if (from === "Aadhaar") {
        //set district
        for (let i = 0; i < this.districts.length; i++) {
          if (this.districts[i].demographyNameEn?.toLowerCase() == this.kycObject?.residentDetails?.district?.toLowerCase() || this.districts[i].demographyNameHi?.trim() == this.kycObject?.residentDetails?.district?.trim()) {
            //this.setDistrictForAadharEKYC(this.districtForAadharEKYC[i].id)
            this.districtId = this.districts[i].id
            this.setDistrict(from);

            break;
          } else if (this.kycObject?.residentDetails?.district?.toLowerCase() == 'hoshangabad' || this.kycObject?.residentDetails?.district?.trim() == 'होशंगाबाद') {
            this.districtId = 48
            this.setDistrict(from);

            break;
          }
        }
      }
    });
  }

  getDistrictsPre(state: string) {
    this.common.getDistrictsWithParent(state).subscribe(res => {
      this.districtsPre = res.responseData;
    });
  }


  setState(from: any) {
    if (from === "Aadhaar") {
      this.userProfileModelAadharInfo.state = this.stateId;
      this.userProfileModel.state = this.stateId;
    } else {
      this.userProfileModel.state = this.stateId;
    }

    let demoNameEn = this.states.find(t => t.id == this.stateId)?.demographyNameEn;
    let demoNameHi = this.states.find(t => t.id == this.stateId)?.demographyNameHi;
    this.userProfileModel.stateName = (demoNameEn ? demoNameEn : "") + " - " + (demoNameHi ? demoNameHi : "");
    this.getDistricts(this.userProfileModel.state, from);

    this.userProfileModel.demography = new Demography;
    this.districtId = "";
  }
  setStatePre() {
    this.userProfileModel.statePre = this.stateIdPre;
    let demoNameEn = this.states.find(t => t.id == this.stateIdPre)?.demographyNameEn;
    let demoNameHi = this.states.find(t => t.id == this.stateIdPre)?.demographyNameHi;
    this.userProfileModel.stateNamePre = (demoNameEn ? demoNameEn : "") + " - " + (demoNameHi ? demoNameHi : "");
    this.getDistrictsPre(this.userProfileModel.statePre);

    this.userProfileModel.demographyPre = new Demography;
    this.districtIdPre = "";
  }
  setDistrict(from: any) {
    let demo: Demography = new Demography();
    demo.id = this.districtId;
    let demoNameEn = this.districts.find(t => t.id == this.districtId)?.demographyNameEn;
    let demoNameHi = this.districts.find(t => t.id == this.districtId)?.demographyNameHi;

    demo.demographyNameEn = demoNameEn ? demoNameEn : "";
    demo.demographyNameHi = demoNameHi ? demoNameHi : "";

    if (from === "Aadhaar") {
      this.userProfileModelAadharInfo.demography = demo;
      this.userProfileModel.demography = demo;
    } else {
      this.userProfileModel.demography = demo;
    }
  }
  setDistrictPre() {
    let demo: Demography = new Demography();
    demo.id = this.districtIdPre;
    let demoNameEn = this.districtsPre.find(t => t.id == this.districtIdPre)?.demographyNameEn;
    let demoNameHi = this.districtsPre.find(t => t.id == this.districtIdPre)?.demographyNameHi;

    demo.demographyNameEn = demoNameEn ? demoNameEn : "";
    demo.demographyNameHi = demoNameHi ? demoNameHi : "";
    this.userProfileModel.demographyPre = demo;
  }

  checkForSameAddress() {
    if (this.sameAddressChecked) {
      this.setSameAddress();
    }
  }

  sameAddress() {
    // console.log('this.sameAddress: '+this.sameAddressChecked);
    if (this.sameAddressChecked) {
      this.sameAddressChecked = false;
      this.addisabled = false;
      this.setDefaultAddress();
    } else {
      this.sameAddressChecked = true;
      this.addisabled = true;
      this.setSameAddress();
    }
  }

  setDefaultAddress() {
    this.stateIdPre = "";
    this.districtIdPre = "";
    this.userProfileModel.statePre = "";
    this.userProfileModel.demographyPre = new Demography();
    this.userProfileModel.addressLine1Pre = "";
    this.userProfileModel.addressLine2Pre = "";
    this.userProfileModel.addressLine1PreHi = "";
    this.userProfileModel.addressLine2PreHi = "";
    this.userProfileModel.cityPre = "";
    this.userProfileModel.cityPreHi = "";
    this.userProfileModel.postalCodePre = "";
  }

  setSameAddress() {
    this.stateIdPre = this.stateId;
    this.setStatePre();
    this.districtIdPre = this.districtId;
    this.setDistrictPre();
    this.userProfileModel.statePre = this.userProfileModel.state;
    this.userProfileModel.demographyPre = this.userProfileModel.demography;
    this.userProfileModel.addressLine1Pre = this.userProfileModel.addressLine1;
    this.userProfileModel.addressLine2Pre = this.userProfileModel.addressLine2;
    this.userProfileModel.addressLine1PreHi = this.userProfileModel.addressLine1Hi;
    this.userProfileModel.addressLine2PreHi = this.userProfileModel.addressLine2Hi;
    this.userProfileModel.cityPre = this.userProfileModel.city;
    this.userProfileModel.cityPreHi = this.userProfileModel.cityHi;
    this.userProfileModel.postalCodePre = this.userProfileModel.postalCode;
  }

  // convertDateFormat(dateString: string): string {
  //   const [day, month, year] = dateString.split('-');
  //   return `${year}-${month}-${day}`;
  // }

  validateDOB(e: any) {
    // console.log('dob: ', e);
    //this.convertedDate = this.datepipe.transform(e, 'YYYY-MM-DD');
    if (e != null) {
      let yearDob = new Date(e).getFullYear();
      let yearToday = new Date().getFullYear();
      let monthDob = new Date(e).getMonth();
      let monthToday = new Date().getMonth();
      let dayDob = new Date(e).getDate();
      let dayToday = new Date().getDate();

      if (yearDob != null && yearDob > 1900) {
        this.age = (yearToday - yearDob);
        if (this.age < 1) {
          this.alertmessage.errorAlertMessage('dob-error');
          this.userProfileModel.dob = '';
        }
        else if (this.age > 0) {
          if (monthDob > monthToday) {
            this.age--;
          }
          else if (monthDob == monthToday) {
            if (dayDob > dayToday) {
              this.age--;
            }
          }
        }
      } else {
        this.age = 0;
        this.alertmessage.errorAlertMessage('dob-error');
        this.userProfileModel.dob = '';
      }
    }
  }


  getAvailability(user: any) {
    if (user == null || user == '' || user == undefined) {
      // this.alertmessage.errorAlertMessage("Kindly enter values in username field first !");
      this.usernametaken = false;
      this.usernameavail = false;
    } else {
      this.citizenAuthService.checkAvailabilityOfUserName(user).subscribe(res => {
        // console.log(res);
        if (res && res.responseStatus == 'true') {
          this.usernametaken = true;
          this.usernameavail = false;
          this.userProfileModel.userName = '';
        } else {
          this.usernameavail = true;
          this.usernametaken = false;
          this.userProfileModel.userName = user;
        }
      });
    }

  }
  getEmailAvailability(email: any) {
    if (email == null || email == '' || email == undefined) {
      this.emailtaken = false;
      this.emailavail = false;
    } else {
      this.citizenAuthService.checkAvailabilityOfEmail(email).subscribe(res => {
        // console.log(res);
        if (res && res.responseStatus == 'true') {
          this.emailtaken = true;
          this.emailavail = false;
          this.userProfileModel.email = '';
        } else {
          this.emailavail = true;
          this.emailtaken = false;
          this.userProfileModel.email = email;
        }
      });
    }

  }

  onSubmitForPersonalProfile(event: any, form: any) {

    let password: any = this.userProfileModel.password;
    let confirmPassword: any = this.userProfileModel.confirmPass;

    let fld = "";
    if (form.status == 'INVALID') {
      for (const field in form.controls) { // 'field' is a string
        const control = form.get(field); // 'control' is a FormControl 
        if (control.status == "INVALID") {
          fld = field;
          // console.log("---------------controle--fld----11111-------- ",fld);
          break;
        }
      }
      // console.log("---------------controle--fld------------ ",fld);
      let err = "";
      if (fld == "firstNameEn") {
        err = 'citizen-registration-details.personal-details.fullname';
      }
      // else if (fld == "lastNameEn") {
      //   err = 'citizen-registration-details.personal-details.fullname';
      // }
      else if (fld == "firstNameHi") {
        err = 'citizen-registration-details.personal-details.fullnamehi';
      }
      // else if (fld == "lastNameHi") {
      //   err = 'citizen-registration-details.personal-details.fullnamehi';
      // }
      else if (fld == "motherNameEn") {
        err = 'citizen-registration-details.personal-details.mothername';
      }
      else if (fld == "motherNameHi") {
        err = 'citizen-registration-details.personal-details.mothernamehi';
      }
      else if (fld == "relativeNameEn") {
        err = 'citizen-registration-details.personal-details.fatherguardianname';
      }
      else if (fld == "relativeNameHi") {
        err = 'citizen-registration-details.personal-details.fatherguardiannamehi';
      }
      else if (fld == "relativeRelation") {
        err = 'relation';
      }
      else if (fld == "gender") {
        err = 'gender';
      }
      else if (fld == "dob") {
        err = 'dob';
      }
      else if (fld == "caste") {
        err = 'category';
      }
      else if (fld == "religion") {
        err = 'religion';
      }
      else if (fld == "stateIds") {
        err = 'citizen-profile-details.citizen-contact-details.state';
      }
      else if (fld == "districtIds") {
        err = 'citizen-profile-details.citizen-contact-details.district';
      }
      else if (fld == "addressLine1") {
        err = 'citizen-profile-details.citizen-contact-details.address-line-1';
      }
      else if (fld == "addressLine1Hi") {
        err = 'citizen-profile-details.citizen-contact-details.address-line-1-hi';
      }
      else if (fld == "city") {
        err = 'citizen-profile-details.citizen-contact-details.city';
      }
      else if (fld == "cityHi") {
        err = 'citizen-profile-details.citizen-contact-details.city-hi';
      }
      else if (fld == "postalCode") {
        err = 'citizen-profile-details.citizen-contact-details.postal-code';
      }
      else if (fld == "stateIdPres") {
        err = 'citizen-profile-details.citizen-contact-details.state';
      }
      else if (fld == "districtIdPres") {
        err = 'citizen-profile-details.citizen-contact-details.district';
      }
      else if (fld == "addressLine1Pre") {
        err = 'citizen-profile-details.citizen-contact-details.address-line-1';
      }
      else if (fld == "addressLine1PreHi") {
        err = 'citizen-profile-details.citizen-contact-details.address-line-1-hi';
      }
      else if (fld == "cityPre") {
        err = 'citizen-profile-details.citizen-contact-details.city';
      }
      else if (fld == "cityPreHi") {
        err = 'citizen-profile-details.citizen-contact-details.city-hi';
      }
      else if (fld == "postalCodePre") {
        err = 'citizen-profile-details.citizen-contact-details.postal-code';
      }
      else if (fld == "email") {
        err = 'citizen-profile-details.citizen-contact-details.e-mail';
      }
      else if (fld == "userName") {
        let userName: any = this.userProfileModel.userName;
        var pattern = '^(?=.*[a-z])(?=.*\d)[A-Za-z\d@_.]{3,}$';
        if (userName != undefined && userName != '') {
          if (!userName.match(pattern)) {
            this.invalidUserNameCheck = true;
            err = "citizen-profile-details.login-detail-profile.invalid-username"
          } else {
            this.invalidUserNameCheck = false;
          }
        } else {
          this.invalidUserNameCheck = false;
          err = "citizen-profile-details.login-detail-profile.username"
        }
        console.log('userName--------', userName);
        //err = 'citizen-profile-details.login-detail-profile.username';
      }
      else if (fld == "password1") {
        // err='citizen-profile-details.login-detail-profile.password';
        let password: any = this.userProfileModel.password;
        var pattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$';
        if (password != undefined && password != '') {
          if (!password.match(pattern)) {
            this.invalidPasswordCheck = true;
            err = "citizen-profile-details.login-detail-profile.invalid-password"
          } else {
            this.invalidPasswordCheck = false;
          }
        } else {
          this.invalidPasswordCheck = false;
          err = "citizen-profile-details.login-detail-profile.password"
        }
        console.log('password--------', password);
      } else if (fld == "confpassword1") {
        this.invalidPasswordCheck = false;
        err = 'citizen-profile-details.login-detail-profile.confirm-password';
      }

      if (this.isIndian == 0) {
        if (fld == "countryIdPres") {
          err = 'citizen-profile-details.citizen-contact-details.country';
        }
      }

      if (this.userProfileModel.citizenType == 1 && this.eRegPartyModel?.applicantType == '2') {
        if (fld == "orgDesignationEn") {
          err = 'citizen-registration-details.personal-details.designation';
        }
        if (fld == "orgDesignationHi") {
          err = 'citizen-registration-details.personal-details.designationHi';
        }
      }
      if (this.userProfileModel.citizenType == 1 && this.eRegPartyModel?.applicantType == '2') {
        if (fld == "govtDeptName") {
          err = 'citizen-registration-details.personal-details.govt-dept-name';
        }
      }

      if (this.invalidPasswordCheck || this.invalidUserNameCheck)
        this.alertmessage.errorAlertMessage(err, '');
      else
        this.alertmessage.errorAlertMessage(err, 'required');

    } else if (password != confirmPassword) {
      //let err = "";
      //err = 'citizen-profile-details.login-detail-profile.nomatched-password-confirmPassword';
      this.alertmessage.errorAlertMessage('citizen-profile-details.login-detail-profile.nomatched-password-confirmPassword', '');
    } else if (!moment(this.userProfileModel.dob, 'YYYY-MM-DD', true).isValid()) {
      this.alertmessage.errorAlertMessage('dob', 'required');
    } else {
      // let uFName = (this.userProfileModel.firstNameEn != null && this.userProfileModel.firstNameEn != undefined)?this.userProfileModel.firstNameEn:this.userProfileModel.firstNameHi;
      // //let uLName = (this.userProfileModel.lastNameEn != null && this.userProfileModel.lastNameEn != undefined)?this.userProfileModel.lastNameEn:this.userProfileModel.lastNameHi;
      // let uDOB = (this.userProfileModel.dob != null && this.userProfileModel.dob != undefined)?this.userProfileModel.dob:'';
      // let uName = uFName?.trim() + "_" + uDOB?.trim();
      // this.getUserProfilePic(uName, this.userProfileModel.profilePic);

      // console.log('Request to save demographic: ',this.userProfileModel)
      let err = "";
      let altemail = this.userProfileModel.altEmail;
      if (altemail != "" && altemail != undefined) {
        if ((this.userProfileModel.email) != (altemail)) { }
        else {
          this.invalidAltEmail = true;
          err = 'citizen-profile-details.login-detail-profile.altemail-email-not-same';
          this.alertmessage.errorAlertMessage(err);
          return;
        }
      }

      let altmob = this.userProfileModel.altContactNo;
      if (altmob != "" && altmob != undefined) {
        if ((this.userProfileModel.contactNo) != (altmob)) { }
        else {
          this.invalidAltMob = true;
          err = 'citizen-profile-details.login-detail-profile.altmob-mob-not-same';
          this.alertmessage.errorAlertMessage(err);
          return;
        }
      }
      this.isSubmitted = true
      this.ngxService.start()
      this.userProfileModel.isFullProfile = 1;
      if (this.userProfileModel.citizenType == 2) {
        this.userProfileModel.contactNo = this.mobileNo;
        this.citizenAuthService.saveJuristicPersonDetail(this.userProfileModel).subscribe(res => {
          this.ngxService.stop();
          if (res && res.responseStatus == 'true') {
            if ((this.eRegPartyModel?.partyTypeNameEn != undefined || this.eRegPartyModel?.partyTypeNameEn != null) || (this.isProtestReq != undefined || this.isProtestReq != null)) {
              this.userProfileModel.id = res.responseData.citizenId;
              this.eRegPartyModel.userProfileModel = this.userProfileModel
              this.eRegPartyModel.juristicPersonProfileModel = res.responseData; //this.userProfileModel.juristicPerson
              this.eRegPartyModel.userId = res.responseData.citizenId;

              if (this.userProfileModel?.kycTxnLogNo != null && this.userProfileModel?.kycTxnLogNo != undefined && this.userProfileModel?.kycUniqueToken != null && this.userProfileModel?.kycUniqueToken != undefined)
                this.saveUserProfilePicByAadhar(res.responseData?.citizenId, this.kycObject.residentDetails.residentPhoto);
              else
                this.dialogRef.close({ data: this.eRegPartyModel });

              //this.dialogRef.close({ data: this.eRegPartyModel });
            } else {
              if (this.userProfileModel?.kycTxnLogNo != null && this.userProfileModel?.kycTxnLogNo != undefined && this.userProfileModel?.kycUniqueToken != null && this.userProfileModel?.kycUniqueToken != undefined)
                this.saveUserProfilePicByAadhar(res.responseData?.citizenId, this.kycObject.residentDetails.residentPhoto);

              this.demographicDetailsSaveOut.emit(res);
            }
          } else {
            this.alertmessage.errorAlertMessage(res);
          }
        });
      } else {
        if (this.isIndian == 1) {
          this.userProfileModel.contactNo = this.mobileNo;
        }
        this.citizenAuthService.updateCitizenProfile(this.userProfileModel).subscribe(res => {
          this.ngxService.stop();
          if (res && res.responseStatus == 'true') {
            if (this.eRegPartyModel?.partyTypeNameEn != undefined || this.eRegPartyModel?.partyTypeNameEn != null || this.eRegPartyModel?.poaType != undefined
              || this.eRegPartyModel?.poaType != null || this.eRegPartyModel?.partyBean?.poaType != undefined || this.eRegPartyModel?.partyBean?.poaType != null
              || this.eRegPartyModel?.transactingThroughPOA == true || this.isProtestReq != undefined || this.isProtestReq != null || this.eRegPartyModel?.isAadhaarKycPerformedByEregCompl) {
              this.eRegPartyModel.userProfileModel = res.responseData;
              this.eRegPartyModel.userId = this.eRegPartyModel.userProfileModel.id
              //this.eRegPartyModel.juristicPersonProfileModel = this.userProfileModel.juristicPerson;

              if (res.responseData?.kycTxnLogNo != null && res.responseData?.kycTxnLogNo != undefined && res.responseData?.kycUniqueToken != null && res.responseData?.kycUniqueToken != undefined)
                this.saveUserProfilePicByAadhar(res.responseData?.id, this.kycObject.residentDetails.residentPhoto);
              else
                this.dialogRef.close({ data: this.eRegPartyModel });

              //this.dialogRef.close({ data: this.eRegPartyModel });
            } else {
              if (res.responseData?.kycTxnLogNo != null && res.responseData?.kycTxnLogNo != undefined && res.responseData?.kycUniqueToken != null && res.responseData?.kycUniqueToken != undefined)
                this.saveUserProfilePicByAadhar(res.responseData?.id, this.kycObject.residentDetails.residentPhoto);

              this.demographicDetailsSaveOut.emit(res);
            }
          } else {
            this.alertmessage.errorAlertMessage(res);
          }
        });
      }
    }
  }

  send(source: any, destination: any, aadharName: any) {
    if (this.lan == 0) {
      if (aadharName == null || aadharName == '' || aadharName == undefined) {
        this.translateService.translateLang(source.value).subscribe(
          (res: any) => {
            let name: string = destination.name;
            this.form.controls[name].setValue(res);
          },
          err => {
            // console.log(err);
          }
        );
      }
    }
  }


  sendHi(source: any, destination: any, aadharName: any) {
    if (this.lan == 1) {
      if (aadharName == null || aadharName == '' || aadharName == undefined) {
        this.translateService.translateLangHi(source.value).subscribe(
          (res: any) => {
            let name: string = destination.name;
            this.form.controls[name].setValue(res);
          },
          err => {
            // console.log(err);
          }
        );
      }
    }
  }

  /*convertHindi(source:any, targetName:string):any {
    console.log('source: '+source);
    if(source!=undefined&&source!=null&&source!=''){
      this.translateService.translateLang(source).subscribe(
        (res: any) => {
          console.log('res: '+res);
  
          if(targetName=='fNameHi')
            this.userProfileModel.firstNameHi = res;
          if(targetName=='mNameHi')
            this.userProfileModel.middleNameHi = res;
          if(targetName=='lNameHi')
            this.userProfileModel.lastNameHi = res;
          if(targetName=='relativeNameHi'){
            this.userProfileModel.relativeNameHi = res;
          }
         return res;
        },
        err => {
          console.log(err);
          return "";
        }
      );
    }
     
  }*/


  convertHindi(source: any, targetName: string): any {
    console.log('source: ' + source);
    let hiValue: string = source
    if (hiValue.includes("S/O") || hiValue.includes("C/O")) {
      hiValue = hiValue.substring(4, hiValue.length)
    }
    console.log('hiValue: ' + hiValue);

    if (source != undefined && source != null && source != '') {
      this.translateService.translateLang(hiValue).subscribe(
        (res: any) => {
          console.log('res: ' + res);

          if (targetName == 'fNameHi')
            this.userProfileModel.firstNameHi = res;
          if (targetName == 'mNameHi')
            this.userProfileModel.middleNameHi = res;
          if (targetName == 'lNameHi')
            this.userProfileModel.lastNameHi = res;
          if (targetName == 'relativeNameHi') {
            this.userProfileModel.relativeNameHi = res;
          }
          if (targetName == 'addressLine1Hi') {
            this.userProfileModel.addressLine1Hi = res;
          }
          if (targetName == 'addressLine2Hi') {
            this.userProfileModel.addressLine2Hi = res;
          }
          if (targetName == 'cityHi') {
            this.userProfileModel.cityHi = res;
          }
          return res;
        },
        err => {
          console.log(err);
          return "";
        }
      );
    }
  }

  openDialog() {
    // console.log("openDialog");
    let element: HTMLElement = document.getElementById('fileid') as HTMLElement;
    element.click();
  }

  ProfileChangeEvent(pfileInput: any): any {
    this.flag = false;
    // console.log("ProfileChangeEvent");
    // console.log(pfileInput);
    this.profileImageError = null;
    if (pfileInput.target.files && pfileInput.target.files[0]) {
      // Size Filter Bytes
      const pmax_size = 100000;
      // const pallowed_types = ["image/png", "image/jpg"];
      // const pmax_height = 15200;
      // const pmax_width = 25600;

      if (pfileInput.target.files[0].size > pmax_size) {
        Swal.fire({
          title: `${this.lan == 0 ? "File Size Exceeds Allowed Limit" : "फ़ाइल का आकार अनुमत सीमा से अधिक है"}`,
          text: `${this.lan == 0 ? "Maximum size allowed is " + pmax_size / 1000 + "Kb" : "अधिकतम आकार अनुमत है " + pmax_size / 1000 + "के.बी"}`,
          icon: "error",
          showCancelButton: false,
          confirmButtonText: (this.lan == 0) ? 'Ok' : 'ठीक है',
          cancelButtonText: (this.lan == 0) ? 'No, keep it' : 'नहीं, इसे रखो',
          allowOutsideClick: false,
        }).then((result) => {
          return false;
        });
        return false;
      }
      //if (!pallowed_types.includes(pfileInput.target.files[0].type, 0)) {
      if (!(pfileInput.target.files[0].name.includes(".jpg") || pfileInput.target.files[0].name.includes(".png"))) {
        Swal.fire({
          title: `${this.lan == 0 ? "UnSupported File Type" : "असमर्थित फ़ाइल प्रकार"}`,
          text: `${this.lan == 0 ? "Only Images are allowed ( JPG | PNG )" : "केवल छवियों की अनुमति है (जेपीजी | पीएनजी)"}`,
          icon: "error",
          showCancelButton: false,
          confirmButtonText: (this.lan == 0) ? 'Ok' : 'ठीक है',
          cancelButtonText: (this.lan == 0) ? 'No, keep it' : 'नहीं, इसे रखो',
          allowOutsideClick: false,
        }).then((result) => {
          return false;
        });

        return false;
      }
      const preader = new FileReader();
      preader.onload = (pe: any) => {
        const pimage = new Image();
        pimage.src = pe.target.result;

        this.userProfileModel.profilePic = (pe.target.result).split(',')[1];
        //this.userProfileModel.profilePic = pe.target.result;

      };
      //this.profileImage.hpf = pfileInput.target.files[0];
      preader.readAsDataURL(pfileInput.target.files[0]);
    }
  }

  detailChecked() {
    // console.log('userProfileModel: ', this.userProfileModel);
    this.isSubmitted = false;
    if (this.detailCheckedVal) {
      this.detailCheckedVal = false;
    } else {
      this.detailCheckedVal = true;
    }
  }

  showConsent() {
    if (this.consentChecked) {
      this.consentChecked = false;
    } else {
      this.consentChecked = true;
    }
  }

  onChangeEmail() {
    this.invalidAltEmail = false;
    let email = this.userProfileModel.email;
    let altemail = this.userProfileModel.altEmail;
    if (email != '' && email != undefined && altemail != "" && altemail != undefined) {
      if ((email) != (altemail)) { }
      else {
        this.invalidAltEmail = true;
        return;
      }
    }
  }

  onChangeAltMob() {
    this.invalidAltMob = false;
    let mob = this.userProfileModel.contactNo;
    let altmob = this.userProfileModel.altContactNo;
    if (mob != '' && mob != undefined && altmob != "" && altmob != undefined) {
      if ((mob) != (altmob)) { }
      else {
        this.invalidAltMob = true;
        return;
      }
    }
  }

  onKeyPress(event: any) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[A-Za-z_ ]+$/.test(inputChar)) {
      event.preventDefault();
    }
  }
  getUserAadhaarDetails(transactionId:string) {
    this.ngxService.start();
      this.common.getUserAadhaarDetails(transactionId).subscribe(
      (res) => {
        this.ngxService.stop();
        if (res.responseStatus == "true") {
          let kycObject: any = res.responseData;
          if(kycObject?.refKey != undefined && kycObject?.refKey != '' && kycObject?.refKey != '0')
            this.userProfileModel.aadharRefKey=kycObject?.refKey;
          } else {
          this.alertmessage.errorAlertMessage(res);
        }
      }
    );
    this.ngxService.stop();
  }
}
