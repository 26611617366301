<div class="auth-main">

    <div class="container">
        <div class="row justify-content-md-center">
            <div *ngIf="paramSource === 'forgot-password'"
                class="col-lg-5 col-md-7 col-xs-12 col-sm-12 bg-white p-4 logInForms">
                <h2 class="loginTitle">{{'forgot-password' | translate}}</h2>


                <lib-alertmessage></lib-alertmessage>
                <div class=" " *ngIf="usernameOption">

                    <form name="form" (ngSubmit)="usernameFormHtml.form.valid && onSubmitUserNameForOtp()"
                        #usernameFormHtml="ngForm" novalidate>
                        <div class="input-group mb-3">
                            <label class="w-100">{{'username-mobile' | translate}} <span
                                    style="color: red;">*</span></label>
                            <div class="input-group-append"> <span class="input-group-text"><i
                                        class="fa fa-user"></i></span> </div>
                            <input type="text" name="userName1" #userName1="ngModel" (contextmenu)="false;" required
                                appAlphaNumericDotUndsDashA [appCopyPastValidation]="true" maxlength="40"
                                [(ngModel)]="userName" class="form-control input_user" 
                                [ngClass]="{ 'is-invalid': usernameFormHtml.submitted && userName1.invalid }"
                                placeholder="{{'username-mobile' | translate}}" id="username" autocomplete="off">
                            <div *ngIf="usernameFormHtml.submitted && userName1.invalid" class="invalid-feedback">
                                <div *ngIf="userName1.errors?.required">{{'required' | translate}}</div>
                            </div>
                        </div>
                        <div class="input-group mb-3 mt-3" *ngIf="isUser">
                            <label class="w-100">{{'select-user' | translate}}
                                <span style="color: red;">*</span></label>
                            <!-- <div class="input-group-append iobh"> 
                                <span class="input-group-text bg-primary"><i
                                        class="fa fa-user"></i></span> 
                                </div> -->

                            <ng-select required class="form-control p-0" name="selectedUser" [(ngModel)]="selectedUser"
                                (change)="selectUser()" placeholder="{{'select-user' | translate}}">
                                <ng-option value="">{{'select-user'|translate}}</ng-option>
                                <ng-option *ngFor="let user of userList" [value]="user.id">
                                    <!-- {{user.userName}} -->
                                    {{ lan == 0 ? 
                                        user.userName + ' [' + user.firstNameEn + (user.middleNameEn?" "+user.middleNameEn: "") + (user.lastNameEn ?" "+user.lastNameEn : "") + ']' : 
                                        user.userName + ' [' + user.firstNameHi + (user.middleNameHi ?" "+user.middleNameHi + " " : "") + (user.lastNameHi ?" "+user.lastNameHi : "") + ']' }}</ng-option>
                            </ng-select>

                            <!-- <select required class="form-control" name="selectedUser"
                                [(ngModel)]="selectedUser" (change)="selectUser()">
                                <option value="" selected="selected">{{'select-user' | translate}}</option>
                                <option *ngFor="let user of userList" [value]="user.id">
                                    {{ lan == 0 ? user.userName + ' [' + user.firstNameEn + ' ' +
                                    user.lastNameEn + ']': user.userName + ' [' + user.firstNameHi + ' ' +
                                    user.lastNameHi + ']' }}
                                </option>
                            </select> -->
                        </div>
                        <div class="captcha">
                            <div class="row mb-2 align-items-center">
                                <div class="col-5">
                                    <div class="input-group " style="align-content: center;">
                                        <img src="{{captchaImageBase64Img}}" />

                                    </div>
                                </div>
                                  <div class="col-2 text-center">
                                     <img
                                            src="assets/images/refresh_image.png" (click)="getStartupCaptcha()"
                                            height="26px" />
                                        </div>
                                <div class="col-5">
                                    <div class="input-group">
                                        <input type="text" name="captchaStr2" #captchaStr2="ngModel"
                                            (contextmenu)="false;" required [appCopyPastValidation]="true" minlength="4"
                                            maxlength="4" autocomplete="off" [(ngModel)]="credential.captchaStr2"
                                            class="form-control input_user"
                                            [ngClass]="{ 'is-invalid': usernameFormHtml.submitted && captchaStr2.invalid }"
                                            placeholder="{{'enter-captcha' | translate}}" id="captchaStr2">
                                        <div *ngIf="usernameFormHtml.submitted && captchaStr2.invalid"
                                            class="invalid-feedback">
                                            <div *ngIf="captchaStr2.errors?.required">{{'required' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="text-center ">
                            <button type="button" mat-button class="btn btn-primary" (click)="changeContactNo('1')"> <span class="pl-2">{{'get-otp' |
                                    translate}}</span></button>
                        </div>
                    </form>
                </div>
                <div class=" " *ngIf="otpOption">

                    <form name="form" (ngSubmit)="otpPwdFormHtml.form.valid && onOtpForPassword()"
                        #otpPwdFormHtml="ngForm" novalidate>
                        <div class="input-group mb-3">
                            <label class="w-100">{{'username-mobile' | translate}} <span
                                    style="color: red;">*</span></label>
                            <div class="input-group-append"> <span class="input-group-text"><i
                                        class="fa fa-user"></i></span> </div>
                            <input type="text" name="disable-userName" [(ngModel)]="userName" (contextmenu)="false;"
                                [appCopyPastValidation]="true" class="form-control input_user" disabled>
                        </div>
                        <div class="input-group mb-3 mt-3" *ngIf="isUser">
                            <label class="w-100">{{'select-user' | translate}}
                                <span style="color: red;">*</span></label>

                            <ng-select disabled class="form-control p-0" name="selectedUser" [(ngModel)]="selectedUser"
                                (change)="selectUser()" placeholder="{{'select-user' | translate}}">
                                <ng-option value="">{{'select-user'|translate}}</ng-option>
                                <ng-option *ngFor="let user of userList" [value]="user.id">
                                    <!-- {{user.userName}} -->
                                    {{ lan == 0 ? 
                                        user.userName + ' [' + user.firstNameEn + (user.middleNameEn?" "+user.middleNameEn: "") + (user.lastNameEn ?" "+user.lastNameEn : "") + ']' : 
                                        user.userName + ' [' + user.firstNameHi + (user.middleNameHi ?" "+user.middleNameHi + " " : "") + (user.lastNameHi ?" "+user.lastNameHi : "") + ']' }}</ng-option>
                            </ng-select>
                        </div>
                        <div class="input-group mb-3">
                            <label class="w-100">{{'otp' | translate}} <span style="color: red;">*</span></label>
                            <div class="input-group-append"> <span class="input-group-text"><i
                                        class="fa fa-key"></i></span> </div>
                            <input type="text" name="otp2" #otp2="ngModel" minlength="6" maxlength="6"
                                (contextmenu)="false;" required [appCopyPastValidation]="true" minlength="6"
                                maxlength="6" [(ngModel)]="otp" class="form-control input_user"
                                [ngClass]="{ 'is-invalid': otpPwdFormHtml.submitted && otp2.invalid }"
                                placeholder="{{'otp' | translate}}" id="otp" autocomplete="off">
                            <div *ngIf="otpPwdFormHtml.submitted && otp2.invalid" class="invalid-feedback">
                                <div *ngIf="otp2.errors?.required">{{'required' | translate}}</div>
                            </div>
                            <div class="d-block w-100 text-right mt-1">
                                <a class="forgetpasswrd">
                                    <span  *ngIf="counter != 0" style="font-weight: 400; font-size: 13px;">
                                        {{'regenerate_otp' | translate}} 
                                    </span> 
                                    <span
                                        *ngIf="counter != 0">{{counter | formatTime}}</span> <span
                                        *ngIf="counter == 0" (click)="onSubmitUserNameForOtp();" >{{'resend-otp' | translate}}</span></a>
                                         <p style="font-weight: 400; font-size: 13px;">( {{'otpValid'| translate}} )</p>
                            </div>
                            <!-- <div class="d-block w-100 text-right mt-1">
                                <a class="forgetpasswrd" (click)="onSubmitUserNameForOtp();" style="float: right;">{{'resend-otp' | translate}}</a>
                            </div> -->
                        </div>
                        <div class="row mb-2 align-items-center">
                            <div class="col-5">
                                <div class="input-group " style="align-content: center;">
                                    <img src="{{captchaImageBase64Img}}" />
                                    <!-- <button (click)="getStartupCaptcha()">REFRESH CAPTCHA</button> -->
                                </div>
                            </div>
                              <div class="col-2 text-center">
                                        <img
                                        src="assets/images/refresh_image.png" (click)="getStartupCaptcha()"
                                        height="26px" />
                                        </div>
                            <div class="col-5">
                                <div class="input-group">
                                    <input type="text" name="captchaStr1" #captchaStr1="ngModel" (contextmenu)="false;"
                                        required [appCopyPastValidation]="true" minlength="4" maxlength="4"
                                        autocomplete="off" [(ngModel)]="captchaStr" class="form-control input_user"
                                        [ngClass]="{ 'is-invalid': otpPwdFormHtml.submitted && captchaStr1.invalid }"
                                        placeholder="{{'enter-captcha' | translate}}" id="captchaStr">
                                    <div *ngIf="otpPwdFormHtml.submitted && captchaStr1.invalid"
                                        class="invalid-feedback">
                                        <div *ngIf="captchaStr1.errors?.required">{{'required' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div>
                            <button type="button" class="btn btn-link" style="float:right;"><span class="click-icon"></span> <span class="pl-2">Resend</span></button>
                        </div> -->
                        <div class="d-flex justify-content-center mt-3 login_container">
                            <button type="submit" mat-button class="btn btn-primary">{{'verify-otp' |
                                translate}}</button>
                        </div>
                    </form>
                </div>
                <div class="  " *ngIf="passwordOption">

                    <form name="form" (ngSubmit)="passwordPwdFormHtml.form.valid && onPasswordForSubmit()"
                        #passwordPwdFormHtml="ngForm" novalidate>
                        <div class="input-group mb-3">
                            <label class="w-100">{{'username-mobile' | translate}} <span
                                    style="color: red;">*</span></label>
                            <div class="input-group-append"> <span class="input-group-text"><i
                                        class="fa fa-user"></i></span> </div>
                            <input type="text" name="uName" [(ngModel)]="userName" (contextmenu)="false;"
                                [appCopyPastValidation]="true" autocomplete="off" class="form-control input_user"
                                disabled>
                        </div>
                        <div class="input-group mb-3">
                            <label class="w-100">{{'password' | translate}} <span style="color: red;"> * </span>


                                <!-- <ng-template #popContent>{{'file-note' | translate}}</ng-template>
                                <ng-template #popTitle>Greeting in {{language}}</ng-template>

                                <i  class="fa fa-info-circle" [ngbPopover]="popContent" [ngbPopover]="popTitle" triggers="mouseenter:mouseleave" ></i>  -->

                            </label>

                            <div class="input-group-append"> <span class="input-group-text"><i
                                        class="fa fa-key"></i></span> </div>
                            <input [type]="passhide ? 'password' : 'text'" name="password1" #password1="ngModel"
                                (contextmenu)="false;" required [appCopyPastValidation]="true" [(ngModel)]="password"
                                class="form-control input_user" minlength="6"
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
                                placeholder="{{'password' | translate}}" id="password"
                                [ngClass]="{ 'is-invalid': passwordPwdFormHtml.submitted && password1.invalid }"
                                autocomplete="off">
                            <div *ngIf="passwordPwdFormHtml.submitted && password1.invalid" class="invalid-feedback">
                                <div *ngIf="password1.errors?.required">{{'required' | translate}}</div>
                            </div>
                            <div class="input-group-prepend showPassword"> <button type="button" class=" "
                                    (click)="passhide = !passhide" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="passhide"><mat-icon>{{passhide ? 'visibility_off' :
                                        'visibility'}}</mat-icon></button> </div>

                        </div>
                        <div *ngIf="password1.touched && password1.invalid">
                            <div *ngIf="password1.errors?.pattern && lan==0" class="invalid-feedback mb-2">
                                Password Must Have<br />
                                1. Minimum 6 and Maximum 14 characters <br />
                                2. at least one small letter <br />
                                3. at least one number <br />
                                4. at least one special character<br />
                            </div>
                            <div *ngIf="password1.errors?.pattern && lan==1" class="invalid-feedback mb-2">
                                पासवर्ड होना चाहिए<br />
                                1. न्यूनतम 8 और अधिकतम 14 वर्ण <br />
                                2. कम से कम एक छोटा अक्षर <br />
                                3. कम से कम एक नंबर <br />
                                4. कम से कम एक विशेष वर्ण<br />
                            </div>
                        </div>
                        <div class="input-group mb-3">
                            <label class="w-100">{{'conf-password' | translate}} <span
                                    style="color: red;">*</span></label>
                            <div class="input-group-append"> <span class="input-group-text"><i
                                        class="fa fa-key"></i></span> </div>
                            <input [type]="passhide1 ? 'password' : 'text'" name="confpassword1"
                                #confpassword1="ngModel" compare-password="password1" minlength="6"
                                (contextmenu)="false;" required [appCopyPastValidation]="true"
                                [(ngModel)]="confPassword" class="form-control input_user"
                                placeholder="{{'conf-password' | translate}}"
                                [ngClass]="{ 'is-invalid': passwordPwdFormHtml.submitted && confpassword1.invalid }"
                                autocomplete="off">
                            <div *ngIf="passwordPwdFormHtml.submitted && confpassword1.invalid"
                                class="invalid-feedback">
                                <div *ngIf="confpassword1.errors?.required">{{'required' | translate}}</div>
                            </div>
                            <div class="input-group-prepend showPassword"> <button type="button" class=" "
                                    (click)="passhide1 = !passhide1" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="passhide1"><mat-icon>{{passhide1 ? 'visibility_off' :
                                        'visibility'}}</mat-icon></button> </div>
                            <div class="invalid-feedback"
                                *ngIf="confpassword1.dirty && ((password1.errors && password1.errors?.compare) || (confpassword1.errors && confpassword1.errors?.compare)) && !confpassword1.errors?.required">
                                {{'notmatch-password' | translate}}
                            </div>
                        </div>
                        <div class="d-flex justify-content-center mt-3 login_container">
                            <button type="submit" mat-button class="btn btn-primary"><span class="click-icon"></span>
                                <span class="pl-2">{{'submit' | translate}}</span></button>
                        </div>
                    </form>
                </div>

                <div id="or" class="mt-3 mb-3">&nbsp;&nbsp;OR&nbsp;&nbsp;</div>



                <div class="mt-4">
                    <div class="d-flex justify-content-center  "><a class="btn btn-secondary" mat-raised-button
                            routerLink="/clogin">{{'switch-login' | translate}}</a> </div>
                </div>

                <!-- <div class="mt-4">
                    <div class="d-flex justify-content-center  "><a class="backtohome" routerLink="/"><i class="fa fa-home"></i> </a> </div>

                </div> -->

            </div>


            <!--------------------------------------------->
            <!-----------------Actived User---------------------------->
            <div *ngIf="paramSource === 'activate-user'"
                class="col-lg-5 col-md-7 col-xs-12 col-sm-12 bg-white p-4 logInForms">
                <h2 class="loginTitle">{{'activate-user' | translate}}</h2>


                <lib-alertmessage></lib-alertmessage>
                <div class=" " *ngIf="usernameOption">

                    <form name="form" (ngSubmit)="usernameFormHtml.form.valid && onSubmitUserNameForOtp()"
                        #usernameFormHtml="ngForm" novalidate>
                        <div class="input-group mb-3">
                            <label class="w-100">{{'username-mobile' | translate}} <span
                                    style="color: red;">*</span></label>
                            <div class="input-group-append"> <span class="input-group-text"><i
                                        class="fa fa-user"></i></span> </div>
                            <input type="text" name="userName1" #userName1="ngModel" (contextmenu)="false;" required
                                appAlphaNumericDotUndsDashA [appCopyPastValidation]="true" maxlength="40"
                                [(ngModel)]="userName" class="form-control input_user" 
                                [ngClass]="{ 'is-invalid': usernameFormHtml.submitted && userName1.invalid }"
                                placeholder="{{'username-mobile' | translate}}" id="username" autocomplete="off">
                            <div *ngIf="usernameFormHtml.submitted && userName1.invalid" class="invalid-feedback">
                                <div *ngIf="userName1.errors?.required">{{'required' | translate}}</div>
                            </div>
                        </div>
                        <div class="input-group mb-3 mt-3" *ngIf="isUser">
                            <label class="w-100">{{'select-user' | translate}}
                                <span style="color: red;">*</span></label>

                            <ng-select required class="form-control p-0" name="selectedUser" [(ngModel)]="selectedUser"
                                (change)="selectUser()" placeholder="{{'select-user' | translate}}">
                                <ng-option value="">{{'select-user'|translate}}</ng-option>
                                <ng-option *ngFor="let user of userList" [value]="user.id">
                                    <!-- {{user.userName}} -->
                                    {{ lan == 0 ? 
                                        user.userName + ' [' + user.firstNameEn + (user.middleNameEn?" "+user.middleNameEn: "") + (user.lastNameEn ?" "+user.lastNameEn : "") + ']' : 
                                        user.userName + ' [' + user.firstNameHi + (user.middleNameHi ?" "+user.middleNameHi + " " : "") + (user.lastNameHi ?" "+user.lastNameHi : "") + ']' }}</ng-option>
                            </ng-select>
                        </div>
                        <div class="captcha">
                            <div class="row align-items-center mb-2">
                                <div class="col-5">
                                    <div class="input-group " style="align-content: center;">
                                        <img src="{{captchaImageBase64Img}}"  />

                                    </div>
                                </div>

                            <div class="col-2 text-center">
                                           <img
                                            src="assets/images/refresh_image.png" (click)="getStartupCaptcha()"
                                            height="26px" />
                                        </div>




                                <div class="col-5">
                                    <div class="input-group ">
                                        <input type="text" name="captchaStr2" #captchaStr2="ngModel"
                                            (contextmenu)="false;" required [appCopyPastValidation]="true" minlength="4"
                                            maxlength="4" autocomplete="off" [(ngModel)]="credential.captchaStr2"
                                            class="form-control input_user"
                                            [ngClass]="{ 'is-invalid': usernameFormHtml.submitted && captchaStr2.invalid }"
                                            placeholder="{{'enter-captcha' | translate}}" id="captchaStr2">
                                        <div *ngIf="usernameFormHtml.submitted && captchaStr2.invalid"
                                            class="invalid-feedback">
                                            <div *ngIf="captchaStr2.errors?.required">{{'required' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="text-center ">
                            <button [disabled]="!isUserDetailFound" type="button" mat-button class="btn btn-primary" (click)="changeContactNo('2')"> <span class="pl-2">{{'get-otp' |
                                    translate}}</span></button>
                        </div>
                    </form>
                </div>
                <div class=" " *ngIf="otpOption">

                    <form name="form" (ngSubmit)="otpPwdFormHtml.form.valid && onOtpForPassword()"
                        #otpPwdFormHtml="ngForm" novalidate>
                        <div class="input-group mb-3">
                            <label class="w-100">{{'username-mobile' | translate}} <span
                                    style="color: red;">*</span></label>
                            <div class="input-group-append"> <span class="input-group-text"><i
                                        class="fa fa-user"></i></span> </div>
                            <input type="text" name="disable-userName" [(ngModel)]="userName" (contextmenu)="false;"
                                [appCopyPastValidation]="true" class="form-control input_user" disabled>
                        </div>
                        <div class="input-group mb-3 mt-3" *ngIf="isUser">
                            <label class="w-100">{{'select-user' | translate}}
                                <span style="color: red;">*</span></label>

                            <ng-select disabled class="form-control p-0" name="selectedUser" [(ngModel)]="selectedUser"
                                (change)="selectUser()" placeholder="{{'select-user' | translate}}">
                                <ng-option value="">{{'select-user'|translate}}</ng-option>
                                <ng-option *ngFor="let user of userList" [value]="user.id">
                                    <!-- {{user.userName}} -->
                                    {{ lan == 0 ? 
                                        user.userName + ' [' + user.firstNameEn + (user.middleNameEn?" "+user.middleNameEn: "") + (user.lastNameEn ?" "+user.lastNameEn : "") + ']' : 
                                        user.userName + ' [' + user.firstNameHi + (user.middleNameHi ?" "+user.middleNameHi + " " : "") + (user.lastNameHi ?" "+user.lastNameHi : "") + ']' }}</ng-option>
                            </ng-select>
                        </div>
                        <div class="input-group mb-3">
                            <label class="w-100">{{'otp' | translate}} <span style="color: red;">*</span></label>
                            <div class="input-group-append"> <span class="input-group-text"><i
                                        class="fa fa-key"></i></span> </div>
                            <input type="text" name="otp2" #otp2="ngModel" minlength="6" maxlength="6"
                                (contextmenu)="false;" required [appCopyPastValidation]="true" minlength="6"
                                maxlength="6" [(ngModel)]="otp" class="form-control input_user"
                                [ngClass]="{ 'is-invalid': otpPwdFormHtml.submitted && otp2.invalid }"
                                placeholder="{{'otp' | translate}}" id="otp" autocomplete="off">
                            <div *ngIf="otpPwdFormHtml.submitted && otp2.invalid" class="invalid-feedback">
                                <div *ngIf="otp2.errors?.required">{{'required' | translate}}</div>
                            </div>
                            <div class="d-block w-100 text-right mt-1">
                                <a class="forgetpasswrd">
                                    <span  *ngIf="counter != 0" style="font-weight: 400; font-size: 13px;">
                                        {{'regenerate_otp' | translate}} 
                                    </span> 

                                    <span
                                        *ngIf="counter != 0">{{counter | formatTime}}</span> <span
                                        *ngIf="counter == 0" (click)="onSubmitUserNameForOtp();">{{'resend-otp' | translate}}</span></a>
                                         <p style="font-weight: 400; font-size: 13px;">( {{'otpValid'| translate}} )</p>
                            </div>

                        </div>
                        <div class="row mb-2 align-items-center">
                            <div class="col-5">
                                <div class="input-group " style="align-content: center;">
                                    <img src="{{captchaImageBase64Img}}"   />

                                </div>
                            </div>
                              <div class="col-2 text-center">
                                            <img
                                        src="assets/images/refresh_image.png" (click)="getStartupCaptcha()"
                                        height="26px" />
                                        </div>
                            <div class="col-5">
                                <div class="input-group ">
                                    <input type="text" name="captchaStr1" #captchaStr1="ngModel" (contextmenu)="false;"
                                        required [appCopyPastValidation]="true" minlength="4" maxlength="4"
                                        autocomplete="off" [(ngModel)]="captchaStr" class="form-control input_user"
                                        [ngClass]="{ 'is-invalid': otpPwdFormHtml.submitted && captchaStr1.invalid }"
                                        placeholder="{{'enter-captcha' | translate}}" id="captchaStr">
                                    <div *ngIf="otpPwdFormHtml.submitted && captchaStr1.invalid"
                                        class="invalid-feedback">
                                        <div *ngIf="captchaStr1.errors?.required">{{'required' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-center mt-3 login_container">
                            <button type="submit" mat-button class="btn btn-primary">{{'verify-otp' |
                                translate}}</button>
                        </div>
                    </form>
                </div>
                <div class="btn-groupDiv " *ngIf="passwordOption">
                    <button type="button" mat-button class="btn btn-primary" (click)="activateUser()">{{'activate-user'
                        | translate}}</button>

                </div>


            </div>

            <!-------------------end Actived user-------------------------->
            <!--------------------------------------------->







            <div class="col-md-4 bg-light d-none d-md-block">
                <div class="d-flex h-100 align-items-center twxt-center">
                    <img src="assets/images/forgot-password.png" class="w-100" alt="Forget Password">
                </div>
            </div>

            <!-- <div class="col-lg-5 col-md-5 col-xs-12 col-sm-12 hidden-xs col-lg-pull-7 pl-5 pr-5">
                <div class="loginimgBox">
                <div class="d-flex text-center" style="align-items: center;">

                    <img class="mr-2" src="assets/images/logo-1.png" width="70" style="position: relative;"> <h2 class="m-0" style="font-size: 36px;    font-weight: 600;    color: #034377;">  SAMPADA 2</h2>
                </div>


                    <img src="assets/images/loginimg.png" class="w-100">
                    <img src="assets/images/loginimg2.png" class="w-100 slideanimation">
                    <img src="assets/images/loginimg3.png" class="w-100 slideanimation">
                    <img src="assets/images/loginimg4.png" class="w-100 slideanimation2">
                    <img src="assets/images/loginimg5.png" class="w-100 slideanimation2">
                </div>
            </div> -->
        </div>
    </div>


</div>


<ngx-ui-loader></ngx-ui-loader>