import { FormArray } from "@angular/forms";
import { DeedInstrumentBean } from "../dutyCalculation.model/deed-instrument-bean";
import { UploadLinkPropertyModelArray } from "../propertyModelArray";
import { TransactionDetailsChallanModel } from "../TransactionDetailsChallanModel";
import { CitizenUserProfileDetails, EregPaymentRequest, EstampPaymentRequest, RepresentativeDetailsModel } from "../citizen.login.model/citizen.profile.model";
import { DutyDetailsBean } from "../dutyCalculation.model/duty-calculation-response";
import { MstStatusBean } from "../serviceprovider/MstStatusBean.model";
import { DutyExemptionMapping } from "../dutyCalculation.model/duty-exemption-mapping";
import { EstampDetails } from "../estamp.model/estamp-details";
import { PropertyValuationRequestBean } from "../PropertyValuationRequestBean";
import { MstRegistryTypeModel } from "../qms-model/mst-registry-type-model";

import { juisticPersonDetail } from "../juristic-Person.model/juristicPersonDetails.model";
import { ApplicableSubClauseResponse } from "../ApplicableSubClauseResponse";
import { AddEstamp } from "../addEstamp.model/add-estamp.model";
import { PropertyValuationDeviationBean } from "../PropertyValuationDeviationBean";
import { PropertyValuationResponseBean } from "../PropertyValuationResponseBean";
import { EstampDeedContent } from "../estamp.model/estamp-deed-content";
import { InstMappingBean } from "../dutyCalculation.model/inst-mapping-bean";
import { MineralMstBean } from "../dutyCalculation.model/mineral-mst-bean";
import { DemographyModel } from "../auth.userdetail.model/user.office.model";
import { MstBankFinancialInstituteTypeBean, MstBankMasterBean } from "../serviceprovider/serviceprovider.model";
import { EstampTemplateBean } from "../estamp.model/estamp-template-bean";
import { InstAdditionalMappingBean } from "../dutyCalculation.model/inst-additional-mapping-bean";
import { IgrsDutyCalculation } from "../dutyCalculation.model/igrs-duty-calculation";
import { TemplateFieldsModel } from "../TemplateFieldModel";
import { DateFilterFn } from "@angular/material/datepicker";
import { MstDemographyBean } from "../MstDemographyBean";
import { IgrsProtestIdentifyBean } from "../IgrsProtestPropIdentifyBean";
import { IgrsProtestRequestTxn } from "../IgrsProtestRequestTxn";
import { Representative } from "../representative/representative.model";
//import { IgrsEregAdjudicationBean } from "projects/citizen-web/src/app/pages/e-registry/raise-adjudication-request/raise-adjudication-request.component";
//import { IgrsEregAdjudicationBean, IgrsEregAdjudicationPropertyBean } from "projects/citizen-web/src/app/pages/e-registry/raise-adjudication-request/raise-adjudication-request.component";


export class ERegistry {


}
//Property Images taken from 3 Different angles
export class SaveERegPropertyImageDetails {
    propertyId: any;
    personName: string;
    MobileNumber: number;
    personContactNumber: string;
    eRegId: string;
    propSource: string;
    propSourceId: number;
    registryId: string;
    userId: number;
    status:number;
}
//Save Four Boundry Details
export class FourBoundryDetails {
    khasraPlotFlatHouseNo: any;
    khasraArea: any;
    landRevenue: any;
    bhuAdhikarNumber: any;
    northBoundary: any;
    southBoundary: any;
    eastBoundary: any;
    westBoundary: any;
}
//Save Four Boundry Details
export class SaveERegFourBoundryDetails {
    premutationId: any;
    khasraPlotFlatHouseNo: any;
    khasraArea: any;
    landRevenue: any;
    bhuAdhikarNumber: any;
    northBoundary: any;
    southBoundary: any;
    westBoundary: any;
    eastBoundary: any;
    propTypeId: any;
    propertyId: any;
}
// Property list Model 
export class ERegPropIdModel {
    IDS: string;
    REG_ID: string;
    PROP_ID: string;
    PROP_TYPE_NAME: string;
}

export class IgrsEregTxnDetlsBean {
    id: number;
    regId: string;
    tempEregId: string;
    eregDate: Date;
    dutyId: number;
    statusId: number;
    srOfficeId: number;
    eregCalYear: number;
    eregFinYear: number;
    representedBy: number;
    representedByIfscCode : string;
    userId: number;
    estampId: number;
    facelessEligibility: number;
    facelessOpted: number;
    ipAddress: string;
    videoKycId: number;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    igrsInstFieldMappingDtoList: InstMappingBean[] = new Array();
    instAdditionalMappingBeanList: InstAdditionalMappingBean[] = new Array();
    igrsEregAddInputFieldsBeanlist: IgrsEregAddInputFieldsBean[] = new Array();
    deedInstrumentSelected: DeedInstrumentBean;
    deedInstrumentSelectedImpound: DeedInstrumentBean;
    deedInstrumentSelectedCOSImpound: DeedInstrumentBean;
    deedInstrumentSelectedForAmendmentCancellation: DeedInstrumentBean;
    deedInstrumentSelectedForAdjudication: DeedInstrumentBean;
    eregTypeBean: MstRegistryTypeModel;
    eregTypeId: number;
    viewParam: string
    dataLoading: boolean = true;
    propValidationFormArray: FormArray;
    igrsEregPropIdentificationBeanImpound: IgrsEregPropIdentificationBean[] = new Array();
    igrsEregPropIdentificationBean: IgrsEregPropIdentificationBean[] = new Array();
    igrsEregPartyDetailsBeanList: IgrsEregPartyDetailsBean[] = new Array();
    igrsEregPartyDetailsBeanListSam1: IgrsEregPartyDetailsBean[] = new Array();
    inputFields: any;
    inputFieldsImpound: any;
    inputFieldsCOSImpound: any;
    inputFieldsAdjudication:any;
    addInputFields: any = new Object();
    eRegUploadLinkModel: UploadLinkPropertyModelArray[]
    igrsMvPropTxnDtoArry: IgrsMvPropTxnDto[] = new Array();
    igrsEregWillOutsidePropDetlsBeanArray: IgrsEregWillOutsidePropDetlsBean[] = new Array();
    igrsEregistryConsdAmtDetailsArray: IgrsEregConsiderationAmtDetailBean[] = new Array();
    slotBookingTransactionDetailsResponseBean: TransactionDetailsChallanModel[] = new Array();
    slotBookingWalletPaymentBean: EstampPaymentRequest;
    eregTransactionDetailsResponseBean: TransactionDetailsChallanModel[] = new Array();
    eregWalletPaymentBean: EstampPaymentRequest;
    eregImpoundWalletPaymentBean:EstampPaymentRequest;
    igrsEregPaymentFinalDetailsBean: IgrsEregPaymentFinalDetailsBean[] = new Array();
    eregPaymentRequest: EregPaymentRequest[] = new Array();
    igrsEregSlotBookedBean: SlotModel;
    dutyDetailsWithConsGlDataDto: DutyDetailsBean = new DutyDetailsBean();
    statusBean: MstStatusBean;
    igrsDutyExemptionMappingDtoList: DutyExemptionMapping[] = new Array();
    igrsDutyExemptionMappingDtoListImpound: DutyExemptionMapping[] = new Array();
    igrsDutyExemptionMappingDtoListCOSImpound: DutyExemptionMapping[] = new Array();
    igrsEregEstampBean: EstampDetails;
    igrsCitizenUserBean: CitizenUserProfileDetails;
    totalConsiderationPaidAmount: number;
    securedAmountInWords : any;
    securedAmountInWordsHindi : any;
    totalConsiderationRemainingAmount: number;

    igrsDutyCalculationTxnDto: any;
    igrsDutyCalculationTxnDtoImpound: any;
    igrsDutyCalculationTxnDtoCOSImpound: any;
    igrsDutyCalculationTxnDtoAdjudication: any;

    dutyCalInputFieldIdDisplayMap: Map<number, any[]>;
    dutyCalInputFieldIdDisplayList: any[];
    impoundPaymentStatus:number;
    partyDtlsVerifyMakerRemarks: string;
    partyDtlsVerifyCheckerRemarks: string;
    movPropertyTransact: boolean;
    partyWitnessPresentation: number;
    partyAddSameWitness: number
    viewJasperDeed: boolean = false;
    movRadioEnDisable: boolean;

    aspDtlsVerifyCheckerRemarks: string;
    aspDtlsVerifyCheckerActionOn: string;
    aspDtlsVerifyCheckerUserId: string;

    partyDtlsVerifyCheckerActionOn: string;
    partyDtlsVerifyCheckerUserId: number;

    pivVerifyMakerRemarks: string;
    prpivDtlsVerifyMakerActionOn: string;
    pivDtlsVerifyMakerUserId: number;

    pivVerifyCheckerRemarks: string;
    pivDtlsVerifyCheckerActionOn: string;
    pivDtlsVerifyCheckerUserId: number;

    deedVerifyMakerRemarks: string;
    deedDetlsVerifyMakerActionOn: string;
    deedDetlsVerifyMakerUserId: number;

    deedVerifyCheckerRemarks: string;
    deedDetlsVerifyCheckerActionOn: string;
    deedDetlsVerifyCheckerUserId: number;
    deedTemplateId: number;
    estampDto: AddEstamp;

    isAllPartiesPresentMaker: boolean;
    isAllPartiesPresentMakerActionOn: string;
    isAllPartiesPresentMakerUserId: number;
    isAllPartiesPresentChecker: boolean;
    isAllPartiesPresentCheckerActionOn: string;
    isAllPartiesPresentCheckerUserId: number;
    eregCompMMYEAR: string;
    cyberTehsilFormA1List: TemplateFieldsModel[];
    cyberTehsilFormA2List: TemplateFieldsModel[];
    sellerPartyDetails: IgrsEregPartyDetailsBean[];
    buyerPartyDetails: IgrsEregPartyDetailsBean[];
    deedContent: string;

    totalConsidAmtPaidInFrontOfSr: number;

    totalConsidPaidAmtMakerRemarks: string;
    totalConsidPaidAmtMakerActionOn: string;
    totalConsidPaidAmtMakerUserId: number;

    totalConsidRemainingAmtMakerRemarks: string;
    totalConsidRemainingAmtMakerActionOn: string;
    totalConsidRemainingAmtMakerUserId: number;

    totalConsidPaidAmtCheckerRemarks: string;
    totalConsidPaidAmtCheckerActionOn: string;
    totalConsidPaidAmtCheckerUserId: number;

    totalConsidRemainingAmtCheckerRemarks: string;
    totalConsidRemainingAmtCheckerActionOn: string;
    totalConsidRemainingAmtCheckerUserId: number;

    totalConsidAmtPaidInFrontOfSrCheckerRemarks: string;
    totalConsidAmtPaidInFrontOfSrCheckerActionOn: string;
    totalConsidAmtPaidInFrontOfSrCheckerUserId: number;
    requiredCyberTehsilForm: boolean;
    formA: string;
    formA1: string;
    formA2: string;
    month: string;
    year: string;
    findPdfText: string;
    isImpoundFlow: boolean = false;
    isCMSImpoundFlow: boolean = false;
    glValueTotal: number; 
    glValueImpoundTotal: number;
    glValueCOSImpoundTotal: number;
    glValueAdjudicationTotal: number;
    considerationAmt:number
    considerationAmtImpound:number
    considerationAmtCOSImpound:number
    considerationAmtAdjudication:number
    estampDeedContent: EstampDeedContent;
    partyTypeList: PartyTypeModel[];
    presentationBy: string;
    igrsBankFinancialInstituteDetailsBean: IgrsBankFinancialInstituteDetailsBean;
    selectedMineralList: MineralMstBean[] = new Array();
    mineralTypeId: number;
    igrsEregParticularDetailBeanList: IgrsEregParticularDetailBean[];
    deedTemplateList: EstampTemplateBean[] = new Array();
    igrsEregImpoundTxnBean: IgrsEregImpoundTxnBean;
    igrsEregImpoundPropertyBean: IgrsEregImpoundPropertyBean[];

    igrsEregAdjudicationBean:IgrsEregAdjudicationBean;
    igrsEregAdjudicationPropertyBean:IgrsEregAdjudicationPropertyBean[];

    eregCompletionDate: string;
    eregCompCheckerId: number;
    eregDmsDocumentId: number;
    isPropAddedFlag: boolean;

    presentationSealStatus: number;
    presentationSealOn: string;
    presentationSealBy: number
    admissionSealStatus: number;
    admissionSealOn: string;
    admissionSealBy: number;
    numberOfPartition: number;
    permissionReqTrxnMplrc: boolean;
    action: string;
    uploadLinkSourceOfProp: EregPropertyLinkModel[] = new Array();
    paymentStatusId: number;
    deedLanguage:string;
    finalDeedgenerated:number;
    totalConsenterConsdAmount:number;
    igrsCmsFinalOrderBean:IgrsCmsFinalOrderBean=new IgrsCmsFinalOrderBean();
    totalConsRemainingAmt:number;
    vkycStatus: string;
    templateId:number;
    additionalPageCount:any;
    reserveRegNo:string
    directionFlag:any
    source:any;
    igrsEregHoldDocumentBean : IgrsEregHoldDocument = new IgrsEregHoldDocument();
    sam1RegNo:any;
    igrsEregCompAdditDocBean : IgrsEregCompAddDocBean[];
    igrsEregReturnDocumentBean : IgrsEregReturnDocument = new IgrsEregReturnDocument();
    igrsEregRefuseDocumentBean : IgrsEregRefuseDocument = new IgrsEregRefuseDocument();
    docNo:any;
    cmsRegSearchDetails : CMSRegSearchDetails = new CMSRegSearchDetails();
    currentEregDocName : any;
    parentDeedCategoryId : any;
    parentDeedTypeId : any;
    parentDeedInstrumentId : any;
    parentRegNo : any;
    parentDateOfExecution : any;
    parentDateOfRegistration : any;
    instrumentType: string;
    parentSampadaIdFlag:any;
    eregMortgageDmsDocId:any;
	igrsProtestIdentifyBeanList: IgrsProtestIdentifyBean[] = new Array();
    igrsProtestReqTxn : IgrsProtestRequestTxn = new IgrsProtestRequestTxn();
    districtId: number;
    minMapAvl:number;
	minMapEastDir:string;
	minMapWestDir:string;
	minMapNorthDir:string;
	minMapSouthDir:string;
	minMapFileName:string;
	minMapMonth:string;
	minMapYear:string;
    minMapFileBase64:string;
    isCompReg:number;
    compParentRegId:number;
    compRegStatusId:number;
    cyberTehsilCheck:boolean;
    representativeDetail:Representative;
    processingStatus:number;
    previousEregTypeId:number;
    ifscCode: string;
    isCompRegPaymentApplicable:number;
    cyberTehsilPushCount:number;
    //compositeIgrsEregTxnDetlsBean:IgrsEregTxnDetlsBean=new IgrsEregTxnDetlsBean();
    srOfficeName: string;
    srOfficeNameHi: string;
    srName: string;
    srNameHi: string;
}

export class IgrsCmsFinalOrderBean{
    finalPayableAmount:number;
    penalityAmount:number;
    netStampDuty:number;
    netRegFees:number;
    esignFlag:number;
}

export class SlotModel {
    id: number;
    slotTime: string;
    usersAllowed: number;
    usersBooked: number;
    usersRemaining: number;

    srOfficeId: number;
    srOfficeDistrictId: number;
    regTypeId: number;
    slotDate: string;
    isPremium: number;
    ackMedium: number;
    slotStatus: number;
    eregId: number;
    slotRefNo: string;
    rescheduleSlot: boolean;
    rescheduleSlotWithPayment: boolean;
    slotSelected: boolean = false;

    tokenNo: string;
    eregNo: string;
    srOfficeName: string;
    srOfficeNameHi: string;
    partyName: string;
    TOKEN_GENERATION_ALLOWED_BEFORE_MINUTES: number;
    eregStatus: number;
    rescheduleFeeMultiplicationFactor: number;
    srName: string;
    srNameHi: string;
}
export class HolidayModel {
    fyHolidayTypeId: number;
    holidayType: string;
    holidayDate: string;
    holidayName: string;
    holidayNameHi: string;
}


export interface DocUploadarr {
    CertID?: number
    month?: string
    year?: string
    CertifiedNo?: string
    filePath?: string
    descriptionDtl?: string
}



export class IgrsEregLoanDetlsBean {
    id: number;
    loanCersaiId: string;
    orgId: number;
    bankOrganisationName: string;
    loanAmount: number;
    loanStartDate: Date;
    loanTenure: number;
    otherDetls: string;
    propertyId: string;
    identificationId: number;
    ipAddress: string;
    makerRemarks: string;
    checkerRemarks: string;
    parentId: number;
}


export class IgrsEregMunicipalTaxesBean {
    id: number;
    waterMunicipalTax: number;
    sewerMunicipalTax: number;
    garbageMunicipalTax: number;
    propertyId: string;
    identificationId: number;
    ipAddress: string;
    makerRemarks: string;
    checkerRemarks: string;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    parentId: number;
}

export class IgrsEregIvrsDetlsBean {
    id: number;
    billNumberIVRS: string;
    addressIVRS: string;
    latitudeIVRS: number;
    longitudeIVRS: number;
    propertyId: string;
    identificationId: number;
    ipAddress: string;
    electricityBillAmount: number;
    makerRemarks: string;
    checkerRemarks: string;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    parentId: number;
}

export class IgrsEregPropChargeCasesBean {
    id: number;
    protestChargeCases: string;
    stampChargeCases: string;
    propertyId: string;
    rrcChargeCases: string;
    ipAddress: string;
    makerRemarks: string;
    checkerRemarks: string;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    parentId: number;
}

export class IgrsEregPropAdditionalDetlsBean {
    id: number;
    transactingArea: number;
    premutationId: string;
    premutationSketchId: string;
    premutationDocId: string;
    northBoundary: string;
    southBoundary: string;
    eastBoundary: string;
    westBoundary: string;
    isPartialTransact: boolean;
    propertyAddress: string;
    propFileName: string;
    propMapUploadPath: string;
    propMapUpload: string;
    fkEregId: number;
    propertyId: string;
    eregId: string;
    identificationId: number;
    ipAddress: string;
    month: string;
    year: string;
    landParcelId: string;
    ULPin: string;
    landParcelIdPropSelected: number;
    preMutationAreaFromClr: number;
    docMapB64_MM_YYYY: string;
    docPreB64_MM_YYYY: string;
    docKhasraB64_MM_YYYY: string;
    parentId: number;
    docMapB64fileName: string;
    docPreB64ileName: string;
    docKhasraB64fileName: string;
    preMutationDocName: string;
    khasraId: string;
    transectionId: string;
    khasraNo: string;
    makerRemarks: string;
    checkerRemarks: string;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    propertyMapBase64: string;
    isMapManualUpload:number;
    districtNameEn:any;
    districtNameHi:any;
    tehsilNameEn:any;
    tehsilNameHi:any;
    localBodyNameEn:any;
    localBodyNameHi:any;
    wardNameEn:any;
    wardNameHi:any;
    villageNameEn:any;
    villageNameHi:any;
    areaTypeNameEn:any;
    areaTypeNameHi:any;
    subAreaType:any;
    areaTypeId:any;
    isMapUpload:number;
    mapFatchedFlag:number;
}

export class CMSRegSearchDetails{
    deedInstrumentSelected: DeedInstrumentBean;
    deedCategoryNameEn : string;
	deedCategoryNameHi : string;
	deedTypeNameEn : string;
	deedTypeNameHi : string;
	instrumentNameEn : string;
	instrumentNameHi : string;
	
	registryInitiationDate : any;
	dateOfExecution : string;
	dateOfPresentation : string;
	documentRegistrationDate : any;
	registeredDocNo : string;
	
	natureOfInstrument : string;
	considerationValue : any;
	guideLineValue : any;
	
	janpad : any;
	upkar : any;
	muncipal : any;
	principle : any;
	exemptedAmount : any; 
	alreadyPaidStampDuty : any;
	netStampDuty : any;
	
	registrationFee : any; 
	exemptedRegistrationAmount : any; 
	alreadyPaidRegistrationFee : any;
	netRegistrationFee : any;
	
	srOfficeId : string;
	srOfficeNameEn : string;
	srOfficeNameHi : string;
	srName : string;
    sam2InstrumentId:any;
}

export class IgrsEregPropIdentificationBean {

    id: number;
    userEnteredPropId: string;
    demographyId: number;
    sampadaPropertyId: string;
    propertyId: string;
    propTypeId: number;
    eregId: string;
    status: boolean;
    fkEregId: number;
    ipAddress: string;
    propIdentificationJson: string;
    totalArea: number;
    partialTransactArea: number;
    propertyType: string; 
    propertySource: string;
    fkPropId: number;
    propertyValuationRequestBean: PropertyValuationRequestBean;
    propertyValuationResponseBean: PropertyValuationResponseBean;
    propertyValuationDeviationBean: PropertyValuationDeviationBean;
    propertyValuationRequestBeanImpound: PropertyValuationRequestBean;
    propertyValuationRequestBeanCOSImpound: PropertyValuationRequestBean;
    propertyValuationRequestBeanCOSAdjudication: PropertyValuationRequestBean;
    propertyMapBase64Image: string;
    makerRemarks: string;
    checkerRemarks: string;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    subClauseDeviationList: ApplicableSubClauseResponse[] = [];
    expanded: boolean;
    inputFields: any;
    inputFieldsImpound: any;
    inputFieldsImpoundCOS: any;
    inputFieldsAdjudication: any;
    partySubTypeId: number;
    isImpoundAddedProp: boolean
    isCOSImpoundAddedProp: boolean
    impoundPropTxnId:any
    forestDivisionId:any;
    forestRangesId:any;
    forestCircleId:any;
    forestDivisionNameEn:any;
    forestDivisionNameHi:any;
    forestRangesNameEn:any;
    forestRangesNameHi:any;
    source:any;
    sam1RegNo:any;
    sam1EregCompletionDate: any;
    fkProtestId:number;
    fkNullAndVoidId:number;
    fkBankCharges: number;
    parentInstrumentId: number;
    currentInstrumentId?:number;
}

export class IgrsEregMutationFeeGroupBean{
    id:Number;
	eregId:Number;
	distCode:Number;
   tehsilCode:string;
    lgdCode:string;
    landParcelId:string;
    propId:string;
	rcmsId:Number;
}

export class IgrsMvPropTxnDto {

    mvPropId: number;
    dutyId: number;
    mvPropDetls: string;
    mvPropConsdAmt: number;
    suppDocMovProp: string;
    fileUploadName: string;
    mvPSD: number;
    mvRegFee: number;
    eregId: string;
    fkEregId: number;
    status: boolean;
    added: boolean;
    edit: boolean;
    movPropMapUploadPath: string;
    fileName: string;
    mmYear: string;
    makerRemarks: string;
    checkerRemarks: string;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
}

export class IgrsEregConsiderationAmtDetailBean {

    id: number;
    buyerId: string;
    sellerId: string;
    transIdOrChequeNo: string;
    bankName: string;
    bankNameHi: string;
    paidAmount: number;
    paymentDate: Date;
    paymentMode: number;
    transactionDetails: string;
    eregConsiderationId: number;
    ipAddress: string;
    status: boolean;
    added: boolean;
    edit: boolean;
    bankId: number;

    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;

    //private IgrsMstPaymentModeEntity igrsMstPaymentModeEntity;	
    igrsMstPaymentModeBean: IgrsMstPaymentModeBean;
    igrsERegPartyDetailsBuyer: IgrsEregPartyDetailsBean;
    igrsERegPartyDetailsSeller: IgrsEregPartyDetailsBean;

    makerRemarks: string;
    checkerRemarks: string;
    consdPaidMakerRemarks: string;
    consdPaidCheckerRemarks: string;
    consdRemainingMakerRemarks: string;
    consdRemainingCheckerRemarks: string;

}

export class IgrsMstPaymentModeBean {
    id: number;
    paymentModeEn: string;
    paymentModeHi: string;
    status: boolean;
    ipAddress: string;
}

// Eregistry Party IgrsEregPartyDetailsBean
export class IgrsEregPartyDetailsBean {

    id: number;
    sNo: number;
    propertyIdIndex: string;
    partyType: number;
    userType: number;
    userName:string;
    applicantType: string;
    cinPanGstin: string;
    mobileNo: string;
    altMobileNo: string;
    whatsappMobileNo: string;
    partyName: string;
    firstNameEn: string;
    middleNameEn: string;
    lastNameEn: string;
    firstNameHi: string;
    middleNameHi: string;
    lastNameHi: string;
    partyNameHindi: string;
    emailId: string;
    eregId: number;
    ekycStatus: number = 0;
    panEkyc: number = 0;
    panNumber: string;
    nameOnPan: string;
    form60Ekyc: number = 0;
    fatherHusbandGardianNameEn: string;
    fatherHusbandGardianNameHi: string;
    dob: string;
    identityCardNo: string;
    address: string;
    addressHindi: string;
    addressLine1: string;
    addressLine2: string;
    gender: string;
    relationshipType: string;
    country: number;
    state: number;
    district: number;
    city: string;
    cityHi: string;
    pinCode: string;
    addressType: string
    corresCountry: number;
    corresCountryName: string;
    corresCountryNameHi: string;
    corresState: number;
    corresStateName: string;
    corresDistrict: number;
    corresDistrictName: string;
    corresCity: string;
    corresCityHi:string;
    corresAddressLine1: string;
    corresAddressLine2: string;
    corresAddressLine1Hi: string;
    corresAddressLine2Hi: string;
    corresAddressHindi: string;
    corresPinCode: string;
    organization: string;
    identityCardType: string
    noOfPerson: string;
    kycTxnLogNo: string;
    kycUniqueToken: string;
    totalArea: string
    partialTransactArea: string
    partyAddress: string
    transactingThroughPOA: boolean

    createdBy: number
    ownerName: string;
    ownerFatherName: string;
    sameAsAadharAddress: boolean;
    userId: number
    age: string
    ageHi: string
    aadharEkyc: number = 0;

    // For Form 60 
    flatRoomNo: string
    floorNumber: string
    noOfPremises: string
    blockNo: string
    roadStreetLane: string
    areaLocality: string
    telephoneNumber: string
    amountOfTransaction: number
    dateOfTransaction: string
    noOfPersons: number
    modeOfTransaction: string
    aadharNumberMasked: string
    aadharRefKey: string
    vidNumber: string
    panDateOfApplication: string
    panAcnowledgementNo: string
    agricultureIncome: number
    otherAgricultureIncome: number
    partyDocCode: string
    partyDocIdentificationNo: string
    partyAddressAuth: string
    addressDocCode: string
    addressDocIdentificationNo: string
    addressAuth: string

    esignTxnId: string;
    esignDate: string;
    aadharPhoto: string;
    aadharPhotoUrl: string;
    cameraPhotoUrl: string;
    cameraPhoto: string;
    applicantTypeNameEn: string
    applicantTypeNameHi: string
    form60: number
    partyTypeNameHi: string
    partyTypeNameEn: string
    checkerRemarks: string

    executantPartyId: number
    parentId: number;
    makerRemarks: string
    remarksChk: boolean
    remarksChkAsp: boolean
    remarkValidation: boolean = false;
    executantRelationshipProof: string
    executantDeathCertificate: string
    status: number;
    statusBean: MstStatusBean;
    partySubTypeId: number;
    contentStatus: number;
    isSRUpdate: number;
    srAddedWitness: number = 0;

    wifeFatherName: string;
    customerUrlNonAssisted: string;
    executiveUrlNonAssisted: string;

    // poa fields
    poaType: number
    poaRegDocNo: string
    estampCodeRefNo: string
    poaEstampCodeRefNo: string
    poaRegistrationNo: string
    poaSubRegistrarOff: string
    poaAuthPersonName: string
    poaRegistrationDate: string
    poaStatus: number
    poaCountry: number
    poaState: number
    poaDistrict: number
    poaCityTehsil: string
    poaAddress: string
    poaPincode: string
    documentPath: string;
    documentType: string;
    editFile: number = 0;
    filePath: string;
    month: string;
    year: string;
    vkycJoinStatus: string;
    latitude: number;
    longitude: number;
    documents: VkycCustomerDoc[];
    poaTypeEn: string;
    poaTypeHi: string;
    executantPartyName: string
    isExecutant: boolean
    partyDisable: boolean
    isChecked: boolean

    partyPropMappingBeanList: IgrsEstampPartyPropMappingBean[] = [];
    userProfileModel: CitizenUserProfileDetails = new CitizenUserProfileDetails();
    juristicPersonProfileModel: juisticPersonDetail = new juisticPersonDetail();
    caste: string;
    religion: string;
    admissionSignStatus: number;

    aspDtlsVerifyCheckerRemarks: string;
    aspDtlsVerifyCheckerActionOn: string;
    aspDtlsVerifyCheckerUserId: string;
    isEdit: boolean = false;
    isMinor: number = 0;
    //buyerName: string;
    //consenterName: string;
    //witnessName: string;
    orderReferenceNo: string;
    partyBean: IgrsEregGuardianPOADetailsBean;//guardian //POA
    //minorMobileNo: string
    idProofType: string;
    orgDesignationEn: string;
    orgDesignationHi: string;
    //poaOwnerMobileNo: string

    countryOrg: number
    stateOrg: number
    districtOrg: number
    cityOrg: string
    addressOrg: string
    pinCodeOrg: string

    //poa
    poaExecutionDate: string
    poaRegDoc: string

    //owner radio btn for yes no
    ownerRegisteredUser: number

    userDetailsFilled: boolean = false;
    eregFlag: any;
    panResponseId: string;
    estampId: number;
    dscSignPartyPhoto: string;
    dscSignPartyThumb: string;
    dscSignPartyThumbTemplate: string;
    dscSignPartyCert: string;
    dscSignPartyPdfBase64: string;
    esignMode: number;
    aadhaarName: string
    govtDeptName: string
    govtDeptNameHi: string
    govtDeptId: number;
    isAdded: boolean = false;
    directionFlag: number;
    faceMatchedStatus: any;
    faceMatchedPer: any;
    docOneId: string;
    docOneType: string;
    docOneUploadType: string;
    docOneUploadName: string;
    docTwoId: string;
    docTwoType: string;
    docTwoUploadType: string;
    docTwoUploadName: string;
    filePathAdd: string;
    documentPathAdd: string;
    filePathAddTwo: string;
    documentPathAddTwo: string;
    igrsPartyTypeBean: PartyTypeModel;
    dlEkyc: number = 0;
    minorAllowed: boolean;
    poaTypeAllowed: string;
    consenterConsiderationAmt: string;
    motherNameEn:string
    motherNameHi:string
    deedTypeId: number;

    bankBranchModel: RepresentativeDetailsModel = new RepresentativeDetailsModel();
    
    //
    nameEn?:string;
    designation?:string;
    tempEregId: string;
    templateKey:string;
    representedBy:any;
    eStampCode:any;
    isValidBase64: boolean;
    isAadhaarKycPerformed:boolean
    sumOfMarketVal:number;
    isAadhaarKycPerformedByEregCompl: boolean = false;
    isKycPerformedByGreenFieldProject: any;
    isIndianResident:number
    alreadyAdded: boolean = false;
}

export class MstBankIfscCodeModelBean {
    id?: number;
    bankMasterId?: string = "";
    ifscCode?: string;
    branchName?: string;
    address?: string;
    district?: string;
    state?: string;
    districtId?: string = "";
    stateId?: string = "";
    pincode?: number;
    status?:number=1;
    mstBankMaster: MstBankMasterBean;
  }

export class IgrsEregGuardianPOADetailsBean extends IgrsEregPartyDetailsBean {


}

export class IgrsEregPropImgDisplayBean {
    id: number;
    propertyLeftView: string;
    propertyRightView: string;
    propertyCenterView: string;
    imageDirectionType:number;
    valuationID: number;
    propId: string;
    regID: string;
    fkEregId:number;
    capturedBy: string;
    contactNumber: number;
    capturedOn: string;
    month: string;
    year: number;
    leftLattitude: number;
    rightLattitude: number;
    centerLattitude: number;
    leftLongitude: number;
    rightLongitude: number;
    centerLongitude: number;
    propertyLeftViewByte: any;
    propertyRightViewByte: any;
    propertyCenterViewByte: any;
    makerRemarks: string;
    checkerRemarks: string;
    identificationId:number;
    fkPropSourceId:number;
}

export class nameBean {
    id: string;
    nameEn: string;
    nameHi: string
    applicantTypeId: string;
    cinPanGstin: string;
    partyPropMappingBeanList: IgrsEstampPartyPropMappingBean[] = [];
}

export class PartyTypeModel {
    partyTypeId: number
    instrumentId: number;
    partySubTypeId: number;
    partyTypeNameEn: string;
    partyTypeNameHi: string;
    minorAllowed: boolean;
    applicantTypeAllowed: string;
    poaTypeAllowed: string;

    constructor(partyTypeNameEn: string,partyTypeNameHi: string){
        this.partyTypeNameEn = partyTypeNameEn
        this.partyTypeNameHi = partyTypeNameHi
    }
}

export class VkycCustomerDoc {

    url: string;
    type: string;
    format: string;

}

export class EregPropertyLinkModel {
    id: number;
    prevRegType: number;
    documentPath: string;
    documentType: string;
    refNo: string;
    detailDesc: string;
    regDate: Date
    propId: string;
    eregId: string;
    webgisPropId: string;
    ipAddress: string;
    sNo: number;
    propIds: string;
    editFile: number = 0;
    filePath: string;
    month: string;
    year: string;
    propIdentificationId: number;
    fkPropSourceId: number;
    propSource: string;
    makerRemarks: string;
    checkerRemarks: string;
    propertyOwnershipIds: number;
    propSourceName:string;
    propSourceNameHi:string;
    isEditable:any;
    isDeletable:any;

    //for leagacy document
    legacyDocumentNo: any;
    legacyVolumeNo: any;
    legacyDistrictId: any;
    legacySroOfficeId:any;
    legacyFinancialYear:any;
}


export class IgrsEregPaymentFinalDetailsBean {
    id: number;
    regId: number;
    urn: string;
    purpose: string;
    paymentStatus: string;
    makerRemarks: string;
    checkerRemarks: string;
    payMode: string;
}

export class IgrsEstampPartyPropMappingBean {

    id: number;
    fkPartyId: number;
    fkPropSourceId: number;
    fkPropIdentificationId: number;
    fkSourceOwnerId: number;
    shareInPropertyArea: string;
    shareInPropertyPercentage: string;
    shareBeingSoldArea: string;
    shareBeingSoldPercentage: string;
    shareBeingPurchasedArea: string;
    shareBeingPurchasedPercentage: string;
    makerRemarks: string;
    checkerRemarks: string;
    propertyType: string;
    propertyTypeHi: string;
    sampadaPropertyId: string;
    propertyId: string;
    khasraPlotHouseNo: string;
    status: boolean;
    propSource: string
    consenterConsiderationAmt: string;
    propertyTypeId: number;
    unit: string;
    totalArea: number
}


export class MstEregOtherDetailsBean {
    id: number;
    otherDescEn: string;
    otherDescHi: string;
    status: boolean;
    ipAddress: string;
}

export class MstEregReasons {
    id: number;
    reasonEn: string;
    reasonHi: string;
    reasonType: string;
    status: boolean;    
}

export class IgrsMstPartyPoaTypeBean {
    id: number;
    partyPoaTypeEn: string;
    partyPoaTypeHi: string;
    poaStatus: number;
    ipAddress: string;
}

export class DutyInputFields {
    id: number;
    value: any
}

export class PropertyIdentificationBySampadaId {
    id: number;
    fkEregId: number;
    propertyType: string;
    propertySource: string;
    propertyTypeId: number;
    propertyId: string;
    propertyNo:number
    landParcelId: string;
    khasraplotNo: string;
    addressOfProperty: string;
    transactingArea: number;
    share: string;
    alreadyAdded: boolean;
    unit : string;
    demography?:MstDemographyBean;
    districtId: number;
    index:number
}


export class IgrsEregImpoundTxnBean {
    id: number;
    eregId: number;
    statusId: number;
    dutyId: number;
    impoundType: number;
    status: boolean;

    createdDate:Date;
    modifiedDate:Date;
    createdBy:number;
    modifiedBy:number;
    ipAddress:string;
    
    subRegistrarRemarks: string;
    headIdSelected: number;
    sectionIdSelected: number;
    impoundDocHoldRemarks: string
    igrsDutyCalDifferencialBean: IgrsDutyCalDifferencialBean=new IgrsDutyCalDifferencialBean();
    igrsDutyCalCOSDifferencialBean: IgrsDutyCalDifferencialBean
    cosDutyId: number;

   // base64ProposalForm :any
    proposalFormFileName:string
    documentName : string;
    documentDesc : string;
    documentFile : string;
    documentFileName : string;

     impoundProposalDocName:string;
     formMMYear:string;

    procedureData:CMSImpoundProcedureBean=new CMSImpoundProcedureBean();
    cosProcedureData:COSImpoundProcedureBean;
    inputFieldsImpound:any
    inputFieldsAdjudication:any
    selectedExemptionListImpound:any
    selectedExemptionListCOSImpound:any
    isImpounded:boolean;
    isCOSImpounded:boolean;
    cosActionOn:string;
	    
	cosUserId:number;

    parentDemographyId:number;

    guidelineValue:number;
	guidelineValueImpound:number;
	guidelineValueCOSImpound:number;

    isImpoundSealAffix:boolean;
	proposalSealActionOn:string;
	 
	isProposalSealAffix:boolean;
	impoundSealActionOn:string;

    isRegfeeSealAffix:boolean;
    regfeeSealActionOn:string;
    isAdditionalDocUpload:boolean;
    isAdditionalDocUploadChecked:boolean;
    instrumentIdImpound:number;
    instrumentIdCOSImpound:number;

}

export class IgrsEregImpoundPropertyBean {
    id: number;
    eregId: number;
    impoundId: number;
    identificationId: number;
    valuationId: number;
    status: boolean;
    propertyJson: any;
    cosPropertyJson:string;
    cosActionOn:Date;    
	cosUserId:number;
    cosValuationId: number;
    inputFieldJson:any
    inputFieldCOSJson:any;

    createdDate:Date;
    modifiedDate:Date;
    createdBy:number;
    modifiedBy:number;
    ipAddress:string;
}

export class IgrsDutyCalDifferencialBean {

    principalStampDutyDiff: number;
    municipalDutyDiff: number;
    janpadDutyDiff: number;
    upkarDutyDiff: number;
    exempStampDiff: number;

   // totalStampDutyDiff:number;
    totalRegFeeDiff: number;
    exempRegDiff: number;

    totalStampDutyToBePaid: number;
    totalRegFeeToBePaid: number;

    principalStampDutyDiffRemarks: string;
    municipalDutyDiffRemarks: string;
    janpadDutyDiffRemarks: string;
    upkarDutyDiffRemarks: string;
    exempStampDiffRemarks: string;

    totalRegFeeDiffRemarks: string;
    exempRegDiffRemarks: string;

    totalStampDutyToBePaidRemarks: string;
    totalRegFeeToBePaidRemarks: string;

    natureOfDocRemarks: string;
    glAmtRemarks: string;
    stampDutyDiffRemarks: string;
    regFeeDiffRemarks: string;
    considerationAmtRemarks: string;
}

export class IgrsBankFinancialInstituteDetailsBean {
    id: number;
    stateId:number;
    districtId: number;
    tehsilId: number;
    repreUserId:number;
    bankFinancialInstituteTypeId: number;
    bankFinancialInstituteName: string;
    ifscCode: string;
    bankFinancialInstituteAddress: string;
    authorizingAuthority: string
    totalLoanAmount: number;
    sanctionedAmountLoan: number;
    eregId: number;
    authorizedPersonDesignation: string;
    msme: string;
    makerRemarks: string;
    checkerRemarks: string;
    bankName:string;
    bankNameHi:string;
    district: DemographyModel;
    tehsil: DemographyModel;
    bankFinancialInstituteType: MstBankFinancialInstituteTypeBean
    bankFinancialInstitute: MstBankMasterBean;
}

export class IgrsEregWillOutsidePropDetlsBean {

    id: number;
    outsidePropDetls: string;
    eregId: string;
    fkEregId: number;
    status: boolean;
    makerRemarks: string;
    checkerRemarks: string;
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
    isEditProp: boolean;
}

export class IgrsEregMasterLandRecordActsts {
    distCode: string;
    tehCode: string;
    ricircleCode: string;
    halkaNo: string;
    bhuCode: string;
    landOwnerIdDept: string;
    landOwnerName: string;
    fatherName: string;
    basraNo: string;
    khasraIdDept: string;
    khasraNo: string;
    khasraArea: string;
    ownerType: string;
    landType: string;
    isIrriGated: string;
    khasraMainPart: string;
    subKhasra1: string;
    subKhasra2: string;
    subKhasra3: string;
    ownerShare: string;
    isDiverted: string;
    actionStatus: string;
    readByMapit: string;
    actionDate: Date;
    lgdCode: string;
    plotType: string;
    landUseType: any;
    khasraRevenue: string;
    noyiyat: string;
    status: any;
    cropType: any;
    khasraUnqNo: any;
    alpinNo: string;
    sourceOfIrrigation: any;
    mobileNo: any;
    pmKisanMobileNo: any;
    isMortgage: string;
    isHypoth: string;
    ownerCreatedDate: Date;
    ownerSamagraId: any;
    aadharReferenceNo: string;
    geomkId: any;
    parentGeomkId: string;
    checked: any;
}
export class IgrsEregParticularDetailBean {
    id: number;
    particularName: string;
    particularDiscription: string;
    eregId: number;
    status: number;
    isInputDisabled: boolean = false;
    isSave: boolean = true;
    isEdit: boolean = false;
    partiCularDiv: boolean = false;
}

export class IgrsEregCompAddDocBean {
    id: number;
    documentName: string ;
    documentDescription: string ;
    documentBase64Str : string ;
    documentFileName : string ;
    eregId: number;
    status: number;
    isInputDisabled: boolean = false;
    isSave: boolean = true;
    isEdit: boolean = false;
    isDelete: boolean = false;
    docNmDiv: boolean = false;
    docDescDiv: boolean = false;
    docFileDiv: boolean = false;
    docUploadedBy : string;
}

export class IgrsMineralMapListBean {
    key: any;
    value: any;
}


export class IgrsEregAddInputFieldsBean {
    id: number;
    fieldId: number;
    value: string;
    eregId: number;
    propValId: number;
    fieldIdName: IgrsEregInstAddFieldMappingDto;
    inputFieldList: IgrsEregAddInputFieldsBean[];
    addInputFieldJson: any;
}

export class IgrsEregInstAddFieldMappingDto {
    id: number;
    instrumentCode: string;
    fieldNameEn: string;
    fieldNameHi: string;
    fieldType: string;
    parentId: number;
    status: boolean;

    instrumentId: DeedInstrumentBean;
    fieldId: IgrsDutyCalculation[];
    //fieldId : any;
    parentMappingList: IgrsEregInstAddFieldMappingDto[];

    isPopup: number;
    popupNameEn: string;
    popupNameHi: string;

    value: string;
    isRequired: boolean;
    minimum: number;
    maximum: number;
    validationMsgEn: string;
    validationMsgHi: string;
    readonly: boolean;
    isEregField: boolean;
    isEregReadonly: boolean;

}

export class InputField {
    key: number;
    value: string;
}

export class ApplicationTypeModel {
    id: number;
    applicantTypeNameEn: string;
    applicantTypeNameHi: string;
    applicantTypeDesc: string;
    status: number;
}

export class IgrsEregMstForestCircleBean{
    id:number;
    circleNameEn:string;
    circleNameHi:string;
    nicCircleCode:number;
    status:number;
}

export class IgrsEregMstForestDivisionBean{
    id:number;
    fkCircleId:number;
    divisionNameEn:string;
    divisionNameHi:string;
    nicDivisionCode:number;
    status:number;
}

export class IgrsEregMstForestRangeBean{
    id:number;
    fkDivisionId:number;
    rangeNameEn:string;
    rangeNameHi:string;
    nicRangeCode:number;
    status:number;
}

export class IgrsEregHoldDocument{
    id:number;
    eregId:number;
    holdRemarks:string;
    unholdRemarks:string;    
    isOnStay : number;
    statusId : number;
    selectedReasons : number[] = new Array();
    fileUploadCourtOrder : string = "";
    holdRemarksMandatoryDiv : boolean;
    unholdRemarksMandatoryDiv : boolean;
    fileUploadCourtOrderMandatoryDiv : boolean;
    documentHoldCount : number;
}

export class IgrsEregRefuseDocument{
    id:number;
    eregId:number;
    referenceId:string;
    srInputRemarks:string;
    refuseDocumentOrderName:string;    
    statusId : number;
    selectedReasons : number[] = new Array();
    additionalDocumentStr : string;
    isSrRemarksMandatoryDiv : boolean = false;
    isSelectedReasonsMandatoryDiv : boolean = false;
    additionalDocNameReqByParty : string;
    additionalDocDescReqByParty : string;
    additionalDocReqByPartyBase64Str : string;
    docNmDiv: boolean = false;
    docDescDiv: boolean = false;
    docFileDiv: boolean = false;
    docFileName : string;
    isSave: boolean = true;
    isEdit: boolean = false;
    isDelete: boolean = false;
    parentDemographyId:number;
    p_district_id:number;
    considerationAmtTotal:number;
    glValueTotal:number;
    appRejectedByDR : number;
    partyAppealRemarks : string;
    paymentStatusId : number;
    urn : string;
    paymentMode : string;
    drRejectRemarks : string;
}

 export class IgrsEregReturnDocument {
    id:number;
    eregId:number;
    referenceId:string;
    srInputRemarks:string;    
    returnDocumentLetterName:string;
    additionalDocumentStr:string;    
    statusId : number;
    selectedReasons : number[] = new Array();
    additionalDocNameReqByParty : string;
    additionalDocDescReqByParty : string;
    additionalDocReqByPartyBase64Str : string;
    docNmDiv: boolean = false;
    docDescDiv: boolean = false;
    docFileDiv: boolean = false;
    docFileName : string;
    isSave: boolean = true;
    isEdit: boolean = false;
    isDelete: boolean = false;
 }
 

export class IgrsEregReturnRefuseDocument{
    id:number;
    eregId:number;
    referenceId:string;
    srInputRemarks:string;
    refuseDocumentName:string;
    //returnDocumentName:string;
    returnDocumentLetterName:string;
    additionalDocumentStr:string;    
    statusId : number;
    selectedReasons : number[] = new Array();
}

export class IgrsEregReturnDocJapserBean{
   
    partyDetails:IgrsEregPartyDetailsBean[];
    selectedReasons : number[] = new Array();		
	regInitiationDate:string;	
	returnLetterFileName : string;
	eregId:string;
    srOfficeName:string;    
    userName:string;
    districtNm : string;    
    
  }

export class EregPayConfigBean {
  mutationFeesFlag: number;
  propReqFlag: boolean;
  propTypeList: string[] = new Array();
}

export class SelectedForestRangeBean {
    compartmentNo:any;
    area:any;
  }

export class CMSImpoundProcedureBean {
  p_application_no: string;
  p_principal_stampduty: number;
  p_proposed_principal_stampduty: number;
  p_municipal_stampduty: number;
  p_proposed_municipal_stampduty: number;
  p_janpad_sd: number;
  p_proposed_janpad_sd: number;
  p_upkar: number;
  p_proposed_upkar: number;
  p_exemptedamount_byparty: number;
  p_proposed_exemptedamount_byro: number;
  p_initiation_no: String;
  p_estamp_no: number;
  p_consideration_value_of_property: number;
  p_property_consideration_reason_en: string;
  p_property_reg_date: string;
  p_proposed_recoverable_reg_fee: number;
  p_guideline_value_property: number;
  p_guideline_prop_value_regisofficer: number;
  p_penality_guideline_value: number;
  p_designation: string;
  p_designation_hindi: string;
  p_sro_id: number;
  p_office: string;
  p_office_hindi: string;
  p_district_id: number;
  p_clientip: string;
  p_demography_id: number;
  p_designation_id: number;
  p_upload_file_path: string;
  p_upload_file_name: string;
  p_upload_file_desc: string;
  p_PROPOSAL_FORM_NAME: string;
  p_PROPOSAL_FORM_PATH: string;
  p_E_REGISTRY_DEED_DOC_NAME: string;
  p_E_REGISTRY_DEED_DOC_PATH: string;
  p_E_REGISTRY_DEED_DOC_MMYEAR: string;
  p_date_of_execution: string;
  p_date_of_presentation: string;

  totalPayableRegfee: number;
  totalPayableStamp: number;

  totalPayableRegfeeImpound: number;
  totalPayableStampImpound: number;
  p_natureofdocuments: number;
  p_natureofdocs_byro: number;
  p_CONSIDERATION_PROP_VALUE_BYPARTY: number;
  p_USER_ID: number;
  p_proposed_stamp_duty_by_internal_audit:number;

  estampId: any;
  basisOfDutyCalBySR:number;
  proposalNo: string;
  
  p_DRO_ID:number;
  p_DR_USERID:number;
  p_READER_USERID:number;

    p_REG_FEES_EXEMPTED_AMT_BYRO:number;
    p_REG_FEES_EXEMPTED_AMT_BYPARTY:number;
    p_REG_FEES_WO_EXEMP_AMT_BYRO:number;
    p_REG_FEES_WO_EXEMP_AMT_BYPARTY:number;

    p_TOTAL_REG_FEES_BYRO:number;
    p_TOTAL_REG_FEES_BYPARTY:number;
    p_TOTAL_STAMPDUTY_BYRO:number;
    p_TOTAL_STAMPDUTY_BYPARTY:number;

    p_ALREDY_PAID_DUTY_BYPARTY :number;
    p_ALREDY_PAID_DUTY_BYRO :number;
    p_ALREDY_PAID_REG_FEE_BYRO :number;
    p_ALREDY_PAID_REG_FEE_BYPARTY :number;
}



export class COSImpoundProcedureBean{
  
    p_COS_TOTALSTAMP_GUIDEVALUE: number;
    p_COS_TOTALSTAMP_CONSIDRATION_AMT: number;
    p_COS_TOTALSTAMP_PRINCIPLE: number;
    p_COS_TOTALSTAMP_MUNCIPLE: number;
    p_COS_TOTALSTAMP_JANPASD: number;
    p_COS_TOTALSTAMP_UPKAR: number;
    p_COS_TOTALSTAMP: number;
    p_COS_TOTALREGFEE: number;
    p_EREG_id: number;


    p_EXEM_STAMPDUTY_COS: number;
    p_NET_STAMPDUTY_COS: number;
    p_EXEM_WO_REGFEES_COS: number;
    p_EXEM_REGFEES_COS: number;
    p_NET_REGFEES_COS: number;

}

export class ReCalDutyService{
    serviceCall:Boolean
    stepper:any
}

export class ImpoundData{
    propertyValuationResponseBean: PropertyValuationResponseBean;
    propertyValuationRequestBean:PropertyValuationRequestBean
    propIdentificationObj:IgrsEregPropIdentificationBean
  }


  
export class ImpoundDataAdjudication{
    propertyValuationResponseBean: PropertyValuationResponseBean;
    propertyValuationRequestBean:PropertyValuationRequestBean
    propIdentificationObj:IgrsEregPropIdentificationBean
  }

  export class IgrsImpoundJapserBean{


    igrsEregImpoundTxnBean:IgrsEregImpoundTxnBean;
	
	deedInstrumentSelected:DeedInstrumentBean;
	
	deedInstrumentSelectedImpound:DeedInstrumentBean;
	
	igrsDutyCalculationTxnDto:any;
	
	igrsDutyCalculationTxnDtoImpound:any;
	
	sellerPartyDetails:IgrsEregPartyDetailsBean[];
	buyerPartyDetails:IgrsEregPartyDetailsBean[];
		
	admissionSealOn:string;	
	presentationSealOn:string;	
	tempEregId:string;
    srOfficeName:string;
    place:string;
    userName:string
    date:string

    considerationAmtDiff:number;
    displayConsiderationMIS:boolean
  }


  export class IgrsEregAdjudicationBean { 
    id : number;
    eregId : number;
    tempRegId : string;
    fkAdjudicationId : number;
    applicationFormBase64 : string;
    affidavitBase64 : string;
    additionalDocBase64 : string;
    additionalDocDescription : string ;
    document : string;
    additionalDocFilenm : string;
    isadditionalDocUploaded : boolean;
    status:boolean;		
		statusId:number;
		//dutyId:number;
		cosDutyId:number;
		igrsDutyCalCOSDifferencialBean:any;
		cosActionOn:Date;
		cosUserId:number;		
		guidelineValue:number;	
		guidelineValueCOSImpound:number;
		//instrumentIdImpound:number;
		instrumentIdCOSImpound:number;
        selectedExemptionListAdjudication:any
    procedureData:CMSAdjudicationProcedureBean
    drProcedureData:CMSAdjudicationDRProcedureBean
    isDRRejected:boolean;

    considerationAmtCOS:number;
    paymentStatusId : number;
    urn : string;
    paymentMode : string;
    adjudicationRefId:string;

}

export class CMSAdjudicationDRProcedureBean{
    p_COS_TOTALSTAMP_GUIDEVALUE:number;
    p_COS_TOTALSTAMP_CONSIDRATION_AMT:number;
    p_CONSIDERATION_PROP_VALUE_BYPARTY:number;
    p_consideration_value_of_property:number;
}

  export class CMSAdjudicationProcedureBean {
    p_application_no: string;
    p_principal_stampduty: number;
    p_proposed_principal_stampduty: number;
    p_municipal_stampduty: number;
    p_proposed_municipal_stampduty: number;
    p_janpad_sd: number;
    p_proposed_janpad_sd: number;
    p_upkar: number;
    p_proposed_upkar: number;
    p_exemptedamount_byparty: number;
    p_proposed_exemptedamount_byro: number;
    p_initiation_no: String;
    p_estamp_no: number;
    p_consideration_value_of_property: number;
    p_property_consideration_reason_en: string;
    p_property_reg_date: string;
    p_proposed_recoverable_reg_fee: number;
    p_guideline_value_property: number;
    p_guideline_prop_value_regisofficer: number;
    p_penality_guideline_value: number;
    p_designation: string;
    p_designation_hindi: string;
    p_sro_id: number;
    p_office: string;
    p_office_hindi: string;
    p_district_id: number;
    p_clientip: string;
    p_demography_id: number;
    p_designation_id: number;
    p_upload_file_path: string;
    p_upload_file_name: string;
    p_upload_file_desc: string;
    p_PROPOSAL_FORM_NAME: string;
    p_PROPOSAL_FORM_PATH: string;
    p_E_REGISTRY_DEED_DOC_NAME: string;
    p_E_REGISTRY_DEED_DOC_PATH: string;
    p_E_REGISTRY_DEED_DOC_MMYEAR: string;
    p_date_of_execution: string;
    p_date_of_presentation: string;
  
    totalPayableRegfee: number;
    totalPayableStamp: number;
  
    totalPayableRegfeeImpound: number;
    totalPayableStampImpound: number;
    p_natureofdocuments: number;
    p_natureofdocs_byro: number;
    p_CONSIDERATION_PROP_VALUE_BYPARTY: number;
    p_USER_ID: number;
    p_proposed_stamp_duty_by_internal_audit:number;
  
    estampId: any;
    basisOfDutyCalBySR:number;
    proposalNo: string;
    
    p_DRO_ID:number;
    p_DR_USERID:number;
    p_READER_USERID:number;
  
      p_REG_FEES_EXEMPTED_AMT_BYRO:number;
      p_REG_FEES_EXEMPTED_AMT_BYPARTY:number;
      p_REG_FEES_WO_EXEMP_AMT_BYRO:number;
      p_REG_FEES_WO_EXEMP_AMT_BYPARTY:number;
  
      p_TOTAL_REG_FEES_BYRO:number;
      p_TOTAL_REG_FEES_BYPARTY:number;
      p_TOTAL_STAMPDUTY_BYRO:number;
      p_TOTAL_STAMPDUTY_BYPARTY:number;
  
      p_ALREDY_PAID_DUTY_BYPARTY :number;
      p_ALREDY_PAID_DUTY_BYRO :number;
      p_ALREDY_PAID_REG_FEE_BYRO :number;
      p_ALREDY_PAID_REG_FEE_BYPARTY :number;
  }

  export class IgrsEregAdjudicationPropertyBean{
    id:number;
      eregId:number;
      adjudicationId:number;
      //valuationId:number;
      identificationId:number;
      status:boolean;
      ipAddress:string;
      cosPropertyJson:string;
      cosActionOn:Date;
      cosUserId:number;
      cosValuationId:number;
      inputFieldCOSJson:any;
  }

  export class IgrsDutyCalculationTxnUpdateDto{
	dutyId:number;
    instrumentId:number;
    addPrincipalStampDuty:number;
	addMunicipalDuty:number;
	addJanpadDuty:number;
	addUpkarDuty:number;
	addRegistrationFee:number;
	addTotalStampDuty:number;
	addTotalRegFee:number;
	isAddDutyApplicable:boolean;
	isAddRegfeeApplicable:boolean;

    igrsDutyCalDifferencialBean:IgrsDutyCalDifferencialBean;
  }

  export class AmountWalletBalanceBean{
    walletName:string;
    walletNameHi:string;
    walletBalance:number;
    totalDebitAmount:number;
    status:boolean
  }