import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TranslateHEService } from "projects/common-lib/src/lib/helper-lib/services/common/translateHE.service";
import { EregistryService } from "projects/front-web/src/app/service/eregistry.service";
import { IgrsEregTxnDetlsBean } from "../../models/e_registry/e-registry.model";
import { CommonService } from "../../helper-lib/services/common.service";
import { addOfficeDetail } from "../../models/addOffice.model/addOffice.model";
import { ActivatedRoute, Router } from "@angular/router";
import { AESEncryptDecryptService } from "../../helper-lib/services/aesencrypt-decrypt.service";
import Swal from "sweetalert2";
import { IgrsEstampDetailDisplay } from "../../models/addEstamp.model/add-estamp.model";
import { AlertmessageComponent } from "../alertmessage/alertmessage.component";
@Component({
  selector: "app-citizen-feedback",
  templateUrl: "./citizen-feedback.component.html",
  styleUrls: ["./citizen-feedback.component.scss"],
})
export class CitizenFeedbackComponent implements OnInit {
  
  @ViewChild('alertMessage') alertmessage: AlertmessageComponent;
  lan: number;
  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: any = 'Your rating';
  count = 5;
  @ViewChild('namebutton') namebutton: ElementRef;
  selectedOption:any;
  srOfficeList: addOfficeDetail[];
  igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();
  srOfficeNameEn: string;
  srOfficeNameHi: string;
  eregId: any;
  partyId: any;
  additionalAmountFlag: number = 0;
  additionalAmount: number;
  remarks: string = "";
  selectedValues: { questionNo: number; selectedValue: number }[] = [];
  eregFeedbackQuesList: any[] = [];
  ratingEregFeedbackQuesList: any[] = [];
  noRatingEregFeedbackQuesList: any[] = [];
  igrsEregFeedbackModelList: IgrsEregFeedbackModel[] = [];
  igrsEstampDetailDisplay: IgrsEstampDetailDisplay = new IgrsEstampDetailDisplay();
  additionalAmountDiv: boolean = false;
  isSubmit: boolean = false;
  moduleType: any;
  estampId: number;
  regNo: string;
  docRegdate: any;
  sroId: any;
  districtId: any;
  districtList: any[] = [];
  disNameEn: any;
  disNameHi: any;
  feedbackResponse: boolean=false;
  constructor(
    private translate: TranslateService, private router: Router,
    private translateService: TranslateHEService, private common: CommonService,
    private ngxService: NgxUiLoaderService, private eRegService: EregistryService, private activatedRoute: ActivatedRoute
    , private _AESEncryptDecryptService: AESEncryptDecryptService
  ) {
    try {
           let selectedLanguage = sessionStorage.getItem("selectedLanguage");
      if (
        sessionStorage.getItem("selectedLanguage") == null ||
        sessionStorage.getItem("selectedLanguage") == "" ||
        sessionStorage.getItem("selectedLanguage") == undefined
      ) {
        this.lan = 1;
        selectedLanguage = "hi";
      }
      this.translateService.currentApprovalStageMessage.subscribe((msg) => {
        this.lan = msg;
        this.translate.use(msg == 1 ? "hi" : "en");
      });
    } catch (err) {
      console.log(err);
    }
    this.activatedRoute.params.subscribe(async params => {
      if (typeof params['moduleType'] != 'undefined') {
        this.moduleType = params['moduleType'];
        //this.eregId = this.eregId.replace(/~!~/g, '/');
      }
      if (typeof params['partyId'] != 'undefined') {
        this.partyId = params['partyId'];
        //this.partyId = this.partyId.replace(/~!~/g, '/');
      }
      if (this.moduleType == 'eregistry') {
      this.feedbackResponse= await this.getEregDetailForFeedbackByEregId(this.partyId);
      if(!this.feedbackResponse)
        return;
      }
      
      else {
        this.router.navigate(['/']);
      }
    })

  }

  ngOnInit(): void {
    
  }
  getEregDetailForFeedbackByEregId(partyId: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // Validate the partyId before proceeding
      if (partyId == null || partyId == undefined) {
        resolve(false); // If partyId is invalid, resolve with false
        return;
      }
  
      const encrtid = this._AESEncryptDecryptService.encrypt("" + partyId);
      const encrtPartyId = this._AESEncryptDecryptService.encrypt("" + this.partyId);
  
      this.ngxService.start();  // Start loading indicator
  
      this.eRegService.getEregDetailForFeedbackByEregId(partyId).subscribe(
        (res: any) => {
          this.ngxService.stop();  // Stop loading indicator
  
          // Check for valid response status
          if (res.responseStatus === 'true') {
            // Successfully got the details
            let response = true;
            this.getEregFeedBackQuesList(this.moduleType);
            if (res.responseData?.regId) {
              this.igrsEregTxnDetlsBean = res.responseData;
              this.regNo = this.igrsEregTxnDetlsBean.regId;
              this.docRegdate = this.igrsEregTxnDetlsBean.eregCompletionDate;
  
              if (this.igrsEregTxnDetlsBean.districtId) {
                this.getSROfficeList(this.igrsEregTxnDetlsBean.districtId);
              }
            } else {
              // Handling e-stamp details
              this.igrsEstampDetailDisplay = res.responseData;
              this.estampId = res.responseData?.id;
            }
  
            // Resolve the promise with 'true' when data is processed
            resolve(response);
          } else if (res.responseMessage === 'Invalid') {
            // Handle invalid response (e.g., wrong partyId)
            this.router.navigate(['/']);
            resolve(false);
          } else {
            // Handle already submitted feedback case
            Swal.fire({
              icon: 'error',
              title: this.lan === 0 ? "You have already Submitted the Feedback" : "आपने पहले ही फीडबैक सबमिट कर दिया है",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(['/']);
              }
            });
  
            resolve(false); // Feedback already submitted, resolve with 'false'
          }
        },
        (error) => {
          // Handle error in service call
          this.ngxService.stop();
          console.error('Error fetching eReg details:', error);
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong. Please try again.',
            allowOutsideClick: false
          });
  
          resolve(false); // Resolve with 'false' on error
        }
      );
    });
  }
  
   getSearchDetailForFeedback(txnId: any) {
    if (txnId != null && txnId != undefined) {
      this.ngxService.start();
      this.eRegService.getSearchDetailForFeedbackByTxnId(txnId).subscribe((res: any) => {
        if (res.responseStatus == "true") {
          this.getEregFeedBackQuesList(this.moduleType);
          this.regNo = res.responseData[0].registrationNumber;
          this.docRegdate = res.responseData[0].registrationDate;
          this.districtId = res.responseData[0]?.districtId;
          if (this.districtId != undefined) {
            this.getDistrictList();
          }
          this.sroId = res.responseData[0]?.sroOfficeId;
          this.ngxService.stop();
          if (this.sroId != undefined) {
            this.srOfficeNameEn = res.responseData[0]?.srNameEn;
            this.srOfficeNameHi = res.responseData[0]?.srNameHi;
          }

        }
        else if (res.responseMessage == 'Invalid') {
          this.ngxService.stop();
          this.router.navigate(['/']);
        }
        else {
          this.ngxService.stop();
          Swal.fire({
            icon: 'error',
            title: `${this.lan == 0 ? "You have already Submitted the Feedback" : "आपने पहले ही फीडबैक सबमिट कर दिया है"}`,
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/']);
            }
          });
        }
      });
    }

  }
  getSROfficeList(districtId: any) {
    let param = {
      districtId: districtId,
    };
    this.ngxService.start();
    this.common.getSROfficeList(param).subscribe(dcdata => {
      this.ngxService.stop();
      if (dcdata.responseData != null) {
        this.srOfficeList = dcdata.responseData;
        this.srOfficeNameEn = this.srOfficeList.find(o => o.id == this.igrsEregTxnDetlsBean.srOfficeId).officeNameEn;
        this.srOfficeNameHi = this.srOfficeList.find(o => o.id == this.igrsEregTxnDetlsBean.srOfficeId).officeNameHi
      }
    });
  }
  getEregFeedBackQuesList(moduleType: any) {
    this.ngxService.start();
    let quesTyp = "";
    if (moduleType == 'eregistry') {
      quesTyp = 'EREGISTRY';
    }
    if (moduleType == 'estamp') {
      quesTyp = 'ESTAMP';
    }

    if (moduleType == 'search') {
      quesTyp = 'SEARCH';
    }

    this.eRegService.getEregFeedBackQuesList(quesTyp).subscribe((res: any) => {
      if (res.responseStatus == "true") {
        this.ngxService.stop();
        this.eregFeedbackQuesList = res.responseData;
        this.eregFeedbackQuesList.forEach((item, index) => {
          item.serialNumber = index + 1;
        });
        console.log("  this.eregFeedbackQuesList", this.eregFeedbackQuesList)
        this.ratingEregFeedbackQuesList = this.eregFeedbackQuesList.filter(x => x.ratingRequired == 1);
        this.noRatingEregFeedbackQuesList = this.eregFeedbackQuesList.filter(x => x.ratingRequired == 0)
      }
      else {
        this.ngxService.stop();
      }
    });

  }
  onSubmitFeedbackForm() {
    if (this.igrsEregTxnDetlsBean.statusId != 72 && this.moduleType == 'eregistry')
      return;
    if (this.selectedValues.length != this.ratingEregFeedbackQuesList.length) {
      this.alertmessage.errorAlertMessage('e-RegUserDetail.compulsoryRequired');
      // Swal.fire({
      //   title: `${this.lan == 0 ? "Please Give the Rating for All the Questions" : "कृपया सभी प्रश्नों के लिए रेटिंग दें"}`,
      //   icon: 'error',
      //   allowOutsideClick: false
      // })
      return;
    }
    for (let i = 0; i < this.selectedValues.length; i++) {

      if (!this.igrsEregFeedbackModelList[i]) {
        this.igrsEregFeedbackModelList[i] = new IgrsEregFeedbackModel();
      }
      this.igrsEregFeedbackModelList[i].queId = this.selectedValues[i].questionNo;
      this.igrsEregFeedbackModelList[i].stars = this.selectedValues[i].selectedValue;
    }
    if(this.selectedOption==undefined || this.selectedOption == "")
      {
        this.alertmessage.errorAlertMessage('e-RegUserDetail.compulsoryRequired');
              return;
      }
    if (this.selectedOption != null && this.selectedOption != undefined && this.selectedOption != '') {
      let igrsEregFeedbackModel: IgrsEregFeedbackModel = new IgrsEregFeedbackModel();
      igrsEregFeedbackModel.queId = this.noRatingEregFeedbackQuesList[0].id;

      if (this.noRatingEregFeedbackQuesList.length > 0) {
        
        if (this.selectedOption == 'Yes' ) {
          igrsEregFeedbackModel.additionalAmountFlag = 1;
          igrsEregFeedbackModel.additionalAmount = this.additionalAmount;
          if (this.additionalAmount == null || this.additionalAmount == undefined || this.additionalAmount == Number("")) {
            this.additionalAmountDiv = true;
            return;
          }
        }
        else {
          this.additionalAmountDiv = false;
          igrsEregFeedbackModel.additionalAmountFlag = 0;
          igrsEregFeedbackModel.additionalAmount = undefined;
        }
        this.igrsEregFeedbackModelList.push(igrsEregFeedbackModel);
      }
      if (this.remarks != null && this.remarks != undefined && this.remarks != "") {
        let igrsEregFeedbackModel1: IgrsEregFeedbackModel = new IgrsEregFeedbackModel();
        igrsEregFeedbackModel1.queId = this.noRatingEregFeedbackQuesList[1].id
        igrsEregFeedbackModel1.remarks = this.remarks;
        this.igrsEregFeedbackModelList.push(igrsEregFeedbackModel1);
      }
    }
    //this.eregId = this.eregId.replace(/~!~/g, '/');
    //this.igrsEregFeedbackModelList[0].decryptEregId = this.eregId;
    //  this.partyId = this.partyId.replace(/~!~/g, '/');
   
    let feedbackflag = "";
    if (this.moduleType == 'eregistry') {
      feedbackflag = 'EREGISTRY';
      this.igrsEregFeedbackModelList[0].decryptPartyId = this.partyId;
    }
    else if (this.moduleType == 'estamp') {
      feedbackflag = 'ESTAMP';
      this.igrsEregFeedbackModelList[0].decryptPartyId = this.partyId;
      this.igrsEregFeedbackModelList.forEach((item) => {
        item.estampId = this.estampId;
      });
    }
    else if (this.moduleType == 'search') {
      feedbackflag = 'SEARCH';
      this.igrsEregFeedbackModelList[0].encryptTxnId = this.partyId;
          }
    this.igrsEregFeedbackModelList.forEach((item) => {
      item.feedbackflag = feedbackflag;
    });
    this.ngxService.start();
    if (this.moduleType == 'eregistry' || this.moduleType == 'estamp') {
      this.saveEregEstampFeedback();
    }
    else if (this.moduleType == 'search') {
      this.saveSearchFeedback()
    }
    this.ngxService.stop();
  }

  onValueChange(event: { questionNo: number; selectedValue: number }) {
    const existing = this.selectedValues.find(q => q.questionNo === event.questionNo);
    if (existing) {
      existing.selectedValue = event.selectedValue;
    } else {
      this.selectedValues.push(event);
    }
  }
  onInputRadio() {

    this.additionalAmountDiv = false;
  }
  getDistrictList() {

    let type_id = 3;
    let parent_id = 1
    this.common.getdemographyByParentAndtype(type_id, parent_id).subscribe(data => {
      if (data.responseStatus == 'true') {
        this.districtList = data.responseData;
        this.disNameEn = this.districtList.find(d => d.id = this.districtId).demographyNameEn;
        this.disNameHi = this.districtList.find(d => d.id = this.districtId).demographyNameHi;
      }
    });
  }
  saveEregEstampFeedback() {
    this.eRegService.saveEregFeedback(this.igrsEregFeedbackModelList).subscribe((res: any) => {
      if (res.responseStatus == "true") {
        this.isSubmit = true;
        this.ngxService.stop();
        Swal.fire({
          icon: 'success',
          title: `${this.lan == 0 ? "Feedback Successfully Submitted" : "फीडबैक सफलतापूर्वक सबमिट किया गया"}`,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['/']);
          }
        });
      }
      else {
        Swal.fire({
          title: `${this.lan == 0 ? "You have already Submitted the Feedback" : "आपने पहले ही फीडबैक सबमिट कर दिया है"}`,
          allowOutsideClick: false
        })
        this.ngxService.stop();
      }
    });
  }
  saveSearchFeedback() {
    this.eRegService.saveSearchFeedback(this.igrsEregFeedbackModelList).subscribe((res: any) => {
      if (res.responseStatus == "true") {
        this.isSubmit = true;
        this.ngxService.stop();
        Swal.fire({
          icon: 'success',
          title: `${this.lan == 0 ? "Feedback Successfully Submitted" : "फीडबैक सफलतापूर्वक सबमिट किया गया"}`,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['/']);
          }
        });
      }
      else {
        Swal.fire({
          title: `${this.lan == 0 ? "You have already Submitted the Feedback" : "आपने पहले ही फीडबैक सबमिट कर दिया है"}`,
          allowOutsideClick: false
        })
        this.ngxService.stop();
      }
    });
  }
  resetButton()
  {
    this.selectedOption=undefined;
    this.remarks="";
    this.selectedValues.forEach((value) => (value.selectedValue = 0));
    this.selectedValues=[]
  
  }

 }

export class IgrsEregFeedbackModel {
  id: number;
  eregId: number;
  partyId: number;
  queId: number;
  stars: number;
  remarks: string;
  additionalAmountFlag: number;
  additionalAmount: number;
  decryptEregId: string;
  decryptPartyId: string;
  estampId: number;
  searchTxnId: number;
  feedbackflag: string;
  encryptTxnId: string;

}