import { SelectionModel } from "@angular/cdk/collections";
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
// import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from '@angular/material/sort';
import { TranslateService } from "@ngx-translate/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TranslateHEService } from "projects/common-lib/src/lib/helper-lib/services/common/translateHE.service";
import { CsvDownloadService } from "../../service/CsvDownloadService";
import { DutyService } from "projects/common-lib/src/lib/service/duty.service";
import { DeedInstrumentBean } from "projects/common-lib/src/lib/models/dutyCalculation.model/deed-instrument-bean";
import { AlertmessageComponent } from "projects/common-lib/src/lib/component/alertmessage/alertmessage.component";
import { DeedTypeBean } from "projects/common-lib/src/lib/models/dutyCalculation.model/deed-type-bean";
import { DatePipe } from "@angular/common";
import { ChangeDetectorRef } from '@angular/core';
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DeedCategoryBean } from "projects/common-lib/src/lib/models/dutyCalculation.model/deed-category-bean";
import { DeedInstrumentComponent } from "projects/common-lib/src/lib/component/deed-instrument/deed-instrument.component";

export class DeedInstrumentModel {
  deedCategory: any;
  deedCategoryNameEn: any;
  deedCategoryNameHi: any;
  deedType: any;
  deedTypeNameEn: any;
  deedTypeNameHi: any;
  InstrumentName: any;
  instrumentNameEn: any;
  instrumentNameHi: any;
  position: number;
  officeBased: string;
  isNonAssisted: any;
  isAssisted: any;
}
let DeedInstrument_ELEMENT_DATA: DeedInstrumentModel[] = [];
@Component({
  selector: "app-off-inter-noninter-based-instrument",
  templateUrl: "./off-inter-noninter-based-instrument.component.html",
  styleUrls: ["./off-inter-noninter-based-instrument.component.scss"],
})
export class OffInterNoninterBasedInstrumentComponent implements OnInit {
  @ViewChild(AlertmessageComponent, { static: false })
  alertmessage: AlertmessageComponent;
  @ViewChild(DeedInstrumentComponent) child: any;
  deedInstrumentBean: DeedInstrumentBean = new DeedInstrumentBean();
  deedInstrumentBeanList: any[] = new Array();
  deedTypeCategoryBeanList: DeedTypeBean[] = new Array();
  deedCategoryBeanList: DeedCategoryBean[] = new Array();
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  searchFilter = "";
  deedCategoryId = "";
  deedTypeId = "";
  active: string = "";
  direction: string = "";
  lan: number;
  columnKey: string[];
  

  displayedColumns: string[] = [
    "position",
    "deedCategory",
    "deedType",
    "instrumentName",
    "officeBased",
    "interactive",
    "nonInteractive",

  ];
  param = {
    "userId": 0,
    "active": "",
    "direction": "",
    "deedCategory": "",
    "deedType": "",
    "instrument": "",
    "deedContentStatus": "",
    "eRegistryStatus": "",
    "searchText": "",
    "isxls": "",
    "regId": "",
    "ToCreatedDate": "",
    "fromCreatedDate": ""
  }

  dataSource = new MatTableDataSource<any>();
  deedInstrumentModel: DeedInstrumentModel = new DeedInstrumentModel();
  selection = new SelectionModel<DeedInstrumentModel>(true, []);
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  officeInteractiveNonInteractiveForm: FormGroup = new FormGroup({});
  isShow = true;
  hiddenvalue: string = "hidden";
  // deedTypeId: number = 0;
  instrumentId: number = 0;
  pInstrumentId: string;
  pDeedCategoryId: string;
  pDeedTypeId: string;
  eRegId: any;
  startCreatedDate: any;
  endCreatedDate: any;
  resetDemo: boolean = true;
  constructor(
    private ngxService: NgxUiLoaderService,
    private translate: TranslateService,
    private translateService: TranslateHEService,
    private csvdownload: CsvDownloadService,
    private translateServiceIns: TranslateService,
    private dcService: DutyService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private liveAnnouncer: LiveAnnouncer,
    private activatedRoute: ActivatedRoute,
  ) {
    this.translateService.currentApprovalStageMessage.subscribe((msg) => {
      this.lan = sessionStorage.getItem("selectedLanguage") == 'hi' ? 1 : 0;
      this.translateService.currentApprovalStageMessage.subscribe(msg => this.lan = msg);
      this.dataSource = new MatTableDataSource(DeedInstrument_ELEMENT_DATA);
      this.getDeedInstrumentTableListing();
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      let deedCategoryId: any = params.get('deedCategoryId');
      let deedTypeId: any = params.get('deedTypeId');
      this.pDeedTypeId = deedTypeId;
      this.pDeedCategoryId = deedCategoryId;
      this.getAllDeedInstrumentsByDeedType();
      console.log(" deedCategoryId:: " + deedCategoryId + " deedTypeId::" + deedTypeId);
    });
    

  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getAllDeedCategoryList();
    this.getAllDeedInstrumentsByDeedTypeId(this.pDeedTypeId);
    this.getDeedInstrumentTableListing();
    this.cdr.detectChanges();
  }
  searchText() { }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  checkboxLabel(row?: DeedInstrumentModel): string {
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1
      }`;
  }
  exportExcel() {
 // Prepare the data based on language (English or Hindi)
    const dataForExport = DeedInstrument_ELEMENT_DATA.map((data: any) => ({
      SNO: data.position,
      Deed_Category: this.lan === 1 ? data.deedCategoryNameHi : data.deedCategoryNameEn,  // Use Hindi or English for Deed Category
      Deed_Type: this.lan === 1 ? data.deedTypeNameHi : data.deedTypeNameEn,  // Use Hindi or English for Deed Type
      Instrument_Name: this.lan === 1 ? data.instrumentNameHi : data.instrumentNameEn,  // Use Hindi or English for Instrument Name
      OfficeBased: this.lan === 1 ? 'हाँ' : 'Yes',  // Always set to "Yes" for officeBased
      Interactive_VKYC: this.lan === 1 ? (data.isAssisted ? 'हाँ' : 'नहीं') : (data.isAssisted ? 'Yes' : 'No'),  // Interactive field based on language
      Non_Interactive_VKYC: this.lan === 1 ? (data.isNonAssisted ? 'हाँ' : 'नहीं') : (data.isNonAssisted ? 'Yes' : 'No'),  // Non-Interactive field based on language
    }));


    // Create the worksheet with the language-specific data
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataForExport);

    // Create the workbook
    const workbook: XLSX.WorkBook = {
      Sheets: { 'data': worksheet },
      SheetNames: ['data'],
    };

    // Create and trigger the download of the Excel file
    const excelFile: Blob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(excelFile);
    link.download = 'deed_instruments.xlsx';  // Filename for the Excel file
    link.click();
  }


  exportPdf() {
    const doc = new jsPDF();

    const columns = [
      { title: "SNO", dataKey: "position" },
      { title: "Deed Type", dataKey: "deedType" },
      { title: "Instrument Name", dataKey: "instrumentName" },
      { title: "Office Based", dataKey: "officeBased" },
      { title: "Interactive", dataKey: "interactive" },
      { title: "Non-Interactive", dataKey: "nonInteractive" },
    ];

    // Mapping rows with language check for Hindi or English
    const rows = DeedInstrument_ELEMENT_DATA.map((data: any) => ({
      position: data.position,
      deedCategory: data.deedCategoryNameEn,  // Use Hindi or English for Deed Category
      deedType: data.deedTypeNameEn,  // Always use the English name for deedType
      instrumentName: data.instrumentNameEn,  // Always use the English name for instrumentName
      officeBased: 'Yes',  // Always 'Yes' in English
      interactive: data.isAssisted ? 'Yes' : 'No',  // Use 'Yes' or 'No' based on `isAssisted`
      nonInteractive: data.isNonAssisted ? 'Yes' : 'No',  // Use 'Yes' or 'No' based on `isNonAssisted`
    }));


    // Base64 font data - replace this with the actual Base64 string of your Hindi font
    //  const fontBase64 = '<your-font-base64-string>'; // Replace with your font Base64 string
    //  doc.addFileToVFS("HindiFont.ttf", fontBase64); // Add font to VFS
    //  doc.addFont("HindiFont.ttf", "HindiFont", "normal"); // Register the font
    //  doc.setFont("HindiFont");
    // Add table with the given columns and rows
    autoTable(doc, { columns, body: rows });

    // Save the generated PDF
    doc.save('deed_instruments.pdf');
  }

  reset_advanceFilter() {
      // Reset the selected values for deed category and deed type
      this.pDeedCategoryId = null;
      this.pDeedTypeId = null;
      this.getAllDeedInstrumentsByDeedType();
   }

  announceSortChange(sortState: any): void {
    if (sortState.direction) {
      this.liveAnnouncer.announce(`Sorted by ${sortState.active} in ${sortState.direction} direction.`);
    } else {
      this.liveAnnouncer.announce('Sorting cleared');
    }
  }
  // pageChangedSrvPrvd(event: PageEvent) {
  //   //  
  //   this.pageSize = event.pageSize;
  //   this.currentPage = event.pageIndex;
  //   this.getAllDeedInstrumentsByDeedType();
  // }
  getAllDeedCategoryList() {
    console.log("Inside getAllDeedCategoryList");
    this.ngxService.start();
    this.dcService.getAllDeedCategory().subscribe(dcdata => {

      if (dcdata.responseStatus == 'true') {
        this.ngxService.stop();
        this.deedCategoryBeanList = dcdata.responseData;
      } else {
        this.ngxService.stop();
      }

    },
      (err) => {

        this.ngxService.stop();
        this.alertmessage.errorAlertMessage('tech_error_msg');
      });

  }
  getAllDeedTypeByCategoryId(deedCategoryIdSelcted: any) {
    this.ngxService.start();
    console.log('deedCategoryIdSelcted', deedCategoryIdSelcted);
    this.dcService.getAllDeedTypeByCategoryId(deedCategoryIdSelcted).subscribe(dcdata => {
      if (dcdata.responseStatus == 'true') {
        this.ngxService.stop();
        this.deedTypeCategoryBeanList = dcdata.responseData;
      } else {
        this.ngxService.stop();
      }

    },
      (err) => {

        this.ngxService.stop();
        this.alertmessage.errorAlertMessage('tech_error_msg');

      });

  }
  getAllDeedInstrumentsByDeedTypeId(deedTypeIdSelected: any) {
    console.log("deedTypeIdSelected ::" + deedTypeIdSelected);
    this.ngxService.start();
    console.log(deedTypeIdSelected);
    this.dcService.getAllDeedInstrumentsByDeedTypeId(deedTypeIdSelected).subscribe(dcdata => {
      if (dcdata.responseStatus == 'true') {
        this.ngxService.stop();
        this.deedInstrumentBeanList = dcdata.responseData;
        this.getDeedInstrumentTableListing();
      } else {
        this.ngxService.stop();
      }

    },
      (err) => {

        this.ngxService.stop();
        this.alertmessage.errorAlertMessage('tech_error_msg');

      });
  }
  getAllDeedInstrumentsByDeedType() {
    // Reset any previous values if necessary
    this.instrumentId = 0;
    this.param.instrument = "";
  
    this.dcService
      .getAllDeedInstruments()
      .subscribe(
        (dcdata) => {
          if (dcdata.responseStatus == "true") {
            this.deedInstrumentBeanList = dcdata.responseData;
            this.getDeedInstrumentTableListing();
          } else {
          }
        },
        (err) => {
          this.ngxService.stop();
          this.alertmessage.errorAlertMessage("tech_error_msg");
        }
      );
  }
  


  getDeedInstrumentTableListing() {
    // Clear existing data in the table
     DeedInstrument_ELEMENT_DATA.length = 0;

    // Check if deedInstrumentBeanList has data
    if (this.deedInstrumentBeanList.length > 0) {
      DeedInstrument_ELEMENT_DATA = []; 
      // Loop through each item in deedInstrumentBeanList
      for (let i = 0; i < this.deedInstrumentBeanList.length; i++) {
        const deedInstrumentModel = new DeedInstrumentModel();

        // Mapping properties from deedInstrumentBeanList to DeedInstrumentModel
        deedInstrumentModel.instrumentNameEn = this.deedInstrumentBeanList[i].instrumentNameEn;
        deedInstrumentModel.instrumentNameHi = this.deedInstrumentBeanList[i].instrumentNameHi;
        deedInstrumentModel.deedTypeNameEn = this.deedInstrumentBeanList[i].deedTypeId.deedTypeNameEn;
        deedInstrumentModel.deedTypeNameHi = this.deedInstrumentBeanList[i].deedTypeId.deedTypeNameHi;
        deedInstrumentModel.deedCategoryNameEn = this.deedInstrumentBeanList[i].deedTypeId.deedCategoryId.deedCategoryNameEn;
        deedInstrumentModel.deedCategoryNameHi = this.deedInstrumentBeanList[i].deedTypeId.deedCategoryId.deedCategoryNameHi;
        deedInstrumentModel.position = i + 1;  // Position will be based on the index
        deedInstrumentModel.isAssisted = this.deedInstrumentBeanList[i].isAssisted;
        deedInstrumentModel.isNonAssisted = this.deedInstrumentBeanList[i].isNonAssisted;

        // Push the model into DeedInstrument_ELEMENT_DATA
        DeedInstrument_ELEMENT_DATA.push(deedInstrumentModel);
      }

      // Assign data to the data source
      this.dataSource = new MatTableDataSource<any>(DeedInstrument_ELEMENT_DATA);
      this.dataSource.sort = this.sort;

      // After data is loaded, you can re-apply pagination if necessary
      // if (this.dataSource.paginator) {
      //   this.dataSource.paginator.firstPage();
      // }
    } else {
      // Handle case when there's no data
      this.ngxService.stop();
    }
  }


  advanceFilter() {
    this.toggleStatus();
  }
  toggleStatus() {
    this.isShow = !this.isShow;
    if (this.isShow) {
      this.hiddenvalue = "hidden";
    } else {
      this.hiddenvalue = "";
    }

  }

  reloadData() {

  this.pDeedCategoryId = null;
  this.pDeedTypeId = null;
  this.getAllDeedInstrumentsByDeedType();
  

  }
}



