import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CitizenAuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/citizen-authentication.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { ColorswitcherService } from './colorswitcher.service';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { AuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/authentication.service';
import { DOCUMENT } from '@angular/common';
import { FeatureList } from 'projects/common-lib/src/lib/models/feature.list.model/featurelist';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LcncService } from 'projects/common-lib/src/lib/service/lcnc.service';
import { CommonService } from 'projects/common-lib/src/lib/helper-lib/services/common.service';
declare var $: any;


@Component({
  selector: 'front-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private settings!: AppSetting;
  lan: number=1
  lanVal: string = sessionStorage.getItem("selectedLanguage") || "hi";
  checkedVal: boolean;
  closeResult = '';
  loginSu: any;
  themeColor: string;
  defaultZoom: string;
  isUserLoggedIn: boolean = false;
  userType: string;
  collapsed = true;
  langOptionFlag: number = 1;
  paramType: string;
  @Output() parentFunction: EventEmitter<any> = new EventEmitter()
  baseDepartUrl:string
  baseCitizenUrl:string
  LoginUserNameEn?: string;
  LoginUserNameHi?: string;
  DesignationEn:string
  DesignationHi:String
  featureList?: FeatureList[] = [];

  public todayDate = Date.now();

 public getScreenWidth: any;

 removeSetting:boolean=false
 addSetting:boolean=true

 

  env: string;


  constructor(private colorservice: ColorswitcherService, 
    public translate: TranslateService, 
    private authService: CitizenAuthenticationService, 
    private adminAuthService:AuthenticationService,
    private router: Router,
    private translateService: TranslateHEService, 
    private modalService: NgbModal,
    private route: ActivatedRoute
    ,private appSettingsService: AppSettingService,
    private lcncService: LcncService,
    public commonService: CommonService
    //@Inject(DOCUMENT) private document: Document
  ) {
   
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
      if(this.settings?.themeColor)
       this.colorservice.updateColor(this.settings.themeColor);
      this.baseDepartUrl=settings.baseUrlDepartment
      this.baseCitizenUrl=settings.baseUrlCitizen
    });

   
    this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
    this.translateService.updateApprovalMessage(this.lan);
    this.translateService.currentApprovalStageMessage.subscribe(msg => {
      this.lan = msg
     })
    this.colorservice.currentColors.subscribe(msg => this.themeColor = msg);
    this.colorservice.currentColors.subscribe(msg => this.defaultZoom = msg);
   
    // const url = this.document.baseURI;
    // const parts = url.split('/');
    // const uri = parts.filter(part => part.trim() !== '').pop();

   //console.log(uri)

    if (sessionStorage.getItem("token") === null && sessionStorage.getItem("authData") === null) {
     // this.router.navigate(['clogin']);
    }
    else {
      if(sessionStorage.getItem("loginType")=='citizen'){
      authService.getprofileUsingToken(sessionStorage.getItem("token"));
      if (authService.currentUserValue) {
        this.LoginUserNameEn = authService.currentUserValue.FullNameEn;
        this.LoginUserNameHi = authService.currentUserValue.FullNameHi;
       
      }
      }
      if(sessionStorage.getItem("loginType")=='admin'){
      adminAuthService.getprofileUsingToken(sessionStorage.getItem("token"));
      if (adminAuthService.currentUserValue) {
        this.LoginUserNameEn = adminAuthService.currentUserValue.FullNameEn;
        this.LoginUserNameHi = adminAuthService.currentUserValue.FullNameHi;
        this.DesignationEn=adminAuthService.currentUserValue.DesignationEn
        this.DesignationHi=adminAuthService.currentUserValue.DesignationHi
      }
    }
    }
      fromEvent(window, 'resize')
      .pipe(debounceTime(200))
      .subscribe(() => {this.getScreenWidth = window.innerWidth});
  }

 



  utcTime() {
    setInterval(() => {
        this.todayDate = Date.now();
        //console.log(this.todayDate); // just testing if it is working
    }, 1000);
}
  open(loginOptions: any, event: any) {
    this.modalService.open(loginOptions, { centered: true },).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
 updateFeatureList() {
    this.featureList = JSON.parse(sessionStorage.getItem("featureList") || "");
    this.featureList = this.featureList.slice().sort((a, b) => {
      if (a.parentId === 0) {
        return a.displayOrder - b.displayOrder;
      }
      
      return 0;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

 
  ngOnInit(): void {
 this.getScreenWidth = window.innerWidth
    this.utcTime();

    this.route.queryParams.subscribe(param => {
      console.log("param['type']", param.type);
      this.paramType = param.type
    })

    if (sessionStorage.getItem("token") != null && sessionStorage.getItem("authData") != null) {
      this.loginSu = 1;
    }

    if (sessionStorage.getItem('isUserLoggedIn') != undefined && sessionStorage.getItem('isUserLoggedIn') == "true") {
      this.isUserLoggedIn = true;
      this.updateFeatureList()
    } else {
      this.isUserLoggedIn = false;
    }

    if (sessionStorage.getItem('loginType') != undefined && sessionStorage.getItem('loginType') != null && sessionStorage.getItem('loginType') != '') {
      this.userType = sessionStorage.getItem('loginType') || '';
    } else {
      this.userType = '';
    }
   this.addBodyClass();

// this.mobileSetting();
// this.mobileSettingRemove();
 this.settings = this.commonService.getAppSetting();
 this.env = this.settings.env;
 
  }
  ngAfterViewInit() {
     
    setTimeout(()=>{
      // if(this.settings.meanu == undefined){
      //   $("#toggle").toggleClass("on");
      //   $("#menu").slideToggle();
      // }
      // if(this.settings.meanu == "on"){
      //   $("#toggle").toggleClass(this.settings.meanu);
      //   $("#menu").slideToggle();
      // }   
      $("#toggle").click(function () {
        $("#toggle").toggleClass("on");
        $("#menu").slideToggle();
      });
    },1000);
    
  }


 setTheme(theme: (any)) {
    this.colorservice.updateColor(theme);
    this.addBodyClass(theme);
  }
  setZoom(zoom: (any)) {
    this.colorservice.updateZoom(zoom);
  }


  sentData() {
    let data = { name: 'jitu' }
    this.parentFunction.emit(data)
  }

  // changeLanguageToHindi(lan:any) {
  //   this.translate.use("hi")
  //   this.langOptionFlag = 1;
  //   this.lan=lan
  //   sessionStorage.setItem("selectedLanguage", "hi")
  //    localStorage.setItem("selectedLanguage", "hi");
  //   this.translateService.updateApprovalMessage(lan);

  // }
  // changeLanguageToEnglish(lan:any) {
  //   this.translate.use("en")
  //   this.langOptionFlag = 0;
  //   this.lan=lan
  //   sessionStorage.setItem("selectedLanguage", "en")
  //   localStorage.setItem("selectedLanguage", "en");
  //   this.translateService.updateApprovalMessage(lan);

  // }
    changeLanguageToEnglish(lan: any) {
    this.translate.use("hi");
    this.langOptionFlag = 1;
    this.lan = lan;
    sessionStorage.setItem("selectedLanguage", "hi");
    localStorage.setItem("selectedLanguage", "hi");
    this.translateService.updateApprovalMessage(lan);
  }

  changeLanguageToHindi(lan: any) {
    this.translate.use("en");
    this.langOptionFlag = 0;
    this.lan = lan;
    sessionStorage.setItem("selectedLanguage", "en");
    localStorage.setItem("selectedLanguage", "en");
    this.translateService.updateApprovalMessage(lan);
  }

  logoutCitizen() {
    this.authService.logout().subscribe(res => {
      res.status ? console.log("User logged out successfully!") : console.log("Some error during log out.");
    });
    // this.router.navigate(['clogin']);
    if (sessionStorage.getItem('isUserLoggedIn') != undefined && sessionStorage.getItem('isUserLoggedIn') == "true") {
      this.isUserLoggedIn = true;
    } else {
      this.isUserLoggedIn = false;
    }
  }
  logoutAdmin() {
    this.adminAuthService.logout().subscribe(res => {
      res.status ? console.log("User logged out successfully!") : console.log("Some error during log out.");
    });
    // this.router.navigate(['clogin']);
    if (sessionStorage.getItem('isUserLoggedIn') != undefined && sessionStorage.getItem('isUserLoggedIn') == "true") {
      this.isUserLoggedIn = true;
    } else {
      this.isUserLoggedIn = false;
    }
  }

  // changeLan(ev: any) {
  //   if (ev.value == 1) {
  //     this.lan = 0;
  //     sessionStorage.setItem("selectedLanguage", 'en');
  //     this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
  //     //this.translate.use(sessionStorage.getItem("selectedLanguage") || "en");
  //     this.translateService.updateApprovalMessage(this.lan);
  //   } else {
  //     this.lan = 1;
  //     sessionStorage.setItem("selectedLanguage", 'hi');
  //     this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
  //    // this.translate.use(sessionStorage.getItem("selectedLanguage") || "hi");
  //     this.translateService.updateApprovalMessage(this.lan);
  //   }
  //   //window.location.reload();
  //   // this.translate.get("citizen-title").subscribe(name=>{
  //   //   this.titleService.setTitle(name);

  //   // });
  // }

  redirect() {
    this.router.navigate([]).then(result => {
      window.open(`http://10.115.96.181:8080/ords/r/sampada2/sam211/login1`, '_blank');
    });
  }

  searchRedirect() {
    //alert("hi")
    this.router.navigate([]).then(result => {
      
     window.open(this.settings.searchBaseUrl+'/#/clogin', '_self');
      
    });
 
  }
 
mobileSetting(){
 if(this.getScreenWidth < 576){
 this.removeSetting=true 
 document.getElementById('mobileShow').classList.add("onlyMobileShow");
 this.addSetting=false
}
 } 
mobileSettingRemove(){
  if(this.getScreenWidth < 576){
   this.removeSetting=false 
   this.addSetting=true
 document.getElementById('mobileShow').classList.remove("onlyMobileShow");
  }
}
 
 addBodyClass(theme?:string) {
     const bodyTag = document.body;
    bodyTag.classList.add("mat-typography");
     //bodyTag.className=this.themeColor
  if ('theme-green' === theme) {
      bodyTag.classList.remove('theme-blue');
      bodyTag.classList.remove('theme-lightGrenn');
      bodyTag.classList.remove('theme-default');
      bodyTag.classList.remove('theme-maroon');
      bodyTag.classList.remove('theme-coffee');
      bodyTag.classList.remove('theme-focalPurple');
      bodyTag.classList.add('theme-green');
      bodyTag.classList.add("mat-typography");
      bodyTag.classList.add("allcontent");
    }
    else if ('theme-blue' === theme) {
      bodyTag.classList.remove('theme-green');
      bodyTag.classList.remove('theme-lightGrenn');
      bodyTag.classList.remove('theme-default');
      bodyTag.classList.remove('theme-maroon');
      bodyTag.classList.remove('theme-coffee');
      bodyTag.classList.remove('theme-focalPurple');
      bodyTag.classList.add('theme-blue');
      bodyTag.classList.add("mat-typography");
      bodyTag.classList.add("allcontent");
    }
    else if ('theme-lightGrenn' === theme) {
      bodyTag.classList.remove('theme-green');
      bodyTag.classList.remove('theme-blue');
      bodyTag.classList.remove('theme-default');
      bodyTag.classList.remove('theme-maroon');
      bodyTag.classList.remove('theme-coffee');
      bodyTag.classList.remove('theme-focalPurple');
      bodyTag.classList.add('theme-lightGrenn');
      bodyTag.classList.add("mat-typography");
      bodyTag.classList.add("allcontent");
    }
    else if ('theme-default' === theme) {
      bodyTag.classList.remove('theme-green');
      bodyTag.classList.remove('theme-blue');
      bodyTag.classList.remove('theme-lightGrenn');
        bodyTag.classList.remove('theme-maroon');
        bodyTag.classList.remove('theme-coffee');
        bodyTag.classList.remove('theme-focalPurple');
      bodyTag.classList.add('theme-default');
      bodyTag.classList.add("mat-typography");
      bodyTag.classList.add("allcontent");
    }
    else if ('theme-maroon' === theme) {
      bodyTag.classList.remove('theme-green');
      bodyTag.classList.remove('theme-blue');
      bodyTag.classList.remove('theme-lightGrenn');
      bodyTag.classList.remove('theme-default');
      bodyTag.classList.remove('theme-coffee');
      bodyTag.classList.remove('theme-focalPurple');
      bodyTag.classList.add('theme-maroon');
      bodyTag.classList.add("mat-typography");
      bodyTag.classList.add("allcontent");
        
    }
    else if ('theme-coffee' === theme) {
      bodyTag.classList.remove('theme-green');
      bodyTag.classList.remove('theme-blue');
      bodyTag.classList.remove('theme-lightGrenn');
      bodyTag.classList.remove('theme-default');
      bodyTag.classList.remove('theme-maroon');
      bodyTag.classList.remove('theme-focalPurple');
      bodyTag.classList.add('theme-coffee');
      bodyTag.classList.add("mat-typography");
      bodyTag.classList.add("allcontent");
        
    }
    else if ('theme-focalPurple' === theme) {
      bodyTag.classList.remove('theme-green');
      bodyTag.classList.remove('theme-blue');
      bodyTag.classList.remove('theme-lightGrenn');
      bodyTag.classList.remove('theme-default');
      bodyTag.classList.remove('theme-maroon');
      bodyTag.classList.remove('theme-coffee');
      bodyTag.classList.add('theme-focalPurple');
      bodyTag.classList.add("mat-typography");
      bodyTag.classList.add("allcontent");
        
    }
    else {
      bodyTag.classList.remove('theme-green');
      bodyTag.classList.remove('theme-blue');
      bodyTag.classList.remove('theme-lightGrenn');
      bodyTag.classList.remove('theme-maroon');
      bodyTag.classList.remove('theme-coffee');
      bodyTag.classList.remove('theme-focalPurple');
      bodyTag.classList.add(this.themeColor);
      bodyTag.classList.add("mat-typography");
      bodyTag.classList.add("allcontent");
    }


  //  if(this.themeColor){
  //   bodyTag.className=this.themeColor
  //       bodyTag.classList.add("mat-typography");
  //       bodyTag.classList.add("allcontent");
  //   } 
 
  }
  //LCNC Service Call:- This method is responsible for get user encription data(token) and redirect to "Search Document" 
  getUserEncDataForSearchDoc() {
    const param = {};
    this.lcncService.getCitizenEncData(param).subscribe(responseData => {
      if (responseData.responseStatus) {
        responseData.responseData;
      //  window.location.href = responseData.responseData.redirectionmodule;
        let menuName: any = 'SearchDocument';
        this.redirectLCNCRequest(responseData, menuName);
      }
    });
  }

  //LCNC Service Call:- This method is responsible for redirect to specified option 
  redirectLCNCRequest(param: any, menuName: any) {
    /* this.lcncService.redirectLCNCRequest(param).subscribe(responseData=>{
          if(responseData.responseStatus){
            responseData.responseData;
          }
    });*/

    let f = document.createElement("form");
    f.setAttribute("method", "post");
    f.setAttribute(
      "action",
      //"http://10.115.96.181:8080/ords/r/igrs/sam21/slot-statitics-report"
      this.settings.lcnc_redirect_url

      // "http://140.238.255.245:8080/ords/mp_tribal/getHomePage/getParameters"
    ); //Your action URL
  //  f.setAttribute("target", "_blank");
    //create hidden input elements

    // let i = document.createElement("input");
    // i.type = "hidden";
    // i.name = "encData";
    // i.value = param.responseData1;
    // f.appendChild(i); //Add it to the form

    let j = document.createElement("input");
    j.type = "hidden";
    j.name = "token";
    j.value = param.responseData.token;
    f.appendChild(j); //Add it to the form

    let k = document.createElement("input");
    k.type = "hidden";
    k.name = "userType";
    k.value = "citizen";
    f.appendChild(k); //Add it to the form

    let l = document.createElement("input");
    l.type = "hidden";
    l.name = "redirectionmodule";
    l.value = param.responseData.redirectionmodule;
    f.appendChild(l); //Add it to the form

    document.body.appendChild(f);//Add the form to the body
    f.submit(); //Submit it immediately
  }
   //LCNC Service Call:- This method is responsible for get user encription data(token) and redirect to "Reports" 
  getUserEncDataForMISReport() {
    const param = {};
    this.lcncService.getCitizenEncData(param).subscribe(responseData => {
      if (responseData.responseStatus) {
        responseData.responseData;
        let menuName: any = 'MISReport';
        this.redirectLCNCRequest(responseData, menuName);
      }
    });
  }
 getUserCMSData() {
    const param = { keyParam: "CMS" };
    //this.ngxService.start();
    this.lcncService.getCitizenEncData(param).subscribe(responseData => {
      if (responseData.responseStatus) {
        responseData.responseData;
        this.redirectCMSRequest(responseData);
      }
    })
  }
 //LCNC Service Call:- This method is responsible for redirect to "Case Management Tracking" option 
  redirectCMSRequest(param: any) {
    let f = document.createElement("form");
    f.setAttribute("method", "post");
    f.setAttribute(
      "action",
      //"http://10.115.96.181:8080/ords/r/igrs/sam21/slot-statitics-report"
      this.settings.lcnc_redirect_url

      // "http://140.238.255.245:8080/ords/mp_tribal/getHomePage/getParameters"
    ); //Your action URL
  //  f.setAttribute("target", "_blank");
    //create hidden input elements

    // let i = document.createElement("input");
    // i.type = "hidden";
    // i.name = "encData";
    // i.value = param.responseData1;
    // f.appendChild(i); //Add it to the form

    let j = document.createElement("input");
    j.type = "hidden";
    j.name = "token";
    j.value = param.responseData.token;
    f.appendChild(j); //Add it to the form

    let k = document.createElement("input");
    k.type = "hidden";
    k.name = "userType";
    k.value = "citizen";
    f.appendChild(k); //Add it to the form

    let l = document.createElement("input");
    l.type = "hidden";
    l.name = "redirectionmodule";
    l.value = param.responseData.redirectionmodule;
    f.appendChild(l); //Add it to the form

    document.body.appendChild(f);//Add the form to the body
    f.submit(); //Submit it immediately
  }

}
