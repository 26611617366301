<div class="fid-header"></div>
<div class="breadcrumb-content">
  <div class="container">
    <div class="d-flex justify-content-between">
      <h2>
        {{
        lan == 0 ? "Registerd Documents Details" : "रजिस्टर दस्तावेज़ विवरण"
        }}
      </h2>
    </div>
  </div>
</div>
<lib-alertmessage #alertMessage></lib-alertmessage>
<div class="container">
  <mat-card class="example-card mb-3">
    <mat-card-title class="p-4"></mat-card-title>

    <mat-card-content>
      <ul class="nav nav-tabs refundStatus_tab" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="paymentservices-tab" data-toggle="tab" href="#paymentservices" role="tab"
            aria-controls="paymentservices" aria-selected="true">
            {{lan == 0 ? "Registerd Documents" : "रजिस्टर दस्तावेज़"}}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="paymentList-tab" data-toggle="tab" href="#paymentList" role="tab"
            aria-controls="paymentList" aria-selected="false">
            {{lan == 0 ? "Registerd Documents Details" : "रजिस्टर दस्तावेज़ विवरण"}}
          </a>
        </li>
      </ul>
      <div class="tab-content refund_tab_content" id="myTabContent">
        <div class="tab-pane  show active" id="paymentservices" role="tabpanel"
          aria-labelledby="paymentservices-tab">

          <fieldset class="legend-section">
            <div class="row">
          
              <div class="col-md-auto">
                <div class="form-group">
                  <label> {{(lan==0)?'From Date':'की तिथि से'}}</label>
                  <input type="date" id="search" #search class="form-control" [min]="minStartDate" [max]="toDate" (keydown)="disableKeyboardInput($event)" [(ngModel)]="startDate">
                </div>
              </div>
              <div class="col-md-auto">
                <div class="form-group">
                  <label> {{(lan==0)?'To Date':'दिनांक तक'}}</label>
                  <input type="date" id="search" #search class="form-control" [min]="minStartDate" [max]="toDate" (keydown)="disableKeyboardInput($event)" [(ngModel)]="endDate">
                </div>
              </div>
              <div class="col-md-auto">
                <div class="form-group">
                  <label class="w-100 d-block"> &nbsp; </label>
                  <button class="btn btn-primary mr-1" (click)="onSubmit()"> {{'searchbtn' | translate}}</button>
                  <!-- <button class="btn btn-danger" (click)="resetButton()">
                    {{(lan==0)?'Reset':'रीसेट'}}
                  </button> -->

                </div>
              </div>
            </div>
          </fieldset>


   <fieldset class="legend-section" *ngIf="dataList.length>0">
        <legend class="sidebar__widget-title">Details</legend>
         
        <div class="table-responsive shadow-none mt-1 example-container">
         
          <!-- <table mat-table [dataSource]="dataList" class="table table-bordered" id="MreportTable">
            <ng-container *ngFor="let columnName of columnNames" [matColumnDef]="columnName">
              <th mat-header-cell *matHeaderCellDef>
                {{ columnObjectss[columnName]?.headerValEn }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row[columnName] }}</td>
              <td mat-footer-cell *matFooterCellDef [ngClass]="{'total-footer': isLastPage}">
                <ng-container  *ngIf="columnName === 'DISTRICT_NAME_HI' && isLastPage">Total</ng-container>
                <ng-container *ngIf="columnName === 'REGISTRY_COMPLTED' && isLastPage">{{ dataPageTotalList[0]?.REGISTRY_COMPLTED }}</ng-container>
                <ng-container *ngIf="columnName === 'TOTAL_PAYABLE_REGFEE' && isLastPage">{{ dataPageTotalList[0]?.TOTAL_PAYABLE_REGFEE }}</ng-container>
                <ng-container *ngIf="columnName === 'TOTAL_PAYABLE' && isLastPage">{{ dataPageTotalList[0]?.TOTAL_PAYABLE }}</ng-container>
                <ng-container *ngIf="columnName === 'EREG_PRINCIPAL_STAMP_DUTY' && isLastPage">{{ dataPageTotalList[0]?.EREG_PRINCIPAL_STAMP_DUTY }}</ng-container>
                <ng-container *ngIf="columnName === 'EREG_UPKAR_DUTY' && isLastPage">{{ dataPageTotalList[0]?.EREG_UPKAR_DUTY }}</ng-container>
                <ng-container *ngIf="columnName === 'EREG_JANPAD_DUTY' && isLastPage">{{ dataPageTotalList[0]?.EREG_JANPAD_DUTY }}</ng-container>
                <ng-container *ngIf="columnName === 'EREG_MUNICIPAL_DUTY' && isLastPage">{{ dataPageTotalList[0]?.EREG_MUNICIPAL_DUTY }}</ng-container>
                <ng-container *ngIf="columnName === 'EREG_EXEMP_REG' && isLastPage">{{ dataPageTotalList[0]?.EREG_EXEMP_REG }}</ng-container>
                <ng-container *ngIf="columnName === 'EXEMP_STAMP' && isLastPage">{{ dataPageTotalList[0]?.EXEMP_STAMP }}</ng-container>
                          </td>
              </ng-container>
          
           
            <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
            <tr mat-footer-row *matFooterRowDef="columnNames" ></tr>
                     </table> -->
                     <div class="table-responsive shadow-none">
                      <table class="table table-bordered" mat-table [dataSource]="dataSourceCount">
                    
                        <!-- Position Column -->
                        <ng-container matColumnDef="position">
                          <th mat-header-cell *matHeaderCellDef>{{'no' | translate}}</th>
                          <td mat-cell *matCellDef="let element"> {{element?.RNUM}} </td>
                        </ng-container>
                    
                        <!-- district -->
                        <ng-container matColumnDef="district">
                          <th mat-header-cell *matHeaderCellDef>  {{'district' | translate}} </th>
                          <td mat-cell *matCellDef="let element"> {{lan ==0 ? element?.DISTRICT_NAME_EN:element?.DISTRICT_NAME_HI}} </td>
                        </ng-container>
                    
                        <!-- registeredDocumentCount -->
                        <ng-container matColumnDef="registeredDocumentCount">
                          <th mat-header-cell *matHeaderCellDef>  {{'ereg-mis-report.registeredDocumentCount' | translate}} </th>
                          <td mat-cell *matCellDef="let element"> {{element?.REGISTRY_COMPLTED}} </td>
                        </ng-container>
                      
                        <!-- totalFees -->
                        <ng-container matColumnDef="totalFees">
                          <th mat-header-cell *matHeaderCellDef>  {{(lan==0)?'Total Stamp Duty and Fee':'कुल स्टाम्प ड्यूटी और शुल्क'}} </th>
                          <td mat-cell *matCellDef="let element"> {{element?.TOTAL_PAYABLE_REGFEE+element?.EREG_JANPAD_DUTY+
                            element?.EREG_MUNICIPAL_DUTY+element?.EREG_PRINCIPAL_STAMP_DUTY+element?.EREG_UPKAR_DUTY}} </td>
                        </ng-container>
                      
                        <!-- AvgFees -->
                        <ng-container matColumnDef="AvgFees">
                          <th mat-header-cell *matHeaderCellDef>  {{(lan==0)?'Average Duty and Fee':'औसत ड्यूटी और शुल्क'}} </th>
                          <td mat-cell *matCellDef="let element"> {{ getRoundedValue(element?.REGISTRY_COMPLTED,element?.TOTAL_PAYABLE_REGFEE,element?.EREG_JANPAD_DUTY,
                            element?.EREG_MUNICIPAL_DUTY,
                            element?.EREG_PRINCIPAL_STAMP_DUTY,element?.EREG_UPKAR_DUTY
                          ) }} </td>
                        </ng-container>
                      
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>
        </div>
        </div>
<mat-paginator [length]="pageLength"
               [pageSize]="pageSize"
               [pageIndex]="pageIndex"
               [pageSizeOptions]="[10, 25, 100]"
               (page)="getPageEvent($event)"
               showFirstLastButtons aria-label="Select page">
</mat-paginator>
      </fieldset>

        </div>
        <div class="tab-pane fade" id="paymentList" role="tabpanel"
          aria-labelledby="paymentList-tab">

          <fieldset class="legend-section">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label> {{ "district" | translate }}</label>
                  <select class="form-control" [(ngModel)]="districtId">
                    <option value="" selected="selected" disabled> {{'Search-Document-Certified.select_district' | translate}}
                    </option>
                 <option *ngFor="let distrow of districtList" [value]="distrow.id"> {{lan == 0 ? distrow?.demographyNameEn : distrow?.demographyNameHi}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-auto">
                <div class="form-group">
                  <label> {{(lan==0)?'From Date':'की तिथि से'}}</label>
                  <input type="date" id="search" #search class="form-control" [min]="minFromDate" (keydown)="disableKeyboardInput($event)" [max]="toDate" [(ngModel)]="fromDate" >
                </div>
              </div>
              <div class="col-md-auto">
                <div class="form-group">
                  <label> {{(lan==0)?'To Date':'दिनांक तक'}}</label>
                  <input type="date" id="search" #search class="form-control" [min]="minFromDate" (keydown)="disableKeyboardInput($event)" [max]="toDate" [(ngModel)]="toDate" >
                </div>
              </div>
           
              
              <div class="col-md-auto">
                <div class="form-group">
                  <label class="w-100 d-block"> &nbsp; </label>
                  <button class="btn btn-primary mr-1" (click)="registryDetailShowData()"> {{'searchbtn' | translate}}</button>
                  <!-- <button class="btn btn-danger">
                    {{(lan==0)?'Reset':'रीसेट'}}
                  </button> -->

                </div>
              </div>
            </div>
          </fieldset>

   <fieldset class="legend-section" *ngIf="dataListDetail.length>0">   
    <!-- //*ngIf="dataListDetail.length>0"        -->
        <legend class="sidebar__widget-title">Details</legend>
         
        <div class="table-responsive shadow-none mt-1 example-container">
         
          <!-- <table mat-table [dataSource]="dataList" class="table table-bordered" id="MreportTable">
            <ng-container *ngFor="let columnName of columnNames" [matColumnDef]="columnName">
              <th mat-header-cell *matHeaderCellDef>
                {{ columnObjectss[columnName].headerValEn }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row[columnName] }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
          </table> -->

          <table mat-table class="table table-bordered" [dataSource]="dataSource">
            <!-- Position Column -->
            <ng-container matColumnDef="position">
              <th mat-header-cell *matHeaderCellDef>{{'no' | translate}}</th>
              <td mat-cell *matCellDef="let element; let i = index">{{i + 1 + (paginator.pageIndex *
                paginator.pageSize)}} </td>
            </ng-container>
            <ng-container matColumnDef="district">
              <th mat-header-cell *matHeaderCellDef >{{'ereg-mis-report.District' | translate}}</th>
              <td mat-cell *matCellDef="let row" > {{lan ==0 ? row?.district:row?.districtHi}} </td>
            </ng-container>
            <ng-container matColumnDef="registrationInitation">
              <th mat-header-cell *matHeaderCellDef>  {{(lan==0)?'Registration Number':'पंजीकरण संख्या'}} </th>
              <td mat-cell *matCellDef="let element"> {{element.registrationNumber}} </td>
            </ng-container>
        
              <ng-container matColumnDef="Applicationdate">
              <th mat-header-cell *matHeaderCellDef>  {{(lan==0)?'Application Date':'आवेदन तिथि'}} </th>
              <td mat-cell *matCellDef="let element"> {{element?.slotDate}} </td>
            </ng-container>

            <ng-container matColumnDef="ApprovalDate">
              <th mat-header-cell *matHeaderCellDef>  {{(lan==0)?'Approval Date':'अनुमोदन तिथि'}} </th>
              <td mat-cell *matCellDef="let element"> {{element?.presentationDate}} </td>
            </ng-container>
          
            <!-- AvgFees -->
            <ng-container matColumnDef="STAMP_DUTY">
              <th mat-header-cell *matHeaderCellDef>{{'ereg-mis-report.Stamp_Duty' | translate}}</th>
              <td mat-cell *matCellDef="let row"> {{row.stampDuty | amount}} </td>
                         </ng-container>
            <ng-container matColumnDef="JANPAD_DUTY">
              <th mat-header-cell *matHeaderCellDef>{{'ereg-mis-report.Janpad_Duty' | translate}}</th>
              <td mat-cell *matCellDef="let row"> {{row.janpadDuty | amount}} </td>
              
            </ng-container>
            <ng-container matColumnDef="MUNICIPAL_DUTY">
              <th mat-header-cell *matHeaderCellDef>{{'ereg-mis-report.Nagar_Duty' | translate}}</th>
              <td mat-cell *matCellDef="let row"> {{row.municipalDuty | amount}} </td>
              <!-- <td mat-footer-cell *matFooterCellDef><b> {{totalmunicipalDuty | amount}} </b></td> -->
                           </ng-container>
  
           
            <ng-container matColumnDef="UPKAR_DUTY">
              <th mat-header-cell *matHeaderCellDef>{{'ereg-mis-report.Upkar-Duty' | translate}}</th>
              <td mat-cell *matCellDef="let row"> {{row.upkarDuty | amount}} </td>
                         </ng-container>
            <ng-container matColumnDef="TOTAL_DUTY">
              <th mat-header-cell *matHeaderCellDef>{{'ereg-mis-report.Total-Duty' | translate}}</th>
              <td mat-cell *matCellDef="let row"> {{row.totalDuty | amount}} </td>
            </ng-container>
            <ng-container matColumnDef="REGISTRATION_FEE">
              <th mat-header-cell *matHeaderCellDef>{{'ereg-mis-report.Registration-Fee' | translate}}</th>
              <td mat-cell *matCellDef="let row"> {{row.registrationFee | amount}} </td>
              <!-- <td mat-footer-cell *matFooterCellDef><b> {{totalRegistrationFee | amount}}</b> </td> -->
            </ng-container>
            <ng-container matColumnDef="totalRevenue">
              <th mat-header-cell *matHeaderCellDef >{{'ereg-mis-report.Total-Revenue-Received' |
                translate}}</th>
                <td mat-cell *matCellDef="let row"> {{row?.totalDutyAndFees | amount}} </td>
            </ng-container>
            
             <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
          </table>
        </div>
<mat-paginator  #paginator [length]="pageLengthDtls"
               [pageSize]="_pageSize"
               [pageIndex]="currentPage"
               [pageSizeOptions]="[5, 10, 25, 100]"
               (page)="pageChanged($event)"
               aria-label="Select page">
</mat-paginator>
      </fieldset>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>