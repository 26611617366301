<div class="position-relative">
  <div class="fid-header"></div>
  <div class="breadcrumb-content">
    <div class="container">
      <div class="d-flex justify-content-between">
        <h2>
          {{ lan == 0 ? "Videos" : "वीडियो" }}
        </h2>
      </div>
    </div>
  </div>

  <div class="container">
    <mat-card class="example-card mb-3">
      <mat-card-title></mat-card-title>
      <mat-card-content class="mat-card-content pt-4">
        <div class="row mt-5">
          <div class="col-xl-3 col-md-4 col-sm-6">
            <div class="relative bg-clip-border shadow-md">
              <div class="video_box">
                <a  class="fancybox-button">
                  <video
                    controls
                    preload="metadata"
                    style="
                      width: 100%;
                      height: 170px;
                      border-width: 5px;
                      border-color: #f1f1f1;
                      border: 5px solid #f1f1f1;
                    "
                    alt="MP Startup Conclave 2022"
                    title="MP Startup Conclave 2022"
                    [src]="baseCitizenUrl + '/videos/Sampada2Launch.mp4'"
                  ></video>
                </a>
              </div>
              <div class="p-6">
                <h5 class="tracking-normal">
                  <a class="fancybox-button">
                    {{
                      lan == 0
                        ? "SAMPADA 2.0 launch by honourable CM (10 Oct 2024)"
                        : "माननीय मुख्यमंत्री द्वारा संपदा 2.0 का शुभारंभ (10 अक्टूबर 2024)"
                    }}
                  </a>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-4 col-sm-6">
            <div class="relative bg-clip-border shadow-md">
              <div class="video_box">
                <a  class="fancybox-button">
                  <video
                    controls
                    preload="metadata"
                    style="
                      width: 100%;
                      height: 170px;
                      border-width: 5px;
                      border-color: #f1f1f1;
                      border: 5px solid #f1f1f1;
                    "
                    alt="MP Startup Conclave 2022"
                    title="MP Startup Conclave 2022"
                    [src]="baseCitizenUrl + '/videos/Sampada2Video.mp4'"
                  ></video>
                </a>
              </div>
              <div class="p-6">
                <h5 class="tracking-normal">
                  <a   class="fancybox-button">
                    {{
                      lan == 0
                        ? "SAMPADA 2.0 features"
                        : "संपदा 2.0 की विशेषताएं"
                    }}
                  </a>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-4 col-sm-6">
            <div class="relative bg-clip-border shadow-md">
              <div class="video_box">
                <a  class="fancybox-button">
                  <video
                    controls
                    preload="metadata"
                    style="
                      width: 100%;
                      height: 170px;
                      border-width: 5px;
                      border-color: #f1f1f1;
                      border: 5px solid #f1f1f1;
                    "
                    alt="MP Startup Conclave 2022"
                    title="MP Startup Conclave 2022"
                    [src]="baseCitizenUrl + '/videos/CitizensFeedback.mp4'"
                  ></video>
                </a>
              </div>
              <div class="p-6">
                <h5 class="tracking-normal">
                  <a  class="fancybox-button">
                    {{
                      lan == 0
                        ? "SAMPADA 2.0 Citizens Feedback"
                        : "संपदा 2.0 नागरिक प्रतिक्रिया"
                    }}
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>


 