import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { CustomHttpClient } from 'projects/common-lib/src/lib/helper-lib/httpclient/custom.httpclient';
import { ResultModel } from 'projects/common-lib/src/lib/models/api.result.model/result.model';
import { EregPropertyLinkModel, IgrsEregImpoundPropertyBean, IgrsBankFinancialInstituteDetailsBean, IgrsEregImpoundTxnBean, IgrsEregPartyDetailsBean, IgrsEregPropAdditionalDetlsBean, IgrsEregPropIdentificationBean, IgrsEregTxnDetlsBean, SaveERegPropertyImageDetails, SlotModel, IgrsEregWillOutsidePropDetlsBean, IgrsEregParticularDetailBean, IgrsEregAddInputFieldsBean, IgrsMvPropTxnDto, EregPayConfigBean, IgrsEregCompAddDocBean } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import { map } from "rxjs/operators";
import { PropertyArray } from 'projects/common-lib/src/lib/models/propertyModelArray';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { AddEstamp } from 'projects/common-lib/src/lib/models/addEstamp.model/add-estamp.model';
import { InstMappingBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/inst-mapping-bean';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EregistryService {
  private settings!: AppSetting;
  constructor(private appSettingsService: AppSettingService, private customeHttpClient: CustomHttpClient, private _AESEncryptDecryptService: AESEncryptDecryptService) {
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
    });
  }

  saveEregPropertyLink(eregPropertyLinkModel: EregPropertyLinkModel) {

    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept-Language": sessionStorage.getItem("selectedLanguage") || ""
    });

    return this.customeHttpClient.Post<ResultModel<any>>(
      this.settings.igrs_services_url + "common/ereg/save_ereg_property_link", eregPropertyLinkModel, { headers: httpHeaders }
    ).pipe(
      map((result: any) => {
        //if (result) {
        return result;
        //}
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
    );
  }

  saveEregUploadLinkPropertyModel(uploadLinkPropertyArray: PropertyArray[]) {

    return this.customeHttpClient.Post<ResultModel<any>>(
      this.settings.igrs_services_url + "common/ereg/save_ereg_uploadLink_property_model", uploadLinkPropertyArray
    ).pipe(
      map((result: any) => {
        //if (result) {
        return result;
        //}
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
    );
  }


  // getPropImgDetailsByPropId(propId: any) {
  //   return this.customeHttpClient
  //     .Get<ResultModel<any>>(
  //       this.settings.igrs_services_url + "citizen/ereg/getPropImgDetailsByPropId?propId="+propId
  //     ).pipe(
  //       map((result: any) => {
  //         return result;
  //       })
  //     );
  // }


  // saveEregPropIdentificationDetails(igrsEregPropIdentificationBean:any)
  // {
  //    return this.customeHttpClient
  //     .Post<ResultModel<any>>(
  //       this.settings.igrs_services_url + "citizen/ereg/saveEregPropIdentificationDetails",
  //       igrsEregPropIdentificationBean
  //     ).pipe(
  //           map((result:any)=>{
  //             if (result){
  //                     return result;
  //             }
  //           },
  //           (error:any)=>{

  //               return error;
  //           }
  //           )
  //     );
  // }

  getPropImgDetailsByReferenceId(propId: any, referenceId: any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/getPropImgDetailsByReferenceIdNew?referenceId=" + referenceId + "&propId=" + propId, {}
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }
  getPropImgDetailsByReferenceIdWebGis(propId: any, webGisid: number, referenceId: any, propTypeid: number, propSource: string, id: number, eregId: string, latitude: number, longitude: number, propertyTypeId: number, districtId: string, landParcelId: any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/getPropImgDetailsByReferenceIdNew?referenceId=" + referenceId + "&webGisId=" + webGisid + "&propId=" + propId + "&propTypeId=" + propTypeid + "&propSource=" + propSource + "&id=" + id + "&regId=" + eregId + "&latitude=" + latitude + "&longitude=" + longitude + "&propertyTypeId=" + propertyTypeId + "&districtId=" + districtId + "&landParcelId=" + landParcelId, {}
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }
  deleteEregPropIdentificationDetails(igrsEregPropIdentificationBean: any) {//not used
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/deleteEregPropIdentificationDetails",
        igrsEregPropIdentificationBean
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  deleteRegistryInitiation(id: number) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/deleteRegistryInitiation?id=" + id, {}
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }


  deleteEstampInitiation(estampId: number) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/estamp/deleteEstampInitiation?estampId=" + estampId, {}
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  deletePropIdentificationDetails(id: number,propId:string) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/deleteEregPropIdentificationDetails?propIdentificationId=" + id + "&propId="+propId,{}
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }


  deleteConsdAmtDetails(id: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/deleteConsdAmtDetails?id=" + id, {}

      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  getAllEregPropLinkingDetails(pageno: any, iDisplayLength: any, active: any, direction: any, search: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ereg/getAllEregPropLinkingDetails?pageno=" + pageno + "&active=" + active + "&direction=" + direction + "&search=" + search + "&iDisplayLength=" + iDisplayLength
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  deleteEregPropLinkingDetails(eregPropertyLinkModel: any) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ereg/deleteEregPropLinkingDetails",
        eregPropertyLinkModel
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  checkEregPropLinkingPropId(propId: any, propIdentificationId: any, fkPropSourceId: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ereg/checkEregPropLinkingPropId?propIdentificationId=" + propIdentificationId + "&propId=" + propId + "&propSourceId=" + fkPropSourceId
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }
  getEregPropIdList(txnId: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ereg/getEregPropIdList?txnId=" + txnId
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }


  saveEregInitDetails(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/saveEregInitDetails",
        eregTxnDetlsBean
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  amendmentCancellationCloningPartyDetails(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/amendmentCancellationCloningPartyDetails",
        eregTxnDetlsBean
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  updateEstampInEreg(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {

    let igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();

    igrsEregTxnDetlsBean = JSON.parse(JSON.stringify(eregTxnDetlsBean));//make copy

    //remove heavy node
    igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = []

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/updateEstampInEreg", igrsEregTxnDetlsBean
      ).pipe(
        map((result: any) => {
          if (result) {

            //add heavy node

            if (eregTxnDetlsBean.igrsEregPropIdentificationBean) {
              if (eregTxnDetlsBean.igrsEregPropIdentificationBean != null || eregTxnDetlsBean.igrsEregPropIdentificationBean.length > 0) {
                result.responseData?.igrsEregPropIdentificationBean?.push(...eregTxnDetlsBean.igrsEregPropIdentificationBean)
              }

            }
          }
          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  updateStatusInEreg(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {

    let igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();
    igrsEregTxnDetlsBean = JSON.parse(JSON.stringify(eregTxnDetlsBean));//make copy

    //remove heavy node 
    igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = []

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/updateStatusInEreg", igrsEregTxnDetlsBean
      ).pipe(
        map((result: any) => {

          //add heavy node
          if (result) {

            if (eregTxnDetlsBean.igrsEregPropIdentificationBean) {
              if (eregTxnDetlsBean.igrsEregPropIdentificationBean != null || eregTxnDetlsBean.igrsEregPropIdentificationBean.length > 0) {
                result.responseData?.igrsEregPropIdentificationBean?.push(...eregTxnDetlsBean.igrsEregPropIdentificationBean)
              }
            }
          }
          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  updateStatusInEregChecker(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/updateStatusInEreg", eregTxnDetlsBean
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  saveEregPropDetails(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/saveEregPropIdentificationDetailsFinal", eregTxnDetlsBean
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  savePropImgUploadUserDetails(igrsEregPropImgUploadUserBean: SaveERegPropertyImageDetails) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/savePropImgUploadUserDetails",
        igrsEregPropImgUploadUserBean
      ).pipe(
        map((result: any) => {
          //console.log("result:-", result);
          //if (result) {
          //console.log("result true:-", result);
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  saveMovablePropDetails(igrsMvPropTxnDto: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/saveMovablePropDetails",
        igrsMvPropTxnDto
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  // getPropImgDetailsByReferenceId(propId: any, referenceId: any) {
  //   return this.customeHttpClient
  //     .GetApi<ResultModel<any>>(
  //       this.settings.igrs_services_url + "citizen/ereg/getPropImgDetailsByReferenceId?referenceId=" + referenceId + "&propId=" + propId, {}
  //     ).pipe(
  //       map((result: any) => {
  //         return result;
  //       }),
  // (error: any) => {
  //   console.log("error:-", error);
  //   return error;
  // }
  //     );
  // }

  getMVPropDtlByEregId(eregId: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ereg/getMVPropDtlByEregId?eregId=" + eregId
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  deleteMovablePropDetails(igrsMvPropTxnDto: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/deleteMovablePropDetails",
        igrsMvPropTxnDto
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }


  getPropImgDetailsByPropId(propId: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ereg/getPropImgDetailsByPropId?propId=" + propId
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }


  // getallmasterstatusrecord() {
  //   return this.customeHttpClient
  //     .GetApi<ResultModel<any>>(
  //       this.settings.igrs_services_url + "department/master/getallmasterstatusrecord", {}
  //     ).pipe(
  //       map((result: any) => {

  //         return result;
  //       },
  //         (error: any) => {
  //           console.log("error:-", error);
  //           return error;
  //         }
  //       )
  //     )
  // }

  saveEregPropIdentificationDetails(igrsEregPropIdentificationBean: IgrsEregPropIdentificationBean) {//saving single property

    // let property = new IgrsEregPropIdentificationBean()

    // property = JSON.parse(JSON.stringify(igrsEregPropIdentificationBean));//make copy

    // //remove heavy node
    // property.propertyValuationResponseBean = null;

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/saveEregPropIdentificationDetails",
        //this.settings.igrs_services_url + "common/ereg/saveEregPropIdentificationDetails",
        igrsEregPropIdentificationBean
      ).pipe(
        map((result: any) => {
          if (result) {

            //add heavy node
            //result.responseData.propertyValuationResponseBean = igrsEregPropIdentificationBean.propertyValuationResponseBean;

          }
          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  getmststatusdetails(param: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/master/getmststatusdetails", param
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }

        )
      )
  }


  savemasterstatus(savemasterstatusObject: any) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/master/savemasterstatus", savemasterstatusObject
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
  }
  updatemasterstatus(updatemasterstatus: any) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/master/updatemasterstatus", updatemasterstatus
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
  }
  getstatusById(id: number) {
    let param = { id: id }
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + 'department/master/getstatusById', param
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      )
  }

  // fetching all ModuleType from MST_STATUS
  getModuleType() {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/master/getModuleType", {}
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  // fetching status from Mst_Status
  getStatus() {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/master/getStatus", {}
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  // getEregistryDetailForDeptById(regId: string) {

  //   return this.customeHttpClient
  //     .GetApi<ResultModel<any>>(
  //       this.settings.igrs_services_url + `department/ereg/getEregistryDetailsById/${regId}`, {}
  //     ).pipe(
  //       map((result: any) => {

  //         return result;
  //       },
  //         (error: any) => {
  //           console.log("error:-", error);
  //           return error;
  //         }

  //       )
  //     )
  // }


  //slot booking 
  checkIfEligibleForFacelessRegistry(id: number) {

    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + `citizen/ereg/checkIfEligibleForFacelessRegistry`, { id }
      ).pipe(
        map((result: any) => {

          return result;

        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }
  getSrOfficeList(eregId: number) {
    return this.customeHttpClient

      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/getSrOfficeList", { eregId }
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }
  getHolidays(formatedDate: string, srOfficeId: number) {

    if (!srOfficeId) {
      srOfficeId = 0
    }
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/getHolidays", { formatedDate, srOfficeId }
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }
  // getLocalHolidays(formatedDate: string, srOfficeId: number) {
  //   if (!srOfficeId) {
  //     srOfficeId = 0
  //   }
  //   return this.customeHttpClient
  //     .GetApi<ResultModel<any>>(
  //       this.settings.igrs_services_url + "citizen/ereg/getLocalHolidays", { formatedDate, srOfficeId }
  //     ).pipe(
  //       map((result: any) => {
  //         return result;
  //       }),
  //       (error: any) => {
  //         console.log("error:-", error);
  //         return error;
  //       }
  //     );
  // }
  // getAvailableSlots(formatedDate:string, srOfficeId: number, regTypeId: number, slotType: number) {
  //     return this.customeHttpClient
  //       .GetApi<ResultModel<any>>(
  //         this.settings.igrs_services_url + "citizen/ereg/getAvailableSlots", {formatedDate, srOfficeId, regTypeId, slotType}
  //       ).pipe(
  //         map((result: any) => {
  //           return result;
  //         }),
  // (error: any) => {
  //   console.log("error:-", error);
  //   return error;
  // }
  //       );
  //   }
  getAvailableSlots(formatedDate: string, srOfficeId: number, regTypeId: number, slotType: number, returnOldSlots: any) {
    if (!srOfficeId) {
      srOfficeId = 0
    }
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ereg/getAvailableSlots?formatedDate=" + formatedDate + "&srOfficeId=" + srOfficeId + "&regTypeId=" + regTypeId + "&slotType=" + slotType + "&returnOldSlots=" + returnOldSlots + ""
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }
  getBookedSlotInfo(eregId: string) {

    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + `citizen/ereg/getBookedSlotInfo`, { eregId }
      ).pipe(
        map((result: any) => {

          return result;

        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }
  saveSlotDetails(slot: SlotModel) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/saveSlotDetails", slot
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  //VKYC changes starts

  sendNonAssistedVkycLink(eregTxnDetlsBean: any, regTypeId: number) {

    let igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();

    igrsEregTxnDetlsBean = JSON.parse(JSON.stringify(eregTxnDetlsBean));//make copy

    //remove heavy node
    igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = []

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/vkyc/sendNonAssistedVkycLink?regTypeId=" + regTypeId, igrsEregTxnDetlsBean
      ).pipe(
        map((result: any) => {

          //add heavy node
          if (eregTxnDetlsBean.igrsEregPropIdentificationBean)
            result.responseData?.igrsEregPropIdentificationBean?.push(...eregTxnDetlsBean.igrsEregPropIdentificationBean)
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }
  resendNonAssistedVkycLink(partyId: number) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/vkyc/resendNonAssistedVkycLink", { partyId }
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  resendAssistedVkycLink(partyId: number) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/vkyc/resendAssistedVkycLink", { partyId }
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }


  saveEregPartyDetails(eRegPartyDetails: IgrsEregPartyDetailsBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/saveEregPartyDetails",
        eRegPartyDetails
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }
  saveDscSignPartyPhoto(eRegPartyDetails: IgrsEregPartyDetailsBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/saveDscSignPartyPhoto",
        eRegPartyDetails
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  saveDscSignPartyThumb(eRegPartyDetails: IgrsEregPartyDetailsBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/saveDscSignPartyThumb",
        eRegPartyDetails
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  saveDscSignPartyThumbCommon(eRegPartyDetails: IgrsEregPartyDetailsBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ereg/saveDscSignPartyThumb",
        eRegPartyDetails
      ).pipe(
        map((result: any) => {
          //if (result) {
            return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  updateAuthenticationStatus(eRegPartyDetails: IgrsEregPartyDetailsBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/updateAuthenticationStatus",
        eRegPartyDetails
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  saveDscSignPartyDetails(eRegPartyDetails: IgrsEregPartyDetailsBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/saveDscSignPartyDetails",
        eRegPartyDetails
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  saveEstampDscSignPartyDetails(eRegPartyDetails: IgrsEregPartyDetailsBean) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/saveDscSignPartyDetails",
        eRegPartyDetails
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  sendLinkForEsign(eRegPartyDetails: IgrsEregPartyDetailsBean) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/sendLinkForEsign",
        eRegPartyDetails
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  saveEstampSpDscSignDetails(eRegPartyDetails: IgrsEregPartyDetailsBean) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        // this.settings.igrs_services_url + "citizen/estamp/saveEstampSpDscSignDetails",
        // eRegPartyDetails
        this.settings.igrs_gateway_url + "common/partyesign/saveEstampSpDscSignDetails",
        eRegPartyDetails
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {

            return error;
          }
        )
      );
  }


  saveDepartmentDscSignPartyDetails(eRegPartyDetails: IgrsEregPartyDetailsBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/saveDscSignPartyDetails",
        eRegPartyDetails
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  get_ListingEregistryByUserId(pageNo: number, pageSize: number, param: any) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/listingEregByUserId/" + pageNo + "/" + pageSize, param
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );

  }

  deleteEregPartyDetails(partyId: number, mappingId: number) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/deleteEregPartyDetails?partyId=" + partyId + "&mappingId=" + mappingId, {}

      ).pipe(
        map((result: any) => {

          return result;

        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }



  getEregPropDetailById(regId: any) {

    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/getEregPropDetailsByEregId?eregId=" + regId, {}
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }

        )
      )
  }

  getMovablePropDetails(regId: any) {

    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/getMovablePropDetails?eregId=" + regId, {}
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }

        )
      )
  }

  getDeedDocVerificationDeatils() {

    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/getDeedDocumentVerificationDeatils", {}
      ).pipe(
        map((result: any) => {

          if (result && result.responseStatus == 'true') {
            return result.responseData;
          } else {
            return result;
          }


        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  loadEregistryDataOnMakerOrChecker(eRegisId: any) {

    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/loadEregistryDataOnMakerOrChecker?eRegisId=" + eRegisId, {}
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }

        )
      )

  }

  saveEregCompPartyDetails(partyModel: IgrsEregPartyDetailsBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/saveEregCompPartyDetails",
        partyModel
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  // savePartyMakerRemarks(igrsEregPartyDetailsBeanList: IgrsEregPartyDetailsBean[]) {
  //   return this.customeHttpClient
  //     .PostApiToken<ResultModel<any>>(
  //       this.settings.igrs_services_url + "department/ereg/savePartyMakerRemarks",
  //       igrsEregPartyDetailsBeanList
  //     ).pipe(
  //       map((result: any) => {
  //         //if (result) {
  //         return result;
  //         //}
  //       },
  //         (error: any) => {
  //           console.log("error:-", error);
  //           return error;
  //         }
  //       )
  //     );
  // }

  saveEregDeedMakerCheckerRemarks(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {

    let igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();

    igrsEregTxnDetlsBean = JSON.parse(JSON.stringify(eregTxnDetlsBean));//make copy

    //remove heavy node
    igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = []

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/saveEregDeedMakerCheckerRemarks",
        igrsEregTxnDetlsBean
      ).pipe(
        map((result: any) => {
          if (result) {

            //add heavy node
            if (eregTxnDetlsBean.igrsEregPropIdentificationBean)
              result.responseData?.igrsEregPropIdentificationBean?.push(...eregTxnDetlsBean.igrsEregPropIdentificationBean)
          }
          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  // sroUpdateEregPartyDetalsById(eRegPartyModel: IgrsEregPartyDetailsBean) {
  //   return this.customeHttpClient
  //     .PostApiToken<ResultModel<any>>(
  //       this.settings.igrs_services_url + "department/ereg/sroUpdateEregPartyDetalsById",
  //       eRegPartyModel
  //     ).pipe(
  //       map((result: any) => {
  //         //if (result) {
  //         return result;
  //         //}
  //       },
  //         (error: any) => {
  //           console.log("error:-", error);
  //           return error;
  //         }
  //       )
  //     );
  // }

  // getExPartyDetailsById(param: any) {
  //   return this.customeHttpClient
  //     .GetApi<ResultModel<any>>(
  //       this.settings.igrs_services_url + "department/ereg/getExPartyDetailsById", param
  //     ).pipe(
  //       map((result: any) => {

  //         return result;

  //       }),
  // (error: any) => {
  //   console.log("error:-", error);
  //   return error;
  // }
  //     );
  // }
  deleteEregExParty(partyId: number) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/deleteEregPartyDetails?partyId=" + partyId, {}

      ).pipe(
        map((result: any) => {

          return result;

        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  // downloadExecutantPartyPDF(executantId: any) {

  //   return this.customeHttpClient
  //     .PostApiToken<ResultModel<any>>(
  //       this.settings.igrs_services_url + "department/ereg/downloadExecutantPDF/" + executantId,
  //       executantId
  //     ).pipe(
  //       map((result: any) => {
  //         //if (result) {
  //         return result;
  //         //}
  //       },
  //         (error: any) => {
  //           console.log("error:-", error);
  //           return error;
  //         }
  //       )
  //     );
  // }


  getPaymentDetailsForSlotBooking(slotBookingType: string) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_gateway_url + "citizen/get_payment_details_for_slot_booking/" + slotBookingType, {}
      ).pipe(
        map((result: any) => {

          if (result && result.responseStatus == 'true') {
            return result.responseData;
          }
          //else {
          return result;
          //}
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  getPaymentDetailsForEregPaymentDetails(payInfoReg: EregPayConfigBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_gateway_url + "citizen/get_payment_details_for_ereg", payInfoReg 
      ).pipe(
        map((result: any) => {

          if (result && result.responseStatus == 'true') {
            return result.responseData;
          }
          //else {
          return result;
          //}
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  getEregConsiderationPaymentDetails(regId: any) {

    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/get_ereg_consideration_payment?eregId=" + regId, {}
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }

        )
      )

  }

  generateOtpForRegModeChange(mobile: string , tempEregNo : string) {
    return this.customeHttpClient
        .GetApi<ResultModel<any>>(
            this.settings.igrs_services_url + "citizen/ereg/verifyOtpDocRegModeChange?mobile_no=" + mobile + "&RegNo=" + tempEregNo,""
        ).pipe(
            map((result: any) => {               
                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }
            })
        );
    }
    changeDocRegModeApi(paramMap : any) {
      return this.customeHttpClient
        .PostApiToken<ResultModel<any>>(
          this.settings.igrs_services_url + "citizen/ereg/ChangeDocumentRegMode/" , paramMap
        ).pipe(
          map((result: any) => {
            if (result && result.responseStatus == 'true') {
              return result;
            } else {
              return result;
            }
          })
        );
    }

  getPropertyByEregId(regId: any, propId: any, fromCheckerModule?: boolean, fromCMSModule?: boolean,valTxnId?:any, isImpound?:any) {
    let controllerName: string = "citizen";
    if (fromCheckerModule||fromCMSModule) {
      controllerName = "department";
    }
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + controllerName + "/pv/indentifation/getPropertyByEregId?eregId=" + regId + "&propId=" + propId
        + "&valTxnId=" + valTxnId + "&isImpound=" + isImpound
        , {}
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }

        )
      )

  }
  updateStatusIfValuationChange(eregTxnDetlsBean:IgrsEregTxnDetlsBean){
    return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + "citizen/ereg/updateStatusInEregIfValuationChange",
      eregTxnDetlsBean
    ).pipe(
      map((result: any) => {
        //if (result) {
        return result;
        //}
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
    );
  }


  sendPaymentRequest(slotPaymentBean: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_gateway_url + "citizen/send_request_onlinepayment",
        slotPaymentBean
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }
  sendPaymentRequestDSC(paymentBean: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_gateway_url + "citizen/send_request_onlinepayment",
        paymentBean
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  sendPaymentRequestFromWallet(slotPaymentBean: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_gateway_url + "citizen/send_request_slot_payment_debit_from_wallet",
        slotPaymentBean
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }


  //for save consederation payment details
  saveERegconsiderationPayment(saveConsederationPayment: any) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url
        + "common/ereg/save_ereg_consideration_payment",
        saveConsederationPayment
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }



  loadEregistryDataByEregId(igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean, eRegisId: string) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/loadEregistryDataByEregId?eregId=" + eRegisId,
        igrsEregTxnDetlsBean
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }

        )
      )
  }

  getEregistryDetailsByEregId(igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean, eRegisId: string) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/getEregistryDetailsByEregId?eregId=" + eRegisId,
        igrsEregTxnDetlsBean
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }

        )
      )
  }

  // getEregistryDetailsByEregId(eregId: string) {
  //   let controllerName: string = "citizen";
  //   // if (fromCheckerModule) {
  //   //   controllerName = "department";
  //   // }
  //   return this.customeHttpClient
  //     .GetApi<ResultModel<any>>(
  //       this.settings.igrs_services_url + controllerName + "/ereg/getEregistryDetailsByEregId?eregId=" + eregId, {}
  //     ).pipe(
  //       map((result: any) => {
  //         return result;
  //       }),
  //       (error: any) => {
  //         console.log("error:-", error);
  //         return error;
  //       }
  //     );
  // }

  loadEregistryDataByEstampId(param: any) {

    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ereg/loadEregistryDataByEstampId", param
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }

        )
      )

  }

  deedAcceptLinkSendToParty(param: any) {

    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/deedAcceptLinkSendToParty", param
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }

        )
      )

  }


  getAllPaymentModeDetails() {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/getAllPaymentMode", {}
      ).pipe(
        map((result: any) => {

          if (result && result.responseStatus == 'true') {
            return result;
          }
          //else {
          return result;
          //}
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }


  // downloadUploadedDocOld(fileName: string) {
  //   let fileNameEnc = this._AESEncryptDecryptService.encrypt("" + fileName);
  //   return this.customeHttpClient

  //     .Get<ResultModel<any>>(

  //       //this.settings.igrs_services_url + "common/gl/downloadGlUploadedDoc/"+docPath, docPath
  //       this.settings.igrs_services_url + "common/ereg/getEregPropMutationImages?fileName=" + fileNameEnc, {}
  //       // filename
  //     ).pipe(
  //       map((result: any) => {
  //         if (result) {
  //           // var string1 = JSON.stringify(result);
  //           //   var data:any;
  //           //  filename: 'yourFileName.pdf',
  //           //   data= result.blob()
  //           return result;
  //         }
  //       },
  //         (error: any) => {
  //           console.log("error:-", error);
  //           return error;
  //         }
  //       )
  //     );
  // }

  downloadPropUploadedDoc(fileName: string, mmYear: string) {
    if(fileName&&mmYear){
    console.log("downloadPropUploadedDoc");
    //let fileNameEnc = this._AESEncryptDecryptService.encrypt("" + fileName);
    //console.log(this.settings.igrs_services_url + "common/ereg/getEregMovablePropImages?fileName=" + fileName + "&mmYear=" + mmYear)
    window.open(this.settings.igrs_services_url + "common/ereg/getEregPropMutationImages?fileName=" + fileName + "&mmYear=" + mmYear, '_blank');
    //window.open(this.settings.igrs_services_url + "common/ereg/getEregPropMutationImages?fileName="+fileNameEnc,'_blank');
    }
  }

  downloadMovableUploadedDoc(fileName: string, mmYear: string) {
    console.log("downloadUploadedDoc");
    //let fileNameEnc = this._AESEncryptDecryptService.encrypt("" + fileName);

    window.open(this.settings.igrs_services_url + "common/ereg/getEregMovablePropImages?fileName=" + fileName + "&mmYear=" + mmYear, '_blank');
    //window.open(this.settings.igrs_services_url + "common/ereg/getEregPropMutationImages?fileName="+fileNameEnc,'_blank');
  }

  getPreMutationDetails(premutationId: any, landParcelId: string, propertyId: any,
    igrsEregPropAdditionalDetlsBean: IgrsEregPropAdditionalDetlsBean) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/preMutationInfo?requestId=" + premutationId + "&landParcelId=" + landParcelId + "&propertyId=" + propertyId, igrsEregPropAdditionalDetlsBean
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }

        )
      )
  }
  generateFilenameForPropertyIdentification(uniqueId: any) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/generateFileNameForPropertyIdentification/" + uniqueId, null
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }

        )
      )
  }
  saveEregTemplateFieldValuesDetails(eRegTempleteFieldValuesDetails: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/saveEregTemplateFieldValuesDetails",
        eRegTempleteFieldValuesDetails
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  getEregDutyCalculationPaymentDetails(dutyId: any, instId: any, instFieldMappingList: InstMappingBean[]) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/get_ereg_duty_calculation_payment?dutyId=" + dutyId +
        "&instId=" + instId, instFieldMappingList
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }

        )
      )

  }

  getEregEstampDetails(param: any) {

    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/getEregEstampDetails", param
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }

        )
      )
  }

  updateEregEstamp(addEstamp: AddEstamp) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/updateEregEstamp",
        addEstamp
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  saveEregEstamp(addEstamp: AddEstamp) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/saveEregEstampDetails",
        addEstamp
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }


  // saveEregDeedContent(eregDeedContentModel: any) {
  //   return this.customeHttpClient
  //     .PostApiToken<ResultModel<any>>(
  //       this.settings.igrs_services_url + "citizen/ereg/saveEregDeedContent",
  //       eregDeedContentModel
  //     ).pipe(
  //       map((result: any) => {
  //         if (result) {
  //           return result;
  //         }
  //       },
  //         (error: any) => {
  //           console.log("error:-", error);
  //           return error;
  //         }
  //       )
  //     );
  // }

  getDeedTemplateByTemplateId(templateId: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/getDeedTemplateByTemplateId?templateId=" + templateId
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  sendDeedEsignLinkToParties(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {

    let igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();

    igrsEregTxnDetlsBean = JSON.parse(JSON.stringify(eregTxnDetlsBean));//make copy

    //remove heavy node
    igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = []

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/sendDeedEsignLinkToParties", igrsEregTxnDetlsBean
      ).pipe(
        map((result: any) => {
          if (result) {
            //add heavy node
            if (eregTxnDetlsBean.igrsEregPropIdentificationBean)
              result.responseData.igrsEregPropIdentificationBean?.push(...eregTxnDetlsBean.igrsEregPropIdentificationBean)
          }
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  getAllPartyTypeByInstrumentId(instrumentId: number) {

    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + `common/duty/getAllPartyTypeByInstrumentId/${instrumentId}`, {}
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }

        )
      )
  }


  getBankMasterList() {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_gateway_url + "common/get_all_bank_master_list", {}
      ).pipe(
        map((result: any) => {

          if (result && result.responseStatus == 'true') {
            return result;
          }
          //else {
          return result;
          //}
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  getSRVKYCLink(eregId: string) {

    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + `department/vkyc/getSRVKYCLink`, { eregId }
      ).pipe(
        map((result: any) => {

          return result;

        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }


  viewDutyOrRegFeeDoc(param: string): void {
    var url = this.settings.igrs_services_url + "common/ereg/downloadDutyAndRegfeeDoc/" + param
    window.open(url, '_blank');
  }

  updateEregAndSlotStatus(eregId: string, remarks: string, isAllPartiesPresent: boolean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/sroUpdateEregStatusAndSlotStatusById?eregId=" + eregId + "&&remarks=" + remarks + "&&isAllPartiesPresent=" + isAllPartiesPresent, {}

      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  // getUserAadhaarDetails(param: any) {

  //   return this.customeHttpClient
  //     .GetApi<ResultModel<any>>(
  //       this.settings.igrs_gateway_url + "common/get_aadhaar_txn_details", param
  //     ).pipe(
  //       map((result: any) => {

  //         if (result && result.responseStatus == 'true') {

  //           return result;
  //         }
  //         //else {
  //         return result;
  //         //}

  //       }),
  //       (error: any) => {
  //         console.log("error:-", error);
  //         return error;
  //       }
  //     );
  // }



  saveEregMakerRemarks(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {

    let igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();

    igrsEregTxnDetlsBean = JSON.parse(JSON.stringify(eregTxnDetlsBean));//make copy

    //remove heavy node
    igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = []

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/saveEregMakerRemarks",
        igrsEregTxnDetlsBean
      ).pipe(
        map((result: any) => {
          if (result) {

            //add heavy node
            if (eregTxnDetlsBean.igrsEregPropIdentificationBean)
              result.responseData.igrsEregPropIdentificationBean?.push(...eregTxnDetlsBean.igrsEregPropIdentificationBean)
          }
          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }
  getEregPartyDetailsById(regId: any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + `department/ereg/getERegPartyDetails/${regId}`, {}
      ).pipe(
        map((result: any) => {
          return result;
        }),
        // (error: any) => {
        //   console.log("error:-", error);
        //   return error;
        // }
      );
  }

  viewEregDeedJrxmlToHtml(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {

    let igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();

    igrsEregTxnDetlsBean = JSON.parse(JSON.stringify(eregTxnDetlsBean));//make copy

    //remove heavy node
    igrsEregTxnDetlsBean.igrsEregPropIdentificationBean?.forEach(property => {
      property.propIdentificationJson = '';
    })

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_jasper_url + "citizen/jasper/ereg/exportJspReportForEregDeed",
        igrsEregTxnDetlsBean
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  viewEregEsignDeedJrxmlToHtml(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_jasper_url + "citizen/jasper/ereg/exportJspReportForEregEsignDeed",
        eregTxnDetlsBean
      ).pipe(
        map((result: any) => {
          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  viewEregDeedJrxmlToHtmlService(igrsEregTxnDetlsBean: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_ereg_url + "citizen/ereg/exportJspReportForEregDeedService",
        igrsEregTxnDetlsBean
      ).pipe(
        map((result: any) => {
          // if (result && result.responseStatus == 'true') {
          return result;
          // }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }


  viewEregDeedByDepartmentJrxmlToHtml(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {

    let igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();

    igrsEregTxnDetlsBean = JSON.parse(JSON.stringify(eregTxnDetlsBean));//make copy

    //remove heavy node
    igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = []

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_jasper_url + "department/jasper/ereg/exportJspReportForEregDeed",
        igrsEregTxnDetlsBean
      ).pipe(
        map((result: any) => {
          if (result) {

            //add heavy node
            if (result.responseStatus == 'true' && eregTxnDetlsBean?.igrsEregPropIdentificationBean)
              result.responseData?.igrsEregPropIdentificationBean?.push(...eregTxnDetlsBean?.igrsEregPropIdentificationBean)
          }
          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }
  viewEregDeedJrxmlToHtmlCommon(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {

    let igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();

    igrsEregTxnDetlsBean = JSON.parse(JSON.stringify(eregTxnDetlsBean));//make copy

    //remove heavy node
    igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = []

    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_jasper_url + "common/jasper/ereg/exportCommonJspReportForEregDeed",
        igrsEregTxnDetlsBean
      ).pipe(
        map((result: any) => {
          if (result) {

            //add heavy node
            if (result.responseStatus == 'true' && eregTxnDetlsBean?.igrsEregPropIdentificationBean)
              result.responseData?.igrsEregPropIdentificationBean?.push(...eregTxnDetlsBean?.igrsEregPropIdentificationBean)
          }
          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  viewEregDeedJrxmlToHtmlCommonForDeedHistory(param: {}) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_jasper_url + "common/jasper/ereg/exportCommonJspReportForEregDeedHistory",
        param
      ).pipe(
        map((result: any) => {
          // if (result && result.responseStatus == 'true') {
          return result;
          // }
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  viewEstampDeedJrxmlToHtmlCommon(estampCommonBean: any) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_jasper_url + "common/jasper/estamp/exportCommonJspReportForEstampDeed",
        estampCommonBean
      ).pipe(
        map((result: any) => {
          // if (result && result.responseStatus == 'true') {
          return result;
          // }
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }
  // getEregistryDetailById(regId: string) {
  //   return this.customeHttpClient
  //     .GetApi<ResultModel<any>>(
  //       this.settings.igrs_services_url + `citizen/ereg/getEregistryDetailsById/${regId}`, {}
  //     ).pipe(
  //       map((result: any) => {
  //         return result;
  //       },
  //         (error: any) => {
  //           return error;
  //         }
  //       )
  //     )
  // }


  getEregOtherDetlsMasterList() {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/get_all_other_detail_master_list", {}
      ).pipe(
        map((result: any) => {

          if (result && result.responseStatus == 'true') {
            return result;
          }
          //else {
          return result;
          //}


        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  getAllMstCyberTehsilFormLabelsDetails(formType: string) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/getAllMstCyberTehsilFormLabelsDetails/" + formType, ""
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  downloadDeedFieldUploadPdf(id: number) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/downloadDeedFieldUploadPdf/" + id, ""
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  // filterCyberTehsilTempleteFieldAsMatrix(igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean) {
  //   return this.customeHttpClient
  //     .Post<ResultModel<any>>(
  //       this.settings.igrs_services_url + "common/ereg/filterCyberTehsilTempleteFieldAsMatrix",
  //       igrsEregTxnDetlsBean
  //     ).pipe(
  //       map((result: any) => {
  //         console.log("result:-", result);
  //         if (result) {
  //           console.log("result true:-", result);
  //           return result;
  //         }
  //       },
  //         (error: any) => {
  //           console.log("error:-", error);
  //           return error;
  //         }
  //       )
  //     );
  // }

  proceedForRegCompletion(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {

    let igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();

    igrsEregTxnDetlsBean = JSON.parse(JSON.stringify(eregTxnDetlsBean));//make copy

    //remove heavy node
    igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = []

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/proceedForRegCompletion",
        igrsEregTxnDetlsBean
      ).pipe(
        map((result: any) => {
          console.log("proceedForRegCompletion result", result);

          if (result) {

            //add heavy node
            if (result.responseStatus == 'true' && eregTxnDetlsBean?.igrsEregPropIdentificationBean)
              result.responseData?.igrsEregPropIdentificationBean?.push(...eregTxnDetlsBean?.igrsEregPropIdentificationBean)
          }
          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }


  sroUpdateEregStatusById(eregId: number, statusId: number) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/sroUpdateEregStatusById?eregId=" + eregId + "&&statusId=" + statusId, {}
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  downloadFinalEregDocument(eregId: number) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/downloadFinalEregDocument?eregId=" + eregId, {}

      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  getMstStatusByModuleType(moduleType: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ereg/getMstStatusByModuleType?moduleType=" + moduleType
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }


  proceedForHSMSignOnEOAOE(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {

    let igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();

    igrsEregTxnDetlsBean = JSON.parse(JSON.stringify(eregTxnDetlsBean));//make copy

    //remove heavy node
    igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = []

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/proceedForHSMSignOnEOAOE",
        igrsEregTxnDetlsBean
      ).pipe(
        map((result: any) => {
          if (result) {

            //add heavy node
            if (eregTxnDetlsBean.igrsEregPropIdentificationBean)
              result.responseData.igrsEregPropIdentificationBean?.push(...eregTxnDetlsBean.igrsEregPropIdentificationBean)
          }
          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  proceedForHSMSignOnPartyVerification(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {

    let igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();

    igrsEregTxnDetlsBean = JSON.parse(JSON.stringify(eregTxnDetlsBean));//make copy

    //remove heavy node
    igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = []

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/proceedForHSMSignOnPartyVerification",
        igrsEregTxnDetlsBean
      ).pipe(
        map((result: any) => {
          if (result) {

            //add heavy node
            if (eregTxnDetlsBean.igrsEregPropIdentificationBean)
              result.responseData.igrsEregPropIdentificationBean?.push(...eregTxnDetlsBean.igrsEregPropIdentificationBean)
          }
          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  updateEregPropIdentificationDetails(eregTxnDetlsBean: IgrsEregTxnDetlsBean, fromCheckerModule?: boolean) {

    let controllerName: string = "citizen";
    // let igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();
    // if (eregTxnDetlsBean.igrsEregPropIdentificationBean)
    //   igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = JSON.parse(JSON.stringify(eregTxnDetlsBean.igrsEregPropIdentificationBean));//make copy

    // //remove heavy node
    // igrsEregTxnDetlsBean.igrsEregPropIdentificationBean?.forEach(property => {
    //   property.propIdentificationJson = '';
    //   property.propertyValuationRequestBean = null;
    //   property.propertyValuationResponseBean = null;
    // })


    if (fromCheckerModule) {
      controllerName = "department";
    }

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(

        this.settings.igrs_services_url + controllerName + "/ereg/updateEregPropIdentificationDetails", eregTxnDetlsBean.igrsEregPropIdentificationBean
      ).pipe(
        map((result: any) => {
          // if (result) {

          //   //add heavy node
          //   result.responseData?.forEach((property: IgrsEregPropIdentificationBean) => {

          //     let identificationBean = eregTxnDetlsBean.igrsEregPropIdentificationBean.find(x => {
          //       return x.id == property.id;
          //     })
          //     property.propIdentificationJson = identificationBean.propIdentificationJson;
          //     property.propertyValuationRequestBean = identificationBean.propertyValuationRequestBean;
          //     property.propertyValuationResponseBean = identificationBean.propertyValuationResponseBean;
          //   })

          // }
          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  downloadCyberTehsilFrom(mmYear: string, fileName: string) {
    window.open(this.settings.igrs_services_url + "common/ereg/downloadCyberTehsilPdf?fileName=" + fileName + "&mmYear=" + mmYear, '_blank');
  }

  /***********MORTGAGE PROPERTY ******************************* */


  searchPropertyByPropertyId(propId: any, type: any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/getSampadaPropertyListByPropertyId/" + propId + '/' + type, {}
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  updateDataForMortgage(propertyIdentificationBeanList: IgrsEregPropIdentificationBean[]) {

    //remove heavy node
    // propertyIdentificationBeanList?.forEach(property => {
    //   //property.propIdentificationJson = ''
    //   property.propertyValuationResponseBean = null;
    // })

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/updateDataForMortgage",
        propertyIdentificationBeanList
      ).pipe(
        map((result: any) => {
          // if (result) {

          //   //add heavy node
          //   result.responseData.forEach((property: IgrsEregPropIdentificationBean) => {

          //     let identificationBean = propertyIdentificationBeanList.find(x => {
          //       return x.id == property.id;
          //     })
          //     //property.propIdentificationJson = identificationBean?.propIdentificationJson
          //     property.propertyValuationResponseBean = identificationBean?.propertyValuationResponseBean;

          //   })
          // }
          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  insertSampada1DataForMortgage(propertyIdentificationBeanList: IgrsEregPropIdentificationBean[]) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/insertSampada1DataForMortgage",
        propertyIdentificationBeanList
      ).pipe(
        map((result: any) => {
          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }


  loadAdditionalFieldDetailsByParentRegNo(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/loadAdditionalFieldDetailsByParentRegNo",
        eregTxnDetlsBean
      ).pipe(
        map((result: any) => {
          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }


	cloneCyberTehsilFromFieldsValueRefByEstampId(param: any) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/cloneCyberTehsilFromFieldsValueRefByEstampId",param
      ).pipe(
        map((result: any) => {
          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
	}


  // savePropertyIdentificationOtherDataForMortgage(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {

  //   let igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();

  //   igrsEregTxnDetlsBean = JSON.parse(JSON.stringify(eregTxnDetlsBean));//make copy

  //   //remove heavy node
  //   igrsEregTxnDetlsBean.igrsEregPropIdentificationBean?.forEach(property => {
  //     property.propIdentificationJson = ''
  //     property.propertyValuationResponseBean = null;
  //   })

  //   return this.customeHttpClient
  //     .PostApiToken<ResultModel<any>>(
  //       this.settings.igrs_services_url + "citizen/ereg/savePropertyIdentificationOtherDataForMortgage",
  //       igrsEregTxnDetlsBean
  //     ).pipe(
  //       map((result: any) => {
  //         if (result) {

  //           //add heavy node
  //           result.responseData.igrsEregPropIdentificationBean.forEach((property: IgrsEregPropIdentificationBean) => {

  //             let identificationBean = eregTxnDetlsBean.igrsEregPropIdentificationBean.find(x => {
  //               return x.id == property.id;
  //             })
  //             property.propIdentificationJson = identificationBean?.propIdentificationJson
  //             property.propertyValuationResponseBean = identificationBean?.propertyValuationResponseBean;
  //           })
  //         }
  //         return result;
  //       },
  //         (error: any) => {
  //           console.log("error:-", error);
  //           return error;
  //         }
  //       )
  //     );
  // }

  getAllMstCyberTehsilFormLabelsDetailsByDept(formType: string) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/getAllMstCyberTehsilFormLabelsDetails/" + formType, ""
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  getPropDeviationReport(eregId: number, propId: string, valTxnId: number, fromCheckerModule: boolean) {
    let controllerName: string = "citizen";
    if (fromCheckerModule) {
      controllerName = "department";
    }
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + controllerName + "/ereg/getPropertyDeviationReport?propId=" + propId + "&eregId=" + eregId + "&valTxnId=" + valTxnId, {}
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  getEregPartyTypeList(partyBean: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/getEregPartyTypeList",
        partyBean
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }


  getAllMstCMSHeads() {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ereg/getAllMstCMSHeads"
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }



  getAllMstCMSSections() {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ereg/getAllMstCMSSections"
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  getFaceMatchingApi(eRegPartyDetails: IgrsEregPartyDetailsBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/faceMatching",
        eRegPartyDetails
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  savePartyFaceMatchedRecords(eRegPartyDetails: IgrsEregPartyDetailsBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/savePartyFaceMatchedRecords",
        eRegPartyDetails
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }
  saveBankFinancialInstituteDetailsForMortgage(igrsBankFinancialInstituteDetailsBean: IgrsBankFinancialInstituteDetailsBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/saveBankFinancialInstituteDetailsForMortgage",
        igrsBankFinancialInstituteDetailsBean
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }


  saveOutsidePropertyForWill(igrsEregWillOutsidePropDetlsBean: IgrsEregWillOutsidePropDetlsBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/saveEregOutsidePropDetailsForWill",
        igrsEregWillOutsidePropDetlsBean
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  getOutsidePropertyListForWill(eregId: string, fkeregId: string) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/getOutsidePropertyDetailsForWill", { eregId, fkeregId }
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  /***********Revenue Land Mining Lease ******************************* */


  getMiningRecord(param: any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        //  this.settings.igrs_services_url + "citizen/ereg/getMiningRecord?lgdCode=" + param.lgdCode + "&plotType=" + param.plotType+"&ulpinNo="+param.ulpinNo, {}
        this.settings.igrs_services_url + "citizen/ereg/getMiningRecord", param
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }
  getMineralLandOwnerList(param: any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/getMineralLandOwnerList", param
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  convertMineralDetailsListToKhasraDetailObject(selectedMinerals: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/convertMineralsToKhasraDetailObject",
        selectedMinerals
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  convertForestDataToRegistryDetailObject(selectedForestData: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/convertForestDataToRegistryDetailObject",
        selectedForestData
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  /***********Forest Land Mining Lease ******************************* */

  getMstForestCircleList(param: any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/getMstForestCircleList", param
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  getMstForestDivisionList(param: any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/getMstForestDivisionList", param
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  getMstForestRangeList(param: any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/getMstForestRangeList", param
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }



  saveEregImpoundTxnDetls(igrsEregImpoundTxnBean: IgrsEregImpoundTxnBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/saveEregImpoundTxnDetails",
        igrsEregImpoundTxnBean
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }


  saveEregImpoundPropertyDetails(igrsEregImpoundPropertyBean: IgrsEregImpoundPropertyBean,fromCMSModule?:boolean) {
    let functName:String  = "saveEregImpoundPropertyDetails";
    if(fromCMSModule){
      functName= "saveEregCOSImpoundPropertyDetails";
    }
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
       
        this.settings.igrs_services_url + "department/ereg/"+functName,
        igrsEregImpoundPropertyBean
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }


  getImpoundTxnDetails(eRegisId: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ereg/getImpoundTxnDetails?eRegisId=" + eRegisId, {}
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      )
  }

  getImpoundPropertyDetails(eRegisId: any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/getImpoundPropertyDetails?eRegisId=" + eRegisId, {}
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      )
  }


  saveIgrsEregParticularDetail(igrsEregParticularDetailBean: IgrsEregParticularDetailBean) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/saveIgrsEregParticularDetail",
        igrsEregParticularDetailBean
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  getDeedFileNameEregEstamp(eregTxnDetlsBean: IgrsEregTxnDetlsBean) {

    let igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();

    igrsEregTxnDetlsBean = JSON.parse(JSON.stringify(eregTxnDetlsBean));//make copy

    //remove heavy node
    igrsEregTxnDetlsBean.igrsEregPropIdentificationBean?.forEach(property => {
      property.propIdentificationJson = '';
    })

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_jasper_url + "citizen/jasper/ereg/getDeedFileNameEreg",
        igrsEregTxnDetlsBean
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  getAllInstAddMappingById(instrumentId: number) {

    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_duty_url + "common/ereg/getAllInstAddMappingById/" + instrumentId
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
  }

  saveEregInstAdditionalFieldInput(bean: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/saveEregInstAdditionalFieldInput",
        bean
      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  getEregInstAdditionalFieldInputByEregId(eregId: number) {

    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_duty_url + "common/ereg/getEregInstAdditionalFieldInputByEregId/" + eregId
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
  }

  getApplicantTypeList() {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/getApplicantTypeList", {}
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }

  get_ListingHoldEregistryByUserId(pageNo: number, pageSize: number, param: any) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/listingHoldEregByUserId/" + pageNo + "/" + pageSize, param
      ).pipe(
        map((result: any) => {
          return result;
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );

  }
  
  downloadFinalCompletedEregDocumentForCitizenWithDmsId(regId:any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/ereg/downloadFinalCompletedEregDocumentForCitizenWithDmsId?regId="+regId, {}

      ).pipe(
        map((result: any) => {
          //if (result) {
          return result;
          //}
        },
          (error: any) => {
            console.log("error:-", error);
            return error;
          }
        )
      );
  }

  // downloadFinalCompletedEregDocumentForCitizenWithDmsIdWhatsup(regId:any) {
  //   return this.customeHttpClient
  //     .Post<ResultModel<any>>(
  //       this.settings.igrs_services_url + "common/ereg/downloadFinalCompletedEregDocumentForCitizenWithDmsId?regId="+regId, {}

  //     ).pipe(
  //       map((result: any) => {
  //         //if (result) {
  //         return result;
  //         //}
  //       },
  //         (error: any) => {
  //           console.log("error:-", error);
  //           return error;
  //         }
  //       )
  //     );
  // }

  removePropImagesAndMap(igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean) {

    igrsEregTxnDetlsBean.igrsEregPropIdentificationBean?.forEach(property => {
      //property.propIdentificationJson = ''

      if (property.propertyValuationRequestBean) {

        if (property.propertyValuationRequestBean.webGisResponseBeanDataArr) {

          property.propertyValuationRequestBean.webGisResponseBeanDataArr?.forEach(khasra => {
            khasra.igrsEregPropAdditionalDetlsBean.propMapUpload = '';
            // khasra.igrsEregPropImgDisplayBean.propertyLeftView = '';
            // khasra.igrsEregPropImgDisplayBean.propertyCenterView = '';
            // khasra.igrsEregPropImgDisplayBean.propertyRightView = '';
          });
        }
        if (property.propertyValuationRequestBean.pNRDResponseData) {

          property.propertyValuationRequestBean.pNRDResponseData.igrsEregPropAdditionalDetlsBean.propMapUpload = '';
          // property.propertyValuationRequestBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyLeftView = '';
          // property.propertyValuationRequestBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyCenterView = '';
          // property.propertyValuationRequestBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyRightView = '';
        }
        if (property.propertyValuationRequestBean.mPenagarpalikaResponseData) {

          property.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropAdditionalDetlsBean.propMapUpload = '';
          // property.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropImgDisplayBean.propertyLeftView = '';
          // property.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropImgDisplayBean.propertyCenterView = '';
          // property.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropImgDisplayBean.propertyRightView = '';
        }
      }

      if (property.propertyValuationResponseBean) {

        if (property.propertyValuationResponseBean.webGisResponseBeanDataArr) {

          property.propertyValuationResponseBean.webGisResponseBeanDataArr?.forEach(khasra => {
            khasra.igrsEregPropAdditionalDetlsBean.propMapUpload = ''
            // khasra.igrsEregPropImgDisplayBean.propertyLeftView = '';
            // khasra.igrsEregPropImgDisplayBean.propertyCenterView = '';
            // khasra.igrsEregPropImgDisplayBean.propertyRightView = '';
          })
        }
        if (property.propertyValuationResponseBean.pNRDResponseData) {

          property.propertyValuationResponseBean.pNRDResponseData.igrsEregPropAdditionalDetlsBean.propMapUpload = ''
          // property.propertyValuationResponseBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyLeftView = '';
          // property.propertyValuationResponseBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyCenterView = '';
          // property.propertyValuationResponseBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyRightView = '';
        }
        if (property.propertyValuationResponseBean.mpenagarpalikaResponse) {

          property.propertyValuationResponseBean.mpenagarpalikaResponse.igrsEregPropAdditionalDetlsBean.propMapUpload = ''
          // property.propertyValuationResponseBean.mpenagarpalikaResponse.igrsEregPropImgDisplayBean.propertyLeftView = '';
          // property.propertyValuationResponseBean.mpenagarpalikaResponse.igrsEregPropImgDisplayBean.propertyCenterView = '';
          // property.propertyValuationResponseBean.mpenagarpalikaResponse.igrsEregPropImgDisplayBean.propertyRightView = '';
        }
      }
    })
  }
  addPropImagesAndMap(result: any, eregTxnDetlsBean: IgrsEregTxnDetlsBean) {

      result.responseData?.igrsEregPropIdentificationBean?.forEach((property: IgrsEregPropIdentificationBean) => {
    
        let identificationBean = eregTxnDetlsBean?.igrsEregPropIdentificationBean.find(x => {
          return x.id == property.id;
        });
        //property.propIdentificationJson = identificationBean?.propIdentificationJson;
    
        if (property.propertyValuationRequestBean) {
    
          if (property.propertyValuationRequestBean.webGisResponseBeanDataArr) {
    
            property.propertyValuationRequestBean.webGisResponseBeanDataArr?.forEach(khasra => {
    
              let khasraBean = identificationBean?.propertyValuationRequestBean.webGisResponseBeanDataArr.find(x => {
                return x.id == khasra.id;
              });
              khasra.igrsEregPropAdditionalDetlsBean.propMapUpload = khasraBean.igrsEregPropAdditionalDetlsBean.propMapUpload;
              khasra.igrsEregPropImgDisplayBean.propertyLeftView = khasraBean.igrsEregPropImgDisplayBean.propertyLeftView;
              khasra.igrsEregPropImgDisplayBean.propertyCenterView = khasraBean.igrsEregPropImgDisplayBean.propertyCenterView;
              khasra.igrsEregPropImgDisplayBean.propertyRightView = khasraBean.igrsEregPropImgDisplayBean.propertyRightView;
            });
          }
          if (property.propertyValuationRequestBean.pNRDResponseData) {
    
            property.propertyValuationRequestBean.pNRDResponseData.igrsEregPropAdditionalDetlsBean.propMapUpload
              = identificationBean?.propertyValuationRequestBean.pNRDResponseData.igrsEregPropAdditionalDetlsBean.propMapUpload;
            property.propertyValuationRequestBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyLeftView
              = identificationBean?.propertyValuationRequestBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyLeftView;
            property.propertyValuationRequestBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyCenterView
              = identificationBean?.propertyValuationRequestBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyCenterView;
            property.propertyValuationRequestBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyRightView
              = identificationBean?.propertyValuationRequestBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyRightView;            
          }
          if (property.propertyValuationRequestBean.mPenagarpalikaResponseData) {
    
            property.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropAdditionalDetlsBean.propMapUpload
              = identificationBean?.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropAdditionalDetlsBean.propMapUpload;
            property.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropImgDisplayBean.propertyLeftView
              = identificationBean?.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropImgDisplayBean.propertyLeftView;
            property.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropImgDisplayBean.propertyCenterView
              = identificationBean?.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropImgDisplayBean.propertyCenterView;
            property.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropImgDisplayBean.propertyRightView
              = identificationBean?.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropImgDisplayBean.propertyRightView;
          }
        }
        if (property.propertyValuationResponseBean) {
    
          if (property.propertyValuationResponseBean.webGisResponseBeanDataArr) {
    
            property.propertyValuationResponseBean.webGisResponseBeanDataArr?.forEach(khasra => {
    
              let khasraBean = identificationBean?.propertyValuationResponseBean.webGisResponseBeanDataArr.find(x => {
                return x.id == khasra.id;
              });
              khasra.igrsEregPropAdditionalDetlsBean.propMapUpload = khasraBean.igrsEregPropAdditionalDetlsBean.propMapUpload;
              khasra.igrsEregPropImgDisplayBean.propertyLeftView = khasraBean.igrsEregPropImgDisplayBean.propertyLeftView;
              khasra.igrsEregPropImgDisplayBean.propertyCenterView = khasraBean.igrsEregPropImgDisplayBean.propertyCenterView;
              khasra.igrsEregPropImgDisplayBean.propertyRightView = khasraBean.igrsEregPropImgDisplayBean.propertyRightView;
            });
          }
          if (property.propertyValuationResponseBean.pNRDResponseData) {
    
            property.propertyValuationResponseBean.pNRDResponseData.igrsEregPropAdditionalDetlsBean.propMapUpload
              = identificationBean?.propertyValuationResponseBean.pNRDResponseData.igrsEregPropAdditionalDetlsBean.propMapUpload;
            property.propertyValuationResponseBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyLeftView
              = identificationBean?.propertyValuationResponseBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyLeftView;
            property.propertyValuationResponseBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyCenterView
              = identificationBean?.propertyValuationResponseBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyCenterView;
            property.propertyValuationResponseBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyRightView
              = identificationBean?.propertyValuationResponseBean.pNRDResponseData.igrsEregPropImgDisplayBean.propertyRightView; 
          }
          if (property.propertyValuationResponseBean.mpenagarpalikaResponse) {
    
            property.propertyValuationResponseBean.mpenagarpalikaResponse.igrsEregPropAdditionalDetlsBean.propMapUpload
              = identificationBean?.propertyValuationResponseBean.mpenagarpalikaResponse.igrsEregPropAdditionalDetlsBean.propMapUpload;
            property.propertyValuationResponseBean.mpenagarpalikaResponse.igrsEregPropImgDisplayBean.propertyLeftView
              = identificationBean?.propertyValuationResponseBean.mpenagarpalikaResponse.igrsEregPropImgDisplayBean.propertyLeftView;
            property.propertyValuationResponseBean.mpenagarpalikaResponse.igrsEregPropImgDisplayBean.propertyCenterView
              = identificationBean?.propertyValuationResponseBean.mpenagarpalikaResponse.igrsEregPropImgDisplayBean.propertyCenterView;
            property.propertyValuationResponseBean.mpenagarpalikaResponse.igrsEregPropImgDisplayBean.propertyRightView
              = identificationBean?.propertyValuationResponseBean.mpenagarpalikaResponse.igrsEregPropImgDisplayBean.propertyRightView;
          }
        }
      });
  }

   uploadImpoundDoc(proposal : any){
       
    return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + "department/ereg/uploadImpoundDoc/" , proposal
    ).pipe(
      map((result: any) => {
        return result;
      }),
      (error: any) => {
        console.log("error:-", error);
        return error;
      }
    );
     

   }

   getUploadedImpoundDoc(fileName : string) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/viewImpoundDoc/" + fileName ,{}
      ).pipe(
        map((result: any) => {

          if (result && result.responseStatus == 'true') {
            return result;
          } else {
            return result;
          }


        })
      );
  } 

  getUploadedAuthenticatedPOADoc(id: number, eregId: number) {
    return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/ereg/viewauthpoadoc/" + id + "/" + eregId, {}
    ).pipe(
     map((result: any) => {
         if (result && result.responseStatus == 'true') {
             return result;
         } else {
             return result;
         }
        })
        );
  }

  getDocByRegistrationNumber(param: any): Observable<any> {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_search_url + "citizen/docsearch/docSearchByRegistrationNo", param
      ).pipe(
        map((result: any) => {

          if (result && result.responseStatus == 'true') {
            return result;
          } else {
            return result;
          }

        })
      );
  }

  getEregDocByRegistrationNumber(param: any): Observable<any> {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_search_url + "citizen/docsearch/eregDocSearchByRegistrationNo", param
      ).pipe(
        map((result: any) => {

          if (result && result.responseStatus == 'true') {
            return result;
          } else {
            return result;
          }

        })
      );
  }

  getSearchHistoryByUser() {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_search_url + "citizen/docsearch/getSearchHistoryByUser", {}
      ).pipe(
        map((result: any) => {
          if (result && result.responseStatus == 'true') {
            return result;
          } else {
            return result;
          }
        })
      );
  }

  getDetailsByRegistrationNo(regNumber: any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_search_url + "citizen/docsearch/getDetailsByRegistrationNo?registrationNumber=" + regNumber, {}
      ).pipe(
        map((result: any) => {

          if (result && result.responseStatus == 'true') {
            return result;
          } else {
            return result;
          }

        })
      );
  }

  getSROfficeList(param: any): Observable<any> {
    return this.customeHttpClient.Post<ResultModel<any>>(
      this.settings.igrs_gateway_url + "common/get_sro_list", param
    ).pipe(
      map((result: any) => {
        if (result && result.responseStatus == 'true') {
          return result;
        } else {
          return result;
        }

      })
    );
  }

  getPdfByDocNumberFromDMS(param: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_search_url + "citizen/docsearch/viewDocumentPdf", param
      ).pipe(
        map((result: any) => {
          if (result && result.responseStatus == 'true') {
            return result;
          } else {
            return result;
          }
        })
      );
  }

  searchDocumentBefore2015(param: any): Observable<any> {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_search_url + "citizen/docsearch/searchByLegacyDocumentNo", param
      ).pipe(
        map((result: any) => {

          if (result && result.responseStatus == 'true') {
            return result;
          } else {
            return result;
          }

        })
      );
  }

  getDocByOthersDetails(param: any) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_search_url + "citizen/docsearch/docSearchByOtherdetails", param
      ).pipe(
        map((result: any) => {

          if (result && result.responseStatus == 'true') {
            return result;
          } else {
            return result;
          }

        })
      );
  }

  getIndexPdfByIndexNo(dmsDocumentId: any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_search_url + "citizen/docsearch/getPdfDMSindexBydocno/" + dmsDocumentId, {}
      ).pipe(
        map((result: any) => {
          if (result && result.responseStatus == 'true') {
            return result;
          } else {
            return result;
          }
        })
      );
  }

  getAllFinancialYear(): Observable<any> {
    return this.customeHttpClient.Get<ResultModel<any>>(
        this.settings.igrs_services_search_url + "common/docsearch/getFinancialYear"
    ).pipe(
        map((result: any) => {

            if (result && result.responseStatus == 'true') {
                return result;
            } else {
                return result;
            }

        })
    );
}

  getImageFromUrl(imagePath: string) {
    return 
        this.settings.igrs_services_url + "common/ereg/getImagesFromPath/" + imagePath
  }
  getPaymentDetailsForImpoundDuty(eregStatus:number) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_gateway_url + "citizen/get_payment_details_for_cms/"+eregStatus, {}
      ).pipe(
        map((result: any) => {

          if (result && result.responseStatus == 'true') {
            return result.responseData;
          }
          //else {
          return result;
          //}
        }),
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      );
  }
  getPaymentDetailsForImpound(eregId: any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_gateway_url + "citizen/getPaymentDetailsbyRefId/" + eregId, {}
      ).pipe(
        map((result: any) => {
          if (result && result.responseStatus == 'true') {
            return result;
          } else {
            return result;
          }
        })
      );
  }
  getDataForRecheckPaymentCms(eregId: any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_gateway_url + "citizen/getPayInfoForPayVerifybyRefId/" + eregId, {}
      ).pipe(
        map((result: any) => {
          if (result && result.responseStatus == 'true') {
            return result;
          } else {
            return result;
          }
        })
      );
  }


  getPayTypeDetails(refNoPayList:any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_gateway_url + "citizen/getPayTypeDetails",refNoPayList
      ).pipe(
        map((result: any) => {
          if (result && result.responseStatus == 'true') {
            return result;
          } else {
            return result;
          }
        })
      );
  }


  getCyberTehsilAckDoc(param: any) {

    return this.customeHttpClient.Post<ResultModel<any>>(
      this.settings.igrs_services_url + "common/ereg/downloadCyberTehsilAcknowledgementDoc", param
    ).pipe(
      map((result: any) => {
        //if (result) {
        return result;
        //}
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
    );

  }

  getBankRepresentativeListByUserId(param: any){
    let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(param));
    return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_services_url + "common/ereg/get_bank_representative_list_by_userid", encVal    
     ).pipe(
         map((result: any) => {
             ;
             //
             if (result && result.responseStatus == 'true') {
                 return result;
             } else {
                 return result;
             }

         })
     );
 }
 

 uploadManualImage(param: any) {

  return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + "citizen/ereg/property_img_upload_manual", param
    ).pipe(
      map((result: any) => {

        if (result && result.responseStatus == 'true') {
          return result;
        } else {
          return result;
        }

      })
    );
}

getConfigForManualImageUpload() {
  return this.customeHttpClient
    .GetApi<ResultModel<any>>(
      this.settings.igrs_services_url+ "citizen/ereg/getIgrsConfig",""
    ).pipe(
      map((result: any) => {
        if (result && result.responseStatus == 'true') {
          return result;
        } else {
          return result;
        }
      })
    );
}

saveEregReturnAddDocReqFromParty(eregReturnBean : any) {
  return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + "citizen/ereg/saveEregReturnAddDocReq/" , eregReturnBean
    ).pipe(
      map((result: any) => {
        if (result && result.responseStatus == 'true') {
          return result;
        } else {
          return result;
        }
      })
    );
}

sendRefuseAppealToCMS(eregRefuseBean : any) {
  return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + "citizen/ereg/sendRefuseAppealToDR/" , eregRefuseBean
    ).pipe(
      map((result: any) => {
        if (result && result.responseStatus == 'true') {
          return result;
        } else {
          return result;
        }
      })
    );
}

sendRefuseComplianceAppealToCMS(eregRefuseBean : any) {
  return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + "citizen/ereg/sendRefuseComplianceAppealToDR/" , eregRefuseBean
    ).pipe(
      map((result: any) => {
        if (result && result.responseStatus == 'true') {
          return result;
        } else {
          return result;
        }
      })
    );
}

generateSRPartyRemarksPdf(eregRefuseBean : any) {
  return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_jasper_url + "citizen/jasper/ereg/exportSRRemarksPdf/" , eregRefuseBean
    ).pipe(
      map((result: any) => {
        if (result && result.responseStatus == 'true') {
          return result;
        } else {
          return result;
        }
      })
    );
}


saveEregCompAddDocument( igrsCompAddDocBean :  IgrsEregCompAddDocBean) {

  return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + "citizen/ereg/saveEregCompDocument", igrsCompAddDocBean
    ).pipe(
      map((result: any) => {

        return result;
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
    )
}


getEregPartyAndDeedDetails(eregId : number) {
  return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + "citizen/ereg/getPartyDetails/" + eregId  , {}
    ).pipe(
      map((result: any) => {
        if (result && result.responseStatus == 'true') {
          return result;
        } else {
          return result;
        }
      })
    );
}

getEregReturnJasperLetter(eregId: any , docType : string) {
  return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + "citizen/ereg/viewReturnDocLetter/" + eregId + "/" + docType , {}
    ).pipe(
      map((result: any) => {
        if (result && result.responseStatus == 'true') {
          return result;
        } else {
          return result;
        }
      })
    );
}

searchByRegIdForAdjudiction(tempRegId : string) {
  return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + "citizen/ereg/checkValidAdjudication/" + tempRegId , {}
    ).pipe(
      map((result: any) => {
        if (result && result.responseStatus == 'true') {
          return result;
        } else {
          return result;
        }
      })
    );
}

saveAdjudictionDocument( adjudicationBean : any) {
  return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + "citizen/ereg/saveDocumentsAdjudication/"  , adjudicationBean
    ).pipe(
      map((result: any) => {
        if (result && result.responseStatus == 'true') {
          return result;
        } else {
          return result;
        }
      })
    );
}


saveAddnlDocumentAdjudication( adjudicationBean : any) {
  return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + "citizen/ereg/savedditionalDocForAdjudication/"  , adjudicationBean
    ).pipe(
      map((result: any) => {
        if (result && result.responseStatus == 'true') {
          return result;
        } else {
          return result;
        }
      })
    );
}

getForCMSSampadaPropertyListByPropertyId(propId: any, type: any) {
  return this.customeHttpClient
    .GetApi<ResultModel<any>>(
      this.settings.igrs_services_url + "citizen/ereg/getForCMSSampadaPropertyListByPropertyId/" + propId + '/' + type, {}
    ).pipe(
      map((result: any) => {
        return result;
      }),
      (error: any) => {
        console.log("error:-", error);
        return error;
      }
    );
}

getForCMSSampadaPropertyListByPropertyIdApi(propId: any, type: any,urlParam:string) {
  return this.customeHttpClient
    .GetApi<ResultModel<any>>(
      this.settings.igrs_services_url + urlParam+"/ereg/getForCMSSampadaPropertyListByPropertyId/" + propId + '/' + type, {}
    ).pipe(
      map((result: any) => {
        return result;
      }),
      (error: any) => {
        console.log("error:-", error);
        return error;
      }
    );
}


loadEregistryDataByEregIdForCompositeEm(igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean, eRegisId: string) {

  return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + "citizen/ereg/loadEregistryDataByEregIdForCompositeEm?eregId=" + eRegisId,
      igrsEregTxnDetlsBean
    ).pipe(
      map((result: any) => {

        return result;
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }

      )
    )
}
 
getEregDutyCalculationPaymentDetailsForCompositeEm(eregId:any) {

  return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + "citizen/ereg/get_ereg_duty_calculation_composite?eregId=" + eregId, null
    ).pipe(
      map((result: any) => {

        return result;
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }

      )
    )

}


searchEstampFromSam1(estampCode: String,eregId:number,eregInitiationNo:String,estampAmt:any) {
  return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + "citizen/ereg/searchEstampFromSam1?estampCode=" + estampCode+"&eregId="+eregId+"&eregInitiationNo="+eregInitiationNo+"&estampAmt="+estampAmt,
      null
    ).pipe(
      map((result: any) => {

        return result;
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }

      )
    )
}


}


