<div class="position-relative">
    <div class="fid-header"></div>
    <div class="breadcrumb-content">
        <div class="container">
            <div class="d-flex justify-content-between">
                <h2>{{(lan==0)?"Office , Interactive Video KYC & Non Interactive Video KYC based instrument":"कार्यालय,
                    इंटरैक्टिव
                    वीडियो केवाईसी और गैर
                    इंटरैक्टिव
                    वीडियो केवाईसी आधारित इंस्ट्रूमेंट"}}</h2>
            </div>
        </div>

    </div>
   
    <form [formGroup]="officeInteractiveNonInteractiveForm">
        <div class="container">
            <mat-card class="example-card mb-3">
                <mat-card-title></mat-card-title>
                <mat-card-content class="mat-card-content">
                    <div class="searchtop">
                        <form class="row pt-2 g-2">
                            <div class="col-lg-4 form-group mb-2">
                                <label class="sr-only">{{
                                    "guideline.Search-Guideline_by_w_v-gl" | translate
                                    }}</label>
                                <input class="form-control" (keyup)="applyFilter($event)"
                                    placeholder="Search by text   [Please Enter atleast Four character]" #input />
                            </div>

                            <div class="col-lg-5 pl-1">
                                <button type="submit" mat-raised-button color="primary" (click)="searchText()"
                                    class="mb-2">
                                    <mat-icon>search</mat-icon>{{ "searchbtn" | translate }}
                                </button>
                                <button mat-raised-button color="primary" class="mb-2 ml-2" (click)="toggleStatus()">
                                    <mat-icon>filter_list</mat-icon> {{'advancefilter' | translate}}
                                </button>

                                <button mat-raised-button color="primary" class="mb-2 ml-2"
                                    (click)="input.value='';reloadData()">
                                    <mat-icon>refresh</mat-icon>
                                </button>
                            </div>

                            <div class="text-right col-lg-3">
                                <i class="fa fa-file-excel-o mt-3 font20 text-info font-weight-bold" placement="top"
                                    ngbTooltip=" {{ 'exCSV' | translate }}" aria-hidden="true"
                                    (click)="exportExcel()"></i>
                                &nbsp;
                                <i class="fa fa-file-pdf-o text-primary font20 font-weight-bold" aria-hidden="true"
                                    (click)="exportPdf()" placement="top" ngbTooltip="Download Pdf"></i>
                            </div>
                        </form>
                    </div>
                    <div class="advanceFilterOption w-100 d-block {{hiddenvalue}}" *ngIf="resetDemo" cdkDrag>

                        <button class="filterClosebtn" (click)="toggleStatus()">X</button>
                        <div class="p-4">

                            <form class="row" #f="ngForm">
                                <!-- Duty Category Selection -->
                                <div class="col-md-5 mr-md-1 mb-5">
                                    <div class="form-group">
                                        <label>{{'duty-calculation.Deed-Catagory' | translate}}</label>
                                        <ng-select type="text" class="form-control" [(ngModel)]="pDeedCategoryId"
                                            #categoryId="ngModel" required name="categoryId"
                                            placeholder="{{ 'duty-calculation.Select-deed-Catagory' | translate }}"
                                            class="mb-1 p-0" bindLabel="deedCategoryNameEn"
                                            (ngModelChange)="getAllDeedTypeByCategoryId($event)">
                                            <ng-option selected [value]="null" [disabled]="true"> {{
                                                'e-stamp.Please-Select-deed-Catagory' |
                                                translate }} </ng-option>
                                            <ng-option value="{{vl.deedCategoryId}}"
                                                *ngFor="let vl of deedCategoryBeanList">
                                                {{lan==0?vl.deedCategoryNameEn:vl.deedCategoryNameHi}}
                                            </ng-option>

                                        </ng-select>

                                    </div>
                                </div>
                                <!-- Deed Type Selection -->
                                <div class="col-md-6">
                                    <div class="form-group  mr-md-1 mb-5">
                                        <label>{{'duty-calculation.Deed-Type' | translate}}</label>
                                        <ng-select class="form-control" [(ngModel)]="pDeedTypeId" name="deedTypeId"
                                            id="deedTypeId"
                                            placeholder="{{ 'duty-calculation.Select-Deed-Type' | translate }}"
                                            class="mb-1 p-0" bindLabel="deedTypeNameEn"
                                            (ngModelChange)="getAllDeedInstrumentsByDeedTypeId($event)"
                                            #deedTypeId="ngModel" required>
                                            <ng-option selected value="null" [disabled]="true"> {{
                                                'e-stamp.Please-Select-Deed-Type' |
                                                translate
                                                }}</ng-option>
                                            <ng-option value="{{item1.id}}"
                                                *ngFor="let item1 of deedTypeCategoryBeanList">
                                                {{lan==0?item1.deedTypeNameEn:item1.deedTypeNameHi}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="advanceFilterOptionfooter p-2 text-right">
                            <button class="ml-1" mat-raised-button color="primary" (click)="advanceFilter()">{{'submit'
                                |
                                translate}}</button>
                            <button class="ml-1" mat-raised-button (click)="reset_advanceFilter()">{{'reset' |
                                translate}}</button>
                            <!-- (click)="transactionForm.reset()" -->
                        </div>
                    </div>


                    <div class="table-responsive shadow-none">
                        <table mat-table [dataSource]="dataSource" matSort class="table table-bordered"
                            (matSortChange)="announceSortChange($event)">

                            <!-- Position Column -->
                            <ng-container matColumnDef="position">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    sortActionDescription="Sort by position">
                                    {{ "duty-calculation.SNO" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.position }}
                                </td>
                            </ng-container>

                            <!-- Deed Category Column -->
                            <ng-container matColumnDef="deedCategory">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "duty-calculation.Deed-Catagory" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ lan == 0 ? element.deedCategoryNameEn : element.deedCategoryNameHi }}
                                </td>
                            </ng-container>

                            <!-- Deed Type Column -->
                            <ng-container matColumnDef="deedType">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "duty-calculation.Deed-Type" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ lan == 0 ? element.deedTypeNameEn : element.deedTypeNameHi }}
                                </td>
                            </ng-container>
                           

                            <!-- Instrument Name Column -->
                            <ng-container matColumnDef="instrumentName">
                                <th mat-header-cell *matHeaderCellDef >
                                    {{ "duty-calculation.inst_name" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ lan == 0 ? element.instrumentNameEn : element.instrumentNameHi }}
                                </td>
                            </ng-container>

                            <!-- Office Based Column (No sorting applied) -->
                            <ng-container matColumnDef="officeBased">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ lan == 0 ? "Office Based" : "कार्यालय आधारित" }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-icon class="text-success">check</mat-icon>
                                </td>
                            </ng-container>

                            <!-- Interactive Column (No sorting applied) -->
                            <ng-container matColumnDef="interactive">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ lan == 0 ? "Interactive Video KYC" : "इंटरएक्टिव वीडियो केवाईसी" }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-icon [class.text-danger]="element.isAssisted === 0"
                                        [class.text-success]="element.isAssisted === 1">
                                        {{ element.isAssisted === 0 ? 'close' : 'check' }}
                                    </mat-icon>
                                </td>
                            </ng-container>

                            <!-- Non-Interactive Column (No sorting applied) -->
                            <ng-container matColumnDef="nonInteractive">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ lan == 0 ? "Non Interactive Video KYC" : "नॉन इंटरएक्टिव वीडियो केवाईसी" }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-icon [class.text-danger]="element.isNonAssisted === 0"
                                        [class.text-success]="element.isNonAssisted === 1">
                                        {{ element.isNonAssisted === 0 ? 'close' : 'check' }}
                                    </mat-icon>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </form>
</div>