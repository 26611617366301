import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { CommonServiceService } from 'projects/common-lib/src/lib/helper-lib/services/common/common-service.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { CitizenUserProfileDetails } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model';
import { EstampDetails } from 'projects/common-lib/src/lib/models/estamp.model/estamp-details';
import { CommunicationServiceService } from '../duty-calculation/duty-service/communication-service.service';
import { DutyService } from '../duty-calculation/duty-service/duty.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EstampPartyModel } from 'projects/common-lib/src/lib/models/addEstamp.model/add-estamp.model';
import { EstampService } from '../../service/estamp.service';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-qr-code-view',
  templateUrl: './qr-code-view.component.html',
  styleUrls: ['./qr-code-view.component.scss']
})
export class QrCodeViewComponent implements OnInit {
  estampDetailsModel:EstampDetails;
  userProfileModel : CitizenUserProfileDetails=new CitizenUserProfileDetails() ;
  firstpartyList:EstampPartyModel[]=[];
  secondpartyList:EstampPartyModel[]=[];
  witnesspartyList:EstampPartyModel[]=[];
  estampPartyDetails:EstampPartyModel[]=[];
  redType:any[]=[];
  estampId:number;
  mobileNo:string;
  partyId:number;
  deedType: string;
  deedTypeHi : string;
  deedInstrument: string;
  deedInstrumentHi: string;
  isViewEstampQRDetails:boolean=false;
  lan: any;
  msgForRefundEn:string;
  msgForRefundHi:string;
  constructor(private router: Router,private route: ActivatedRoute,private _AESEncryptDecryptService: AESEncryptDecryptService,
    private ngxService: NgxUiLoaderService, private translateService: TranslateHEService, 
    private translateServiceIns: TranslateService,private commServ: CommunicationServiceService,
    private estampService: EstampService,private dutyService:DutyService,private commonService:CommonServiceService) {
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translateServiceIns.use(msg==1?'hi':'en')
       });
      const codec = new HttpUrlEncodingCodec();
      this.redType=this.route.snapshot.url.toString().split(",");
    this.estampId = this.redType[1];
    console.log("status code::"+this.redType[1]);
    if(this.redType[1] == "invalid"){
      Swal.fire("Invalid Estamp Code !!");
    }
    
     }

  ngOnInit(): void {
    
    this.getPreviewOfSelectedEstamp(this.estampId);
  }
  getPreviewOfSelectedEstamp(estampId:any){
    this.ngxService.start();
        this.estampService.getQREstampDetailsById(estampId).subscribe(res => {
          if (res.responseStatus == 'true') {
            this.ngxService.stop();
            this.estampDetailsModel=res.responseData;
            console.log("estamp status::"+this.estampDetailsModel.estampStatus)
            if(this.estampDetailsModel.estampStatus == 26 ){
              this.isViewEstampQRDetails = true;
            }
            if(this.estampDetailsModel.estampStatus == 134){
              this.msgForRefundEn="Requested for refund";
              this.msgForRefundHi="रिफंड के लिए अनुरोध किया गया";
              this.isViewEstampQRDetails = true;
            }
            if(this.estampDetailsModel.estampStatus == 129){
              this.msgForRefundEn="Cancelled";
              this.msgForRefundHi="रद्द";
              this.isViewEstampQRDetails = true;
            }
           // this.estampCreatedDate = this.pipe.transform(this.estampDetailsModel.createdDate,'dd-MM-yyyy hh-mm-ss');
           // console.log( this.estampDetailsModel);
           // this.loadDeedInstrumentDetails(this.estampDetailsModel.categoryId,this.estampDetailsModel.deedTypeId,this.estampDetailsModel.instrumentId);
            this.loadPartyDetailsByEstampId(this.estampDetailsModel.id);
            this.getEstampDetails(this.estampDetailsModel.id);
          }
          else
          {
            this.ngxService.stop();
          }
        });
      }
      getEstampDetails(estampId:number){
        this.estampService.getListingEstamp(estampId).subscribe(templateData => {
          if (templateData.responseStatus == 'true') {
            this.estampDetailsModel = templateData.responseData;
            this.deedType = this.estampDetailsModel.deedTypeEn;
            this.deedTypeHi = this.estampDetailsModel.deedTypeHi;
            this.deedInstrument = this.estampDetailsModel.instrumentNameEn;
            this.deedInstrumentHi = this.estampDetailsModel.instrumentNameHi;
          } else {
          }
        },
          (err) => {
          });
      }
      loadPartyDetailsByEstampId(estampId:number){
        this.ngxService.start();
        this.loadCitizenWithServiceProvider(this.estampDetailsModel.createdBy,estampId);
        
      }
      getMaskedEmail(email: string) {
        if (email != undefined) {
          // Split the email into local part and domain part
          const [localPart, domainPart] = email.split('@');
          
          // If the local part has more than 2 characters, mask everything except the first 2 characters
          if (localPart.length > 2) {
            const maskedLocalPart = localPart.substring(0, 2) + 'X'.repeat(localPart.length - 2);
            return maskedLocalPart + '@' + domainPart;
          } else {
            // If the local part is 2 characters or fewer, just mask the local part
            return 'XX' + '@' + domainPart;
          }
        } else {
          return null;
        }
      }
      getMaskedMobileNumber(mobileNo: string) {
        if (mobileNo != undefined) {
          if (mobileNo.length > 4) {
            var mobileSub = mobileNo.substring(0, mobileNo.length - 4);
            var maskedString = "";
            for (let index = 0; index < mobileNo.length - 4; index++) {
              maskedString = maskedString.concat("X");
            }
            mobileNo = mobileNo.replace(mobileSub, maskedString);
          }
          return mobileNo;
        } else {
          return null;
        }
      }
      serviceProviderName : any;
      serviceProviderNameHi : any;
      loadCitizenWithServiceProvider(citizenid:number,estampId:any){
        this.ngxService.start();
        this.estampService.getCitizenDetailsWithSp(citizenid).subscribe(res=>{
          if(res.responseStatus=='true'){
            this.ngxService.stop();
            this.userProfileModel = res.responseData;
            if( this.userProfileModel.firstNameEn != null && this.userProfileModel.firstNameEn != undefined){
              this.serviceProviderName = this.userProfileModel.firstNameEn;
            }
            if( this.userProfileModel.middleNameEn != null && this.userProfileModel.middleNameEn != undefined){
              this.serviceProviderName = this.serviceProviderName+ " " +this.userProfileModel.middleNameEn;
            }
            if( this.userProfileModel.lastNameEn != null && this.userProfileModel.lastNameEn != undefined){
              this.serviceProviderName = this.serviceProviderName+ " " +this.userProfileModel.lastNameEn;
            }

            if( this.userProfileModel.firstNameHi != null && this.userProfileModel.firstNameHi != undefined){
              this.serviceProviderNameHi = this.userProfileModel.firstNameHi;
            }
            if( this.userProfileModel.middleNameHi != null && this.userProfileModel.middleNameHi != undefined){
              this.serviceProviderNameHi = this.serviceProviderNameHi+ " " +this.userProfileModel.middleNameHi;
            }
            if( this.userProfileModel.lastNameHi != null && this.userProfileModel.lastNameHi != undefined){
              this.serviceProviderNameHi = this.serviceProviderNameHi+ " " +this.userProfileModel.lastNameHi;
            }
            console.log("inside  loadCitizenWithServiceProvider----------"+JSON.stringify(res.responseData));
            // this.estampPartyDetails=res.responseData;

            this.estampService.getEstampPartyDetailsByEstampId(estampId).subscribe(res=>{
              console.log("party detal list ::"+JSON.stringify(res.responseData))
              if(res.responseStatus=='true'){
                this.ngxService.stop();
                this.estampPartyDetails=res.responseData;
                this.estampPartyDetails = res.responseData.map((item: { mobileNo: string; emailId: string; }) => {
                  return {
                    ...item,  // Copies all properties from the 'item' object
                    mobileNo: this.getMaskedMobileNumber(item.mobileNo), 
                    emailId: this.getMaskedEmail(item.emailId)  
                  };
                });
                
                 this.estampPartyDetails.forEach(item=>{
                  // if(item.mobileNo==this.mobileNo)
                  // {
                  //   this.partyId=item.id;
                  // }
                  if(item.partySubTypeId===1){
                     this.firstpartyList.push(item);
                  }
                  if(item.partySubTypeId===2){
                    this.secondpartyList.push(item);
                  }
                  if(item.partySubTypeId===4){
                 
                    this.witnesspartyList.push(item);
                  }
                 })
              }
              else
              {
                this.ngxService.stop();
                console.log("ERROR:"+JSON.stringify(res));
              }
            })
            
          }
          else
          {
            this.ngxService.stop();
            console.log("ERROR:"+JSON.stringify(res));
          }
        })
      }
      
             


      onBtnPrintClick(lan:any) {
        let printContents, popupWin;
    printContents = document.getElementById('print-card').innerHTML;
    popupWin = window.open('', '');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
    <head>
        <title>Print tab</title>
      
            
        <style type = "text/css" > @media screen {
              body {
               font-family: 'Roboto', sans-serif;
                color: #000;
                font-weight: 600;
              }
                table,
                td,
                th {border: 1px solid #828282;
                    text-align: left;
                }

                table {border: 1px solid #828282;
                    border-collapse: collapse;
                    width: 98%;
                    margin-bottom: 1rem;
                }

                th,
                td {border: 1px solid #828282;
                    padding: 15px;
                }
                
                .col-md-12 {
                  flex: 0 0 100%;
                  max-width: 100%;
                 
                }
                .row {
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: 0px;
                    margin-left: 0px;
                }
                .col-6{
                  flex: 0 0 50%;
                  max-width: 50%;
              
                }
                .d-flex {
                    display: flex !important;
                }
                .col-md-6{
                  position: relative;
                  flex: 0 0 49%;
                  max-width: 49%;
                  padding-top: 14px;
                  padding-bottom: 0;
                  line-height: normal;
           
              }
              .form-group {
                margin-bottom: 1rem;
            }
            fieldset{
              margin-bottom: 10px;
              border:none;
              margin-bottom: 14px;
              padding: 0px;
              width: 100%;
            }
            #print-card {
              padding: 15px;
            }
            fieldset.common-fieldset legend {
              font-size: 18px;
              background: none;
              display: inline-block;
              width: auto;
              border-radius: 10rem;
              padding: 6px 0px 6px;
              font-weight: 700;
            }
              .cardprint:last-child {
                page-break-after: auto;
           }
            }

            @media print {
              body{ background-image: url(./assets/images/sampada-logo11.png);
               filter: grayscale(100%); 
              background-size: 10%;}
                table,
                td,
                th {border: 1px solid #828282;
                    text-align: left;
                }

                table {border: 1px solid #828282;
                    border-collapse: collapse;
                    width: 98%;
                   
                }

                th,
                td {border: 1px solid #828282;
                    padding: 15px;
                }
                .col-md-12 {
                  flex: 0 0 100%;
                  max-width: 100%;
                 
                }
                .row {
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: 0px;
                    margin-left: 0px;
                }
                .col-6{
                  flex: 0 0 49%;
                  max-width: 49%;
              
                }
                .d-flex {
                    display: flex !important;
                }
                .col-md-6{
                  position: relative;
                  flex: 0 0 50%;
                  max-width: 50%;
                  padding-top: 14px;
                  padding-bottom: 0;
                  line-height: normal;
              
              }
              .form-group {
                margin-bottom: 1rem;
            }
            fieldset{
              margin-bottom: 10px;
              border:none;
              margin-bottom: 14px;
             
              width: 100%;
            }
            #print-card {
              padding: 15px;
            }
            fieldset.common-fieldset legend {
              font-size: 18px;
              background: none;
              display: inline-block;
              width: auto;
              border-radius: 10rem;
              padding: 6px 0px 6px;
              font-weight: 700;
            }
              .cardprint:last-child {
                page-break-after: auto;
           }
           .name{ font-size:20px;}
           .font-weight-bold{font-weight:600;}
            }
            @media screen, print {
                table,
                td,
                th {border: 1px solid #828282;
                    text-align: left;
                }

                table {border: 1px solid #828282;
                    border-collapse: collapse;
                    width: 98%;
                    
                }

                th,
                td {border: 1px solid #828282;
                    padding: 15px;
                }
                .col-md-12 {
                  flex: 0 0 100%;
                  max-width: 100%;
                 
                }
                .row {
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: 0px;
                    margin-left: 0px;
                }
                .col-6{
                  flex: 0 0 50%;
                  max-width: 50%;
              
                }
                .d-flex {
                    display: flex !important;
                }
                .col-md-6{
                  position: relative;
                  flex: 0 0 49%;
                  max-width: 49%;
                  padding-top: 14px;
                  padding-bottom: 0;
                  line-height: normal;
                
              }
              .form-group {
                margin-bottom: 1rem;
            }
            fieldset{
              margin-bottom: 10px;
              border:none;
              margin-bottom: 14px;
              padding: 15px;
              width: 100%;
            }
            #print-card {
              padding: 15px;
            }
            fieldset.common-fieldset legend {
              font-size: 18px;
              background: none;
              display: inline-block;
              width: auto;
              border-radius: 10rem;
              padding: 6px 0px 6px;
              font-weight: 700;
            }
              .cardprint:last-child {
                page-break-after: auto;
           }
           .bg-grey{ background:#eee}
           .fid-ht { margin-top:-20px}
           .bg_new{background-image: url(/assets/images/sampada-logo11.png);}
            }

        </style>
    </head>
    <body onload="window.print();window.close()" style="font-family: Roboto, "Helvetica Neue", sans-serif;
    color: #000;
    font-weight: 600;">
    <table width="100%" border="0" cellspacing="0" cellpadding="0" class="mt-3" style="text-align: center;
    border: none;">
    <tr>
      <td style="text-align: center;
      border: none;padding: 0px;">
        <img src="assets/images/sampada-logo.png" alt="Madhya Pradesh" height="80" />
      </td>
    </tr>
  </table>
        ${printContents}

 


    </body>
</html>`
    );
    popupWin.document.close();
      }

}
