<button mat-icon-button matDialogClose class="colsebtn d-print-none" (click)="close()" [mat-dialog-close]="true">
  <mat-icon>close</mat-icon>
</button>

<h2 class="Consenterdialog-title">
  {{ "property-valuation.property-valuation-title" | translate }}
</h2>


<mat-dialog-content class="mat-typography pb-4">
  <div id="print-card">
    <h2 class="d-none d-print d-print-block" style="text-align: center;padding: 3px 0 5px;
        font-size: 17px;
        margin: 0 0 5px;">{{ "property-valuation.property-valuation-title" | translate }}</h2>
    <div>
      <div class="row mb-2">
        <div class="col-md-6">

          <label class="d-block"
            *ngIf="propertyValuationResponseBean?.webGisResponseBeanDataArr!=undefined&&propertyValuationResponseBean?.webGisResponseBeanDataArr.lenght>0"><span
              *ngIf="propertyValuationRequestBean?.webGisResponseBeanDataArr!=undefined">
              {{ "ereg-property-details.propertyType" | translate }} </span>:
          </label>

          <label
            *ngIf="propertyValuationResponseBean.mpenagarpalikaResponse!=null||undefined">{{'duty-calculation.propId' |
            translate}} : <span>
              {{propertyValuationResponseBean.mpenagarpalikaResponse.propertyNo??propertyValuationResponseBean.mpenagarpalikaResponse.propertyId}}
              <!-- {{propertyValuationResponseBean.mpenagarpalikaResponse!=null||undefined?propertyValuationResponseBean.mpenagarpalikaResponse.propertyNo:''
              }}{{ 
              propertyValuationResponseBean.mpenagarpalikaResponse!=null||undefined?propertyValuationResponseBean.mpenagarpalikaResponse.propertyId:''}}-->
            </span> </label>
          <label *ngIf="propertyValuationResponseBean.pNRDResponseData">{{'duty-calculation.propId' | translate}} :
            <span>
              {{
              propertyValuationResponseBean.pNRDResponseData!=null||undefined?propertyValuationResponseBean.pNRDResponseData.propertyId:''}}
            </span> </label>
          <label *ngIf="propertyValuationResponseBean.propertyType.id==3">
            <span *ngIf="propertyValuationResponseBean?.webGisResponseBeanDataArr[0]?.khasraType=='P' else isNewspan">
              {{lan==0?'Plot No:':'प्लॉट नंबर:'}}
            </span>
            <ng-template #isNewspan>
              <span>
                {{'valuationproperty.KhashraNo' | translate}} :
              </span>
            </ng-template>

            <span *ngFor="let web of propertyValuationResponseBean.webGisResponseBeanDataArr">
              <b *ngIf="propertyValuationResponseBean.propertyType.id==3"> {{web.surveyNo}}</b>
            </span>
          </label>
        </div>
        <div class="col-md-6 text-right">
          <label>{{'valuationproperty.SAMPADAPropertyValuation' | translate}} :
            <span>{{propertyValuationResponseBean.valTxnId}}</span></label>
        </div>
      </div>
      <div class="row mb-2">
        <div [ngClass]="isCheckOwnerDetail(propertyValuationResponseBean)? 'col-md-6' : 'col-md-12'">
          <fieldset class="legend-section mt-2 legend-section2"
            *ngIf="propertyValuationResponseBean?.mstDemographyBean != null">
            <legend class="sidebar__widget-title">
              {{"property-valuation.property-location" | translate}}

            </legend>
            <div class="table-responsive shadow-none">
              <table class="table table-bordered">
                <tr>
                  <th class="bg-light" style="width: 35%">
                    {{"address" | translate}}
                  </th>
                  <td>
                    {{propertyValuationResponseBean?.address}}
                  </td>
                </tr>
                <tr>
                  <th class="bg-light">{{'valuationproperty.WardVillageColony' | translate}}</th>
                  <td *ngIf="
                    propertyValuationResponseBean?.mstDemographyBean?.location
                      ?.ward?.demographyName != null
                  ">
                    {{
                    lan == 0
                    ? propertyValuationResponseBean?.mstDemographyBean
                    ?.location?.ward?.demographyNameEn
                    : propertyValuationResponseBean?.mstDemographyBean
                    ?.location?.ward?.demographyNameHi
                    }}/
                    {{
                    lan == 0
                    ? propertyValuationResponseBean?.mstDemographyBean
                    ?.location?.ward_colony?.demographyNameEn
                    : propertyValuationResponseBean?.mstDemographyBean
                    ?.location?.ward_colony?.demographyNameHi
                    }}
                  </td>
                  <td *ngIf="
                propertyValuationResponseBean?.mstDemographyBean?.location
                  ?.village?.demographyName != null
              ">
                    {{
                    lan == 0
                    ? propertyValuationResponseBean?.mstDemographyBean
                    ?.location?.village?.demographyNameEn
                    : propertyValuationResponseBean?.mstDemographyBean
                    ?.location?.village?.demographyNameHi
                    }}/
                    {{
                    lan == 0
                    ? propertyValuationResponseBean?.mstDemographyBean
                    ?.location?.village_colony?.demographyNameEn
                    : propertyValuationResponseBean?.mstDemographyBean
                    ?.location?.village_colony?.demographyNameHi
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="bg-light"> {{'valuationproperty.LocalBodyTehsil' | translate}}</th>
                  <td *ngIf="
                  propertyValuationResponseBean?.mstDemographyBean?.location
                    ?.tehsil?.demographyName != null||propertyValuationResponseBean?.mstDemographyBean
                    ?.location?.localBody?.demographyName!=null
                ">
                    {{
                    lan == 0
                    ? propertyValuationResponseBean?.mstDemographyBean
                    ?.location?.tehsil?.demographyNameEn
                    : propertyValuationResponseBean?.mstDemographyBean
                    ?.location?.tehsil?.demographyNameHi
                    }}
                    {{
                    lan == 0
                    ? propertyValuationResponseBean?.mstDemographyBean
                    ?.location?.localBody?.demographyNameEn
                    : propertyValuationResponseBean?.mstDemographyBean
                    ?.location?.localBody?.demographyNameHi
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="bg-light">
                    {{'valuationproperty.DistrictPinCode' | translate}}</th>
                  <td *ngIf="
                    propertyValuationResponseBean?.mstDemographyBean?.location
                      ?.district?.demographyName != null
                  ">
                    {{
                    lan == 0
                    ? propertyValuationResponseBean?.mstDemographyBean
                    ?.location?.district?.demographyNameEn
                    : propertyValuationResponseBean?.mstDemographyBean
                    ?.location?.district?.demographyNameHi
                    }}
                  </td>
                </tr>

                <tr *ngIf="guildeLineObj">
                  <th class="bg-light">
                    <span *ngIf="lan==0">Vishist Gram</span>
                    <span *ngIf="lan==1">विशिष्ट ग्राम</span>

                  </th>
                  <td>
                    {{guildeLineObj?.isVishistGram==0?lan==0?'NO':'नहीं':lan==0?'YES':'हाँ'}}

                  </td>
                </tr>
              </table>
            </div>
          </fieldset>
        </div>
        <div class="col-md-6">
          <!--Owner Details start-->
          <!--plot Owner-->
          <!-- <fieldset class="legend-section mt-2 legend-section2" *ngIf="propertyValuationResponseBean.propertyType.id==1||propertyValuationResponseBean.propertyType.id==2">
            <legend class="sidebar__widget-title">
              {{ "property-valuation.Owner-Details" | translate }}
            </legend>
            <div class="table-responsive shadow-none">
              <table class="table table-bordered">
                <thead class="thead-dark text-center">
                  <tr>
                    <th>#</th>
                    
                    <th>{{'valuationproperty.PropertyNo' | translate}}</th>
                 
                    <th>{{'valuationproperty.OwnerName' | translate}}</th>
                    <th>{{'valuationproperty.FatherName' | translate}}</th>


                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let owner of propertyValuationResponseBean?.pnrdresponseData?.propertyOwner; index as i">

                    <td >{{i+1}}</td>

                    <td>{{propertyValuationResponseBean?.pnrdresponseData?.propertyId}}</td>

                    <td>{{owner?.ownerName}}</td>
                    <td>{{owner?.fatherName}}</td>

                  </tr>
                </tbody>
              </table>
            </div>pnrdresponseData
          </fieldset> -->
          <fieldset class="legend-section mt-2 legend-section2"
            *ngIf="(propertyValuationResponseBean.pNRDResponseData!=null||undefined)&&(propertyValuationResponseBean.propertyType.id==2||propertyValuationResponseBean.propertyType.id==1)">
            <legend class="sidebar__widget-title">
              {{ "property-valuation.Owner-Details" | translate }}
            </legend>
            <div class="table-responsive shadow-none">
              <table class="table table-bordered">
                <thead class="thead-dark text-center">
                  <tr>
                    <th>#</th>
                    <!----------Agriculture Land---------------->
                    <th>{{'valuationproperty.PropertyNo' | translate}}</th>
                    <!----------end Agriculture Land---------------->
                    <th>{{'valuationproperty.OwnerName' | translate}}</th>
                    <th>{{'valuationproperty.FatherName' | translate}}</th>


                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let owner of propertyValuationResponseBean?.pnrdresponseData?.propertyOwner; index as i">

                    <td>{{i+1}}</td>

                    <td>{{propertyValuationResponseBean?.pnrdresponseData?.propertyId}}</td>

                    <td>{{owner?.ownerName}}</td>
                    <td>{{owner?.fatherName}}</td>

                  </tr>
                </tbody>
              </table>
            </div>
          </fieldset>
          <!--Building/plot enagarpalika -->
          <fieldset class="legend-section mt-2 legend-section2"
            *ngIf="(propertyValuationResponseBean.mpenagarpalikaResponse!=null||undefined)&&(propertyValuationResponseBean.propertyType.id==2||propertyValuationResponseBean.propertyType.id==1)">
            <legend class="sidebar__widget-title">
              {{ "property-valuation.Owner-Details" | translate }}
            </legend>
            <div class="table-responsive shadow-none">
              <table class="table table-bordered">
                <thead class="thead-dark text-center">
                  <tr>
                    <th>#</th>
                    <!----------Agriculture Land---------------->
                    <th>{{'valuationproperty.PropertyNo' | translate}}</th>
                    <!----------end Agriculture Land---------------->
                    <th>{{'valuationproperty.OwnerName' | translate}}</th>

                    <th>{{'valuationproperty.FatherName' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let owner of propertyValuationResponseBean?.mpenagarpalikaResponse?.propertyOwner; index as i">

                    <td>{{i+1}}</td>

                    <td>{{propertyValuationResponseBean?.mpenagarpalikaResponse?.propertyNo}}</td>

                    <td>{{owner?.ownerName}}</td>
                    <td>{{owner?.fatherName}}</td>

                  </tr>
                </tbody>
              </table>
            </div>
          </fieldset>
          <!--Agriculture  -->
          <fieldset class="legend-section mt-2 legend-section2"
            *ngIf="propertyValuationResponseBean.propertyType.id==3||(propertyValuationResponseBean.propertyType.id==1&&propertyValuationResponseBean.isPlotOnAgriLand)">
            <legend class="sidebar__widget-title">
              {{ "property-valuation.Owner-Details" | translate }}
            </legend>
            <div class="table-responsive shadow-none">
              <table class="table table-bordered">
                <thead class="thead-dark text-center">
                  <tr>
                    <th>#</th>
                    <!----------Agriculture Land---------------->
                    <th>{{'valuationproperty.LandParcel' | translate}}</th>
                    <!----------end Agriculture Land---------------->
                    <th>{{'valuationproperty.OwnerName' | translate}}</th>
                    <th>{{'valuationproperty.FatherName' | translate}}</th>
                  </tr>
                </thead>
                <tbody *ngFor="let owner of propertyValuationResponseBean.webGisResponseBeanDataArr; index as i">
                  <tr *ngFor="let o of owner.propertyOwner; index as k">

                    <td>{{k+1}}</td>

                    <td>{{owner.landParcelId}}</td>

                    <td>{{o.ownerName}}</td>
                    <td>{{o.fatherName}}</td>

                  </tr>
                </tbody>
              </table>
            </div>

          </fieldset>
          <!--Owner Details end-->
        </div>
      </div>
      <!-------------------------------------------------------------
   -------------------------------------------------------------
   ------------------------ Agriculture Land --------------
   -------------------------------------------------------------
  -------------------------------------------------------------->
      <div class="row mb-2" *ngIf="propertyValuationResponseBean.propertyType.id==3">
        <div class="col-md-12">
          <fieldset class="legend-section mt-2 legend-section2">
            <legend class="sidebar__widget-title">
              {{"ereg-property-details.propertyDetails" | translate}}
            </legend>

            <div class="table-responsive shadow-none">
              <table class="table table-bordered">
                <tr>
                  <th class="f-w500"> {{'valuationproperty.Transactingpartialareatheproperty' | translate}}</th>
                  <td colspan="3">{{
                    propertyValuationResponseBean.isPartialTransact == false
                    ? lan==0 ? 'NO':'नहीं':lan==0 ?'Yes':'हां'
                    }}</td>

                </tr>
                <tr>
                  <th class="f-w500">{{'valuationproperty.PropertyType' | translate}} </th>
                  <td colspan="3">{{
                    lan==0 ? propertyValuationResponseBean.propertyType.nameEn
                    :propertyValuationResponseBean.propertyType.nameHi
                    }}{{propertyValuationResponseBean?.webGisResponseBeanDataArr[0]?.khasraType=='P'? lan==0?' (PLOT)':'
                    (प्लॉट) ':'' }}</td>
                </tr>
                <tr>
                  <th class="f-w500">{{'valuationproperty.PropertyTotalArea' | translate}} ({{
                    lan==0 ?
                    getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                    }})</th>
                  <td>{{propertyValuationResponseBean.overAllArea}}</td>
                  <th class="f-w500">{{'valuationproperty.PropertyTotalTransactingArea' | translate}} ({{
                    lan==0 ?
                    getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                    }})</th>
                  <td>{{propertyValuationResponseBean.transactArea}}</td>
                </tr>
              </table>
              <!--Agriculture Undiverted-->
              <table class="table table-bordered"
                *ngIf="propertyValuationResponseBean.webGisResponseBeanDataArr!=null&&propertyValuationResponseBean.totalSellableAreaUndiverted!=null">
                <tr>
                  <th class="bg-light f-w500"> {{'valuationproperty.UnDivertedArea' | translate}} ({{
                    lan==0 ?
                    getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                    }})</th>
                  <td colspan="3">{{propertyValuationResponseBean.totalSellableAreaUndiverted}}</td>
                </tr>
                <tr>
                  <th *ngIf="propertyValuationResponseBean.irrigatedArea!=null||undefined" class="f-w500">
                    {{'valuationproperty.IrrigatedArea' | translate}} ({{
                    lan==0 ?
                    getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                    }})</th>
                  <td *ngIf="propertyValuationResponseBean.irrigatedArea!=null||undefined">
                    {{propertyValuationResponseBean.irrigatedArea}}</td>
                  <th *ngIf="propertyValuationResponseBean.singleCropArea!=null||undefined" class="f-w500">
                    {{'valuationproperty.UnirrigatedSingleCorpArea' | translate}} ({{
                    lan==0 ?
                    getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                    }})</th>
                  <td *ngIf="propertyValuationResponseBean.singleCropArea!=null||undefined">
                    {{propertyValuationResponseBean.singleCropArea}}</td>
                </tr>
                <tr *ngIf="propertyValuationResponseBean.doubleCropArea!=null||undefined">
                  <th class="f-w500" *ngIf="propertyValuationResponseBean.doubleCropArea!=null||undefined">
                    {{'valuationproperty.UnirrigatedDoubleCorpArea' | translate}} ({{
                    lan==0 ?
                    getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                    }})</th>
                  <td *ngIf="propertyValuationResponseBean.doubleCropArea!=null||undefined">
                    {{propertyValuationResponseBean.doubleCropArea}}</td>
                  <!-- <th></th>
                  <td></td> -->
                </tr>
              </table>
              <!--Agriculture diverted-->
              <table class="table table-bordered"
                *ngIf="propertyValuationResponseBean.webGisResponseBeanDataArr!=null&&propertyValuationResponseBean.totalSellableAreaDiverted!=null||undefined">
                <tr>
                  <th class="bg-light">{{'valuationproperty.DivertedAreaHA' | translate}}({{ lan==0 ?
                    getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)}})
                  </th>
                  <td colspan="3">{{propertyValuationResponseBean.totalSellableAreaDiverted}}</td>
                </tr>
                <tr>
                  <th class="f-w500" *ngIf="propertyValuationResponseBean.residentialPlotArea!=null||undefined">
                    {{'valuationproperty.ResidentialArea' | translate}} ({{
                    lan==0 ?
                    getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                    }})</th>
                  <td *ngIf="propertyValuationResponseBean.residentialPlotArea!=null||undefined">
                    {{propertyValuationResponseBean.residentialPlotArea}}</td>
                  <th class="f-w500" *ngIf="propertyValuationResponseBean.healthPlotArea!=null||undefined">
                    {{'valuationproperty.HealthArea' | translate}} ({{
                    lan==0 ?
                    getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                    }})</th>
                  <td *ngIf="propertyValuationResponseBean.healthPlotArea!=null||undefined">
                    {{propertyValuationResponseBean.healthPlotArea}}</td>
                </tr>
                <tr>
                  <th class="f-w500" *ngIf="propertyValuationResponseBean.commercialPlotArea!=null||undefined">
                    {{'valuationproperty.CommercialArea' | translate}} ({{
                    lan==0 ?
                    getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                    }})</th>
                  <td *ngIf="propertyValuationResponseBean.commercialPlotArea!=null||undefined">
                    {{propertyValuationResponseBean.commercialPlotArea}}</td>
                  <th class="f-w500" *ngIf="propertyValuationResponseBean.eduPlotArea!=null||undefined">
                    {{'valuationproperty.EducationArea' | translate}} ({{
                    lan==0 ?
                    getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                    }})</th>
                  <td *ngIf="propertyValuationResponseBean.eduPlotArea!=null||undefined">
                    {{propertyValuationResponseBean.eduPlotArea}}</td>
                </tr>
                <tr>
                  <th class="f-w500" *ngIf="propertyValuationResponseBean.industrialPlotArea!=null||undefined">
                    {{'valuationproperty.IndustrialArea' | translate}} ({{
                    lan==0 ?
                    getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                    }})</th>
                  <td *ngIf="propertyValuationResponseBean.industrialPlotArea!=null||undefined">
                    {{propertyValuationResponseBean.industrialPlotArea}}</td>
                  <!-- <th></th>
                  <td></td> -->
                </tr>
              </table>
              <!--Agriculture diverted end-->
              <!--Agriculture building start-->
              <table class="table table-bordered">

                <tr>
                  <th class="f-w500">
                    {{'valuationproperty.ConstructionDoneontheAgricultureLand' | translate}}
                  </th>
                  <td>{{propertyValuationResponseBean.constOnAgriLand==false? lan==0 ? 'NO':'नहीं':lan==0 ?'Yes':'हां'}}
                  </td>
                  <th
                    *ngIf="(propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType!=null||undefined)&&propertyValuationResponseBean.constOnAgriLand==true"
                    class="f-w500">{{lan==0?'Building Type':'
                    बिल्डिंग प्रकार'}}</th>
                  <td
                    *ngIf="(propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType!=null||undefined)&&propertyValuationResponseBean.constOnAgriLand==true">
                    {{lan==0?propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType:getBuildingTypeHi(propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType)}}
                  </td>
                </tr>
                <ng-container
                  *ngIf="(propertyValuationResponseBean.agriculturalWithConstruction.independentBuildBean!=null||undefined)&&propertyValuationResponseBean.constOnAgriLand==true">
                  <tr>
                    <th class="f-w500">{{'valuationproperty.PropertyTotalArea' | translate}} ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                      }})</th>
                    <td>
                      {{propertyValuationResponseBean.agriculturalWithConstruction.overAllArea==null||undefined?propertyValuationResponseBean.agriculturalWithConstruction.totalArea:propertyValuationResponseBean.agriculturalWithConstruction.overAllArea}}
                    </td>
                    <th class="f-w500">{{'valuationproperty.PropertyTotalTransactingArea' | translate}} ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                      }})</th>
                    <td>{{propertyValuationResponseBean.agriculturalWithConstruction.partialTransactArea}}</td>
                  </tr>
                  <!-- New changes -->
                  <tr *ngIf="propertyValuationResponseBean.constructioRespBean.isResidentialCumCommercial">
                    <th class="f-w500">
                      <span *ngIf="lan==0">Building Floor/Subtype </span>
                      <span *ngIf="lan==1">भवन का तल/उपप्रकार </span>
                    </th>


                    <th class="p-0">
                      <table class="table table-bordered m-0 vt1">
                        <tr>
                          <th class="f-w500" style="width:12%;">
                            <span *ngIf="lan==0">RCC Rate</span>
                            <span *ngIf="lan==1">आरसीसी दर </span>
                          </th>
                          <th class="f-w500" style="width:12%;">
                            <span *ngIf="lan==0">RBC Rate</span>
                            <span *ngIf="lan==1">आरबीसी दर</span>
                          </th>
                          <th class="f-w500" style="width:18%;">
                            <span *ngIf="lan==0">Kachha kabelu Rate</span>
                            <span *ngIf="lan==1">कच्चा कबेलु दर</span>
                          </th>
                          <th class="f-w500" style="width:18%;">
                            <span *ngIf="lan==0">Tin shade Rate</span>
                            <span *ngIf="lan==1">टिन शेड दर</span>
                          </th>
                          <th class="f-w500" style="width:12%;">
                            <span *ngIf="lan==0">Office Rate</span>
                            <span *ngIf="lan==1">कार्यालय दर</span>
                          </th>
                          <th class="f-w500" style="width:12%;">
                            <span *ngIf="lan==0">shop Rate</span>
                            <span *ngIf="lan==1">दुकान दर</span>
                          </th>
                          <th class="f-w500" style="width:12%;">
                            <span *ngIf="lan==0">Godown Rate</span>
                            <span *ngIf="lan==1">गोदाम दर</span>
                          </th>
                        </tr>
                      </table>
                    </th>
                    <th class="f-w500">
                      <span *ngIf="lan==0">Total Cost</span>
                      <span *ngIf="lan==1">कुल लागत</span>
                    </th>

                  </tr>
                  <ng-container
                    *ngFor="let buildArea of propertyValuationResponseBean?.constructioRespBean?.independentBuildTypeBeanList">

                    <ng-container
                      *ngIf=" buildArea?.resiCumCommType != undefined &&buildArea?.resiCumCommType != null&&isObjectEmpty(buildArea?.resiCumCommType)">

                      <tr>
                        <td>
                          {{lan == 0
                          ? getFloorName(buildArea?.floorId)
                          : getFloorNameHi(buildArea?.floorId)
                          }}
                          <span class="badge badge-primary bg-primary"> {{'valuationproperty.resiCumComm' | translate}}
                          </span>
                        </td>
                        <td class="p-0">
                          <table class="table table-bordered m-0 vt2">
                            <tr>

                              <td style="width:12%;">{{getPriceConversion(buildArea?.resiCumCommType?.rccRate) | amount
                                }}</td>
                              <td style="width:12%;">{{getPriceConversion(buildArea?.resiCumCommType?.rbcRate) | amount
                                }}</td>
                              <td style="width:18%;">{{getPriceConversion(buildArea?.resiCumCommType?.kacchaKabeluRate)
                                | amount }}</td>
                              <td style="width:18%;">{{getPriceConversion(buildArea?.resiCumCommType?.tinShadeRate) |
                                amount }}</td>
                              <td style="width:12%;">{{getPriceConversion(buildArea?.resiCumCommType?.officeRate) |
                                amount }}</td>
                              <td style="width:12%;">{{getPriceConversion(buildArea?.resiCumCommType?.shopRate) | amount
                                }}</td>
                              <td style="width:12%;">{{getPriceConversion(buildArea?.resiCumCommType?.godownRate) |
                                amount }}</td>
                            </tr>
                          </table>

                        </td>
                        <td>
                          {{ buildArea?.constCost }}
                        </td>

                      </tr>


                    </ng-container>
                  </ng-container>
                  <tr *ngIf="!propertyValuationResponseBean.constructioRespBean.isResidentialCumCommercial">
                    <th class="f-w500">
                      <span *ngIf="lan==0">Building Floor/Subtype </span>
                      <span *ngIf="lan==1">भवन का तल/उपप्रकार </span>
                    </th>
                    <th class="p-0">
                      <table class="table table-bordered m-0 ">
                        <tr>
                          <th class="f-w500" style="width: 25%;">
                            <span *ngIf="lan==0">RCC Rate</span>
                            <span *ngIf="lan==1">आरसीसी दर </span>
                          </th>
                          <th class="f-w500" style="width: 25%;">
                            <span *ngIf="lan==0">RBC Rate</span>
                            <span *ngIf="lan==1">आरबीसी दर</span>
                          </th>
                          <th class="f-w500" style="width: 25%;">
                            <span *ngIf="lan==0">Kachha kabelu Rate</span>
                            <span *ngIf="lan==1">कच्चा कबेलु दर</span>
                          </th>
                          <th class="f-w500" style="width: 25%;">
                            <span *ngIf="lan==0">Tin shade Rate</span>
                            <span *ngIf="lan==1">टिन शेड दर</span>
                          </th>
                        </tr>
                      </table>
                    </th>
                    <th class="f-w500">
                      <span *ngIf="lan==0">Total Cost</span>
                      <span *ngIf="lan==1">कुल लागत</span>
                    </th>

                  </tr>
                  <ng-container
                    *ngFor="let buildArea of propertyValuationResponseBean?.constructioRespBean?.independentBuildTypeBeanList">

                    <ng-container
                      *ngIf=" buildArea?.resiConstType != undefined &&buildArea?.resiConstType != null&&isObjectEmpty(buildArea?.resiConstType)">

                      <tr>
                        <td>
                          {{lan == 0
                          ? getFloorName(buildArea?.floorId)
                          : getFloorNameHi(buildArea?.floorId)
                          }}
                          <span class="badge badge-primary bg-primary">{{'valuationproperty.Residential' |
                            translate}}</span>
                        </td>
                        <td class="p-0">
                          <table class="table table-bordered m-0 ">
                            <tr>
                              <td style="width: 25%;">{{ getPriceConversion(buildArea?.resiConstType?.rccRate) | amount
                                }}</td>
                              <td style="width: 25%;">{{ getPriceConversion(buildArea?.resiConstType?.rbcRate) | amount
                                }}</td>
                              <td style="width: 25%;"> {{ getPriceConversion(buildArea?.resiConstType?.kacchaKabeluRate)
                                | amount }}</td>
                              <td style="width: 25%;"> {{ getPriceConversion(buildArea?.resiConstType?.tinShadeRate) |
                                amount }}</td>
                            </tr>
                          </table>

                        </td>
                        <td>
                          {{ buildArea?.constCost}}
                        </td>

                      </tr>


                    </ng-container>
                  </ng-container>
                  <!--Industrial-->
                  <ng-container
                    *ngFor="let buildArea of propertyValuationResponseBean?.constructioRespBean?.independentBuildTypeBeanList">

                    <ng-container
                      *ngIf=" buildArea?.industrialConstructionType != undefined &&buildArea?.industrialConstructionType != null&&isObjectEmpty(buildArea?.industrialConstructionType)">

                      <tr>
                        <td>
                          {{lan == 0
                          ? getFloorName(buildArea?.floorId)
                          : getFloorNameHi(buildArea?.floorId)
                          }}
                          <span class="badge badge-primary bg-primary">{{'valuationproperty.Industrial' |
                            translate}}</span>
                        </td>
                        <td class="p-0">
                          <table class="table table-bordered m-0 ">
                            <tr>
                              <td style="width: 25%;">{{
                                getPriceConversion(buildArea?.industrialConstructionType?.rccRate) | amount }}</td>
                              <td style="width: 25%;">{{
                                getPriceConversion(buildArea?.industrialConstructionType?.rbcRate) | amount }}</td>
                              <td style="width: 25%;">
                                {{getPriceConversion(buildArea?.industrialConstructionType?.kacchaKabeluRate) | amount
                                }}</td>
                              <td style="width: 25%;"> {{
                                getPriceConversion(buildArea?.industrialConstructionType?.tinShadeRate) | amount}}</td>
                            </tr>
                          </table>

                        </td>
                        <td>
                          {{ getPriceConversion(buildArea?.constCost )}}
                        </td>

                      </tr>


                    </ng-container>
                  </ng-container>
                  <!--Health-->
                  <ng-container
                    *ngFor="let buildArea of propertyValuationResponseBean?.constructioRespBean?.independentBuildTypeBeanList">

                    <ng-container
                      *ngIf=" buildArea?.healthAreaConstructionType != undefined &&buildArea?.healthAreaConstructionType != null&&isObjectEmpty(buildArea?.healthAreaConstructionType)">

                      <tr>
                        <td>
                          {{lan == 0
                          ? getFloorName(buildArea?.floorId)
                          : getFloorNameHi(buildArea?.floorId)
                          }}
                          <span class="badge badge-primary bg-primary">{{'valuationproperty.Health' | translate}}</span>
                        </td>
                        <td class="p-0">
                          <table class="table table-bordered m-0 ">
                            <tr>
                              <td style="width: 25%;">{{getPriceConversion(
                                buildArea?.healthAreaConstructionType?.rccRate) | amount}}</td>
                              <td style="width: 25%;">{{ getPriceConversion(
                                buildArea?.healthAreaConstructionType?.rbcRate) | amount }}</td>
                              <td style="width: 25%;"> {{ getPriceConversion(
                                buildArea?.healthAreaConstructionType?.kacchaKabeluRate) | amount }}</td>
                              <td style="width: 25%;"> {{ getPriceConversion(
                                buildArea?.healthAreaConstructionType?.tinShadeRate) | amount}}</td>
                            </tr>
                          </table>

                        </td>
                        <td>
                          {{ buildArea?.constCost}}
                        </td>

                      </tr>


                    </ng-container>
                  </ng-container>

                  <ng-container
                    *ngFor="let buildArea of propertyValuationResponseBean?.constructioRespBean?.independentBuildTypeBeanList">

                    <ng-container
                      *ngIf=" buildArea?.eduAreaConstructionType != undefined &&buildArea?.eduAreaConstructionType != null&&isObjectEmpty(buildArea?.eduAreaConstructionType)">

                      <tr>
                        <td>
                          {{lan == 0
                          ? getFloorName(buildArea?.floorId)
                          : getFloorNameHi(buildArea?.floorId)
                          }}
                          <span class="badge badge-primary bg-primary">{{'valuationproperty.Education' |
                            translate}}</span>
                        </td>
                        <td class="p-0">
                          <table class="table table-bordered m-0 ">
                            <tr>
                              <td style="width: 25%;">{{ getPriceConversion(
                                buildArea?.eduAreaConstructionType?.rccRate) | amount }}</td>
                              <td style="width: 25%;">{{ getPriceConversion(
                                buildArea?.eduAreaConstructionType?.rbcRate) | amount}}</td>
                              <td style="width: 25%;"> {{ getPriceConversion(
                                buildArea?.eduAreaConstructionType?.kacchaKabeluRate) | amount}}</td>
                              <td style="width: 25%;"> {{ getPriceConversion(
                                buildArea?.eduAreaConstructionType?.tinShadeRate) | amount}}</td>
                            </tr>
                          </table>

                        </td>
                        <td>
                          {{getPriceConversion(buildArea?.constCost)}}
                        </td>

                      </tr>


                    </ng-container>
                  </ng-container>

                  <!--Other-->
                  <ng-container
                    *ngFor="let buildArea of propertyValuationResponseBean?.constructioRespBean?.independentBuildTypeBeanList">

                    <ng-container
                      *ngIf=" buildArea?.otherAreaConstructionType != undefined &&buildArea?.otherAreaConstructionType != null&&isObjectEmpty(buildArea?.otherAreaConstructionType)">

                      <tr>
                        <td>
                          {{lan == 0
                          ? getFloorName(buildArea?.floorId)
                          : getFloorNameHi(buildArea?.floorId)
                          }}
                          <span class="badge badge-primary bg-primary">{{'valuationproperty.Other' | translate}}</span>
                        </td>
                        <td class="p-0">
                          <table class="table table-bordered m-0 ">
                            <tr>
                              <td style="width: 25%;">{{
                                getPriceConversion(buildArea?.otherAreaConstructionType?.rccRate) | amount }}</td>
                              <td style="width: 25%;">{{
                                getPriceConversion(buildArea?.otherAreaConstructionType?.rbcRate) | amount}}</td>
                              <td style="width: 25%;"> {{
                                getPriceConversion(buildArea?.otherAreaConstructionType?.kacchaKabeluRate) | amount}}
                              </td>
                              <td style="width: 25%;"> {{
                                getPriceConversion(buildArea?.otherAreaConstructionType?.tinShadeRate) | amount }}</td>
                            </tr>
                          </table>

                        </td>
                        <td>
                          {{ buildArea?.constCost}}
                        </td>

                      </tr>


                    </ng-container>
                  </ng-container>
                  <!--commercial-->
                  <tr
                    *ngIf="propertyValuationResponseBean.constructioRespBean.commercialPlot!=0&&propertyValuationResponseBean.constructioRespBean.commercialPlot!=undefined">
                    <th>
                      <span>{{'valuationproperty.CommercialBuilding' | translate}}</span>
                    </th>
                    <th class="p-0">
                      <table class="table table-bordered m-0 ">
                        <tr>
                          <th style="width: 33.33%;">
                            {{'valuationproperty.ShopRate' | translate}}
                          </th>
                          <th style="width: 33.33%;">
                            {{'valuationproperty.OfficeRate' | translate}}
                          </th>
                          <th style="width: 33.33%;">
                            {{'valuationproperty.GodownRate' | translate}}
                          </th>
                        </tr>
                      </table>
                    </th>
                    <th>
                      {{'valuationproperty.TotalCost' | translate}}
                    </th>

                  </tr>

                  <ng-container
                    *ngFor="let buildArea of propertyValuationResponseBean?.constructioRespBean?.independentBuildBean">

                    <ng-container
                      *ngIf=" buildArea?.commConstType != undefined &&buildArea?.commConstType != null&&isObjectEmpty(buildArea?.commConstType)">

                      <tr>
                        <td>
                          {{lan == 0
                          ? getFloorName(buildArea?.floorId)
                          : getFloorNameHi(buildArea?.floorId)
                          }}
                          <span class="badge badge-primary bg-primary">{{'valuationproperty.Commercial' |
                            translate}}</span>
                        </td>
                        <td class="p-0">
                          <table class="table table-bordered m-0 ">
                            <tr>
                              <td style="width: 33.33%;">{{getPriceConversion(buildArea?.commConstType?.shopRate ) |
                                amount}}</td>
                              <td style="width: 33.33%;">{{getPriceConversion(buildArea?.commConstType?.officeRate) |
                                amount }}</td>
                              <td style="width: 33.33%;">{{getPriceConversion(buildArea?.commConstType?.godownRate ) |
                                amount}}</td>
                            </tr>
                          </table>

                        </td>
                        <td>
                          {{ getPriceConversion(buildArea?.constCost)}}
                        </td>

                      </tr>



                    </ng-container>
                  </ng-container>
                  <!-- new Change end -->
                  <!-- Residental Cum commercial -->
                  <!-- <tr>
                  <th *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.residentialCumCommercialPlotArea!=null||undefined"> {{'valuationproperty.ResidentialCumCommercialArea' | translate}}</th>
                  <td colspan="3" *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.residentialCumCommercialPlotArea!=null||undefined">{{propertyValuationResponseBean.agriculturalWithConstruction.residentialCumCommercialPlotArea}}</td>
                </tr> -->
                  <!-- residental with health -->
                  <!-- <tr>
                  <th class="f-w500" *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.residentialPlotArea">  {{'valuationproperty.ResidentialArea' | translate}} ({{
                    lan==0 ? getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                    }})</th>
                  <td *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.residentialPlotArea">{{propertyValuationResponseBean.agriculturalWithConstruction.residentialPlotArea}}</td>
                  <th class="f-w500" *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.healthPlotArea">{{'valuationproperty.HealthArea' | translate}} ({{
                    lan==0 ? getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                    }})</th>
                  <td  *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.healthPlotArea" >{{propertyValuationResponseBean.agriculturalWithConstruction.healthPlotArea}}</td>
                </tr> -->
                  <!-- <tr>
                  <th class="f-w500"  *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.commercialPlotArea"> {{'valuationproperty.CommercialArea' | translate}} ({{
                    lan==0 ? getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                    }})</th>
                  <td  *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.commercialPlotArea">{{propertyValuationResponseBean.agriculturalWithConstruction.commercialPlotArea}}</td>
                  <th class="f-w500" *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.eduPlotArea"> {{'valuationproperty.EducationArea' | translate}} ({{
                    lan==0 ? getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                    }})</th>
                  <td *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.eduPlotArea">{{propertyValuationResponseBean.agriculturalWithConstruction.eduPlotArea}}</td>
                </tr> -->
                  <!-- <tr>
                  <th class="f-w500" *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.industrialPlotArea">{{'valuationproperty.IndustrialArea' | translate}} ({{
                    lan==0 ? getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                    }})</th>
                  <td *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.industrialPlotArea">{{propertyValuationResponseBean.agriculturalWithConstruction.industrialPlotArea}}</td>

                </tr> -->
                </ng-container>
              </table>

            </div>

            <div class="table-responsive shadow-none"
              *ngIf="(propertyValuationResponseBean.agriculturalWithConstruction.independentBuildBean!=null||undefined)&&propertyValuationResponseBean.constOnAgriLand==true">
              <table class="table table-bordered"
                *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Independent Building'">
                <thead class="thead-dark">
                  <tr>
                    <th>{{'valuationproperty.FloorType' | translate}}</th>
                    <th>
                      {{'valuationproperty.RCCArea' | translate}} <br />
                      ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                      }})
                    </th>
                    <th>
                      {{'valuationproperty.RBCArea' | translate}} <br />
                      ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                      }})
                    </th>
                    <th>
                      {{'valuationproperty.KachhaKabeluArea' | translate}}<br />
                      ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                      }})
                    </th>
                    <th>
                      {{'valuationproperty.TinShadeArea' | translate}} <br />
                      ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                      }})
                    </th>
                    <th>
                      {{'valuationproperty.ShopArea' | translate}} <br />
                      ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                      }})
                    </th>
                    <th>
                      {{'valuationproperty.OfficeArea' | translate}} <br />
                      ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                      }})
                    </th>
                    <th>
                      {{'valuationproperty.GodownArea' | translate}} <br />
                      ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                      }})
                    </th>
                    <th>
                      {{'valuationproperty.TotalArea' | translate}} <br />
                      ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                      }})
                    </th>
                  </tr>
                </thead>
                <tbody>


                  <tr *ngFor="
                  let buildArea of propertyValuationResponseBean.agriculturalWithConstruction.independentBuildBean">
                    <ng-container
                      *ngIf=" buildArea?.resiConstType != undefined &&buildArea?.resiConstType != null&&isObjectEmpty(buildArea?.resiConstType)">
                      <td> {{
                        lan == 0
                        ? getFloorName(buildArea?.floorId)
                        : getFloorNameHi(buildArea?.floorId)
                        }}
                        <span class="badge badge-primary bg-primary"> {{'valuationproperty.Residential' | translate}}
                        </span>

                      </td>
                      <td>{{ buildArea?.resiConstType?.rccArea }}</td>
                      <td>{{ buildArea?.resiConstType?.rbcArea }}</td>
                      <td> {{ buildArea?.resiConstType?.kacchaKabeluArea }}</td>
                      <td> {{ buildArea?.resiConstType?.tinShadeArea }}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <!-- <td>{{buildArea?.resiConstType?.totalTypeArea}}</td> -->
                      <td>{{(buildArea?.resiConstType?.rccArea==undefined?0:buildArea?.resiConstType?.rccArea)+
                        (buildArea?.resiConstType?.rbcArea==undefined?0:buildArea?.resiConstType?.rbcArea)+
                        (buildArea?.resiConstType?.kacchaKabeluArea==undefined?0:buildArea?.resiConstType?.kacchaKabeluArea)
                        +(buildArea?.resiConstType?.tinShadeArea==undefined? 0:buildArea?.resiConstType?.tinShadeArea)}}
                      </td>
                    </ng-container>
                  </tr>

                  <tr
                    *ngFor="let buildArea of propertyValuationResponseBean.agriculturalWithConstruction.independentBuildBean">
                    <ng-container
                      *ngIf=" buildArea?.commConstType != undefined &&buildArea?.commConstType != null&&isObjectEmpty(buildArea?.commConstType)">
                      <td> {{
                        lan == 0
                        ? getFloorName(buildArea?.floorId)
                        : getFloorNameHi(buildArea?.floorId)
                        }}

                        <span class="badge badge-primary bg-primary"> {{'valuationproperty.Commercial' | translate}}
                        </span>

                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{{buildArea?.commConstType?.shopArea}}</td>
                      <td>{{buildArea?.commConstType?.officeArea}}</td>
                      <td>{{buildArea?.commConstType?.godownArea}}</td>
                      <!-- <td>{{ buildArea?.commConstType?.totalTypeArea}}</td> -->
                      <td>{{(buildArea?.commConstType?.shopArea==undefined?0:buildArea?.commConstType?.shopArea)+
                        (buildArea?.commConstType?.officeArea==undefined?0:buildArea?.commConstType?.officeArea)+
                        (buildArea?.commConstType?.godownArea==undefined?0:buildArea?.commConstType?.godownArea)
                        }}</td>
                    </ng-container>
                  </tr>

                  <tr
                    *ngFor="let buildArea of propertyValuationResponseBean.agriculturalWithConstruction.independentBuildBean">
                    <ng-container
                      *ngIf=" buildArea?.industrialConstructionType != undefined &&buildArea?.industrialConstructionType != null&&isObjectEmpty(buildArea?.industrialConstructionType)">
                      <td> {{
                        lan == 0
                        ? getFloorName(buildArea?.floorId)
                        : getFloorNameHi(buildArea?.floorId)
                        }}

                        <span class="badge badge-primary bg-primary"> {{'valuationproperty.Industrial' | translate}}
                        </span>
                      </td>
                      <td>{{buildArea?.industrialConstructionType?.rccArea}}</td>
                      <td>{{buildArea?.industrialConstructionType?.rbcArea}}</td>
                      <td>{{buildArea?.industrialConstructionType?.kacchaKabeluArea}}</td>
                      <td>{{buildArea?.industrialConstructionType?.tinShadeArea}}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <!-- <td>{{ buildArea?.industrialConstructionType?.totalTypeArea}}</td> -->
                      <td>
                        {{(buildArea?.industrialConstructionType?.rccArea==undefined?0:buildArea?.industrialConstructionType?.rccArea)+
                        (buildArea?.industrialConstructionType?.rbcArea==undefined?0:buildArea?.industrialConstructionType?.rbcArea)+
                        (buildArea?.industrialConstructionType?.kacchaKabeluArea==undefined?0:buildArea?.industrialConstructionType?.kacchaKabeluArea)
                        +(buildArea?.industrialConstructionType?.tinShadeArea==undefined?
                        0:buildArea?.industrialConstructionType?.tinShadeArea)}}</td>
                    </ng-container>
                  </tr>

                  <tr
                    *ngFor="let buildArea of propertyValuationResponseBean.agriculturalWithConstruction.independentBuildBean">
                    <ng-container
                      *ngIf=" buildArea?.eduAreaConstructionType != undefined &&buildArea?.eduAreaConstructionType != null&&isObjectEmpty(buildArea?.eduAreaConstructionType)">
                      <td> {{
                        lan == 0
                        ? getFloorName(buildArea?.floorId)
                        : getFloorNameHi(buildArea?.floorId)
                        }}

                        <span class="badge badge-primary bg-primary"> {{'valuationproperty.Education' | translate}}
                        </span>
                      </td>
                      <td>{{buildArea?.eduAreaConstructionType?.rccArea}}</td>
                      <td>{{buildArea?.eduAreaConstructionType?.rbcArea}}</td>
                      <td>{{buildArea?.eduAreaConstructionType?.kacchaKabeluArea}}</td>
                      <td>{{buildArea?.eduAreaConstructionType?.tinShadeArea}}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <!-- <td>{{ buildArea?.eduAreaConstructionType?.totalTypeArea}}</td> -->
                      <td>
                        {{(buildArea?.industrialConstructionType?.rccArea==undefined?0:buildArea?.industrialConstructionType?.rccArea)+
                        (buildArea?.industrialConstructionType?.rbcArea==undefined?0:buildArea?.industrialConstructionType?.rbcArea)+
                        (buildArea?.industrialConstructionType?.kacchaKabeluArea==undefined?0:buildArea?.industrialConstructionType?.kacchaKabeluArea)
                        +(buildArea?.industrialConstructionType?.tinShadeArea==undefined?
                        0:buildArea?.industrialConstructionType?.tinShadeArea)}}</td>
                    </ng-container>
                  </tr>

                  <tr
                    *ngFor="let buildArea of propertyValuationResponseBean.agriculturalWithConstruction.independentBuildBean">
                    <ng-container
                      *ngIf=" buildArea?.healthAreaConstructionType != undefined &&buildArea?.healthAreaConstructionType != null&&isObjectEmpty(buildArea?.healthAreaConstructionType)">
                      <td> {{
                        lan == 0
                        ? getFloorName(buildArea?.floorId)
                        : getFloorNameHi(buildArea?.floorId)
                        }}

                        <span class="badge badge-primary bg-primary"> {{'valuationproperty.Health' | translate}} </span>

                      </td>
                      <td>{{buildArea?.healthAreaConstructionType?.rccArea}}</td>
                      <td>{{buildArea?.healthAreaConstructionType?.rbcArea}}</td>
                      <td>{{buildArea?.healthAreaConstructionType?.kacchaKabeluArea}}</td>
                      <td>{{buildArea?.healthAreaConstructionType?.tinShadeArea}}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <!-- <td>{{ buildArea?.healthAreaConstructionType?.totalTypeArea}}</td> -->
                      <td>
                        {{(buildArea?.healthAreaConstructionType?.rccArea==undefined?0:buildArea?.healthAreaConstructionType?.rccArea)+
                        (buildArea?.healthAreaConstructionType?.rbcArea==undefined?0:buildArea?.healthAreaConstructionType?.rbcArea)+
                        (buildArea?.healthAreaConstructionType?.kacchaKabeluArea==undefined?0:buildArea?.healthAreaConstructionType?.kacchaKabeluArea)
                        +(buildArea?.healthAreaConstructionType?.tinShadeArea==undefined?
                        0:buildArea?.healthAreaConstructionType?.tinShadeArea)}}</td>
                    </ng-container>
                  </tr>

                  <tr
                    *ngFor="let buildArea of propertyValuationResponseBean.agriculturalWithConstruction.independentBuildBean">
                    <ng-container
                      *ngIf=" buildArea?.otherAreaConstructionType != undefined &&buildArea?.otherAreaConstructionType != null&&isObjectEmpty(buildArea?.otherAreaConstructionType)">
                      <td> {{
                        lan == 0
                        ? getFloorName(buildArea?.floorId)
                        : getFloorNameHi(buildArea?.floorId)
                        }}
                        <span class="badge badge-primary bg-primary"> {{'valuationproperty.Other' | translate}} </span>
                      </td>
                      <td>{{buildArea?.otherAreaConstructionType?.rccArea}}</td>
                      <td>{{buildArea?.otherAreaConstructionType?.rbcArea}}</td>
                      <td>{{buildArea?.otherAreaConstructionType?.kacchaKabeluArea}}</td>
                      <td>{{buildArea?.otherAreaConstructionType?.tinShadeArea}}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <!-- <td>{{ buildArea?.otherAreaConstructionType?.totalTypeArea}}</td> -->
                      <td>
                        {{(buildArea?.otherAreaConstructionType?.rccArea==undefined?0:buildArea?.otherAreaConstructionType?.rccArea)+
                        (buildArea?.otherAreaConstructionType?.rbcArea==undefined?0:buildArea?.otherAreaConstructionType?.rbcArea)+
                        (buildArea?.otherAreaConstructionType?.kacchaKabeluArea==undefined?0:buildArea?.otherAreaConstructionType?.kacchaKabeluArea)
                        +(buildArea?.otherAreaConstructionType?.tinShadeArea==undefined?
                        0:buildArea?.otherAreaConstructionType?.tinShadeArea)}}</td>
                    </ng-container>
                  </tr>
                  <tr
                    *ngFor="let buildArea of propertyValuationResponseBean.agriculturalWithConstruction.independentBuildBean">
                    <ng-container
                      *ngIf=" buildArea?.resiCumCommType != undefined &&buildArea?.resiCumCommType != null&&isObjectEmpty(buildArea?.resiCumCommType)">
                      <td> {{
                        lan == 0
                        ? getFloorName(buildArea?.floorId)
                        : getFloorNameHi(buildArea?.floorId)
                        }}
                        <span class="badge badge-primary bg-primary"> {{'valuationproperty.Other' | translate}} </span>
                      </td>
                      <td>{{buildArea?.resiCumCommType?.rccArea}}</td>
                      <td>{{buildArea?.resiCumCommType?.rbcArea}}</td>
                      <td>{{buildArea?.resiCumCommType?.kacchaKabeluArea}}</td>
                      <td>{{buildArea?.resiCumCommType?.tinShadeArea}}</td>
                      <td>{{buildArea?.resiCumCommType?.shopArea}}</td>
                      <td>{{buildArea?.resiCumCommType?.officeArea}}</td>
                      <td>{{buildArea?.resiCumCommType?.godownArea}}</td>
                      <!-- <td>{{ buildArea?.resiCumCommType?.totalTypeArea}}</td> -->
                      <td>{{(buildArea?.resiCumCommType?.rccArea==undefined?0:buildArea?.resiCumCommType?.rccArea)+
                        (buildArea?.resiCumCommType?.rbcArea==undefined?0:buildArea?.resiCumCommType?.rbcArea)+
                        (buildArea?.resiCumCommType?.kacchaKabeluArea==undefined?0:buildArea?.resiCumCommType?.kacchaKabeluArea)
                        +(buildArea?.resiCumCommType?.tinShadeArea==undefined?
                        0:buildArea?.resiCumCommType?.tinShadeArea)
                        +(buildArea?.resiCumCommType?.shopArea==undefined?0:buildArea?.resiCumCommType?.shopArea)+
                        (buildArea?.resiCumCommType?.officeArea==undefined?0:buildArea?.resiCumCommType?.officeArea)+
                        (buildArea?.resiCumCommType?.godownArea==undefined?0:buildArea?.resiCumCommType?.godownArea)}}
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>


            <div class="table-responsive shadow-none"
              *ngIf="(propertyValuationResponseBean.agriculturalWithConstruction.independentBuildBean!=null||undefined)&&propertyValuationResponseBean.constOnAgriLand==true">
              <!-- <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th colspan="4">Construction Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Multistorey'">Build up Area ({{
                      lan==0 ? getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                      }})</th>
                    <td *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Multistorey'">{{propertyValuationResponseBean.buitupArea}}</td>
                    <th *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Multistorey'">Common Area ({{
                      lan==0 ? getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                      }})</th>
                    <td *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Multistorey'">{{propertyValuationResponseBean.agriculturalWithConstruction.commonArea}}</td>
                  </tr>
                  <tr>
                    <th *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.constrnTime!=null||undefined&&(propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Independent Building'||propertyValuationResponseBean.buildingSubType=='Multistorey')">Period of Construction Year</th>
                    <td *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.constrnTime!=null||undefined&&(propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Independent Building'||propertyValuationResponseBean.buildingSubType=='Multistorey')">{{propertyValuationResponseBean.agriculturalWithConstruction.constrnTime}}</td>
                    <th *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='IndependentFloor'||propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Multistorey'">Lift facility available in the building</th>
                    <td *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='IndependentFloor'||propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Multistorey'">{{propertyValuationResponseBean.agriculturalWithConstruction.liftFacility== false
                      ? lan==0 ? 'NO':'नहीं':lan==0 ?'Yes':'हां'}}</td>
                  </tr>
                </tbody>
              </table> -->
              <table class="table table-bordered"
                *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Independent Building'; else other">
                <thead class="thead-dark">
                  <tr>
                    <th colspan="4">{{'valuationproperty.ConstructionDetails' | translate}}</th>
                  </tr>
                </thead>
                <tbody>

                  <tr>
                    <th class="f-w500"
                      *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.constrnTime!=null||undefined&&(propertyValuationResponseBean.buildingSubType=='Independent Building'||propertyValuationResponseBean.buildingSubType=='Multistorey')">
                      {{'valuationproperty.PeriodofConstructionYear' | translate}}</th>
                    <td
                      *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.constrnTime!=null||undefined&&(propertyValuationResponseBean.buildingSubType=='Independent Building'||propertyValuationResponseBean.buildingSubType=='Multistorey')">
                      {{propertyValuationResponseBean.agriculturalWithConstruction.constrnTime}}</td>
                  </tr>

                </tbody>
              </table>
              <ng-template #other>
                <table class="table table-bordered">
                  <thead class="thead-dark">
                    <tr>
                      <th colspan="4">{{'valuationproperty.ConstructionDetails' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <th
                        *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Multistorey'">
                        {{'valuationproperty.BuildupArea' | translate}} ({{
                        lan==0 ?
                        getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                        }})</th>
                      <td
                        *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Multistorey'">
                        {{propertyValuationResponseBean.agriculturalWithConstruction.buitupArea}}</td>
                      <th
                        *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Multistorey'">
                        {{'valuationproperty.CommonArea' | translate}} ({{
                        lan==0 ?
                        getUnitMea(propertyValuationResponseBean.agriculturalWithConstruction.unit):getUnitMeaHi(propertyValuationResponseBean.agriculturalWithConstruction.unit)
                        }})</th>
                      <td
                        *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Multistorey'">
                        {{propertyValuationResponseBean.agriculturalWithConstruction.commonArea}}</td>
                    </tr>
                    <tr>
                      <th
                        *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.constrnTime!=null||undefined&&(propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Independent Building'||propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Multistorey')">
                        {{'valuationproperty.PeriodConstructionYear' | translate}}</th>
                      <td
                        *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.constrnTime!=null||undefined&&(propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Independent Building'||propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Multistorey')">
                        {{propertyValuationResponseBean.agriculturalWithConstruction.constrnTime}}</td>
                      <th
                        *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='IndependentFloor'||propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Multistorey'">
                        {{'valuationproperty.Liftfacilityavailablebuilding' | translate}}</th>
                      <td
                        *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='IndependentFloor'||propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Multistorey'">
                        {{propertyValuationResponseBean.agriculturalWithConstruction.liftFacility== false
                        ? lan==0 ? 'NO':'नहीं':lan==0 ?'Yes':'हां'}}</td>
                      <th
                        *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='IndependentFloor'||propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Multistorey'">
                        {{'valuationproperty.ConveyanceAreaforopenTerrace' | translate}}</th>
                      <td
                        *ngIf="propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='IndependentFloor'||propertyValuationResponseBean.agriculturalWithConstruction.buildingSubType=='Multistorey'">
                        {{propertyValuationResponseBean.agriculturalWithConstruction.transactOpenTerrace== false
                        ? lan==0 ? 'NO':'नहीं':lan==0 ?'Yes':'हां'}}</td>
                    </tr>
                  </tbody>
                </table>
              </ng-template>
            </div>
            <!--Agriculture building end-->

          </fieldset>
        </div>
      </div>

      <div class="row mb-2" *ngIf="propertyValuationResponseBean.propertyType.id==3">
        <div class="col-md-12">
          <fieldset class="legend-section mt-2 legend-section2">
            <legend class="sidebar__widget-title">{{'valuationproperty.SellerBuyerDetails' | translate}}</legend>
            <div class="table-responsive shadow-none">
              <table class="table table-bordered">
                <tr>

                  <th class="bg-light" colspan="2">{{getPartyName(lan,1)}}</th>
                </tr>
                <tr>
                  <td>{{'valuationproperty.MorethanoneSellee' | translate}}</td>
                  <td>{{propertyValuationResponseBean.isMoreSellers == false
                    ? lan==0 ? 'No':'नहीं':lan==0 ?'Yes':'हां'}}</td>
                </tr>
                <tr *ngIf="propertyValuationResponseBean.isMoreSellers">
                  <td>{{'valuationproperty.AllSellersOwnersareareCo-owner' | translate}}</td>
                  <td>{{propertyValuationResponseBean.isCoOwner == false
                    ? lan==0 ? 'No':'नहीं':lan==0 ?'Yes':'हां'}}</td>
                </tr>
                <tr *ngIf="propertyValuationResponseBean.isMoreSellers&&!propertyValuationResponseBean.isCoOwner">
                  <td>{{'valuationproperty.AllSellersOwnersbelongtFamily' | translate}}</td>
                  <td>{{propertyValuationResponseBean.isOwnerSameFamily == false
                    ? lan==0 ? 'No':'नहीं':lan==0 ?'Yes':'हां'}}</td>
                </tr>
                <tr *ngIf="propertyValuationResponseBean.isMoreSellers">
                  <td>{{'valuationproperty.TotalNumberofSellersOwners' | translate}}</td>
                  <td>{{ propertyValuationResponseBean.noOfSellers }}</td>
                </tr>
              </table>
              <table class="table table-bordered">
                <tr>
                  <th class="bg-light" colspan="2">{{getPartyName(lan,2)}}</th>
                </tr>
                <tr>
                  <td>{{'valuationproperty.MorethanoneBuyer' | translate}}</td>
                  <td> {{
                    propertyValuationResponseBean.isMoreBuyers == false
                    ? lan==0 ? 'No':'नहीं':lan==0 ?'Yes':'हां'
                    }}</td>
                </tr>
                <tr *ngIf="propertyValuationResponseBean.isMoreBuyers">
                  <td>{{'valuationproperty.AllBuyersbelongFamily' | translate}}</td>
                  <td>
                    {{
                    propertyValuationResponseBean.isBuyerSameFamily == false
                    ? lan==0 ? 'No':'नहीं':lan==0 ?'Yes':'हां'
                    }}
                  </td>
                </tr>
                <tr *ngIf="propertyValuationResponseBean.isMoreBuyers">
                  <td>{{'valuationproperty.TotalNumberBuyer' | translate}}</td>
                  <td>{{ propertyValuationResponseBean.noOfBuyers }}</td>
                </tr>
              </table>
            </div>
          </fieldset>
        </div>
      </div>

      <!-------------------------------------------------------------
   -------------------------------------------------------------
   ------------------------end Agriculture Land --------------
   -------------------------------------------------------------
  -------------------------------------------------------------->

      <!-------------------------------------------------------------
   -------------------------------------------------------------
   ------------------------ Building --------------
   -------------------------------------------------------------
  -------------------------------------------------------------->

      <div class="row mb-2" *ngIf="propertyValuationResponseBean.propertyType.id==2">
        <div class="col-md-12">
          <fieldset class="legend-section mt-2 legend-section2">
            <legend class="sidebar__widget-title">
              {{"ereg-property-details.propertyDetails" | translate}}
            </legend>

            <div class="table-responsive shadow-none">
              <table class="table table-bordered">
                <tr>
                  <th class="f-w500">{{'valuationproperty.Transactingpartialproperty' | translate}}</th>
                  <td colspan="3">{{
                    propertyValuationResponseBean.isPartialTransact == false
                    ? lan==0 ? 'NO':'नहीं':lan==0 ?'Yes':'हां'
                    }}</td>

                </tr>
                <tr>
                  <th class="f-w500">{{'valuationproperty.PropertyType' | translate}}</th>
                  <td>{{lan==0 ? propertyValuationResponseBean.propertyType.nameEn
                    :propertyValuationResponseBean.propertyType.nameHi}}</td>
                  <th class="f-w500">{{'valuationproperty.BuildingType' | translate}}</th>
                  <td *ngIf="propertyValuationResponseBean.buildingSubType=='Independent Building'">
                    {{lan==0?propertyValuationResponseBean.buildingSubType:'स्वतंत्र भवन'}}</td>
                  <td *ngIf="propertyValuationResponseBean.buildingSubType=='IndependentFloor'">
                    {{lan==0?propertyValuationResponseBean.buildingSubType:'स्वतंत्र तल'}}</td>
                  <td *ngIf="propertyValuationResponseBean.buildingSubType=='Multistorey'">
                    {{lan==0?propertyValuationResponseBean.buildingSubType:'बहुमंजिला'}}</td>
                  <td *ngIf="propertyValuationResponseBean.buildingSubType=='Open Terrace'">
                    {{lan==0?propertyValuationResponseBean.buildingSubType:'खुली छत'}}</td>
                </tr>
                <tr>
                  <th class="f-w500"> {{'valuationproperty.PropertyTotalArea' | translate}} ({{
                    lan==0 ?
                    getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                    }})</th>
                  <td>
                    {{propertyValuationResponseBean.overAllArea==null||undefined?propertyValuationResponseBean.totalArea:propertyValuationResponseBean.overAllArea}}
                  </td>
                  <th class="f-w500">{{'valuationproperty.PropertyTotalTransactingArea' | translate}}({{
                    lan==0 ?
                    getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                    }})</th>
                  <td>{{propertyValuationResponseBean.partialTransactArea!=null
                    ?propertyValuationResponseBean.transactArea:propertyValuationResponseBean.totalArea}}</td>
                </tr>
                <ng-container *ngIf="propertyValuationResponseBean.buildingSubType=='Independent Building'">
                  <tr>
                    <th class="f-w500">{{'valuationproperty.ResidentialCumCommercial' | translate}}</th>
                    <td colspan="3">{{propertyValuationResponseBean.isResidentialCumCommercial==false? lan==0 ?
                      'NO':'नहीं':lan==0 ?'Yes':'हां'}}</td>
                  </tr>

                  <tr *ngIf="propertyValuationResponseBean.isResidentialCumCommercial; else isResicom">
                    <th class="f-w500"
                      *ngIf="propertyValuationResponseBean.residentialCumCommercialPlotArea!=null||undefined">
                      {{'valuationproperty.ResidentialCumCommercial' | translate}}</th>
                    <td colspan="3"
                      *ngIf="propertyValuationResponseBean.residentialCumCommercialPlotArea!=null||undefined">
                      {{propertyValuationResponseBean.residentialCumCommercialPlotArea}}</td>
                  </tr>
                  <ng-template #isResicom>
                    <tr>
                      <th class="f-w500" *ngIf="propertyValuationResponseBean.residentialPlotArea!=null||undefined">
                        {{'valuationproperty.ResidentialArea' | translate}} ({{
                        lan==0 ?
                        getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                        }})</th>
                      <td *ngIf="propertyValuationResponseBean.residentialPlotArea!=null||undefined">
                        {{propertyValuationResponseBean.residentialPlotArea}}</td>
                      <th class="f-w500" *ngIf="propertyValuationResponseBean.healthPlotArea!=null||undefined">
                        {{'valuationproperty.HealthArea' | translate}} ({{
                        lan==0 ?
                        getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                        }})</th>
                      <td *ngIf="propertyValuationResponseBean.healthPlotArea!=null||undefined">
                        {{propertyValuationResponseBean.healthPlotArea}}</td>
                    </tr>
                    <tr>
                      <th class="f-w500" *ngIf="propertyValuationResponseBean.commercialPlotArea!=null||undefined">
                        {{'valuationproperty.CommercialArea' | translate}} ({{
                        lan==0 ?
                        getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                        }})</th>
                      <td *ngIf="propertyValuationResponseBean.commercialPlotArea!=null||undefined">
                        {{propertyValuationResponseBean.commercialPlotArea}}</td>
                      <th class="f-w500" *ngIf="propertyValuationResponseBean.eduPlotArea!=null||undefined">
                        {{'valuationproperty.EducationArea' | translate}} ({{
                        lan==0 ?
                        getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                        }})</th>
                      <td *ngIf="propertyValuationResponseBean.eduPlotArea!=null||undefined">
                        {{propertyValuationResponseBean.eduPlotArea}}</td>
                    </tr>
                    <tr>
                      <th class="f-w500" *ngIf="propertyValuationResponseBean.industrialPlotArea!=null||undefined">
                        {{'valuationproperty.IndustrialArea' | translate}} ({{
                        lan==0 ?
                        getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                        }})</th>
                      <td *ngIf="propertyValuationResponseBean.industrialPlotArea!=null||undefined">
                        {{propertyValuationResponseBean.industrialPlotArea}}</td>
                      <th class="f-w500" *ngIf="propertyValuationResponseBean.othersPlotArea!=null||undefined">
                        {{'valuationproperty.Other' | translate}}({{
                        lan==0 ?
                        getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                        }})</th>
                      <td *ngIf="propertyValuationResponseBean.othersPlotArea!=null||undefined">
                        {{propertyValuationResponseBean.othersPlotArea}}
                      </td>
                    </tr>
                  </ng-template>
                </ng-container>
              </table>
            </div>

            <div class="table-responsive shadow-none"
              *ngIf="propertyValuationResponseBean.buildingSubType=='Independent Building'">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th>{{'valuationproperty.FloorType' | translate}}</th>
                    <th>
                      {{'valuationproperty.RCCArea' | translate}} <br />
                      ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                      }})
                    </th>
                    <th>
                      {{'valuationproperty.RBCArea' | translate}} <br />
                      ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                      }})
                    </th>
                    <th>
                      {{'valuationproperty.KachhaKabeluArea' | translate}}<br />
                      ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                      }})
                    </th>
                    <th>
                      {{'valuationproperty.TinShadeArea' | translate}} <br />
                      ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                      }})
                    </th>
                    <th>
                      {{'valuationproperty.ShopArea' | translate}} <br />
                      ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                      }})
                    </th>
                    <th>
                      {{'valuationproperty.OfficeArea' | translate}} <br />
                      ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                      }})
                    </th>
                    <th>
                      {{'valuationproperty.GodownArea' | translate}} <br />
                      ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                      }})
                    </th>
                    <th>
                      {{'valuationproperty.TotalArea' | translate}} <br />
                      ({{
                      lan==0 ?
                      getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                      }})
                    </th>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor="
                  let buildArea of propertyValuationResponseBean.independentBuildTypeBeanList">
                    <ng-container
                      *ngIf=" buildArea?.resiConstType != undefined &&buildArea?.resiConstType != null&&isObjectEmpty(buildArea?.resiConstType)">
                      <td> {{
                        lan == 0
                        ? getFloorName(buildArea?.floorId)
                        : getFloorNameHi(buildArea?.floorId)
                        }}
                        <span class="badge badge-primary bg-primary">{{'valuationproperty.Residential' |
                          translate}}</span>


                      </td>
                      <td>{{ buildArea?.resiConstType?.rccArea }}</td>
                      <td>{{ buildArea?.resiConstType?.rbcArea }}</td>
                      <td> {{ buildArea?.resiConstType?.kacchaKabeluArea }}</td>
                      <td> {{ buildArea?.resiConstType?.tinShadeArea }}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <!-- <td>{{buildArea?.resiConstType?.totalTypeArea}}</td> -->
                      <td>{{(buildArea?.resiConstType?.rccArea==undefined?0:buildArea?.resiConstType?.rccArea)+
                        (buildArea?.resiConstType?.rbcArea==undefined?0:buildArea?.resiConstType?.rbcArea)+
                        (buildArea?.resiConstType?.kacchaKabeluArea==undefined?0:buildArea?.resiConstType?.kacchaKabeluArea)
                        +(buildArea?.resiConstType?.tinShadeArea==undefined? 0:buildArea?.resiConstType?.tinShadeArea)}}
                      </td>
                    </ng-container>
                  </tr>

                  <tr *ngFor="let buildArea of propertyValuationResponseBean.independentBuildTypeBeanList">
                    <ng-container
                      *ngIf=" buildArea?.commConstType != undefined &&buildArea?.commConstType != null&&isObjectEmpty(buildArea?.commConstType)">
                      <td> {{
                        lan == 0
                        ? getFloorName(buildArea?.floorId)
                        : getFloorNameHi(buildArea?.floorId)
                        }}
                        <span class="badge badge-primary bg-primary">{{'valuationproperty.Commercial' |
                          translate}}</span>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{{buildArea?.commConstType?.shopArea}}</td>
                      <td>{{buildArea?.commConstType?.officeArea}}</td>
                      <td>{{buildArea?.commConstType?.godownArea}}</td>
                      <!-- <td>{{ buildArea?.commConstType?.totalTypeArea}}</td> -->
                      <td>{{(buildArea?.commConstType?.shopArea==undefined?0:buildArea?.commConstType?.shopArea)+
                        (buildArea?.commConstType?.officeArea==undefined?0:buildArea?.commConstType?.officeArea)+
                        (buildArea?.commConstType?.godownArea==undefined?0:buildArea?.commConstType?.godownArea)
                        }}</td>
                    </ng-container>
                  </tr>

                  <tr *ngFor="let buildArea of propertyValuationResponseBean.independentBuildTypeBeanList">
                    <ng-container
                      *ngIf=" buildArea?.industrialConstructionType != undefined &&buildArea?.industrialConstructionType != null&&isObjectEmpty(buildArea?.industrialConstructionType)">
                      <td> {{
                        lan == 0
                        ? getFloorName(buildArea?.floorId)
                        : getFloorNameHi(buildArea?.floorId)
                        }}
                        <span class="badge badge-primary bg-primary">{{'valuationproperty.Industrial' |
                          translate}}</span>
                      </td>
                      <td>{{buildArea?.industrialConstructionType?.rccArea}}</td>
                      <td>{{buildArea?.industrialConstructionType?.rbcArea}}</td>
                      <td>{{buildArea?.industrialConstructionType?.kacchaKabeluArea}}</td>
                      <td>{{buildArea?.industrialConstructionType?.tinShadeArea}}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <!-- <td>{{ buildArea?.industrialConstructionType?.totalTypeArea}}</td> -->
                      <td>
                        {{(buildArea?.industrialConstructionType?.rccArea==undefined?0:buildArea?.industrialConstructionType?.rccArea)+
                        (buildArea?.industrialConstructionType?.rbcArea==undefined?0:buildArea?.industrialConstructionType?.rbcArea)+
                        (buildArea?.industrialConstructionType?.kacchaKabeluArea==undefined?0:buildArea?.industrialConstructionType?.kacchaKabeluArea)
                        +(buildArea?.industrialConstructionType?.tinShadeArea==undefined?
                        0:buildArea?.industrialConstructionType?.tinShadeArea)}}</td>
                    </ng-container>
                  </tr>

                  <tr *ngFor="let buildArea of propertyValuationResponseBean.independentBuildTypeBeanList">
                    <ng-container
                      *ngIf=" buildArea?.eduAreaConstructionType != undefined &&buildArea?.eduAreaConstructionType != null&&isObjectEmpty(buildArea?.eduAreaConstructionType)">
                      <td> {{
                        lan == 0
                        ? getFloorName(buildArea?.floorId)
                        : getFloorNameHi(buildArea?.floorId)
                        }}
                        <span class="badge badge-primary bg-primary">{{'valuationproperty.Education' |
                          translate}}</span>
                      </td>
                      <td>{{buildArea?.eduAreaConstructionType?.rccArea}}</td>
                      <td>{{buildArea?.eduAreaConstructionType?.rbcArea}}</td>
                      <td>{{buildArea?.eduAreaConstructionType?.kacchaKabeluArea}}</td>
                      <td>{{buildArea?.eduAreaConstructionType?.tinShadeArea}}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <!-- <td>{{ buildArea?.eduAreaConstructionType?.totalTypeArea}}</td> -->
                      <td>
                        {{(buildArea?.eduAreaConstructionType?.rccArea==undefined?0:buildArea?.eduAreaConstructionType?.rccArea)+
                        (buildArea?.eduAreaConstructionType?.rbcArea==undefined?0:buildArea?.eduAreaConstructionType?.rbcArea)+
                        (buildArea?.eduAreaConstructionType?.kacchaKabeluArea==undefined?0:buildArea?.eduAreaConstructionType?.kacchaKabeluArea)
                        +(buildArea?.eduAreaConstructionType?.tinShadeArea==undefined?
                        0:buildArea?.eduAreaConstructionType?.tinShadeArea)}}</td>
                    </ng-container>
                  </tr>

                  <tr *ngFor="let buildArea of propertyValuationResponseBean.independentBuildTypeBeanList">
                    <ng-container
                      *ngIf=" buildArea?.healthAreaConstructionType != undefined &&buildArea?.healthAreaConstructionType != null&&isObjectEmpty(buildArea?.healthAreaConstructionType)">
                      <td> {{
                        lan == 0
                        ? getFloorName(buildArea?.floorId)
                        : getFloorNameHi(buildArea?.floorId)
                        }}
                        <span class="badge badge-primary bg-primary">{{'valuationproperty.Health' | translate}}</span>
                      </td>
                      <td>{{buildArea?.healthAreaConstructionType?.rccArea}}</td>
                      <td>{{buildArea?.healthAreaConstructionType?.rbcArea}}</td>
                      <td>{{buildArea?.healthAreaConstructionType?.kacchaKabeluArea}}</td>
                      <td>{{buildArea?.healthAreaConstructionType?.tinShadeArea}}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <!-- <td>{{ buildArea?.healthAreaConstructionType?.totalTypeArea}}</td> -->
                      <td>
                        {{(buildArea?.healthAreaConstructionType?.rccArea==undefined?0:buildArea?.healthAreaConstructionType?.rccArea)+
                        (buildArea?.healthAreaConstructionType?.rbcArea==undefined?0:buildArea?.healthAreaConstructionType?.rbcArea)+
                        (buildArea?.healthAreaConstructionType?.kacchaKabeluArea==undefined?0:buildArea?.healthAreaConstructionType?.kacchaKabeluArea)
                        +(buildArea?.healthAreaConstructionType?.tinShadeArea==undefined?
                        0:buildArea?.healthAreaConstructionType?.tinShadeArea)}}</td>
                    </ng-container>
                  </tr>

                  <tr *ngFor="let buildArea of propertyValuationResponseBean.independentBuildTypeBeanList">
                    <ng-container
                      *ngIf=" buildArea?.otherAreaConstructionType != undefined &&buildArea?.otherAreaConstructionType != null&&isObjectEmpty(buildArea?.otherAreaConstructionType)">
                      <td> {{
                        lan == 0
                        ? getFloorName(buildArea?.floorId)
                        : getFloorNameHi(buildArea?.floorId)
                        }}
                        <span class="badge badge-primary bg-primary">{{'valuationproperty.Other' | translate}}</span>
                      </td>
                      <td>{{buildArea?.otherAreaConstructionType?.rccArea}}</td>
                      <td>{{buildArea?.otherAreaConstructionType?.rbcArea}}</td>
                      <td>{{buildArea?.otherAreaConstructionType?.kacchaKabeluArea}}</td>
                      <td>{{buildArea?.otherAreaConstructionType?.tinShadeArea}}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <!-- <td>{{ buildArea?.otherAreaConstructionType?.totalTypeArea}}</td> -->
                      <td>
                        {{(buildArea?.otherAreaConstructionType?.rccArea==undefined?0:buildArea?.otherAreaConstructionType?.rccArea)+
                        (buildArea?.otherAreaConstructionType?.rbcArea==undefined?0:buildArea?.otherAreaConstructionType?.rbcArea)+
                        (buildArea?.otherAreaConstructionType?.kacchaKabeluArea==undefined?0:buildArea?.otherAreaConstructionType?.kacchaKabeluArea)
                        +(buildArea?.otherAreaConstructionType?.tinShadeArea==undefined?
                        0:buildArea?.otherAreaConstructionType?.tinShadeArea)}}</td>
                    </ng-container>
                  </tr>
                  <tr *ngFor="let buildArea of propertyValuationResponseBean.independentBuildTypeBeanList">
                    <ng-container
                      *ngIf=" buildArea?.resiCumCommType != undefined &&buildArea?.resiCumCommType != null&&isObjectEmpty(buildArea?.resiCumCommType)">
                      <td> {{
                        lan == 0
                        ? getFloorName(buildArea?.floorId)
                        : getFloorNameHi(buildArea?.floorId)
                        }}
                        <span class="badge badge-primary bg-primary"> {{'valuationproperty.resiCumComm' | translate}}
                        </span>
                      </td>
                      <td>{{buildArea?.resiCumCommType?.rccArea}}</td>
                      <td>{{buildArea?.resiCumCommType?.rbcArea}}</td>
                      <td>{{buildArea?.resiCumCommType?.kacchaKabeluArea}}</td>
                      <td>{{buildArea?.resiCumCommType?.tinShadeArea}}</td>
                      <td>{{buildArea?.resiCumCommType?.shopArea}}</td>
                      <td>{{buildArea?.resiCumCommType?.officeArea}}</td>
                      <td>{{buildArea?.resiCumCommType?.godownArea}}</td>
                      <!-- <td>{{ buildArea?.resiCumCommType?.totalTypeArea}}</td> -->
                      <td>{{(buildArea?.resiCumCommType?.rccArea==undefined?0:buildArea?.resiCumCommType?.rccArea)+
                        (buildArea?.resiCumCommType?.rbcArea==undefined?0:buildArea?.resiCumCommType?.rbcArea)+
                        (buildArea?.resiCumCommType?.kacchaKabeluArea==undefined?0:buildArea?.resiCumCommType?.kacchaKabeluArea)
                        +(buildArea?.resiCumCommType?.tinShadeArea==undefined?
                        0:buildArea?.resiCumCommType?.tinShadeArea)
                        +(buildArea?.resiCumCommType?.shopArea==undefined?0:buildArea?.resiCumCommType?.shopArea)+
                        (buildArea?.resiCumCommType?.officeArea==undefined?0:buildArea?.resiCumCommType?.officeArea)+
                        (buildArea?.resiCumCommType?.godownArea==undefined?0:buildArea?.resiCumCommType?.godownArea)}}
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="table-responsive shadow-none">
              <table class="table table-bordered"
                *ngIf="propertyValuationResponseBean.buildingSubType=='Independent Building'; else other">
                <thead class="thead-dark">
                  <tr *ngIf="propertyValuationResponseBean.constrnTime!=null||undefined">
                    <th colspan="4">{{'valuationproperty.ConstructionDetails' | translate}}</th>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngIf="propertyValuationResponseBean.constrnTime!=null||undefined">
                    <th
                      *ngIf="propertyValuationResponseBean.constrnTime!=null||undefined&&(propertyValuationResponseBean.buildingSubType=='Independent Building'||propertyValuationResponseBean.buildingSubType=='Multistorey')">
                      {{'valuationproperty.PeriodConstructionYear' | translate}}</th>
                    <td
                      *ngIf="propertyValuationResponseBean.constrnTime!=null||undefined&&(propertyValuationResponseBean.buildingSubType=='Independent Building'||propertyValuationResponseBean.buildingSubType=='Multistorey')">
                      {{propertyValuationResponseBean.constrnTime}}</td>
                  </tr>

                </tbody>
              </table>
              <ng-template #other>
                <table class="table table-bordered">
                  <thead class="thead-dark">
                    <tr>
                      <th colspan="4">{{'valuationproperty.ConstructionDetails' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th *ngIf="propertyValuationResponseBean.buildingSubType=='Multistorey'">
                        {{'valuationproperty.BuildupArea' | translate}} ({{
                        lan==0 ?
                        getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                        }})</th>
                      <td>{{propertyValuationResponseBean.buitupArea}}</td>
                      <th *ngIf="propertyValuationResponseBean.buildingSubType=='Multistorey'">
                        {{'valuationproperty.CommonArea' | translate}} ({{
                        lan==0 ?
                        getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)
                        }})</th>
                      <td *ngIf="propertyValuationResponseBean.buildingSubType=='Multistorey'">
                        {{propertyValuationResponseBean.commonArea}}</td>
                    </tr>
                    <tr>
                      <th
                        *ngIf="propertyValuationResponseBean.constrnTime!=null||undefined&&(propertyValuationResponseBean.buildingSubType=='Independent Building'||propertyValuationResponseBean.buildingSubType=='Multistorey')">
                        {{'valuationproperty.PeriodConstructionYear' | translate}}</th>
                      <td
                        *ngIf="propertyValuationResponseBean.constrnTime!=null||undefined&&(propertyValuationResponseBean.buildingSubType=='Independent Building'||propertyValuationResponseBean.buildingSubType=='Multistorey')">
                        {{propertyValuationResponseBean.constrnTime}}</td>
                      <th
                        *ngIf="propertyValuationResponseBean.buildingSubType=='IndependentFloor'||propertyValuationResponseBean.buildingSubType=='Multistorey'">
                        {{'valuationproperty.Liftfacilityavailablebuilding' | translate}}</th>
                      <td
                        *ngIf="propertyValuationResponseBean.buildingSubType=='IndependentFloor'||propertyValuationResponseBean.buildingSubType=='Multistorey'">
                        {{propertyValuationResponseBean.liftFacility== false
                        ? lan==0 ? 'NO':'नहीं':lan==0 ?'Yes':'हां'}}</td>
                    </tr>
                    <tr>
                      <th
                        *ngIf="propertyValuationResponseBean.buildingSubType=='Multistorey'||propertyValuationResponseBean.buildingSubType=='Open Terrace'||propertyValuationResponseBean.buildingSubType=='IndependentFloor'">
                        {{'valuationproperty.ConveyanceTypeForopenTerrace' | translate}}</th>
                      <td
                        *ngIf="propertyValuationResponseBean.buildingSubType=='Multistorey'||propertyValuationResponseBean.buildingSubType=='Open Terrace'||propertyValuationResponseBean.buildingSubType=='IndependentFloor'">
                        {{lan==0?propertyValuationResponseBean.conveyanceType:propertyValuationResponseBean.conveyanceType=='Residential'?'आवासीय':'व्यावसायिक'}}
                      </td>
                      <th
                        *ngIf="propertyValuationResponseBean.buildingSubType=='Multistorey'||propertyValuationResponseBean.buildingSubType=='IndependentFloor'">
                        {{'valuationproperty.ConveyanceAreaforopenTerrace' | translate}}</th>
                      <td
                        *ngIf="propertyValuationResponseBean.buildingSubType=='Multistorey'||propertyValuationResponseBean.buildingSubType=='IndependentFloor'">
                        {{propertyValuationResponseBean.conveyanceArea}}</td>

                    </tr>
                  </tbody>
                </table>
              </ng-template>
            </div>
          </fieldset>
        </div>
      </div>

      <!-------------------------------------------------------------
   -------------------------------------------------------------
   ------------------------ end Building --------------
   -------------------------------------------------------------
  -------------------------------------------------------------->


      <!-------------------------------------------------------------
   -------------------------------------------------------------
   ------------------------ Plot --------------
   -------------------------------------------------------------
  -------------------------------------------------------------->

      <div class="row mb-2" *ngIf="propertyValuationResponseBean.propertyType.id==1">
        <div class="col-md-12">
          <fieldset class="legend-section mt-2 legend-section2">
            <legend class="sidebar__widget-title">
              {{"ereg-property-details.propertyDetails" | translate}}
            </legend>

            <div class="table-responsive shadow-none">
              <table class="table table-bordered">
                <tr>
                  <th class="f-w500">{{'valuationproperty.Transactingpartialproperty' | translate}}</th>
                  <td colspan="3">{{
                    propertyValuationResponseBean.isPartialTransact == false
                    ? lan==0 ? 'NO':'नहीं':lan==0 ?'Yes':'हां'
                    }}</td>

                </tr>
                <tr>
                  <th class="f-w500">{{'valuationproperty.PropertyType' | translate}}</th>
                  <td>{{
                    lan==0 ? propertyValuationResponseBean.propertyType.nameEn
                    :propertyValuationResponseBean.propertyType.nameHi
                    }}</td>
                  <th class="f-w500"> </th>
                  <td> </td>
                </tr>
                <tr>
                  <th class="f-w500"> {{'valuationproperty.PropertyTotalArea' | translate}} ({{lan==0 ?
                    getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)}})
                  </th>
                  <td>
                    {{propertyValuationResponseBean.overAllArea==null||undefined?propertyValuationResponseBean.totalArea:propertyValuationResponseBean.overAllArea}}
                  </td>
                  <th class="f-w500">{{'valuationproperty.PropertyTotalTransactingArea' | translate}} ({{lan==0 ?
                    getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)}})
                  </th>
                  <td>{{propertyValuationResponseBean.isPartialTransact
                    ?propertyValuationResponseBean.transactArea:propertyValuationResponseBean.totalArea}}</td>
                </tr>
                <tr>
                  <!-- <th class="f-w500"  *ngIf="propertyValuationResponseBean.unit=='SQFT'">Property Total Area  ({{lan==0 ? getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)}})</th>
                  <td  *ngIf="propertyValuationResponseBean.unit=='SQFT'">{{propertyValuationResponseBean.overAllArea==null||undefined?propertyValuationResponseBean.totalArea:propertyValuationResponseBean.overAllArea}}</td>
                  <th class="f-w500" *ngIf="propertyValuationResponseBean.unit=='SQFT'">Property Total Transacting Area ({{ lan==0 ? getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)}})</th>
                  <td colspan="3"  *ngIf="propertyValuationResponseBean.unit=='SQFT'">{{propertyValuationResponseBean.partialTransact ?propertyValuationResponseBean.transactArea:propertyValuationResponseBean.totalArea}}</td>
                </tr> -->
                <tr>
                  <th class="f-w500">{{'valuationproperty.ResidentialCumCommercial' | translate}} </th>
                  <td colspan="3">{{propertyValuationResponseBean.isResidentialCumCommercial==false? lan==0 ?
                    'NO':'नहीं':lan==0 ?'Yes':'हां'}}</td>
                </tr>


                <tr *ngIf="propertyValuationResponseBean.isResidentialCumCommercial; else isResicom">
                  <th *ngIf="propertyValuationResponseBean.residentialCumCommercialPlotArea!=null||undefined">
                    {{'valuationproperty.ResidentialCumCommercialArea' | translate}} ({{ lan==0 ?
                    getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)}})
                  </th>
                  <td colspan="3"
                    *ngIf="propertyValuationResponseBean.residentialCumCommercialPlotArea!=null||undefined">
                    {{propertyValuationResponseBean.residentialCumCommercialPlotArea}}</td>
                </tr>
                <ng-template #isResicom>
                  <tr>
                    <th class="f-w500" *ngIf="propertyValuationResponseBean.residentialPlotArea!=null||undefined">
                      {{'valuationproperty.ResidentialArea' | translate}} ({{ lan==0 ?
                      getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)}})
                    </th>
                    <td *ngIf="propertyValuationResponseBean.residentialPlotArea!=null||undefined">
                      {{propertyValuationResponseBean.residentialPlotArea}}</td>
                    <th class="f-w500" *ngIf="propertyValuationResponseBean.healthPlotArea!=null||undefined">
                      {{'valuationproperty.HealthArea' | translate}} ({{ lan==0 ?
                      getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)}})
                    </th>
                    <td *ngIf="propertyValuationResponseBean.healthPlotArea!=null||undefined">
                      {{propertyValuationResponseBean.healthPlotArea }}</td>
                  </tr>
                  <tr>
                    <th class="f-w500" *ngIf="propertyValuationResponseBean.commercialPlotArea!=null||undefined">
                      {{'valuationproperty.CommercialArea' | translate}} ({{ lan==0 ?
                      getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)}})
                    </th>
                    <td *ngIf="propertyValuationResponseBean.commercialPlotArea!=null||undefined">
                      {{propertyValuationResponseBean.commercialPlotArea}}</td>
                    <th class="f-w500" *ngIf="propertyValuationResponseBean.eduPlotArea!=null||undefined">
                      {{'valuationproperty.EducationArea' | translate}} ({{ lan==0 ?
                      getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)}})
                    </th>
                    <td *ngIf="propertyValuationResponseBean.eduPlotArea!=null||undefined">
                      {{propertyValuationResponseBean.eduPlotArea}}</td>
                  </tr>
                  <tr>
                    <th class="f-w500" *ngIf="propertyValuationResponseBean.industrialPlotArea!=null||undefined">
                      {{'valuationproperty.IndustrialArea' | translate}} ({{ lan==0 ?
                      getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)}})
                    </th>
                    <td *ngIf="propertyValuationResponseBean.industrialPlotArea!=null||undefined">
                      {{propertyValuationResponseBean.industrialPlotArea}}</td>

                  </tr>
                </ng-template>

              </table>
            </div>

          </fieldset>
        </div>
      </div>

      <!-------------------------------------------------------------
   -------------------------------------------------------------
   ------------------------ end Plot --------------
   -------------------------------------------------------------
  -------------------------------------------------------------->



      <div class="row mb-2" *ngIf="propertyValuationResponseBean.propertyType.id==1">
        <div class="col-md-12">
          <fieldset class="legend-section mt-2 legend-section2">
            <legend class="sidebar__widget-title">{{'valuationproperty.PropertyValuation' | translate}}</legend>
            <div class="table-responsive shadow-none">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th>{{'valuationproperty.ApplicableGuidelineRate' | translate}}</th>
                    <th>{{'valuationproperty.BasicGuidelineRate' | translate}} ({{lan==0?'per ':'प्रति
                      '}}{{lan==0?getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)}})
                    </th>
                    <th>{{'valuationproperty.GuidelineValue' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="propertyValuationResponseBean.isResidentialCumCommercial; else isResicomp">
                    <td>{{'valuationproperty.ResidentialCumCommercialRate' | translate}}</td>
                    <td>{{getPriceConversion(propertyValuationResponseBean.residentialCumCommercialPlotRate) | amount}}
                    </td>
                    <td class="text-right">
                      {{getPriceConversion(propertyValuationResponseBean.residentialCumCommercialPlot)|amount}}</td>
                  </tr>

                  <ng-template #isResicomp>
                    <tr *ngIf="propertyValuationResponseBean.residentialPlot!=0">
                      <td>{{'valuationproperty.ResidentialPlotRate' | translate}}</td>
                      <td>{{getPriceConversion(propertyValuationResponseBean.residentialPlotRate)|amount}}</td>
                      <td class="text-right">
                        {{getPriceConversion(propertyValuationResponseBean.residentialPlot)|amount}}</td>
                    </tr>


                    <!----------start Plot Case----------->
                    <tr *ngIf="propertyValuationResponseBean.commercialPlot!=0">
                      <td>{{'valuationproperty.CommercialPlotRate' | translate}}</td>
                      <td>{{getPriceConversion(propertyValuationResponseBean.commercialPlotRate) | amount}}</td>
                      <td class="text-right">{{getPriceConversion(propertyValuationResponseBean.commercialPlot)|amount}}
                      </td>
                    </tr>
                    <tr *ngIf="propertyValuationResponseBean.industrialPlot!=0">
                      <td>{{'valuationproperty.IndustrialPlotRate' | translate}}</td>
                      <td>{{getPriceConversion(propertyValuationResponseBean.industrialPlotRate) | amount}}</td>
                      <td class="text-right">{{getPriceConversion(propertyValuationResponseBean.industrialPlot)|amount}}
                      </td>
                    </tr>
                    <tr *ngIf="propertyValuationResponseBean.healthPlot!=0">
                      <td>{{'valuationproperty.HealthPlotRate' | translate}}</td>
                      <td>{{getPriceConversion(propertyValuationResponseBean.healthPlotRate) | amount}}</td>
                      <td class="text-right">{{getPriceConversion(propertyValuationResponseBean.healthPlot)|amount}}
                      </td>
                    </tr>
                    <tr *ngIf="propertyValuationResponseBean.eduPlot!=0">
                      <td>{{'valuationproperty.EduationPlotRate' | translate}}</td>
                      <td>{{getPriceConversion(propertyValuationResponseBean.eduPlotRate )| amount}}</td>
                      <td class="text-right">{{getPriceConversion(propertyValuationResponseBean.eduPlot)|amount}}</td>
                    </tr>
                    <!-- <tr *ngIf="propertyValuationResponseBean.eduPlot!=null||undefined">
                    <td>Other Plot Rate</td>
                    <td>{{propertyValuationResponseBean.eduPlotRate}}</td>
                    <td class="text-right">{{propertyValuationResponseBean.eduPlot}}</td>
                  </tr> -->
                    <!----------end plot Case----------->

                  </ng-template>

                  <tr *ngFor="
                  let prtbsub of propertyValuationResponseBean.applicableSubClauseList;let i = index">
                    <td>
                      {{ lan == 0 ? prtbsub?.dispSubclauseEn : prtbsub?.dispSubclauseHi }}
                      <span class="badge badge-primary bg-primary">{{'valuationproperty.Applicablevaluationrule' |
                        translate}}</span>
                    </td>
                   
                    <td>
                      {{ prtbsub?.computationDescription }}
                      <span *ngIf="prtbsub?.operator == '+'" class="text-success"
                        style='font-size: 20px;font-weight: 900;color: #28a745 !important;'>&#8593;</span>
                      <span class="text-danger" *ngIf="prtbsub?.operator == '-'"
                        style='font-size: 20px;font-weight: 900;color: #dc3545 !important;'>&#8595;</span>



                    </td>
                    <td>
                      {{ prtbsub?.computationValue | amount }}
                      <span *ngIf="prtbsub?.operator == '+'" class="text-success"
                        style='font-size: 20px;font-weight: 900;color: #28a745 !important;'>&#8593;</span>

                      <span class="text-danger" *ngIf="prtbsub?.operator == '-'"
                        style='font-size: 20px;font-weight: 900;color: #dc3545 !important;'>&#8595;</span>

                     

                    </td>
                  </tr>
                  <tr *ngFor="
                  let prtbsub of propertyValuationResponseBean?.selectSubClause;let i = index">
                    <td *ngIf="!itemExists(prtbsub.id)">{{lan == 0 ? prtbsub?.dispSubclauseEn : prtbsub?.dispSubclauseHi
                      }} </td>
                    <td *ngIf="!itemExists(prtbsub.id)"></td>
                    <td *ngIf="!itemExists(prtbsub.id)"></td>
                  </tr>

                  <tr>
                    <td>{{'valuationproperty.FinalValuationofProperty' | translate}}</td>
                    <td>{{propertyValuationResponseBean.calculatedValue|AmtToWords}}</td>
                    <td class="text-right">{{propertyValuationResponseBean.calculatedValue|amount}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="row mb-2" *ngIf="propertyValuationResponseBean.propertyType.id==2">
        <div class="col-md-12">
          <fieldset class="legend-section mt-2 legend-section2">
            <legend class="sidebar__widget-title">{{'valuationproperty.PropertyValuation' | translate}}</legend>
            <div class="table-responsive shadow-none">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th>{{'valuationproperty.ApplicableGuidelineRate' | translate}}</th>
                    <th>{{'valuationproperty.BasicGuidelineRate' | translate}} ({{lan==0?'per ':'प्रति
                      '}}{{lan==0?getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)}})
                    </th>
                    <th>{{'valuationproperty.GuidelineValue' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- *ngIf="propertyValuationResponseBean.residentialCumCommercialPlot!=0&&propertyValuationResponseBean.residentialCumCommercialPlot!=undefined" -->
                  <tr *ngIf="propertyValuationResponseBean.isResidentialCumCommercial">
                    <th class="f-w500">
                      <span *ngIf="lan==0">Building Floor/Subtype </span>
                      <span *ngIf="lan==1">भवन का तल/उपप्रकार </span>
                    </th>


                    <th class="p-0">
                      <table class="table table-bordered m-0 vt3">
                        <tr>
                          <th class="f-w500" style="width:12%;">
                            <span *ngIf="lan==0">RCC Rate</span>
                            <span *ngIf="lan==1">आरसीसी दर </span>
                          </th>
                          <th class="f-w500" style="width:12%;">
                            <span *ngIf="lan==0">RBC Rate</span>
                            <span *ngIf="lan==1">आरबीसी दर</span>
                          </th>
                          <th class="f-w500" style="width:18%;">
                            <span *ngIf="lan==0">Kachha kabelu Rate</span>
                            <span *ngIf="lan==1">कच्चा कबेलु दर</span>
                          </th>
                          <th class="f-w500" style="width:18%;">
                            <span *ngIf="lan==0">Tin shade Rate</span>
                            <span *ngIf="lan==1">टिन शेड दर</span>
                          </th>
                          <th class="f-w500" style="width:12%;">
                            <span *ngIf="lan==0">Office Rate</span>
                            <span *ngIf="lan==1">कार्यालय दर</span>
                          </th>
                          <th class="f-w500" style="width:12%;">
                            <span *ngIf="lan==0">shop Rate</span>
                            <span *ngIf="lan==1">दुकान दर</span>
                          </th>
                          <th class="f-w500" style="width:12%;">
                            <span *ngIf="lan==0">Godown Rate</span>
                            <span *ngIf="lan==1">गोदाम दर</span>
                          </th>
                        </tr>
                      </table>
                    </th>
                    <th class="f-w500">
                      <span *ngIf="lan==0">Total Cost</span>
                      <span *ngIf="lan==1">कुल लागत</span>
                    </th>

                  </tr>
                  <ng-container *ngFor="let buildArea of propertyValuationResponseBean.independentBuildTypeBeanList">

                    <ng-container
                      *ngIf=" buildArea?.resiCumCommType != undefined &&buildArea?.resiCumCommType != null&&isObjectEmpty(buildArea?.resiCumCommType)">

                      <tr>
                        <td>
                          {{lan == 0
                          ? getFloorName(buildArea?.floorId)
                          : getFloorNameHi(buildArea?.floorId)
                          }}
                          <span class="badge badge-primary bg-primary"> {{'valuationproperty.resiCumComm' | translate}}
                          </span>
                        </td>
                        <td class="p-0">
                          <table class="table table-bordered m-0 vt4">
                            <tr>

                              <td style="width:12%;">{{getPriceConversion(buildArea?.resiCumCommType?.rccRate) |
                                amount}}</td>
                              <td style="width:12%;">{{getPriceConversion(buildArea?.resiCumCommType?.rbcRate) |
                                amount}}</td>
                              <td style="width:18%;">{{getPriceConversion(buildArea?.resiCumCommType?.kacchaKabeluRate)
                                | amount}}</td>
                              <td style="width:18%;">{{getPriceConversion(buildArea?.resiCumCommType?.tinShadeRate) |
                                amount}}</td>
                              <td style="width:12%;">{{getPriceConversion(buildArea?.resiCumCommType?.officeRate) |
                                amount}}</td>
                              <td style="width:12%;">{{getPriceConversion(buildArea?.resiCumCommType?.shopRate) |
                                amount}}</td>
                              <td style="width:12%;">{{getPriceConversion(buildArea?.resiCumCommType?.godownRate) |
                                amount}}</td>
                            </tr>
                          </table>

                        </td>
                        <td>
                          {{ buildArea?.constCost }}
                        </td>

                      </tr>


                    </ng-container>
                  </ng-container>
                  <!----------start Building Case----------->
                  <!-- <tr *ngIf="propertyValuationResponseBean.isResidentialCumCommercial>
                      <td *ngIf="propertyValuationResponseBean.isResidentialCumCommercial!=null||undefined">{{'valuationproperty.ResidentialCumCommercialRate' | translate}}</td>
                      <td *ngIf="propertyValuationResponseBean.isResidentialCumCommercial!=null||undefined">&#x20B9;{{propertyValuationResponseBean.residentialCumCommercialPlotRate}}</td>
                      <td *ngIf="propertyValuationResponseBean.isResidentialCumCommercial!=null||undefined" class="text-right">{{propertyValuationResponseBean.	residentialCumCommercialPlot}}</td>
                    </tr> -->


                  <!-- <tr *ngIf="propertyValuationResponseBean.residentialPlot!=0">
                    <td *ngIf="propertyValuationResponseBean.residentialPlot!=null||undefined">{{'valuationproperty.ResidentialBuildingRate' | translate}}</td>
                    <td *ngIf="propertyValuationResponseBean.residentialPlot!=null||undefined">&#x20B9;{{propertyValuationResponseBean.residentialPlotRate}}</td>
                    <td *ngIf="propertyValuationResponseBean.residentialPlot!=null||undefined" class="text-right">{{propertyValuationResponseBean.residentialPlot}}</td>
                  </tr> -->

                  <!-- <tr *ngIf="propertyValuationResponseBean.commercialPlot!=0">
                    <td *ngIf="propertyValuationResponseBean.commercialPlot!=null||undefined">Commercial Building Rate</td>
                    <td *ngIf="propertyValuationResponseBean.commercialPlot!=null||undefined">&#x20B9;{{propertyValuationResponseBean.commercialPlotRate}}</td>
                    <td *ngIf="propertyValuationResponseBean.commercialPlot!=null||undefined" class="text-right">{{propertyValuationResponseBean.commercialPlot}}</td>
                  </tr> -->
                  <!-- <tr *ngIf="propertyValuationResponseBean.industrialPlot!=0">
                    <td *ngIf="propertyValuationResponseBean.industrialPlot!=null||undefined">{{'valuationproperty.IndustrialBuildingRate' | translate}}</td>
                    <td *ngIf="propertyValuationResponseBean.industrialPlot!=null||undefined">&#x20B9;{{propertyValuationResponseBean.industrialPlotRate}}</td>
                    <td *ngIf="propertyValuationResponseBean.industrialPlot!=null||undefined" class="text-right">{{propertyValuationResponseBean.industrialPlot}}</td>
                  </tr> -->
                  <!-- <tr *ngIf="propertyValuationResponseBean.healthPlot!=0">
                    <td *ngIf="propertyValuationResponseBean.healthPlot!=null||undefined">{{'valuationproperty.HealthBuildingRate' | translate}}</td>
                    <td *ngIf="propertyValuationResponseBean.healthPlot!=null||undefined">&#x20B9;{{propertyValuationResponseBean.healthPlotRate}}</td>
                    <td *ngIf="propertyValuationResponseBean.healthPlot!=null||undefined" class="text-right">{{propertyValuationResponseBean.healthPlot}}</td>
                  </tr> -->
                  <!-- <tr *ngIf="propertyValuationResponseBean.eduPlot!=0">
                    <td *ngIf="propertyValuationResponseBean.eduPlot!=null||undefined">{{'valuationproperty.EduationBuildingRate' | translate}}</td>
                    <td *ngIf="propertyValuationResponseBean.eduPlot!=null||undefined">&#x20B9;{{propertyValuationResponseBean.eduPlotRate}}</td>
                    <td *ngIf="propertyValuationResponseBean.eduPlot!=null||undefined" class="text-right">{{propertyValuationResponseBean.eduPlot}}</td>
                  </tr> -->
                  <!-- <tr *ngIf="propertyValuationResponseBean.otherPlot!=0">
                    <td *ngIf="propertyValuationResponseBean.otherPlot!=null||undefined">{{'valuationproperty.OtherBuildingRate' | translate}}</td>
                    <td *ngIf="propertyValuationResponseBean.otherPlot!=null||undefined">&#x20B9;{{propertyValuationResponseBean.otherPlotRate}}</td>
                    <td *ngIf="propertyValuationResponseBean.otherPlot!=null||undefined" class="text-right">{{propertyValuationResponseBean.otherPlot}}</td>
                  </tr> -->
                  <tr *ngIf="!propertyValuationResponseBean.isResidentialCumCommercial
                  &&(propertyValuationResponseBean.buildingSubType=='Independent Building'&&(propertyValuationResponseBean.isResidensial
                  ||propertyValuationResponseBean.isIndustrial
                  ||propertyValuationResponseBean.isOthers
                  ||propertyValuationResponseBean.isHealth
                  ||propertyValuationResponseBean.isEduArea))">
                    <th class="f-w500">
                      <span *ngIf="lan==0">Building Floor/Subtype </span>
                      <span *ngIf="lan==1">भवन का तल/उपप्रकार </span>
                    </th>
                    <th class="p-0">
                      <table class="table table-bordered m-0 ">
                        <tr>
                          <th class="f-w500" style="width: 25%;">
                            <span *ngIf="lan==0">RCC Rate</span>
                            <span *ngIf="lan==1">आरसीसी दर </span>
                          </th>
                          <th class="f-w500" style="width: 25%;">
                            <span *ngIf="lan==0">RBC Rate</span>
                            <span *ngIf="lan==1">आरबीसी दर</span>
                          </th>
                          <th class="f-w500" style="width: 25%;">
                            <span *ngIf="lan==0">Kachha kabelu Rate</span>
                            <span *ngIf="lan==1">कच्चा कबेलु दर</span>
                          </th>
                          <th class="f-w500" style="width: 25%;">
                            <span *ngIf="lan==0">Tin shade Rate</span>
                            <span *ngIf="lan==1">टिन शेड दर</span>
                          </th>
                        </tr>
                      </table>
                    </th>
                    <th class="f-w500">
                      <span *ngIf="lan==0">Total Cost</span>
                      <span *ngIf="lan==1">कुल लागत</span>
                    </th>

                  </tr>
                  <ng-container *ngFor="let buildArea of propertyValuationResponseBean.independentBuildTypeBeanList">

                    <ng-container
                      *ngIf=" buildArea?.resiConstType != undefined &&buildArea?.resiConstType != null&&isObjectEmpty(buildArea?.resiConstType)">

                      <tr>
                        <td>
                          {{lan == 0
                          ? getFloorName(buildArea?.floorId)
                          : getFloorNameHi(buildArea?.floorId)
                          }}
                          <span class="badge badge-primary bg-primary">{{'valuationproperty.Residential' |
                            translate}}</span>
                        </td>
                        <td class="p-0">
                          <table class="table table-bordered m-0 ">
                            <tr>
                              <td style="width: 25%;">{{ getPriceConversion(buildArea?.resiConstType?.rccRate )
                                |amount}}</td>
                              <td style="width: 25%;">{{ getPriceConversion(buildArea?.resiConstType?.rbcRate ) |amount
                                }}</td>
                              <td style="width: 25%;"> {{ getPriceConversion(buildArea?.resiConstType?.kacchaKabeluRate)
                                |amount }}</td>
                              <td style="width: 25%;"> {{ getPriceConversion(buildArea?.resiConstType?.tinShadeRate )
                                |amount }}</td>
                            </tr>
                          </table>

                        </td>
                        <td>
                          {{ buildArea?.constCost }}
                        </td>

                      </tr>


                    </ng-container>
                  </ng-container>
                  <!--Industrial-->
                  <ng-container *ngFor="let buildArea of propertyValuationResponseBean.independentBuildTypeBeanList">

                    <ng-container
                      *ngIf=" buildArea?.industrialConstructionType != undefined &&buildArea?.industrialConstructionType != null&&isObjectEmpty(buildArea?.industrialConstructionType)">

                      <tr>
                        <td>
                          {{lan == 0
                          ? getFloorName(buildArea?.floorId)
                          : getFloorNameHi(buildArea?.floorId)
                          }}
                          <span class="badge badge-primary bg-primary">{{'valuationproperty.Industrial' |
                            translate}}</span>
                        </td>
                        <td class="p-0">
                          <table class="table table-bordered m-0 ">
                            <tr>
                              <td style="width: 25%;">{{
                                getPriceConversion(buildArea?.industrialConstructionType?.rccRate) |amount}}</td>
                              <td style="width: 25%;">{{
                                getPriceConversion(buildArea?.industrialConstructionType?.rbcRate) |amount}}</td>
                              <td style="width: 25%;"> {{
                                getPriceConversion(buildArea?.industrialConstructionType?.kacchaKabeluRate)|amount }}
                              </td>
                              <td style="width: 25%;"> {{
                                getPriceConversion(buildArea?.industrialConstructionType?.tinShadeRate) |amount}}</td>
                            </tr>
                          </table>

                        </td>
                        <td>
                          {{ buildArea?.constCost }}
                        </td>

                      </tr>


                    </ng-container>
                  </ng-container>
                  <!--Health-->
                  <ng-container *ngFor="let buildArea of propertyValuationResponseBean.independentBuildTypeBeanList">

                    <ng-container
                      *ngIf=" buildArea?.healthAreaConstructionType != undefined &&buildArea?.healthAreaConstructionType != null&&isObjectEmpty(buildArea?.healthAreaConstructionType)">

                      <tr>
                        <td>
                          {{lan == 0
                          ? getFloorName(buildArea?.floorId)
                          : getFloorNameHi(buildArea?.floorId)
                          }}
                          <span class="badge badge-primary bg-primary">{{'valuationproperty.Health' | translate}}</span>
                        </td>
                        <td class="p-0">
                          <table class="table table-bordered m-0 ">
                            <tr>
                              <td style="width: 25%;">{{
                                getPriceConversion(buildArea?.healthAreaConstructionType?.rccRate )|amount}}</td>
                              <td style="width: 25%;">{{
                                getPriceConversion(buildArea?.healthAreaConstructionType?.rbcRate )|amount }}</td>
                              <td style="width: 25%;"> {{
                                getPriceConversion(buildArea?.healthAreaConstructionType?.kacchaKabeluRate )|amount }}
                              </td>
                              <td style="width: 25%;"> {{
                                getPriceConversion(buildArea?.healthAreaConstructionType?.tinShadeRate )|amount}}</td>
                            </tr>
                          </table>

                        </td>
                        <td>
                          {{ getPriceConversion(buildArea?.constCost )}}
                        </td>

                      </tr>


                    </ng-container>
                  </ng-container>

                  <ng-container *ngFor="let buildArea of propertyValuationResponseBean.independentBuildTypeBeanList">

                    <ng-container
                      *ngIf=" buildArea?.eduAreaConstructionType != undefined &&buildArea?.eduAreaConstructionType != null&&isObjectEmpty(buildArea?.eduAreaConstructionType)">

                      <tr>
                        <td>
                          {{lan == 0
                          ? getFloorName(buildArea?.floorId)
                          : getFloorNameHi(buildArea?.floorId)
                          }}
                          <span class="badge badge-primary bg-primary">{{'valuationproperty.Education' |
                            translate}}</span>
                        </td>
                        <td class="p-0">
                          <table class="table table-bordered m-0 ">
                            <tr>
                              <td style="width: 25%;">{{ getPriceConversion(buildArea?.eduAreaConstructionType?.rccRate)
                                |amount}}</td>
                              <td style="width: 25%;">{{ getPriceConversion(buildArea?.eduAreaConstructionType?.rbcRate)
                                |amount}}</td>
                              <td style="width: 25%;">{{
                                getPriceConversion(buildArea?.eduAreaConstructionType?.kacchaKabeluRate) |amount}}</td>
                              <td style="width: 25%;"> {{
                                getPriceConversion(buildArea?.eduAreaConstructionType?.tinShadeRate ) |amount}}</td>
                            </tr>
                          </table>

                        </td>
                        <td>
                          {{ buildArea?.constCost }}
                        </td>

                      </tr>


                    </ng-container>
                  </ng-container>

                  <!--Other-->
                  <ng-container *ngFor="let buildArea of propertyValuationResponseBean.independentBuildTypeBeanList">

                    <ng-container
                      *ngIf=" buildArea?.otherAreaConstructionType != undefined &&buildArea?.otherAreaConstructionType != null&&isObjectEmpty(buildArea?.otherAreaConstructionType)">

                      <tr>
                        <td>
                          {{lan == 0
                          ? getFloorName(buildArea?.floorId)
                          : getFloorNameHi(buildArea?.floorId)
                          }}
                          <span class="badge badge-primary bg-primary">{{'valuationproperty.Other' | translate}}</span>
                        </td>
                        <td class="p-0">
                          <table class="table table-bordered m-0 ">
                            <tr>
                              <td style="width: 25%;">{{
                                getPriceConversion(buildArea?.otherAreaConstructionType?.rccRate) |amount }}</td>
                              <td style="width: 25%;">{{
                                getPriceConversion(buildArea?.otherAreaConstructionType?.rbcRate )|amount}}</td>
                              <td style="width: 25%;"> {{
                                getPriceConversion(buildArea?.otherAreaConstructionType?.kacchaKabeluRate)|amount }}
                              </td>
                              <td style="width: 25%;"> {{
                                getPriceConversion(buildArea?.otherAreaConstructionType?.tinShadeRate)|amount }}</td>
                            </tr>
                          </table>

                        </td>
                        <td>
                          {{ getPriceConversion(buildArea?.constCost)|amount }}
                        </td>

                      </tr>


                    </ng-container>
                  </ng-container>
                  <!--commercial-->
                  <tr
                    *ngIf="propertyValuationResponseBean.commercialPlot!=0&&propertyValuationResponseBean.commercialPlot!=undefined">
                    <th class="f-w500">
                      <span>{{'valuationproperty.CommercialBuilding' | translate}}</span>
                    </th>
                    <th class="p-0">
                      <table class="table table-bordered m-0 ">
                        <tr>
                          <th class="f-w500" style="width: 33.33%;">
                            {{'valuationproperty.ShopRate' | translate}}
                          </th>
                          <th class="f-w500" style="width: 33.33%;">
                            {{'valuationproperty.OfficeRate' | translate}}
                          </th>
                          <th class="f-w500" style="width: 33.33%;">
                            {{'valuationproperty.GodownRate' | translate}}
                          </th>
                        </tr>
                      </table>
                    </th>
                    <th class="f-w500">
                      {{'valuationproperty.TotalCost' | translate}}
                    </th>

                  </tr>

                  <ng-container *ngFor="let buildArea of propertyValuationResponseBean.independentBuildTypeBeanList">

                    <ng-container
                      *ngIf=" buildArea?.commConstType != undefined &&buildArea?.commConstType != null&&isObjectEmpty(buildArea?.commConstType)">

                      <tr>
                        <td>
                          {{lan == 0
                          ? getFloorName(buildArea?.floorId)
                          : getFloorNameHi(buildArea?.floorId)
                          }}
                          <span class="badge badge-primary bg-primary">{{'valuationproperty.Commercial' |
                            translate}}</span>
                        </td>
                        <td class="p-0">
                          <table class="table table-bordered m-0 ">
                            <tr>
                              <td style="width: 33.33%;">{{getPriceConversion(buildArea?.commConstType?.shopRate
                                )|amount}}</td>
                              <td style="width: 33.33%;">
                                {{getPriceConversion(buildArea?.commConstType?.officeRate)|amount }}</td>
                              <td style="width: 33.33%;">{{getPriceConversion(buildArea?.commConstType?.godownRate
                                )|amount}}</td>
                            </tr>
                          </table>

                        </td>
                        <td>
                          {{ getPriceConversion(buildArea?.constCost)|amount}}
                        </td>

                      </tr>



                    </ng-container>
                  </ng-container>
                  <!----------end Building Case----------->



                  <tr *ngFor="
                  let prtbsub of propertyValuationResponseBean.applicableSubClauseList
                  ">
                    <td>
                      {{ lan == 0 ? prtbsub?.dispSubclauseEn : prtbsub?.dispSubclauseHi }}
                      <span class="badge badge-primary bg-primary">{{'valuationproperty.Applicablevaluationrule' |
                        translate}}</span>
                    </td>
                  
                    <td>
                      {{ prtbsub?.computationDescription }}

                      <span *ngIf="prtbsub?.operator == '+'"
                        style='font-size: 20px;font-weight: 900;color: #28a745 !important;'
                        class="text-success">&#8593;</span>
                      <span *ngIf="prtbsub?.operator == '-'"
                        style='font-size: 20px;font-weight: 900;color: #dc3545 !important;'
                        class="text-danger">&#8595;</span>

                    
                    </td>
                    <td>
                      {{ prtbsub?.computationValue }}
                      <span *ngIf="prtbsub?.operator == '+'"
                        style='font-size: 20px;font-weight: 900;color: #28a745 !important;'
                        class="text-success">&#8593;</span>
                      <span *ngIf="prtbsub?.operator == '-'"
                        style='font-size: 20px;font-weight: 900;color: #dc3545 !important;'
                        class="text-danger">&#8595;</span>
                      <!-- <mat-icon class="text-success io-arrow upArrow">arrow_right_alt</mat-icon>

                    <mat-icon  class="text-danger io-arrow downArrow">arrow_right_alt</mat-icon> -->

                    </td>
                  </tr>
                  <tr *ngFor="
                  let prtbsub of propertyValuationResponseBean?.selectSubClause let i = index">
                    <td *ngIf="!itemExists(prtbsub.id)"> {{ lan == 0 ? prtbsub?.dispSubclauseEn :
                      prtbsub?.dispSubclauseHi }} </td>
                    <td *ngIf="!itemExists(prtbsub.id)"></td>
                    <td *ngIf="!itemExists(prtbsub.id)"></td>
                  </tr>

                  <tr>
                    <td>{{'valuationproperty.FinalValuationProperty' | translate}}</td>
                    <td>{{propertyValuationResponseBean.calculatedValue|AmtToWords}}</td>
                    <td class="text-right">{{propertyValuationResponseBean.calculatedValue|amount}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="row mb-2" *ngIf="propertyValuationResponseBean.propertyType.id==3">
        <div class="col-md-12">
          <fieldset class="legend-section mt-2 legend-section2">
            <legend class="sidebar__widget-title">{{'valuationproperty.PropertyValuation' | translate}}</legend>
            <div class="table-responsive shadow-none">
              <table class="table table-bordered">
                <thead class="thead-dark">
                  <tr>
                    <th>{{'valuationproperty.ApplicableGuidelineRate' | translate}}</th>
                    <th>{{'valuationproperty.BasicGuidelineRate' | translate}} ({{lan==0?'per ':'प्रति
                      '}}{{lan==0?getUnitMea(propertyValuationResponseBean.unit):getUnitMeaHi(propertyValuationResponseBean.unit)}})
                    </th>
                    <th>{{'valuationproperty.GuidelineValue' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="propertyValuationResponseBean.usage=='undiverted';else diverted ">
                    <tr>
                      <td
                        *ngIf="propertyValuationResponseBean.irrigatedArea!=null||propertyValuationResponseBean.irrigatedArea!=undefined">
                        {{'valuationproperty.AgriucltureirrigatedRate' | translate}}</td>
                      <td
                        *ngIf="propertyValuationResponseBean.irrigatedArea!=null||propertyValuationResponseBean.irrigatedArea!=undefined">
                        {{getPriceConversion(propertyValuationResponseBean.irrigatedRate) | amount}}</td>
                      <td class="text-right"
                        *ngIf="propertyValuationResponseBean.irrigatedArea!=null||propertyValuationResponseBean.irrigatedArea!=undefined">
                      </td>
                    </tr>
                    <!-- <tr>
                    <td>Agriculture Unirrigated Rate</td>
                    <td>{{propertyValuationResponseBean.unIrrigatedRate}}</td>
                    <td class="text-right"></td>
                  </tr> -->
                    <tr>
                      <td *ngIf="propertyValuationResponseBean.singleCropArea!=null||undefined">
                        {{'valuationproperty.AgriucltureSinglecropRate' | translate}}</td>
                      <td *ngIf="propertyValuationResponseBean.singleCropArea!=null||undefined">
                        {{getPriceConversion(propertyValuationResponseBean.singleCropRate) | amount}}</td>
                      <td *ngIf="propertyValuationResponseBean.singleCropArea!=null||undefined" class="text-right"></td>
                    </tr>
                    <tr>
                      <td *ngIf="(propertyValuationResponseBean.doubleCropArea!=null||undefined)">
                        {{'valuationproperty.AgriuclturedoublecropRate' | translate}}</td>
                      <td *ngIf="(propertyValuationResponseBean.doubleCropArea!=null||undefined)">
                        {{getPriceConversion(propertyValuationResponseBean.doubleCropRate) | amount}}</td>
                      <td *ngIf="(propertyValuationResponseBean.doubleCropArea!=null||undefined)" class="text-right">
                      </td>
                    </tr>
                  </ng-container>
                  <!----------start Agriculture Case----------->
                  <ng-template #diverted>
                    <tr>
                      <td *ngIf="propertyValuationResponseBean.residentialPlot!=null||undefined">
                        {{'valuationproperty.ResidentialRate' | translate}}</td>
                      <td *ngIf="propertyValuationResponseBean.residentialPlot!=null||undefined">
                        {{getPriceConversion(propertyValuationResponseBean.residentialPlotRate) | amount}}</td>
                      <td class="text-right" *ngIf="propertyValuationResponseBean.residentialPlot!=null||undefined">
                        {{getPriceConversion(propertyValuationResponseBean.residentialPlot)| amount}}</td>
                    </tr>
                    <tr>
                      <td *ngIf="propertyValuationResponseBean.commercialPlot!=null||undefined">
                        {{'valuationproperty.CommercialAgriucltureRate' | translate}}</td>
                      <td *ngIf="propertyValuationResponseBean.commercialPlot!=null||undefined">
                        {{getPriceConversion(propertyValuationResponseBean.commercialPlotRate) | amount}}</td>
                      <td *ngIf="propertyValuationResponseBean.commercialPlot!=null||undefined" class="text-right">
                        {{getPriceConversion(propertyValuationResponseBean.commercialPlot)| amount}}</td>
                    </tr>
                    <tr>
                      <td *ngIf="propertyValuationResponseBean.industrialPlot!=null||undefined">
                        {{'valuationproperty.IndustrialAgriucltureRate' | translate}}</td>
                      <td *ngIf="propertyValuationResponseBean.industrialPlot!=null||undefined">
                        {{getPriceConversion(propertyValuationResponseBean.industrialPlotRate )| amount}}</td>
                      <td *ngIf="propertyValuationResponseBean.industrialPlot!=null||undefined" class="text-right">
                        {{getPriceConversion(propertyValuationResponseBean.industrialPlot)| amount}}</td>
                    </tr>
                    <tr>
                      <td *ngIf="propertyValuationResponseBean.healthPlot!=null||undefined">
                        {{'valuationproperty.HealthAgriucltureRate' | translate}}</td>
                      <td *ngIf="propertyValuationResponseBean.healthPlot!=null||undefined">
                        {{getPriceConversion(propertyValuationResponseBean.healthPlotRate) | amount}}</td>
                      <td *ngIf="propertyValuationResponseBean.healthPlot!=null||undefined" class="text-right">
                        {{getPriceConversion(propertyValuationResponseBean.healthPlot)| amount}}</td>
                    </tr>
                    <tr>
                      <td *ngIf="propertyValuationResponseBean.eduPlot!=null||undefined">
                        {{'valuationproperty.EduationAgriucltureRate' | translate}}</td>
                      <td *ngIf="propertyValuationResponseBean.eduPlot!=null||undefined">
                        {{getPriceConversion(propertyValuationResponseBean.eduPlotRate )| amount}}</td>
                      <td *ngIf="propertyValuationResponseBean.eduPlot!=null||undefined" class="text-right">
                        {{getPriceConversion(propertyValuationResponseBean.eduPlot)| amount}}</td>
                    </tr>
                  </ng-template>


                  <!----------end Agriculture land Case----------->

                  <tr *ngFor="
                  let prtbsub of propertyValuationResponseBean.applicableSubClauseList">
                    <td>
                      {{ lan == 0 ? prtbsub?.dispSubclauseEn : prtbsub?.dispSubclauseHi }}
                      <span class="badge badge-primary bg-primary">{{'valuationproperty.Applicablevaluationrule' |
                        translate}}</span>
                    </td>
                    
                    <td>
                      {{ prtbsub?.computationDescription }}
                      <span *ngIf="prtbsub?.operator == '+'"
                        style='font-size: 20px;font-weight: 900;color: #28a745 !important;'
                        class="text-success">&#8593;</span>
                      <span *ngIf="prtbsub?.operator == '-'"
                        style='font-size: 20px;font-weight: 900;color: #dc3545 !important;'
                        class="text-danger">&#8595;</span>
                
                    </td>
                    <td>
                      {{ prtbsub?.computationValue }}
                      <span *ngIf="prtbsub?.operator == '+'"
                        style='font-size: 20px;font-weight: 900;color: #28a745 !important;'
                        class="text-success">&#8593;</span>
                      <span *ngIf="prtbsub?.operator == '-'"
                        style='font-size: 20px;font-weight: 900;color: #dc3545 !important;'
                        class="text-danger">&#8595;</span>
                     

                    </td>
                  </tr>
                  <tr *ngFor="
                  let prtbsub of propertyValuationResponseBean?.selectSubClause let i = index">
                    <td *ngIf="!itemExists(prtbsub.id)"> {{ lan == 0 ? prtbsub?.dispSubclauseEn :
                      prtbsub?.dispSubclauseHi }} </td>
                    <td *ngIf="!itemExists(prtbsub.id)"></td>
                    <td *ngIf="!itemExists(prtbsub.id)"></td>
                  </tr>


                  <tr>
                    <td>{{'valuationproperty.FinalValuationProperty' | translate}}</td>
                    <td>{{propertyValuationResponseBean.calculatedValue|AmtToWords}}</td>
                    <td class="text-right">{{propertyValuationResponseBean.calculatedValue|amount}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </fieldset>
        </div>
      </div>


      <div class="row mb-2" *ngIf="propertyValuationDeviationBean!=null||undefined">
        <div class="col-md-12">
          <fieldset class="legend-section mt-2 legend-section2">
            <legend class="sidebar__widget-title">{{'valuationproperty.DeviationReport' | translate}}</legend>
            <div class="table-responsive shadow-none">
              <table class="table table-bordered">
                <thead class="thead-dark text-center">
                  <tr>
                    <th>{{'valuationproperty.Details' | translate}}</th>
                    <th>{{'valuationproperty.PartySelectedFlags' | translate}}</th>
                    <th>{{'valuationproperty.SystemIdentifiedFlags' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{'valuationproperty.PropertyAddress' | translate}}</td>
                    <td>{{propertyValuationDeviationBean.address}}</td>
                    <td>{{propertyValuationDeviationBean?.identifiedAddress}}</td>
                    <td></td>
                  </tr>
                  <tr *ngFor="let subclause of propertyValuationDeviationBean.subClauseList;let i = index">
                    <td> {{ lan == 0 ? subclause?.descEn : subclause?.descHi }}</td>
                    <td>{{subclause.userSelect ?this.lan==0?"YES":"हां":this.lan==0?"NO":"नहीं"}}</td>
                    <td>{{subclause.systemSelect ?this.lan==0?"YES":"हां":this.lan==0?"NO":"नहीं"}}</td>
                    <!-- <td>{{getSystemIdentified(subclause.id)}}</td> -->
                  </tr>

                </tbody>
              </table>
            </div>
          </fieldset>
        </div>
      </div>
      <fieldset class="legend-section mt-3 legend-section2" *ngIf="!this.fromEregModule">
        <p *ngIf="lan==0" style="font-weight: 500;"><b>Note</b> : The valuation of the said property is not final, other
          details of the property may affect
          the said valuation of the property.
          For more information contact the Sub-Registrar Office.

        </p>
        <p *ngIf="lan==1" style="font-weight: 500;"><b>टिप्पणी</b> : उक्त संपत्ति का मूल्यांकन अंतिम नहीं हैं, संपत्ति
          के अन्य विवरण संपत्ति के उक्त वेल्यूशन को प्रभावित कर सकते है। अधिक जानकारी हेतु उपपंजीयक कार्यालय में संपर्क
          करें

        </p>
      </fieldset>


    </div>
    <div class="d-none d-print d-print-block rotingtxt" style="
                position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: auto;
            text-align: center;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            opacity: 0.1;
            width: 100%;

          ">
      <div style="
              transform: rotate(-33deg);
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
            ">
        <table width="100%" border="0" cellspacing="0" cellpadding="0" class="mt-3" style="border: none;">
          <tr>
            <td style="border: none;text-align: center;">
              <img src="assets/images/sampada-logo.png" alt="Madhya Pradesh" height="80" />
            </td>
          </tr>
        </table>
      </div>
    </div>



  </div>
  <div class="row mt-3 mb-1">
    <div class="col-md-12 text-right">
      <button *ngIf="fromCitizenEregModule||fromEregModule||fromCheckerModule || fromCMSModule" class="btn btn-secondary mr-1" [mat-dialog-close]="true" (click)="close()">
        {{'valuationproperty.saveClose' | translate}}
      </button>
      
      <button *ngIf="(this.formPropModule||fromDutyModule) && !(fromCheckerModule || fromCMSModule)" class="btn btn-secondary mr-1" [mat-dialog-close]="true"
        (click)="close()">
        {{'valuationproperty.Close' | translate}}
      </button>
      
      <button class="btn btn-primary mr-1" (click)="onBtnPrintClick()" target="_blank">{{'valuationproperty.Print' |
        translate}}
      </button>

       <!--Only property Valuation without login-->
      <button *ngIf="this.formPropModule && !(fromCheckerModule || fromCMSModule || fromDutyModule)"
        class="btn btn-info d-print-none" (click)="continue()">
        {{'valuationproperty.contin' | translate}}
      </button>
    </div>
  </div>
</mat-dialog-content>