import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ResultModel } from "../../../../common-lib/src/lib/models/api.result.model/result.model";
import { AppSetting, AppSettingService } from "../../../../common-lib/src/lib/helper-lib/app.setting";
import { CustomHttpClient } from "../../../../common-lib/src/lib/helper-lib/httpclient/custom.httpclient";
import { TranslateService } from "@ngx-translate/core";
import { Params, Router } from "@angular/router";
import { UserProfileModel } from "projects/common-lib/src/lib/models/auth.userdetail.model/user.profile.model";
import { map } from "rxjs/operators";
import { eSignatureBean, SPStatusUpdateDetails } from "projects/common-lib/src/lib/models/serviceprovider/serviceprovider.model";
import { IgrsEregTxnDetlsBean } from "projects/common-lib/src/lib/models/e_registry/e-registry.model";
import { CitizenForgot } from "projects/common-lib/src/lib/models";
import { AESEncryptDecryptService } from "projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service";
import { Observable } from "rxjs";
import { CitizenUserProfileDetails, ServiceProviderLicenseDetails } from "projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model";
import { IgrsRefundDataForRegFeesBean, PaymentRefundModel, RefundJasperBean } from "projects/common-lib/src/lib/models/TransactionDetailsChallanModel";
const shajs = require('sha.js');
@Injectable({ providedIn: "root" })
export class UserManagementServive {
    private settings!: AppSetting;
    seconds: number = 0;
    constructor(private appSettingsService: AppSettingService, private customeHttpClient: CustomHttpClient, private http: HttpClient, private _AESEncryptDecryptService: AESEncryptDecryptService) {
        this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
            this.settings = settings;
        });
    }

    updateAdminProfile(userProfile: UserProfileModel) {
        ;
        // let user: any = { "newProfileBean": userProfile }
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/update_department_user_profile",
                userProfile
            ).pipe(
                map((result: any) => {
                    // if (result && result.responseStatus == 'true') {
                    return result;
                    // }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    // getOfficeByDepartment(department: number) {
    //     //
    //     
    //      return this.customeHttpClient
    //          .GetApi<ResultModel<any>>(
    //              this.settings.igrs_gateway_url + "get_office_by_department",
    //              department,
    //          ).pipe(
    //              map((result: any) => {
    //                  
    //                  
    //                  
    //                  if (result && result.status == 'true') {

    //                  }
    //                  return result;
    //              })
    //          );
    //  }

    get_offices_map(department_id: number, demography_id: number) {
        //
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/fetchOffice", { 'demographyId': demography_id, "departmentId": department_id }
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    loadOfficesByName(param: any) {
        //

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/searchOfficeByName", param
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    get_Subroles(department_id: number, demography_id: number) {
        //
        // demography="+demography_id+"&
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_subroles_map?department_id=" + department_id
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }


    get_Subroles_DepartmetId(department_id: number) {
        //
        // demography="+demography_id+"&
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_subroles_map?department_id=" + department_id
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }
    get_SubrolesByDepartmentId(obj: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_subrole_list", obj
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    get_templateMessagesAll(obj: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_notification_template_list", obj
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    get_templateMessagesById(obj: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_notification_template_by_id", obj
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    get_UpdateTemplateMessages(obj: any) {

        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/update_notification_template", obj
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    get_AllDepartmetTypeList() {
        let param = {};
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_department_type", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    get_userBulkUploadFileList(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/bulkfilelist", param
            ).pipe(
                map((result: any) => {
                    // 
                    //  
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    bulkUploadUsers(param: any) {

        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/usersbulkupload", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    bulkUploadUsersList(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_all_bulk_user_details", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    bulkUploadUsersSubmitForApproval(param: any) {

        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/bulksubmitforapproval", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }


    get_AllDepartmet_FirstLevel(status: any) {
        //
        // demography="+demography_id+"&

        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_departments?status=" + status
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    get_all_departmets() {
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_all_departments"
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
                })
            );
    }


    get_AllOfficeLevel() {
        //
        // demography="+demography_id+"&
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/geOfficeLevel"
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    geOfficeType() {
        //
        // demography="+demography_id+"&
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/geOfficeType"
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }


    get_DepartmetByparentId(parentId: any) {
        //
        // demography="+demography_id+"&
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_departments?parentDepartmentId=" + parentId
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }
    checkEmailidExists(email: any) {
        //
        // demography="+demography_id+"&
        let param = { email_id: email }
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/department_user_email_exists", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    getDemoGraphyByCurrentLevel(id: any, departmentId: any, areaType: any) {
        let obj = {};
        if (areaType != null) {
            obj = { 'demographyId': id, 'departmentId': departmentId, 'areaType': areaType }
        }
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/fetchDemoGraphyCurrentLevel", obj
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }
    getFetchDemoGraphyNextLevel(id: any, departmentId: any, areaType: any) {
        let obj = {};
        if (id != null) {
            obj = { 'demographyId': id, 'departmentId': departmentId, 'areaType': areaType }
        } else {
            obj = { 'areaType': areaType }
        }
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/fetchDemoGraphyNextLevel", obj
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }


    getDemoGraphyTypeAll() {
        let param = "";
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_demography_type", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    fetchDemoGraphyHierarchy(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/fetchDemoGraphyHierarchy", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }


    getDemoGraphyByCurrentLevelByareaType(areaType: any) {
        let obj = {};
        if (areaType != null) {
            obj = { 'areaType': areaType }
        }
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/fetchDemoGraphyCurrentLevel", obj
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    get_selectedFeatureListBySubroleId(subroleid: number) {
        //
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_feature_mapping_by_subrole?subrole=" + subroleid
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    get_SubrolesAll() {
        //
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_subroles_map"
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    get_FeatureAll(param: any) {
        //
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_features_map?status=" + param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    get_departmentUserList(param: any) {
        //
        //
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_department_users_list", param
            ).pipe(
                map((result: any) => {

                    //  

                    return result.responseData;


                })
            );
    }


    getComplaintRegisteredList(param: any) {
        //
        //
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/getregisteredcomplainlist", param
            ).pipe(
                map((result: any) => {

                    //  

                    return result.responseData;


                })
            );
    }

    get_departments(param: any) {
        //
        //
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_department_list", param
            ).pipe(
                map((result: any) => {

                    //  

                    return result.responseData;


                })
            );
    }

    get_officeList(param: any) {
        //
        //
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_office_list", param
            ).pipe(
                map((result: any) => {

                    //  
                    return result.responseData;
                })
            );
    }

    saveOfficeDetail(officeDetail: any) {
        ;
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/save_office",
                officeDetail
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    get_officeProfile(param: any) {
        //
        //
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_office_by_id", param
            ).pipe(
                map((result: any) => {

                    //  
                    return result.responseData;
                })
            );
    }

    changeDepartmentStatus(id: any) {
        //
        //
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/update_department_status_by_id", id
            ).pipe(
                map((result: any) => {

                    //  

                    return result;


                })
            );
    }

    changeFeatureStatus(param: any) {
        //
        //
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/update_feature_status_by_id", param
            ).pipe(
                map((result: any) => {

                    //  

                    return result;


                })
            );
    }

    changeUserStatus(id: any) {
        //
        // 
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/update_department_user_status_by_id", id
            ).pipe(
                map((result: any) => {

                    //  

                    return result;


                })
            );
    }


    changeComplaintRequestStatus(id: any) {
        //
        // 
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/update_registeredcomplain_status_by_id", id
            ).pipe(
                map((result: any) => {

                    //  

                    return result;


                })
            );
    }

    get_userProfile(param: any) {
        //
        //
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_department_user_profile_by_id", param
            ).pipe(
                map((result: any) => {

                    //  

                    return result.responseData;


                })
            );
    }

    addOnboardUser(departmentUser: any) {

        ;
        let user: any = { "departmentUser": departmentUser }
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/save_department_user_profile",
                departmentUser
            ).pipe(
                map((result: any) => {

                    return result;

                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    addDepartment(departmentobj: any) {

        //    let user:any={"departmentUser":departmentUser}
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/save_department",
                departmentobj
            ).pipe(
                map((result: any) => {

                    return result;

                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }


    updateOnboardUser(departmentUser: any) {

        ;
        let user: any = { "departmentUser": departmentUser }
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/update_department_user_profile",
                departmentUser
            ).pipe(
                map((result: any) => {

                    return result;

                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    updateSubRole(subRole: any) {

        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/save_subrole",
                subRole
            ).pipe(
                map((result: any) => {

                    return result;

                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }


    saveSubroleFeatureMapping(featureBeans: any, subRoleId: any) {


        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/save_subrole_feature_mappings/" + subRoleId,
                featureBeans
            ).pipe(
                map((result: any) => {

                    return result;

                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }
    savedemographyHierarchy(demographyhierachy: any) {


        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/save_demography_dept_hierarchy",
                demographyhierachy
            ).pipe(
                map((result: any) => {

                    return result;

                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    updatePassword(formData: FormData) {
        return this.customeHttpClient
            .PostFormApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/reset_department_user_password",
                formData
            ).pipe(
                map((result: any) => {

                    // if (result && result.responseStatus == 'true') {
                    //
                    return result;
                    // } else {
                    //     return result;
                    // }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    refreshToken(param: any) {
        //
        return this.customeHttpClient
            .GetApiForRefreshTokenOnly<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/regenerate_token",
                param
            ).pipe(
                map((result: any) => {
                    //  
                    return result;
                },
                    (error: any) => {
                        //    
                        return error;
                    }
                )
            );
    }

    //  sub user role services start here
    get_subRoleList(departmentid: any) {
        let obj = "";
        if (departmentid) {
            obj = "?departmentid=" + departmentid;
        }

        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_subroles" + obj,
            ).pipe(
                map((result: any) => {

                    //   
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }

    //  sub ubser role services start here
    get_subRoleByid(id: number) {

        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_subroles?id=" + id
            ).pipe(
                map((result: any) => {

                    //   
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }

    get_departmentByid(id: number) {
        let param = { id: id }
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_departmentbyid", param
            ).pipe(
                map((result: any) => {

                    return result;
                    // } else {
                    //     return result.responseMessage;
                    // }

                })
            );
    }

    // Get Feature list Services start here
    get_featureList(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_feature_list", param
            ).pipe(
                map((result: any) => {

                    //  
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }


    getDivision(stateid: any) {
        //
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_demography_by_parent" + "?parent=" + stateid
            ).pipe(
                map((result: any) => {

                    //  
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }

    getDistrict() {
        //
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_demography_by_type" + "?type=3"
            ).pipe(
                map((result: any) => {

                    //   
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }

    getBlocks(disId: number) {
        //
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_demography_by_parent" + "?parent=" + disId
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }

    get_serviceProviderList(param: any) {
        //
        //  
        console.log("--------------------------------- ", param);
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_serviceprovider_list", param
            ).pipe(
                map((result: any) => {

                    //    
                    return result.responseData;
                })
            );
    }

    get_serviceProviderbyid(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_serviceprovider_by_id", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }
    //print sp license using jasper

    exportSPLicensePdf(serviceProviderBean: ServiceProviderLicenseDetails) {
        console.log("citizen Bean ", serviceProviderBean);
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_services_jasper_url + "department/jasper/printSPLicense/exportSPLicense", serviceProviderBean
            ).pipe(
                map((result: any) => {

                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }

    saveSPLicensePdf(serviceProviderBean: any) {

        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/saveServiceProviderLicensePdf", serviceProviderBean
            ).pipe(
                map((result: any) => {
                    return result;
                },
                    (error: any) => {

                        return error;
                    })
            );
    }


    get_ServiceProviderSelectedNames() {
        //
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_serviceprovider_selected_names"
            ).pipe(
                map((result: any) => {

                    //   
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }

    changeServiceProviderStatus(spStatusUpdateDetails: SPStatusUpdateDetails) {
        //
        // 
        ;
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/update_serviceproviderstatus_by_id", spStatusUpdateDetails
            ).pipe(
                map((result: any) => {

                    //  

                    return result;


                },
                    (error: any) => {

                        return error;
                    })
            );
    }

    changeFranchiseStatus(id: any) {
        //
        //  
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/update_franchisestatus_by_id", id
            ).pipe(
                map((result: any) => {

                    //  

                    return result;


                })
            );
    }

    get_RepresentativesList(param: any) {
        //
        // 
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/get_all_representatives_list", param
            ).pipe(
                map((result: any) => {

                    //  
                    return result.responseData;
                })
            );
    }

    updateRepresentativeStatusById(id: any) {
        //
        //  
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/update_representative_status_by_id", id
            ).pipe(
                map((result: any) => {

                    //  

                    return result;


                })
            );
    }

    getRepresentativesList(param: any) {
        //
        //  
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/get_representatives_list", param
            ).pipe(
                map((result: any) => {

                    //     
                    return result.responseData;
                })
            );
    }

    getRepresentativeById(param: any) {
        //
        // 
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/get_representative_by_id", param
            ).pipe(
                map((result: any) => {

                    //    
                    return result.responseData;
                })
            );
    }

    checkRdService() {
        //
        // 
        return this.customeHttpClient
            .GetRdSerice("https://127.0.0.1:8005/", "RDSERVICE").pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    checkRdServiceInfo() {
        //
        // 
        return this.customeHttpClient
            .GetRdSerice("https://127.0.0.1:8005/rd/info", "DEVICEINFO").pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    checkRdServiceCaptureFP(rebody: any) {
        //
        let wadh = btoa(shajs('sha256').update('2.5' + 'F' + 'Y' + 'N' + 'N').digest('hex'));
        rebody = '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="0" iCount="0" pCount="0" format="0" pidVer="2.0" wadh="' + wadh + '" timeout="10000" posh="UNKNOWN" env="P" />  </PidOptions>'

        // 
        return this.customeHttpClient
            .captureRdSerice("https://127.0.0.1:8005/", "CAPTURE", rebody).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    generateOtpForUser(citizenForgot: CitizenForgot) {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(citizenForgot));
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/generate_otp_for_user_login", encVal
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    verifyFirstTimeOpt(param: any) {
        //
        //  
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/update_dept_user_verify_status", param
            ).pipe(
                map((result: any) => {

                    //  
                    return result;

                })
            );
    }

    verifyFirstTimeKyc(param: any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/update_dept_user_verify_status_email", param
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }


    //    printSPLicense(eSignaturebean:eSignatureBean)
    //    {
    //          ;
    //        return this.customeHttpClient
    //         .PostApiToken<ResultModel<any>>(
    //             this.settings.igrs_gateway_url + "department/eSignature",
    //             eSignaturebean
    //         ).pipe(
    //               map((result:any)=>{
    //                 if (result){
    //                         return result;
    //                 }
    //               },
    //               (error:any)=>{
    //                   
    //                   return error;
    //               }
    //               )
    //         );
    //     }

    printEsignForm(eSignaturebean: eSignatureBean) {
        ;
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/partyesign/eSignatureForParty",
                eSignaturebean
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }



    esignRedirect(msg: string) {
        ;
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                "/asm_audit/eSignature/eSignature/Index", msg
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    get_departmentGLDraftSuggestion(param: any) {
        //
        //
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/GL/search_gl_draft_suggestion", param
            ).pipe(
                map((result: any) => {

                    //  

                    return result.responseData;


                })
            );
    }


    printSPLicense(eSignaturebean: eSignatureBean) {
        ;
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/eSignature",
                eSignaturebean
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    downloadSignedSpLicense(spLicence: string) {
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/serviceprovider/downloadSPLicensePDF/" + spLicence,
                spLicence
                // filename
            ).pipe(
                map((result: any) => {
                    if (result) {
                        // var string1 = JSON.stringify(result);
                        //   var data:any;
                        //  filename: 'yourFileName.pdf',
                        //   data= result.blob()
                        return result;
                    }
                },
                    (error: any) => {
                        alert("not found");
                        return error;
                    }
                )
            );
    }

    downloadDscSoft() {
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/downloadscsoft"
            ).pipe(
                map((result: any) => {

                    return result.responseData;

                })
            );
    }

    downloadDscUserManual() {
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/downloadDscUserManual"
            ).pipe(
                map((result: any) => {

                    return result.responseData;

                })
            );
    }
    // getAllWalletTypeMaster() {
    //     //
    //     return this.http.get(`http://localhost:8082//common/master`);
    //     console.log("inside method");

    // }


    getAllWalletTypeMaster() {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_url + "department/master/allwalletmaster", {}
            ).pipe(
                map((result: any) => {

                    //     
                    return result;
                })
            );
    }

    updateWalletTypeByID(updateWalletType: any) {

        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_services_url + 'department/master/updatemaster', updateWalletType
            ).pipe(
                map((result: any) => {
                    return result;
                    console.log(result);

                })
            )
    }
    //   getWalletTypeById(id:any)
    //   {
    //     return this.http.get(`http://localhost:8081/sampadaGateway/common/master/${id}`);
    //     console.log();

    //   }
    getWalletTypeById(id: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_url + `department/master/master/${id}`, {}
            ).pipe(
                map((result: any) => {

                    //     
                    return result.responseData;
                })
            );
    }


    addNewWalletType(addWalletType: any) {

        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_services_url + "department/master/addNewmaster", addWalletType
            ).pipe(
                map((result: any) => {
                    return result;
                    console.log(result);

                })
            )
    }

    //   getWalletTypeByStatus(status:any)
    //   {
    //     return this.http.get(`http://localhost:8081/sampadaGateway/common/getDataByStatus/${status}`);
    //     console.log();

    //   }


    //   getWalletTypeByStatus(status:any) 
    // {

    //     return this.customeHttpClient
    //         .GetApi<ResultModel<any>>(
    //             this.settings.igrs_services_url+ `department/getDataByStatus/${status}`,{}
    //         ).pipe(
    //             map((result: any) => {

    //                 //     
    //                 return result.responseData;
    //             })
    //         );
    // }



    getwalletstatusdetails(param: any) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_services_url + "department/master/getwalletstatusdetails", param
            ).pipe(
                map((result: any) => {

                    return result;
                },
                    (error: any) => {

                        return error;
                    }

                )
            )

    }


    // fetching all ModuleType from MST_STATUS
    getPurpose() {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_url + "department/master/getPurpose", {}
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }
    //   updateWalletTypeByID(updateWalletType:any){
    //     return this.http.put('http://localhost:8081/sampadaGateway/common/master',updateWalletType);
    //     console.log();
    //   }


    // getallstatus() {
    //     return this.customeHttpClient
    //       .GetApi<ResultModel<any>>(
    //         this.settings.igrs_services_url +"department/master/getallstatus",{}
    //       ).pipe(
    //         map((result: any) => {

    //           return result;
    //         },
    //         (error:any)=>{

    //             return error;
    //         }

    //         )
    //       )
    //   }

    getStatus() {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_url + 'department/master/getwalletStatus', {}
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    getSRUserList(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_sr_list", param
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
                })
            );
    }

    getPendingApplicationListOnMakerOrChecker(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_url + "department/ereg/getAllPendingApplicationOnMakerOrChecker", param
            ).pipe(
                map((result: any) => {

                    return result;
                })
            );
    }
    getApplicationListOnMakerOrChecker(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_url + "department/ereg/getAllApplicationOnMakerOrChecker", param
            ).pipe(
                map((result: any) => {

                    return result;
                })
            );
    }
    getAllDeedCategory() {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_url + "department/ereg/getAllDeedCategory", {}
            ).pipe(
                map((result: any) => {

                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result;
                    }


                })
            );
    }
    getDocumentTypeByDeedCat(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_url + "department/ereg/getAllDeedCategory", param
            ).pipe(
                map((result: any) => {

                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result;
                    }


                })
            );
    }
    saveTempEregEstamp(igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean) {
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                this.settings.igrs_services_url + "common/ereg/exportJspReportForEregDeed",
                igrsEregTxnDetlsBean
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    /*saveDeedEregEstamp(igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean) {
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                this.settings.igrs_services_url + "common/ereg/saveJspReportForEregDeed",
                igrsEregTxnDetlsBean
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }*/


    //  saveCyberTehsilFormPdf(igrsEregTxnDetlsBean:IgrsEregTxnDetlsBean)
    //  {
    //     return this.customeHttpClient
    //      .Post<ResultModel<any>>(
    //          this.settings.igrs_services_url + "common/ereg/saveCyberTehsilFormPdf",
    //          igrsEregTxnDetlsBean
    //      ).pipe(
    //            map((result:any)=>{
    //              if (result){
    //                      return result;
    //              }
    //            },
    //            (error:any)=>{

    //                return error;
    //            }
    //            )
    //      );
    //  }

    getSrOfficeMappingList(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_sr_office_mapping_list", param
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
                })
            );
    }


    getAllApplicationOnHoldChecker(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_url + "department/ereg/getAllApplicationOnHoldChecker", param
            ).pipe(
                map((result: any) => {

                    return result;
                })
            );
    }

    getAllHoldReturnRefuseApplicationOnHoldChecker(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_url + "department/ereg/getAllHoldReturnRefuseOnMakerOrChecker", param
            ).pipe(
                map((result: any) => {

                    return result;
                })
            );
    }

    getLegalEntityRequestList(param: any) {
        console.log("--------------------------------- ", param);
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/getLegalEntitiesList", param
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    updateLegalEntityStatus(param: any) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/updateLegalEntityStatus", param
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    getLegalEntityDetailsById(param: any) {

        let param1 = {
            "userid": param
        }
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/getLegalEntityDetailsById", param1
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    checkAadhaarAlreadyExist(kycUniqueToken: any): Observable<any> {
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/department_user_aadhaar_exists",
            kycUniqueToken
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    checkPanAlreadyExist(panNumber: string, mobileNo:string): Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/department_user_pan_exists?pan=" + panNumber +'&mobileNo=' + mobileNo
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    // Method For Update SP user Status
    private method: any;
    private downLoadMethod: any;
    setUpdateStatusSpMethod(method: any): void {
        this.method = method;
    }
    getUpdateStatusSpMethod(): any {
        return this.method;
    }

    // End Method For Update SP user Status 
    setdownloadSpLicence(downLoadMethod: any): void {
        this.downLoadMethod = downLoadMethod;
    }
    getdownloadSpLicence(): any {
        return this.downLoadMethod;
    }

    saveuserLocation(param: any) {
        return this.customeHttpClient.PostApiToken<ResultModel<any>>(
            this.settings.igrs_gateway_url + "department/updateGeoLocation", param
        ).pipe(
            map((result: any) => {
                if (result) {
                    return result;
                }
            },
                (error: any) => {
                    return error;
                }
            )
        )
    }

    getDesignation() {
        return this.http.get<ResultModel<any>>(
            "assets/data/designations.json",
        ).pipe(
            map((result: any) => {

                if (result) {
                    return result;
                } else {
                    return result;
                }

            })
        );


    }
  
    getDetailsForSroDashBoard() {

        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_services_url + "department/ereg/getDetailsForSroDashBoard",{}
            ).pipe(
                map((result: any) => {

                    return result;
                })
            );
    }   
    getAllCompletedRegistryOnChecker(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_url + "department/ereg/getAllCompletedRegistryOnChecker", param
            ).pipe(
                map((result: any) => {

                    return result;
                })
            );
    }
    getRefundRequestList(param:any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/getPaymentRefundList", param
            ).pipe(
                map((result: any) => {
    
                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
    
                })
            );
    
    }

    getTransactionInfoForRefund(encData:any,param:any) {

        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/getModuleDetailsForRefund/"+encData, param
            ).pipe(
                map((result: any) => {
    
                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
    
                })
            );
    
    }

    getRefundDetailsForSanctionOrder(encData:any,param:any) {

        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/getDetailsForSanctionorder/"+encData, param
            ).pipe(
                map((result: any) => {
    
                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
    
                })
            );
    
    }

    submitRequestForRefund(refundRequestBean: PaymentRefundModel) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/update_refund_status_dept", refundRequestBean
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

   

    getBase64OfFileRefundDept(param: any) {
        return this.customeHttpClient
        .GetApi<ResultModel<any>>(
            this.settings.igrs_gateway_url + "department/get_base64_from_file_refund", param
        ).pipe(
            map((result: any) => {

                // 
                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    generateApprovedorder1(igrsRefundDataForRegFeesBean:IgrsRefundDataForRegFeesBean) {
        return this.customeHttpClient
          .PostApiToken<ResultModel<any>>(
            this.settings.igrs_services_jasper_url + "department/jasper/refund/exportApprovedRefundOrder1",
            igrsRefundDataForRegFeesBean
          ).pipe(
            map((result: any) => {
              return result;
            },
              (error: any) => {
                console.log("error:-", error);
                return error;
              }
            )
          );
      }
    generateApprovedorder(refundJasperBean:RefundJasperBean) {
        return this.customeHttpClient
          .PostApiToken<ResultModel<any>>(
            this.settings.igrs_services_jasper_url + "department/jasper/refund/exportApprovedRefundOrder",
            refundJasperBean
          ).pipe(
            map((result: any) => {
              return result;
            },
              (error: any) => {
                console.log("error:-", error);
                return error;
              }
            )
          );
      }

      getRefundDetails(param: any) {
        return this.customeHttpClient
        .GetApi<ResultModel<any>>(
            this.settings.igrs_gateway_url + "department/getRefundRequestDetailsById", param
        ).pipe(
            map((result: any) => {

                // 
                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    getRefundOrder(refundRequestBean: PaymentRefundModel) {
        return this.customeHttpClient
        .PostApiToken<ResultModel<any>>(
            this.settings.igrs_gateway_url + "department/get_refund_sanction_order_dept", refundRequestBean
        ).pipe(
            map((result: any) => {

                // 
                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    sealSanctionOrder(refundRequestBean: PaymentRefundModel) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/proceedForRefundApproval", refundRequestBean
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    timer(seconds: number): Observable<string> {
        return new Observable<string>((observer) => {
          this.seconds = seconds;
          let textSec: any = '0';
          let statSec: number = seconds;
          const prefix = '0';
      
          const timerInterval = setInterval(() => {
            this.seconds--;
            if (statSec !== 0) {
              statSec--;
            } else {
              statSec = 59;
            }
      
            if (statSec < 10) {
              textSec = '0' + statSec;
            } else {
              textSec = statSec;
            }
      
            const display = `${prefix}${Math.floor(this.seconds / 60)}:${textSec}`;
            observer.next(display);
      
            if (this.seconds === 0) {
              console.log('finished');
              clearInterval(timerInterval);
              observer.complete();
            }
          }, 1000);
      
          // Cleanup function to clear interval if unsubscribed
          return () => clearInterval(timerInterval);
        });
      }
}

