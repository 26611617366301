import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';

@Component({
  selector: 'app-feedback-question',
  templateUrl: './feedback-question.component.html',
  styleUrls: ['./feedback-question.component.scss']
})
export class FeedbackQuestionComponent implements OnInit,OnChanges  {
  lan: number;
  @Input() question: {serialNumber:number,id: number, quesEn: string, quesHi: string };
  @Input() stars: number[]=[];
  @Input() selectedValue: number;
  @Output() valueChange = new EventEmitter<{serialNumber:number, questionNo: number; selectedValue: number }>();
   constructor(
    private translate: TranslateService,
    private translateService: TranslateHEService,
    private ngxService: NgxUiLoaderService
  ) {
    try {
            let selectedLanguage = sessionStorage.getItem("selectedLanguage");
      if (
        sessionStorage.getItem("selectedLanguage") == null ||
        sessionStorage.getItem("selectedLanguage") == "" ||
        sessionStorage.getItem("selectedLanguage") == undefined
      ) {
        this.lan = 1;
        selectedLanguage = "hi";
      }
      this.translateService.currentApprovalStageMessage.subscribe((msg) => {
        this.lan = msg;
        this.translate.use(msg == 1 ? "hi" : "en");
      });
    } catch (err) {
      console.log(err);
    }
  }
  ngOnInit(): void {
  }
  countStar(star: number) {
    this.selectedValue = star;
    this.valueChange.emit({serialNumber:this.question.serialNumber, questionNo: this.question.id, selectedValue: this.selectedValue });
     }
  resetStars(): void {
    this.selectedValue = 0; 
  }
  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['selectedValue'] && changes['selectedValue'].currentValue === 0) {
    //   this.resetStars();
    // }
  }

}
